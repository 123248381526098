import * as React from 'react'

import { i18n } from '../i18n'
import { PageTitle } from '../molecules/HeaderLayout/PageTitle'
import { AppNameScreen } from '../ui/AppNameScreen'

type TProps = NoChildren

export const NotFoundPage: React.FC<TProps> = () => (
  <AppNameScreen.Layout>
    <AppNameScreen.Headline textAlign={`center`} />
    <PageTitle title={i18n.t`Not found`} />
    <AppNameScreen.Message
      textAlign={`center`}
    >{i18n.t`There is nothing here, sorry!`}</AppNameScreen.Message>
  </AppNameScreen.Layout>
)
