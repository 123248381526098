import { getSpaceValue } from '../../../designSystem'
import { Colors } from '../../../styling'

export const MODAL_SPACES = {
  none: getSpaceValue('s0'),
  sm: getSpaceValue('s8'),
  md: getSpaceValue('s16'),
  lg: getSpaceValue('s24'),
}

export const MODAL_SEPARATOR_CONFIG = {
  color: Colors.grey.light, // TODO fg color
  size: '2px',
}
