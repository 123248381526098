import { SvgIconComponent } from '@material-ui/icons'
import * as React from 'react'
import styled from 'styled-components'

import { queries } from '../app/Responsive'
import { useFormGridCheck } from '../components/FormGrid'
import { petiteCaps } from '../helpers/styles/petiteCaps'
import { cn } from '../libs/classnames'
import { Colors, Spacing } from '../styling'
import { Icon } from './Icon'

// the 'first-input-center' option aligns the icon to the start,
// but also make it look like it was aligned to the vertical center of an input (by setting the height & inner align)
// the result is that the icon looks "centered" to the first input in the group of inputs it is paired with
type TAlign = 'center' | 'flex-start' | 'flex-end' | 'first-input-center'

type TStyledGridDescriptionProps = {
  labelMargin: string
}

type TStyledProps = {
  iconAlign: TAlign
  labelWidth: number
  labelMargin: string
  labelWidthUnits: string
}

type TProps = Children & {
  iconSize?: number
  iconAlign?: TAlign
  className?: string
  iconColor?: string
  labelWidth?: number
  labelMargin?: string
  icon?: SvgIconComponent
  labelWidthUnits?: string
  labelSmallCaps?: boolean
  label?: string | React.ReactNode
  customIconContent?: React.ReactNode
  descriptionClassName?: string
  title?: string
}

const StyledIconLine = styled.div<TStyledProps>`
  display: flex;
  align-items: center;

  .description {
    margin: ${({ labelMargin }) => labelMargin};
    min-width: ${({ labelWidth, labelWidthUnits }) =>
      `${labelWidth}${labelWidthUnits}`};
    flex: 0 0 auto;
    text-align: right;
    align-self: ${({ iconAlign }) => iconAlign};

    &.input-height {
      height: ${Spacing.formElementHeight};
      display: flex;
      align-items: center;
    }
  }

  .contents {
    margin-left: 0.5rem;
    flex: 1 1 auto;
  }

  .label {
    display: inline-block;

    &.smallCaps {
      ${petiteCaps};
    }
  }
`

const StyledGridDescription = styled.div<TStyledGridDescriptionProps>`
  grid-column: desc;
  margin: ${({ labelMargin }) => labelMargin};

  display: grid;
  justify-items: right;
  align-items: center;

  div.label {
    display: inline-block;

    &.smallCaps {
      ${petiteCaps};
    }
  }
  @media ${queries.mobile} {
    grid-column: content;
    justify-self: start;
    margin: ${({ labelMargin }) => labelMargin?.replace('auto', '0.7rem')};
  }
`

const StyledGridContent = styled.div<TProps>`
  grid-column: content;
  margin-left: 0.5rem;
`

export const IconLine: React.FC<TProps> = ({
  icon,
  label,
  children,
  iconSize,
  title,
  className,
  labelWidth = 0,
  labelMargin = '0px',
  iconAlign = 'center',
  descriptionClassName,
  labelSmallCaps = true,
  customIconContent = null,
  iconColor = Colors.iconColor,
  labelWidthUnits = Spacing.DefaultSizeUnit,
}) => {
  const isInFormGrid = useFormGridCheck()

  const actualIconAlign =
    iconAlign === 'first-input-center' ? 'flex-start' : iconAlign

  const descriptionPart = (
    <>
      {customIconContent}
      {icon && <Icon icon={icon} color={iconColor} fontSize={iconSize} />}
      {label ? (
        <div className={cn('label', { smallCaps: labelSmallCaps })}>
          {label}
        </div>
      ) : null}
    </>
  )

  if (isInFormGrid) {
    return (
      <>
        <StyledGridDescription labelMargin={labelMargin}>
          {descriptionPart}
        </StyledGridDescription>
        <StyledGridContent>{children}</StyledGridContent>
      </>
    )
  }

  return (
    <StyledIconLine
      className={cn(className)}
      labelWidth={labelWidth}
      labelMargin={labelMargin}
      iconAlign={actualIconAlign}
      labelWidthUnits={labelWidthUnits}
      title={title}
    >
      <div
        className={cn(descriptionClassName, 'description', {
          'input-height': iconAlign === 'first-input-center',
        })}
      >
        {descriptionPart}
      </div>
      <div className="contents">{children}</div>
    </StyledIconLine>
  )
}
