import * as React from 'react'

import { useActiveExternalDeliveriesModel } from '../models/useActiveExternalDeliveriesModel'

/**
 * returns the external delivery for the given order id
 */
export const useGetOrderExternalDelivery = (orderId: ID) => {
  const {
    activeExternalDeliveries, //
    getOrderExternalDelivery,
  } = useActiveExternalDeliveriesModel()

  // ! this is a hack to force any component reading this to rerender when the external delivery array is updated
  // because `getOrderExternalDelivery` itself depends on a Map that does not change its reference
  // -> this should trigger re-read each time the array of external deliveries changes
  // and current state from the Map is returned
  React.useEffect(() => {}, [activeExternalDeliveries])

  return getOrderExternalDelivery(orderId)
}
