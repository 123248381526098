import { TOrderStateCategoryEnum } from '../../graph/generated'

type TOrder = {
  orderStateCategory: TOrderStateCategoryEnum
}

/**
 * returns true if the order is in canceled state,
 * does not consider cancel requests
 */
export const isCanceledOrder = ({ orderStateCategory }: TOrder) => {
  return orderStateCategory === `CANCEL`
}
