import * as React from 'react'

import {
  TMCreateExternalDeliveryVariables,
  useMCreateExternalDelivery,
} from '../../../../graph/generated'
import { Modal } from '../../../../ui/Modal'

type TVariables = TMCreateExternalDeliveryVariables

type TProps = NoChildren &
  TVariables & {
    onClose: () => void
    confirmDisabled?: boolean
  }

/**
 * action buttons for external delivery creation modal,
 * handles modal submit as well
 */
export const CreateExternalDeliveryActions: React.FC<TProps> = ({
  orderId,
  onClose,
  deliveryProvider,
  closestPickupTime,
  confirmDisabled = false,
}) => {
  const [mCreateExternalDelivery, { loading }] = useMCreateExternalDelivery()

  const createDelivery = React.useCallback(async () => {
    try {
      const { data, errors } = await mCreateExternalDelivery({
        variables: { orderId, closestPickupTime, deliveryProvider },
      })

      if (errors || !data?.isSuccess) {
        return
      }

      onClose()
    } catch {}
  }, [
    orderId,
    onClose,
    deliveryProvider,
    closestPickupTime,
    mCreateExternalDelivery,
  ])

  return (
    <Modal.Footer>
      <Modal.Actions>
        <Modal.Button type={`cancel`} onClick={onClose} disabled={loading} />
        <Modal.Button
          type={`confirm`}
          onClick={createDelivery}
          disabled={confirmDisabled || loading}
        />
      </Modal.Actions>
    </Modal.Footer>
  )
}
