import { Instance } from 'mobx-state-tree'

import { BaseModel } from '../../../../models/BaseModel'
import { RM } from '../../../../tools/ramda'
import {
  TPrintableObject,
  TPrinterQueueLink,
  TPrinterType,
  TPrintQueue,
  TPrintQueueWithPrinters,
  TPrintTemplate,
  TPrintTrigger,
  TRegisteredPrinter,
} from '../typings/printSettings.types'
import { isKiosk } from '../utils/printSettings.utils'

type TPrintQueueConfigs = {
  printTriggers: RoA<TPrintTrigger>
  printTemplates: RoA<TPrintTemplate>
  printableObjects: RoA<TPrintableObject>
}

type TPrinterConfigs = {
  printerTypes: RoA<TPrinterType>
}

type TVolatileProps = {
  printerConfigs: Nullable<TPrinterConfigs>
  printQueueConfigs: Nullable<TPrintQueueConfigs>
  printQueues: Nullable<RoA<TPrintQueueWithPrinters>>
  registeredPrinters: Nullable<RoA<TRegisteredPrinter>>
}

export const PrintSettingsModel = BaseModel.named(`PrintSettings`)
  .volatile<TVolatileProps>(() => ({
    printQueues: null,
    printerConfigs: null,
    printQueueConfigs: null,
    registeredPrinters: null,
  }))

  .views(self => ({
    get registeredKiosks() {
      return self.registeredPrinters?.filter(isKiosk) ?? null
    },

    get registeredThermalPrinters() {
      return self.registeredPrinters
        ? RM.reject(isKiosk)([...self.registeredPrinters])
        : null
    },
  }))

  // print queues
  .actions(self => ({
    // data needed for print queue creation/editing
    setPrintQueueConfigs(printQueueConfigs: TPrintQueueConfigs) {
      self.printQueueConfigs = printQueueConfigs
    },

    // save current print queues from the Q on page load into the app state
    setPrintQueues(printQueues: RoA<TPrintQueueWithPrinters>) {
      self.printQueues = printQueues
    },

    // after successfully creating the print queue on BE, add it to the local state as well (so it would show even before page refresh)
    addPrintQueue(createdPrintQueue: TPrintQueue) {
      if (!self.printQueues) {
        return
      }

      self.printQueues = [
        ...self.printQueues,
        { ...createdPrintQueue, printerBindings: [] },
      ]
    },

    // after successfully editing the print queue on BE, replace it in the local state as well
    replacePrintQueue(updatedPrintQueue: TPrintQueue) {
      if (!self.printQueues) {
        return
      }

      self.printQueues = self.printQueues.map(printQueue => {
        if (printQueue.id === updatedPrintQueue.id) {
          return {
            ...updatedPrintQueue,
            printerBindings: printQueue.printerBindings,
          }
        }

        return printQueue
      })
    },

    // after successfully deleting the print queue on BE, remove it from the local state as well
    removePrintQueue(removedPrintQueueId: ID) {
      if (!self.printQueues) {
        return
      }

      self.printQueues = self.printQueues.filter(printQueue => {
        return printQueue.id !== removedPrintQueueId
      })
    },
  }))

  // printer-queue bindings/links
  .actions(self => ({
    // after successfully creating the printer-queue link on BE, add it to the local state as well
    addPrinterQueueBinding(queueId: ID, newBinding: TPrinterQueueLink) {
      if (!self.printQueues) {
        return
      }

      self.printQueues = self.printQueues.map(printQueue => {
        if (printQueue.id === queueId) {
          return {
            ...printQueue,
            printerBindings: [...printQueue.printerBindings, newBinding],
          }
        }

        return printQueue
      })
    },

    // after successfully deleting the printer-queue link on BE, remove it from the local state as well
    removePrinterQueueBinding(queueId: ID, removedBindingId: ID) {
      if (!self.printQueues) {
        return
      }

      self.printQueues = self.printQueues.map(printQueue => {
        if (printQueue.id === queueId) {
          const restOfBindings = printQueue.printerBindings.filter(binding => {
            return binding.id !== removedBindingId
          })

          return {
            ...printQueue,
            printerBindings: restOfBindings,
          }
        }

        return printQueue
      })
    },
  }))

  // printers
  .actions(self => ({
    // data needed for printer creation/editing
    setPrinterConfigs(printerConfigs: TPrinterConfigs) {
      self.printerConfigs = printerConfigs
    },

    // save current print queues from the Q on page load into the app state
    setRegisteredPrinters(registeredPrinters: RoA<TRegisteredPrinter>) {
      self.registeredPrinters = registeredPrinters
    },

    // after successfully creating the printer on BE, add it to the local state as well
    addPrinter(createdPrinter: TRegisteredPrinter) {
      if (!self.registeredPrinters) {
        return
      }

      self.registeredPrinters = [...self.registeredPrinters, createdPrinter]
    },

    // after successfully editing the printer on BE, replace it in the local state as well
    replacePrinter(updatedPrinter: TRegisteredPrinter) {
      if (!self.registeredPrinters) {
        return
      }

      self.registeredPrinters = self.registeredPrinters.map(printer => {
        if (printer.id === updatedPrinter.id) {
          return updatedPrinter
        }

        return printer
      })
    },

    // after successfully deleting the printer on BE, remove it from the local state as well
    removePrinter(removedPrinterId: ID) {
      if (!self.registeredPrinters) {
        return
      }

      self.registeredPrinters = self.registeredPrinters.filter(printer => {
        return printer.id !== removedPrinterId
      })
    },
  }))

export interface TPrintSettingsModel
  extends Instance<typeof PrintSettingsModel> {}
