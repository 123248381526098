import { Trans } from '@lingui/macro'
import * as React from 'react'
import styled from 'styled-components'

import { GridBox } from '../atoms/Box/Box'
import { Icon } from '../atoms/Icon'
import { getSpaceValue } from '../designSystem'
import { i18n } from '../i18n'
import { PageTitle } from '../molecules/HeaderLayout/PageTitle'
import { SmallTouchButton } from '../molecules/TouchButton'
import { Colors } from '../styling'
import { AppNameScreen } from '../ui/AppNameScreen'
import { IconAppError } from '../ui/Icons'

type TProps = NoChildren & {
  onFeedback(): void
}

const StyledButton = styled(SmallTouchButton)`
  padding: 0 ${getSpaceValue(`s36`)};
  margin: 0 auto;
`

export const ErrorPage: React.FC<TProps> = ({ onFeedback }) => (
  <AppNameScreen.Layout>
    <PageTitle title={i18n.t`Application error`} />
    <AppNameScreen.Headline textAlign={`center`} />

    <GridBox gridGap={getSpaceValue(`s32`)}>
      <AppNameScreen.Message textAlign={`center`}>
        <Trans>
          We are sorry, but there is an unexpected problem in the app. Our team
          was notified and will fix this soon.
        </Trans>
      </AppNameScreen.Message>

      <Icon icon={IconAppError} fontSize={7} color={Colors.red.dark} />

      <GridBox gridGap={getSpaceValue(`s8`)}>
        <StyledButton
          width={`fit-content`}
          color={Colors.speedlo.main}
          onClick={() => window.location.reload()}
        >
          <Trans>Restart application</Trans>
        </StyledButton>
        <StyledButton
          onClick={onFeedback}
          color={Colors.appBg}
          width={`fit-content`}
          textColor={Colors.grey.lightish}
        >
          <Trans>Send feedback</Trans>
        </StyledButton>
      </GridBox>
    </GridBox>
  </AppNameScreen.Layout>
)
