import { getRadiusValue, TRadius, TRadiusSemantic } from './radius'

/**
 * props or options related to element radiuses,
 * can be made into accepting only semantic keys with
 * `TRadiusProps<TRadiusSemantic>`
 */
export type TRadiusProps<
  TRadiusKey extends TRadius | TRadiusSemantic = TRadius,
> = {
  // general value
  radius?: TRadiusKey
  // side values
  radiusTop?: TRadiusKey
  radiusRight?: TRadiusKey
  radiusBottom?: TRadiusKey
  radiusLeft?: TRadiusKey
  // corner values
  radiusTopRight?: TRadiusKey
  radiusTopLeft?: TRadiusKey
  radiusBottomRight?: TRadiusKey
  radiusBottomLeft?: TRadiusKey
}

/**
 * returns a value for top right radius of an element
 * based on provided radius props,
 * prefers `corner` > `vertical side` > `horizontal side` > `general` value
 */
export const getRadiusTopRight = ({
  radius,
  radiusTop,
  radiusRight,
  radiusTopRight,
}: TRadiusProps) => {
  const radiusTopRightKey = radiusTopRight ?? radiusTop ?? radiusRight ?? radius
  return radiusTopRightKey ? getRadiusValue(radiusTopRightKey) : undefined
}

/**
 * returns a value for top left radius of an element
 * based on provided radius props,
 * prefers `corner` > `vertical side` > `horizontal side` > `general` value
 */
export const getRadiusTopLeft = ({
  radius,
  radiusTop,
  radiusLeft,
  radiusTopLeft,
}: TRadiusProps) => {
  const radiusTopLeftKey = radiusTopLeft ?? radiusTop ?? radiusLeft ?? radius
  return radiusTopLeftKey ? getRadiusValue(radiusTopLeftKey) : undefined
}

/**
 * returns a value for bottom right radius of an element
 * based on provided radius props,
 * prefers `corner` > `vertical side` > `horizontal side` > `general` value
 */
export const getRadiusBottomRight = ({
  radius,
  radiusRight,
  radiusBottom,
  radiusBottomRight,
}: TRadiusProps) => {
  const radiusBottomRightKey =
    radiusBottomRight ?? radiusBottom ?? radiusRight ?? radius
  return radiusBottomRightKey ? getRadiusValue(radiusBottomRightKey) : undefined
}

/**
 * returns a value for bottom left radius of an element
 * based on provided radius props,
 * prefers `corner` > `vertical side` > `horizontal side` > `general` value
 */
export const getRadiusBottomLeft = ({
  radius,
  radiusLeft,
  radiusBottom,
  radiusBottomLeft,
}: TRadiusProps) => {
  const radiusBottomLeftKey =
    radiusBottomLeft ?? radiusBottom ?? radiusLeft ?? radius
  return radiusBottomLeftKey ? getRadiusValue(radiusBottomLeftKey) : undefined
}

/**
 * returns values of radius for all corners of an element
 * based on provided radius props,
 * prefers `corner` > `vertical side` > `horizontal side` > `general` value for each side
 */
export const getRadiusCornerValues = (radiusProps: TRadiusProps) => {
  const borderTopRightRadius = getRadiusTopRight(radiusProps)
  const borderTopLeftRadius = getRadiusTopLeft(radiusProps)
  const borderBottomRightRadius = getRadiusBottomRight(radiusProps)
  const borderBottomLeftRadius = getRadiusBottomLeft(radiusProps)

  return {
    borderTopRightRadius,
    borderTopLeftRadius,
    borderBottomRightRadius,
    borderBottomLeftRadius,
  }
}
