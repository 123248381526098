import { useRoot } from '-app/root'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components/macro'

import { IconLine } from '../../../atoms/IconLine'
import {
  FONT_WEIGHTS,
  getRadiusValue,
  getShadow,
  getSpaceValue,
} from '../../../designSystem'
import { formatTime } from '../../../helpers/formats'
import { getOrderNumber } from '../../../helpers/order/getOrderNumber'
import { i18n } from '../../../i18n'
import { useOrderListModel } from '../../../screens/order/hooks/useOrderListModel'
import { Colors } from '../../../styling'
import {
  IconBranch,
  IconExternalDelivery,
  IconExternalDeliveryPickupTime,
} from '../../../ui/Icons'
import { NoWrap } from '../../../ui/Typography'
import { useOrderHandoverTime } from '../../handoverTime'
import { TActiveExternalDelivery } from '../types/externalDelivery.types'
import { getExternalDeliveryStatusCategory } from '../utils/getExternalDeliveryStatusCategory'
import { AcknowledgeExternalDeliveryFailButton } from './AcknowledgeExternalDeliveryFailButton'
import { ExternalDeliveryStatus } from './ExternalDeliveryStatus'

type TStyledCardProps = {
  borderColor?: string
}

type TStyledTimeProps = {
  smallFont: boolean
  bgColor: ReturnType<typeof useOrderHandoverTime>['bgColor']
  textColor: ReturnType<typeof useOrderHandoverTime>['textColor']
}

type TProps = NoChildren & {
  delivery: TActiveExternalDelivery
}

const BADGE_SIZE = 1.2

const StyledCard = styled.div<TStyledCardProps>`
  --padding: ${getSpaceValue(`s8`)};
  --border-width: 3px;

  background: ${Colors.grey.lightest};
  border-radius: ${getRadiusValue(`r4`)};
  box-shadow: ${getShadow(`lightSharp`)};

  display: grid;
  gap: ${getSpaceValue(`s4`)};
  width: fit-content;
  /* make the border inside the card so it’s always the same size & without transparent border */
  padding: ${({ borderColor }) =>
    borderColor
      ? `calc(var(--padding) - var(--border-width))`
      : `var(--padding)`};

  border: ${({ borderColor }) =>
    borderColor ? `var(--border-width) solid ${borderColor}` : `none`};
`

const StyledHandoverTime = styled.div<TStyledTimeProps>`
  --box-spacing: 1px;
  --dimensions: calc(${BADGE_SIZE}rem + 2 * var(--box-spacing));

  display: grid;
  place-items: center;

  background: ${({ bgColor }) => bgColor};
  color: ${({ textColor }) => textColor};

  width: var(--dimensions);
  height: var(--dimensions);
  border-radius: 50%;
  line-height: 1;
  font-size: ${({ smallFont }) => (smallFont ? `0.625rem` : `0.75rem`)};
  overflow: hidden;
  /* get to similar size as other icons */
  margin: 1.5px;
`

const StyledOrderNumber = styled.div`
  cursor: pointer;
  font-weight: ${FONT_WEIGHTS.boldSemi};
  text-decoration: underline;
`

const StyledStatusLine = styled.div`
  display: flex;
  align-items: center;
  gap: ${getSpaceValue(`s8`)};
  margin-top: ${getSpaceValue(`s4`)};
`

const ICON_COLOR = Colors.grey.darkish

/**
 * Displays a card for an active external delivery
 * rendered in a list.
 */
const ActiveExternalDeliveryCardComponent: React.FC<TProps> = ({
  delivery,
}) => {
  const { user } = useRoot()
  const { focusOrderDetail } = useOrderListModel()

  const { order, provider, status } = delivery
  const handoverTime = useOrderHandoverTime({ order, iconSize: BADGE_SIZE })

  // when the delivery is in certain failed states, it requires user action (acknowledgement)
  const statusCategory = getExternalDeliveryStatusCategory(delivery)
  const requiresAttention = statusCategory === 'FAILED'
  const pickupTime =
    statusCategory !== 'FAILED' ? order.driverWillPickupAt : null

  return (
    <StyledCard
      key={delivery.id}
      borderColor={requiresAttention ? Colors.red.main : undefined}
    >
      <IconLine
        customIconContent={
          <StyledHandoverTime
            bgColor={handoverTime.bgColor}
            textColor={handoverTime.textColor}
            smallFont={handoverTime.outputShortLength > 2}
          >
            {handoverTime.outputShort}
          </StyledHandoverTime>
        }
      >
        <StyledOrderNumber onClick={() => focusOrderDetail(order.id)}>
          <NoWrap># {getOrderNumber(order)}</NoWrap>
        </StyledOrderNumber>
      </IconLine>

      {!user.hasSingleBranch && (
        <IconLine icon={IconBranch} iconColor={ICON_COLOR}>
          <NoWrap>{order.branch.name}</NoWrap>
        </IconLine>
      )}

      <IconLine icon={IconExternalDelivery} iconColor={ICON_COLOR}>
        <NoWrap>
          {provider.label} [# {delivery.deliveryNumber}]
        </NoWrap>
      </IconLine>

      <IconLine icon={IconExternalDeliveryPickupTime} iconColor={ICON_COLOR}>
        <NoWrap>{getPickupTimeLabel(pickupTime)}</NoWrap>
      </IconLine>

      <StyledStatusLine>
        <ExternalDeliveryStatus status={status} />
        <AcknowledgeExternalDeliveryFailButton delivery={delivery} />
      </StyledStatusLine>
    </StyledCard>
  )
}

export const ActiveExternalDeliveryCard = observer(
  ActiveExternalDeliveryCardComponent,
)

// * HELPERS

const getPickupTimeLabel = (pickupTime: Nullable<Date>) => {
  return pickupTime ? `${i18n.t`Pickup at`} ${formatTime(pickupTime)}` : `-`
}
