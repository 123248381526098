import { computeDestinationPoint, getBounds } from 'geolib'

/* global google */

export const gpsToLatLng = (gps: GeoPoint): google.maps.LatLng =>
  new google.maps.LatLng(Number(gps.latitude), Number(gps.longitude))

export const gpsToLatLngLit = (gps: GeoPoint): google.maps.LatLngLiteral => ({
  lat: Number(gps.latitude),
  lng: Number(gps.longitude),
})

export const latLngToGps = (latLng: google.maps.LatLng): GeoPoint => ({
  latitude: Number(latLng.lat()),
  longitude: Number(latLng.lng()),
})

type TGeneratePolygonOptions = {
  center: GeoPoint
  pointsCount?: number
  metersRadius?: number
}

export const generatePolygon = ({
  center,
  pointsCount = 15,
  metersRadius = 1000,
}: TGeneratePolygonOptions) => {
  return new Array(pointsCount).fill(null).map((_, idx) => {
    const degree = (360 / pointsCount) * idx

    return computeDestinationPoint(center, metersRadius, degree)
  })
}

export const getBoundsOfPoints = (points: GeoPoint[]) => {
  const bounds = getBounds(points)

  if (!bounds) {
    return null
  }

  return new google.maps.LatLngBounds(
    {
      lat: bounds.minLat,
      lng: bounds.minLng,
    },
    {
      lat: bounds.maxLat,
      lng: bounds.maxLng,
    },
  )
}
