import { Sentry } from '@speedlo/sentry'

import { appConfig } from '../../../config'

type TOptions = {
  base64Html: string
  requiredImageWidth?: number
}

type TResponse =
  | {
      isSuccess: true
      base64Image: string
    }
  | {
      isSuccess: false
      errorMessage?: string
    }

// https://html2png.dev.speedlo.cloud/renderImage
// https://html2png.stage.speedlo.cloud/renderImage
// https://html2png.speedlo.cloud/renderImage
const convertorUrl = `${appConfig.getEndpointApp(`html2png`)}/renderImage`

// convert base64 encoded HTML to base64 encoded image using Kamil’s lambda function
export const convertHtmlToPng = async ({
  base64Html,
  requiredImageWidth = 400,
}: TOptions): Promise<TResponse> => {
  try {
    const response = await fetch(convertorUrl, {
      method: `POST`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'text/plain',
      },
      body: JSON.stringify({
        html: base64Html,
        width: requiredImageWidth,
      }),
    })

    const base64Image = await response.text()

    return { isSuccess: true, base64Image }
  } catch (error) {
    Sentry.captureException(error)

    return { isSuccess: false, errorMessage: error?.message ?? undefined }
  }
}
