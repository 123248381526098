import { startOfDay } from 'date-fns'
import { Instance } from 'mobx-state-tree'

import { TQCustomerHistory } from '../../../../graph/generated'
import { formatDate, formatPrice } from '../../../../helpers/formats'
import {
  getGroupItemsByHash,
  TGroupedItem,
} from '../../../../helpers/groupItems'
import { isCanceledOrder } from '../../../../helpers/order/isCanceledOrder'
import { isOnlineOrder } from '../../../../helpers/order/isOnlineOrder'
import { sortCollection } from '../../../../helpers/sort'
import { BaseModel } from '../../../../models/BaseModel'
import {
  TCustomerHistorySummary,
  TCustomerHistoryTimelineDay,
  TCustomerProfile,
  TPromoCodeInList,
  TPromoStatistics,
  TTimelineEvent,
  TTimelineOrder,
  TTimelineOrderEvent,
  TTimelinePromoAcquirement,
  TTimelinePromoApplication,
  TTimelinePromoExpiration,
} from '../typings/customerHistory.types'

type TDisplayHistoryOptions = {
  currency: string
  forMultipleBranches: boolean
  customerHistory: TQCustomerHistory['stats']['customerHistory']
}

type TVolatileProps = {
  timeline: Nullable<RoA<TCustomerHistoryTimelineDay>>
  forMultipleBranches: Nullable<boolean>
  promoCodes: Nullable<RoA<TPromoCodeInList>>
  customerProfile: Nullable<TCustomerProfile>
  historySummary: Nullable<TCustomerHistorySummary>
}

export const CustomerHistoryModel = BaseModel.named(`CustomerHistory`)
  .volatile<TVolatileProps>(() => ({
    timeline: null,
    promoCodes: null,
    historySummary: null,
    customerProfile: null,
    forMultipleBranches: null,
  }))

  .actions(self => ({
    displayCustomerHistory({
      currency,
      customerHistory,
      forMultipleBranches,
    }: TDisplayHistoryOptions) {
      self.forMultipleBranches = forMultipleBranches

      // prepare data for <<customer profile>> section
      const { phone, names, emails, orders, addresses, promoCodeStatistics } =
        customerHistory

      self.customerProfile = {
        phone,
        names,
        emails,
        addresses,
      }

      // compute data for <<summary>> section
      const onlineOrders = orders.filter(isOnlineOrder)
      const canceledOrders = orders.filter(isCanceledOrder)

      const totalOrderCount = orders.length
      const onlineOrderCount = onlineOrders.length
      const canceledOrderCount = canceledOrders.length

      const totalSum = sumOrdersAndFormat(orders, sumOrdersTotal, currency)
      const onlineSum = sumOrdersAndFormat(
        onlineOrders,
        sumOrdersTotal,
        currency,
      )
      const canceledSum = sumOrdersAndFormat(
        canceledOrders,
        sumOrdersTotal,
        currency,
      )

      const totalTipSum = sumOrdersAndFormat(orders, sumOrdersTip, currency)
      const onlineTipSum = sumOrdersAndFormat(
        onlineOrders,
        sumOrdersTip,
        currency,
      )

      const discountSum = sumOrdersAndFormat(
        orders,
        sumOrdersDiscounts,
        currency,
      )

      const vouchers = promoCodeStatistics.filter(isVoucher)
      const usedVouchers = promoCodeStatistics.filter(isUsedVoucher)

      const voucherGrantedSum = sumVouchersAndFormat(
        vouchers,
        sumVoucherValues,
        currency,
      )
      const voucherUsedSum = sumVouchersAndFormat(
        usedVouchers,
        sumVoucherValues,
        currency,
      )

      self.historySummary = {
        totalSum,
        onlineSum,
        discountSum,
        canceledSum,
        totalTipSum,
        onlineTipSum,
        voucherUsedSum,
        totalOrderCount,
        onlineOrderCount,
        voucherGrantedSum,
        canceledOrderCount,
      }

      // transform promo statistics into promo codes (used in <<promo codes>> section, and also in the <<customer timeline>>)
      const promoCodes = promoCodeStatistics.map(transformStatToPromoCode)

      self.promoCodes = sortCollection(promoCodes, sortCodesByDates)

      // prepare all the events for <<customer history>> section (based on users’ orders & promo code generated by these orders / used on them)
      const orderEvents = orders.map(orderToEvent)

      const promoAcquirements = promoCodes
        .filter(hasAcquirementDate)
        .map(promoCodeToTimelineAcquirement)

      const promoApplications = promoCodes
        .filter(wasApplied)
        .map(promoCodeToTimelineApplication)

      const promoExpirations = promoCodes
        .filter(hasExpiredAndNotApplied)
        .map(promoCodeToTimelineExpiration)

      const timelineEvents = [
        ...orderEvents,
        ...promoApplications,
        ...promoAcquirements,
        ...promoExpirations,
      ]

      // group the events by the date they appeared on
      const groupedEvents = groupTimelineEventsByDate(timelineEvents)
      const timelineDays = groupedEvents.map(groupedEventsToTimelineDays)
      const sortedTimeline = sortTimeline(timelineDays)

      self.timeline = sortedTimeline
    },
  }))

export interface TCustomerHistoryModel
  extends Instance<typeof CustomerHistoryModel> {}

// *** ––––––––––––––––
// *** HELPER FUNCTIONS
// *** ––––––––––––––––

// *** FILTER FUNCTIONS

const isVoucher = ({ promoCode }: TPromoStatistics) => {
  return promoCode.type.enum === `VOUCHER`
}

const isUsedVoucher = ({ promoCode, applicationOrder }: TPromoStatistics) => {
  return promoCode.type.enum === `VOUCHER` && applicationOrder !== null
}

// *** REDUCE FUNCTIONS (summarizing values in an array)

// TSumOrderFunction is a function used for adding some TTimelineOrder property values (totals, discounts, tips)
// it’s designed as a callback to the Array.reduce
type TSumOrderFunction = (acc: number, order: TTimelineOrder) => number

const sumOrdersTip: TSumOrderFunction = (acc, { tip }) => acc + tip.value
const sumOrdersTotal: TSumOrderFunction = (acc, { totalSum }) =>
  acc + totalSum.value
const sumOrdersDiscounts: TSumOrderFunction = (acc, { totalDiscount }) => {
  // discount values comes as negative – flipping it here to add a proper minus sign later in JSX
  return acc + -1 * totalDiscount.value
}

type TSumVoucherFunction = (acc: number, order: TPromoStatistics) => number

const sumVoucherValues: TSumVoucherFunction = (acc, { discount }) =>
  acc + (discount?.value ?? 0)

// this function uses the sumFunction as a callback when reducing (summarizing) the orders values
// then it also formats the result by adding the passed currency code
const sumOrdersAndFormat = (
  orders: RoA<TTimelineOrder>,
  sumFunction: TSumOrderFunction,
  currency: string,
) => {
  const sumValue = orders.reduce(sumFunction, 0)

  return formatPrice(sumValue, currency)
}

const sumVouchersAndFormat = (
  vouchers: RoA<TPromoStatistics>,
  sumFunction: TSumVoucherFunction,
  currency: string,
) => {
  const sumValue = vouchers.reduce(sumFunction, 0)

  return formatPrice(sumValue, currency)
}

// *** GROUP FUNCTIONS

// functions for grouping events by the date they occurred on
const getTimelineEventDate = (event: TTimelineEvent) => formatDate(event.time)
const groupTimelineEventsByDate = getGroupItemsByHash(getTimelineEventDate)

// *** MAP FUNCTiONS (transforming)

// dates are somehow broken when coming from BE (not really Dates, because they are transferred as a string I guess)
// creating new Date from it fixes that
const fixDate = (date: Nullable<Date>) => (date ? new Date(date) : null)

// map TPromoStatistics object (from GQL) to TPromoCodeInList object (used in <<promo codes>> and <<customer timeline>> sections)
const transformStatToPromoCode = ({
  discount,
  promoCode,
  acquiredBy,
  description,
  expirationDate,
  acquirementDate,
  acquirementOrder,
  applicationOrder,
}: TPromoStatistics): TPromoCodeInList => {
  const appliedBy =
    applicationOrder?.customer.phones &&
    applicationOrder.customer.phones.length > 0
      ? applicationOrder.customer.phones[0].phone
      : null

  return {
    appliedBy,
    acquiredBy,
    description,
    id: promoCode.id,
    code: promoCode.code,
    type: promoCode.type,
    boundToPhone: !promoCode.public,
    expirationDate: fixDate(expirationDate),
    acquirementDate: fixDate(acquirementDate),
    discount: discount?.formattedValue ?? null,
    isBrandPromo: Boolean(promoCode.company?.name),
    acquirementOrderId: acquirementOrder?.id ?? null,
    applicationOrderId: applicationOrder?.id ?? null,
    applicationDate: fixDate(applicationOrder?.deliveredAt ?? null),
    companyName:
      promoCode.companyBranch?.name ?? promoCode.company?.name ?? null,
  }
}

// map order to timeline order event
const orderToEvent = (order: TTimelineOrder): TTimelineOrderEvent => ({
  order,
  type: `order`,
  time: order.deliveredAt ?? order.acceptedAt ?? order.createdAt,
})

// map promo code to timeline promo acquirement event
const promoCodeToTimelineAcquirement = (
  promoCode: TPromoCodeInList,
): TTimelinePromoAcquirement => ({
  promoCode,
  type: `promoAcquirement`,
  time: new Date(promoCode.acquirementDate!),
})

// map promo code to timeline promo application event
const promoCodeToTimelineApplication = (
  promoCode: TPromoCodeInList,
): TTimelinePromoApplication => ({
  promoCode,
  type: `promoApplication`,
  time: new Date(promoCode.applicationDate!),
})

// map promo code to timeline promo expiration event
const promoCodeToTimelineExpiration = (
  promoCode: TPromoCodeInList,
): TTimelinePromoExpiration => ({
  promoCode,
  type: `promoExpiration`,
  time: new Date(promoCode.expirationDate!),
})

// map grouped timeline events (by date) to the timeline day object
const groupedEventsToTimelineDays = ({
  items,
  firstItem,
}: TGroupedItem<TTimelineEvent>): TCustomerHistoryTimelineDay => ({
  events: items,
  date: startOfDay(firstItem.time),
})

// *** FILTER FUNCTIONS

// function for filtering promo codes that was acquired (and their acquirement should be shown in <<customer timeline>> section)
const hasAcquirementDate = ({ acquirementDate }: TPromoCodeInList) => {
  return acquirementDate !== null
}

// function for filtering promo codes that was applied (and their application should be shown in <<customer timeline>> section)
const wasApplied = ({
  applicationDate,
  applicationOrderId,
}: TPromoCodeInList) => {
  return applicationDate !== null && applicationOrderId !== null
}

// function for filtering promo codes that has expired (and their expiration should be shown in <<customer timeline>> section)
const hasExpiredAndNotApplied = ({
  expirationDate,
  applicationOrderId,
}: TPromoCodeInList) => {
  return (
    expirationDate !== null &&
    applicationOrderId === null &&
    expirationDate <= new Date()
  )
}

// *** SORT FUNCTIONS

// sorting function used for <<promo codes>> section table
const sortCodesByDates = (codeA: TPromoCodeInList, codeB: TPromoCodeInList) => {
  const dateTimeA = codeA.acquirementDate ?? codeA.applicationDate
  const dateTimeB = codeB.acquirementDate ?? codeB.applicationDate

  if (!dateTimeA && !dateTimeB) {
    return 0
  }

  if (!dateTimeA) {
    return 1
  }

  if (!dateTimeB) {
    return -1
  }

  return dateTimeA > dateTimeB ? -1 : 1
}

// sorting function used for days in <<customer timeline>> section
const sortDaysDesc = (
  dayA: TCustomerHistoryTimelineDay,
  dayB: TCustomerHistoryTimelineDay,
) => {
  return dayA.date > dayB.date ? -1 : 1
}

// sorting function used for events in a day in <<customer timeline>> section
const sortEventsAsc = (eventA: TTimelineEvent, eventB: TTimelineEvent) => {
  return eventA.time < eventB.time ? -1 : 1
}

const sortTimeline = (timelineDays: RoA<TCustomerHistoryTimelineDay>) => {
  const sortedTimelineDays = sortCollection(timelineDays, sortDaysDesc)

  const sortedTimelineEvents = sortedTimelineDays.map(day => ({
    ...day,
    events: sortCollection(day.events, sortEventsAsc),
  }))

  return sortedTimelineEvents
}
