type TSplitTagBase = {
  originTag?: Nullable<{ id: ID }>
}

/**
 * helper fnc for splitting product tags into `company` and `system` tags
 */
export const splitProductTags = <TProductTag extends TSplitTagBase>(
  productTags: RoA<TProductTag>,
) => {
  const companyProductTags = productTags.filter(tag => Boolean(tag.originTag))
  const systemProductTags = productTags.filter(tag => !Boolean(tag.originTag))

  return { companyProductTags, systemProductTags }
}
