import { useRoot } from '-app/root'
import { useApolloClient } from '@speedlo/graphql'
import * as React from 'react'
import { Redirect } from 'react-router-dom'

import { RedirectToLogin } from './RedirectToLogin'

type TProps = NoChildren

export const RedirectAfterLogout: React.FC<TProps> = () => {
  const { auth } = useRoot()
  const apolloClient = useApolloClient()

  // user has logged out clear his data
  if (auth.isAuthenticated) {
    auth.root.invalidate()
    apolloClient.cache.reset()

    // clear the data in browser memory as well (volatile stuff in MobX models)
    return <RedirectToLogin clearDataInMemory />
  }

  // user has somehow get here without logout (eg. through browser history -> redirect him to the base URL)
  return <Redirect to="/" />
}
