import { Trans } from '@lingui/macro'
import * as React from 'react'

import { GridBox } from '../../../../atoms/Box/Box'
import { IconLine } from '../../../../atoms/IconLine'
import { formatTime } from '../../../../helpers/formats'
import { FLAT_SELECT_CONFIG } from '../../../../ui/FlatSelect'
import { IconInfoMessage } from '../../../../ui/Icons'
import { MinuteSelect } from '../../../../ui/MinuteSelect'
import { Modal, MODAL_SPACES } from '../../../../ui/Modal'
import { Bold } from '../../../../ui/Typography'
import { useExternalDeliveryPickupTime } from '../../hooks/useExternalDeliveryPickupTime'

type TProps = NoChildren & ReturnType<typeof useExternalDeliveryPickupTime>

// options for how many minutes it can take to prepare the order
const OPTIONS = [0, 10, 20, 30, 40]

/**
 * external delivery creation modal section for selecting courier pickup time,
 * use in combination with useExternalDeliveryPickupTime
 */
export const ExternalDeliveryPickupTime: React.FC<TProps> = ({
  minutes,
  setMinutes,
  closestPickupTime,
}) => {
  const pickupMessage = React.useMemo(() => {
    if (closestPickupTime === null) {
      return <Trans>First, select a time above</Trans>
    }

    const formattedPickupTime = <Bold>{formatTime(closestPickupTime)}</Bold>

    return (
      <Trans>
        The order will be ready to be picked up by the courier at{' '}
        {formattedPickupTime}
      </Trans>
    )
  }, [closestPickupTime])

  return (
    <section>
      <Modal.SectionTitle>
        <Trans>How much time do you need to prepare the order?</Trans>
      </Modal.SectionTitle>
      <p>
        <Trans>Select one of available options or enter your own value</Trans>
      </p>

      <GridBox
        marginY={MODAL_SPACES.sm}
        gridRowGap={FLAT_SELECT_CONFIG.optionGap}
      >
        <MinuteSelect.Provider value={minutes} onChange={setMinutes}>
          <MinuteSelect.Options options={OPTIONS} />
          <MinuteSelect.CustomInput />
        </MinuteSelect.Provider>
      </GridBox>

      <IconLine icon={IconInfoMessage}>{pickupMessage}</IconLine>
    </section>
  )
}
