import * as React from 'react'
import { useExternalDeliveryPickupTime } from '../../hooks/useExternalDeliveryPickupTime'
import { ExternalDeliveryOrderSummary } from './ExternalDeliveryOrderSummary'
import { CreateExternalDeliveryActions } from './CreateExternalDeliveryActions'
import { ExternalDeliveryPickupTime } from './ExternalDeliveryPickupTime'
import { Modal } from '../../../../ui/Modal'
import { useCreateExternalDeliveryCtx } from '../../contexts/CreateExternalDeliveryProvider'

type TSummaryProps = OmitSafe<
  PropsOf<typeof ExternalDeliveryOrderSummary>,
  'children' | 'deliveryProvider' | 'order'
>

type TProps = TSummaryProps & {
  externalDeliveryEstimates?: React.ReactNode
}

/**
 * content for external delivery creation modal,
 * contains order summary (modal content) and action buttons,
 *
 * external delivery **with pickup time** is created here,
 * for external delivery without pickup time see CreateExternalDelivery.tsx
 */
export const CreateExternalDeliveryWithPickupTime: React.FC<TProps> = ({
  externalDeliveryEstimates,
}) => {
  const pickupTime = useExternalDeliveryPickupTime()
  const { onClose, order, deliveryProvider } = useCreateExternalDeliveryCtx()

  return (
    <>
      <Modal.Content>
        <ExternalDeliveryOrderSummary
          order={order}
          deliveryProvider={deliveryProvider}
        />

        {externalDeliveryEstimates && (
          <>
            <Modal.Separator />
            {externalDeliveryEstimates}
          </>
        )}

        <Modal.Separator />

        <ExternalDeliveryPickupTime {...pickupTime} />
      </Modal.Content>

      <CreateExternalDeliveryActions
        onClose={onClose}
        orderId={order.id}
        deliveryProvider={deliveryProvider.enum}
        closestPickupTime={pickupTime.closestPickupTime}
        confirmDisabled={pickupTime.closestPickupTime === null}
      />
    </>
  )
}
