import * as DF from 'date-fns'
import * as DFP from 'date-fns/fp'

import { RM } from './ramda'

export { DF, DFP }

export let DFLocale: Locale & {
  locale: string
}

export const formatWithLocale = RM.curry((format: string, value: Date) =>
  DF.format(value, format, { locale: DFLocale }),
)

export async function changeDFLocale(lang: string) {
  let locale = lang

  if (lang === 'en') {
    locale = 'en-US'
  }

  if (lang === 'zu') {
    // date-fns doesn’t have locale for zulu
    locale = 'en-US'
  }

  // prettier-ignore
  DFLocale = (await import(
    /* webpackMode: "lazy", webpackChunkName: "df-[index]" */
    `date-fns/locale/${locale}/index.js`
  )).default

  if (lang === 'cs') {
    forkCSLang()
  }

  DFLocale.locale = locale
}

/**
 * Temporary because official localization uses
 * AM/PM format for time which is most likely wrong
 */
function forkCSLang() {
  const timeFormats = {
    full: 'HH:mm:ss zzzz',
    long: 'HH:mm:ss z',
    medium: 'HH:mm:ss',
    short: 'HH:mm',
  }

  const defaultWidth = 'long'
  // @ts-ignore
  DFLocale.formatLong.time = function (dirtyOptions) {
    const options = dirtyOptions || {}
    const width = options.width ? String(options.width) : defaultWidth
    const format = timeFormats[width] || timeFormats[defaultWidth]

    return format
  }
}
