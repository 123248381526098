import { SvgIconComponent } from '@material-ui/icons'
import React, { MouseEvent } from 'react'
import styled from 'styled-components/macro'

import { desktopBreakpoint, mobileBreakpoint } from '../app/Responsive'
import { GridCenteredBox } from '../atoms/Box/Box'
import { Icon } from '../atoms/Icon'
import { responsiveFontByWidth } from '../helpers/styles/responsiveFont'
import { applyAlphaToColor, Colors, Radiuses, Spacing } from '../styling'

type TFlatSide = 'left' | 'right' | 'none' | 'top' | 'bottom'

type TPrivateStyledProps = {
  borderColor?: TColor
}

type TPublicStyledProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  color?: TColor
  width?: string
  height?: string
  bgAlpha?: number
  minWidth?: string
  minHeight?: string
  className?: string
  textColor?: TColor
  disabledColor?: TColor
}

type TStyledProps = TPrivateStyledProps & TPublicStyledProps

export type TTouchButtonProps = React.PropsWithChildren<
  TPublicStyledProps & {
    text?: string
    iconSize?: number
    badge?: React.ReactNode
    outline?: boolean
    iconColor?: TColor
    disabled?: boolean
    icon?: SvgIconComponent
    textNextToIcon?: boolean
    flatSides?: RoA<TFlatSide>
    onClick?(e: MouseEvent<HTMLButtonElement>): void
  }
>

const applyAlphaToBg =
  (alphaValue: number) =>
  ({ color }: TStyledProps) => {
    return applyAlphaToColor(color!, alphaValue)
  }

const applyAlphaToBorder =
  (alphaValue: number) =>
  ({ borderColor }: TStyledProps) => {
    return applyAlphaToColor(borderColor!, alphaValue)
  }

const minButtonDimension = Spacing.ButtonHeightWithUnit

const StyledTouchButton = styled.button<TStyledProps>`
  min-height: ${({ minHeight = minButtonDimension }) => minHeight};
  min-width: ${({ minWidth = minButtonDimension }) => minWidth};
  width: ${({ width = '100%' }) => width};
  height: ${({ height = '100%' }) => height};
  margin: 0;
  padding: 0 ${Spacing.small};
  border: 2px solid ${({ borderColor }) => borderColor};
  border-radius: ${Radiuses.Button};
  background-color: ${({ bgAlpha = 1 }) => applyAlphaToBg(bgAlpha)};
  color: ${({ textColor }) => textColor};
  position: relative;
  user-select: none;
  transition-duration: 0.2s;

  /* this is needed for semi transparent borders to work */
  /* but it also broken the regular flat btn bg */
  /* -webkit-background-clip: padding-box;
  background-clip: padding-box; */

  /* fallback font size for incompatible browsers */
  font-size: 1rem;

  cursor: pointer;

  ${responsiveFontByWidth({
    minSize: 14,
    maxSize: 18,
    minBreakpoint: mobileBreakpoint,
    maxBreakpoint: desktopBreakpoint,
  })}

  &:hover:enabled {
    background-color: ${({ bgAlpha = 1 }) =>
      bgAlpha >= 0.5 ? applyAlphaToBg(0.8) : applyAlphaToBg(0.2)};
    border-color: ${({ bgAlpha = 1 }) =>
      bgAlpha >= 0.5 ? applyAlphaToBorder(0.8) : applyAlphaToBorder(0.2)};
  }

  &:active:enabled {
    background-color: ${applyAlphaToBg(1)};
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
    background-color: ${({ disabledColor = Colors.grey.medium }) =>
      disabledColor};
    border-color: ${({ disabledColor = Colors.grey.medium }) => disabledColor};
  }

  --no-radius: 0px;

  &.flat-left {
    border-top-left-radius: var(--no-radius);
    border-bottom-left-radius: var(--no-radius);
  }

  &.flat-right {
    border-top-right-radius: var(--no-radius);
    border-bottom-right-radius: var(--no-radius);
  }

  &.flat-top {
    border-top-right-radius: var(--no-radius);
    border-top-left-radius: var(--no-radius);
  }

  &.flat-bottom {
    border-bottom-right-radius: var(--no-radius);
    border-bottom-left-radius: var(--no-radius);
  }

  @media print {
    opacity: 0;

    &:hover:enabled {
      opacity: 1;
    }
  }
`

const ButtonLabelStyled = styled.div`
  color: ${Colors.white.main};
  text-align: center;
  width: 100%;
  overflow: hidden;
`
const ButtonLabelNextToIconStyled = styled(ButtonLabelStyled)`
  width: calc(100% - 4rem);
  margin: 0 0 0 1rem;
  text-align: left;
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
`

export const TouchButton = React.forwardRef<
  HTMLButtonElement,
  TTouchButtonProps
>(
  (
    {
      icon,
      text,
      badge,
      bgAlpha,
      children,
      className,
      iconColor,
      iconSize = 2,
      flatSides = [],
      outline = false,
      textNextToIcon = false,
      color = Colors.grey.medium,
      textColor = Colors.white.main,
      ...props
    },
    forwardedRef,
  ) => {
    const flatSideClasses = flatSides.map(side => `flat-${side}`).join(' ')

    const actualTextColor = outline ? color : textColor
    const actualBgAlpha = outline ? 0 : bgAlpha
    const actualIconColor = iconColor ?? actualTextColor

    return (
      <StyledTouchButton
        type="button"
        color={color}
        borderColor={color}
        bgAlpha={actualBgAlpha}
        textColor={actualTextColor}
        className={
          className ? `${flatSideClasses} ${className}` : flatSideClasses
        }
        {...props}
        ref={forwardedRef}
      >
        {icon && (
          <Icon icon={icon} color={actualIconColor} fontSize={iconSize} />
        )}

        {text && textNextToIcon && (
          <ButtonLabelNextToIconStyled>{text}</ButtonLabelNextToIconStyled>
        )}

        {text && !textNextToIcon && (
          <ButtonLabelStyled>{text}</ButtonLabelStyled>
        )}

        {badge}
        {children}
      </StyledTouchButton>
    )
  },
)

export const SmallTouchButton = styled(TouchButton)`
  min-height: ${Spacing.formElementHeight};
`

// ! using display: grid on a button is still bugged in Chrome, even after 7 years of working on it – it’s still recommended to use some inner element
export const CenteredTouchButton: React.FC<TTouchButtonProps> = ({
  children,
  ...props
}) => {
  return (
    <TouchButton {...props}>
      <GridCenteredBox>{children}</GridCenteredBox>
    </TouchButton>
  )
}
