import { observable } from 'mobx'
import { getParent, Instance, SnapshotIn, types } from 'mobx-state-tree'
import { scroller } from 'react-scroll'

import { TOrderStateCategoryEnum } from '../../../graph/generated'
import { BaseModel } from '../../../models/BaseModel'
import { TOrderModel } from './OrderModel'

export const MAX_FAST_ACTION_BRANCHES = 4

const FINISHED: TOrderStateCategoryEnum = `FINISHED`
const NEW_ORDERS: TOrderStateCategoryEnum = `NEW_ORDERS`

export const OrderListModel = BaseModel.named('OrderList')
  .props({
    branches: types.optional(types.array(types.string), []),
    collapsedCategories: types.optional(types.array(types.string), [FINISHED]),
    toggleDisabledCategories: types.optional(types.array(types.string), [
      NEW_ORDERS,
    ]),
  })

  .volatile(() => ({
    lastFocusedOrder: null as Nullable<ID>, // used for marking last focused order
  }))

  .views(self => ({
    get branchesFilter() {
      return self.branches.length ? Array.from(self.branches) : []
    },

    get hasBranchFilter() {
      return self.branches.length > 0
    },

    get isSingleBranchSelected() {
      return self.branches.length === 1
    },

    get firstBranchId() {
      if (self.branches.length === 1) {
        return self.branches[0]
      }

      return null
    },

    get fastActionBranches() {
      if (self.branches.length === 0) {
        return null
      }

      if (self.branches.length > MAX_FAST_ACTION_BRANCHES) {
        return null
      }

      return [...self.branches]
    },

    get orderMap(): TOrderModel {
      return getParent(self).map
    },

    isLastFocusedOrder(orderId: ID) {
      return orderId === self.lastFocusedOrder
    },
  }))

  .actions(self => ({
    invalidate() {
      self.branches.clear()
    },

    clearBranchFilter() {
      self.branches.clear()
    },

    setBranchFilter(branches: ID[]) {
      self.branches.replace(branches)
    },
  }))

  .extend(self => {
    const detailOpen = observable.set<ID>()

    return {
      views: {
        isOrderDetailOpen(orderId: ID) {
          return detailOpen.has(orderId)
        },

        isCategoryCollapsed(categoryCode: TOrderStateCategoryEnum) {
          return self.collapsedCategories.includes(categoryCode)
        },
      },

      actions: {
        closeOrderDetail(orderId: ID) {
          detailOpen.delete(orderId)

          if (self.lastFocusedOrder === orderId) {
            self.lastFocusedOrder = null
          }
        },

        toggleOrderDetail(orderId: ID) {
          if (detailOpen.has(orderId)) {
            this.closeOrderDetail(orderId)
          } else {
            detailOpen.add(orderId)
            self.lastFocusedOrder = orderId
          }
        },

        toggleCategoryCollapse(category: TOrderStateCategoryEnum) {
          const index = self.collapsedCategories.indexOf(category)

          if (index >= 0) {
            self.collapsedCategories.splice(index, 1)
          } else {
            self.collapsedCategories.push(category)
          }
        },

        forceCategoryCollapse(category: TOrderStateCategoryEnum) {
          const indexInCollapsed = self.collapsedCategories.indexOf(category)

          if (indexInCollapsed < 0) {
            self.collapsedCategories.push(category)
          }

          const indexInForced = self.toggleDisabledCategories.indexOf(category)

          if (indexInForced < 0) {
            self.toggleDisabledCategories.push(category)
          }
        },

        enableCategoryToggle(category: TOrderStateCategoryEnum) {
          const index = self.toggleDisabledCategories.indexOf(category)

          if (index >= 0) {
            self.toggleDisabledCategories.splice(index, 1)
          }
        },

        focusOrderDetail(orderId: ID) {
          if (!detailOpen.has(orderId)) {
            detailOpen.add(orderId)
          }

          if (self.lastFocusedOrder !== orderId) {
            self.lastFocusedOrder = orderId
          } else {
            self.lastFocusedOrder = null
          }

          setImmediate(() => {
            scroller.scrollTo(`order-${orderId}`, {
              offset: -140,
              absolute: true,
              smooth: 'easeInOutQuart',
              containerId: 'order-list',
            })
          })
        },

        onOrderDetailOpen(handler: (orderId: ID) => void) {
          return detailOpen.observe(change => {
            if (change.type === 'add') {
              handler(change.newValue)
            }
          })
        },
      },
    }
  })

export interface TOrderListModel extends Instance<typeof OrderListModel> {}
export interface TOrderListModelProps
  extends SnapshotIn<typeof OrderListModel> {}
