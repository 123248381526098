import { Instance, SnapshotIn, types } from 'mobx-state-tree'

import { BaseModel } from '../../../models/BaseModel'
import { StockInventoryModel } from '../inventory/models/StockInventoryModel'
import { StockTransferModel } from './StockTransferModel'

export const StockModel = BaseModel.named('Stock').props({
  transfer: types.optional(StockTransferModel, {}),
  inventories: types.optional(StockInventoryModel, {}),
})

export interface TStockModelModel extends Instance<typeof StockModel> {}
export interface TStockModelModelProps extends SnapshotIn<typeof StockModel> {}
