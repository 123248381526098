import { Instance, types } from 'mobx-state-tree'

import { BaseModel } from '../../../models/BaseModel'

export const AppSettingsModel = BaseModel.named('AppSettings')
  .props({
    accessibleDashboard: types.optional(types.boolean, false),

    sortOrdersByTakeoverTime: types.optional(types.boolean, false), // deprecated, kept for backwards compatibility
    sortOrdersByHandoverTimeValue: types.maybeNull(types.boolean),
    showItemCodes: types.optional(types.boolean, true),

    preferredHandoverType: types.optional(types.maybeNull(types.string), null),

    showOtherKitchenWorkplaces: types.optional(types.boolean, false),
    kitchenHeaderCustomerOpen: types.optional(types.boolean, false),
    kitchenHeaderTimeOpen: types.optional(types.boolean, false),
    kitchenSortByPreparationStart: types.optional(types.boolean, false),
    showItemCategory: types.optional(types.boolean, false),

    customerScreenNotifications: types.optional(types.boolean, false),
    customerScreenTheme: types.optional(types.string, 'dark'),

    printSuccessful: types.optional(types.boolean, true),
    simulatePrintService: types.optional(types.boolean, true),
    demoPcMac: types.optional(types.maybeNull(types.string), null),
  })

  .views(self => ({
    get isCustomerScreenThemeDark() {
      return self.customerScreenTheme === 'dark'
    },
    get isCustomerScreenThemeLight() {
      return self.customerScreenTheme === 'light'
    },
    get sortOrdersByHandoverTime() {
      // return with fallback to deprecated value, because some users might still have that one persisted
      return self.sortOrdersByHandoverTimeValue ?? self.sortOrdersByTakeoverTime
    },
  }))

  .actions(self => ({
    initDemoPcMac() {
      const userId = self.root.user.id
      const nowMilliseconds = new Date().getTime()

      self.demoPcMac = `${userId}-${nowMilliseconds}`
    },

    togglePrintSuccessful() {
      self.printSuccessful = !self.printSuccessful
    },

    toggleSimulatePrintService() {
      self.simulatePrintService = !self.simulatePrintService
    },

    toggleAccessibleDashboard() {
      self.accessibleDashboard = !self.accessibleDashboard
    },

    toggleShowItemCodes() {
      self.showItemCodes = !self.showItemCodes
    },

    toggleItemCategory() {
      self.showItemCategory = !self.showItemCategory
    },

    toggleKitchenHeaderTimeOpen() {
      self.kitchenHeaderTimeOpen = !self.kitchenHeaderTimeOpen
    },

    toggleKitchenHeaderCustomerOpen() {
      self.kitchenHeaderCustomerOpen = !self.kitchenHeaderCustomerOpen
    },

    toggleShowOtherKitchenWorkplaces() {
      self.showOtherKitchenWorkplaces = !self.showOtherKitchenWorkplaces
    },

    toggleSortOrdersByHandoverTime() {
      self.sortOrdersByHandoverTimeValue = !self.sortOrdersByHandoverTimeValue
    },

    toggleKitchenSortByPreparationStart() {
      self.kitchenSortByPreparationStart = !self.kitchenSortByPreparationStart
    },

    setPreferredHandoverType(handoverType: string) {
      self.preferredHandoverType = handoverType
    },

    toggleCustomerScreenNotifications() {
      self.customerScreenNotifications = !self.customerScreenNotifications
    },

    toggleCustomerScreenTheme() {
      self.customerScreenTheme =
        self.customerScreenTheme === 'dark' ? 'light' : 'dark'
    },
  }))

export interface TAppSettingsModel extends Instance<typeof AppSettingsModel> {}
