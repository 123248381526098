import * as React from 'react'
import styled from 'styled-components/macro'

import { Spacing } from '../styling'

type TProps = NoChildren & {
  text?: string
}

const StyledLoading = styled.div`
  padding: ${Spacing.medium};
  text-align: center;
  font-size: 1.5rem;
`

export const Loading: React.FC<TProps> = ({ text = '...' }) => (
  <StyledLoading>{text}</StyledLoading>
)
