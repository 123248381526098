import {
  BOX_SHADOWS_SEMANTIC,
  INNER_BOX_SHADOWS,
  OUTER_BOX_SHADOWS,
} from './boxShadowValues'

/**
 * key that can be used as outer box shadows
 */
export type TOuterShadow = keyof typeof OUTER_BOX_SHADOWS
/**
 * key that can be used as inner box shadows
 */
export type TInnerShadow = keyof typeof INNER_BOX_SHADOWS
/**
 * key that can be used as box shadows, either outer, inner or semantic
 */
export type TShadow =
  | TOuterShadow
  | TInnerShadow
  | keyof typeof BOX_SHADOWS_SEMANTIC

/**
 * returns a value for provided outer box shadow key
 */
export const getOuterShadow = (shadow: TOuterShadow) =>
  OUTER_BOX_SHADOWS[shadow]

/**
 * returns a value for provided inner box shadow key
 */
export const getInnerShadow = (shadow: TInnerShadow) =>
  INNER_BOX_SHADOWS[shadow]

/**
 * returns a value for provided box shadow key
 */
export const getShadow = (shadow: TShadow) => {
  const shadows = {
    ...INNER_BOX_SHADOWS,
    ...OUTER_BOX_SHADOWS,
    ...BOX_SHADOWS_SEMANTIC,
  }
  return shadows[shadow]
}
