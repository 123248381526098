import * as React from 'react'

import { TFOrderForExternalDelivery } from '../../../graph/generated'
import { CreateExternalDeliveryModal } from '../components/CreateExternalDeliveryModal'

type TOrder = TFOrderForExternalDelivery
type TDeliveryProvider = TOrder['availableExternalDeliveries'][number]

type TOptions = {
  order: TOrder
}

/**
 * handles logic required to select an external delivery provider
 * and present the modal to create an external delivery
 */
export const useCreateExternalDelivery = ({ order }: TOptions) => {
  const [modalElement, setModalElement] = React.useState<React.ReactNode>(null)

  const presentModal = React.useCallback(
    (deliveryProvider: TDeliveryProvider) => {
      const modalOptions: PropsOf<typeof CreateExternalDeliveryModal> = {
        order,
        isOpen: true,
        deliveryProvider,
        onClose: () => setModalElement(null),
        withPickupTime: deliveryProvider.enum === 'WOLT',
      }

      setModalElement(
        React.createElement(CreateExternalDeliveryModal, modalOptions),
      )
    },
    [order],
  )

  return { startCreation: presentModal, modalElement }
}
