import { useSentryInit } from '@speedlo/sentry'
import * as React from 'react'

import { appConfig } from '../config'
import { I18nManager } from '../i18n'
import { SnackProvider } from './contexts/SnackProvider'
import { RootProvider } from './root'

type TProps = Children

export const AppProvider: React.FC<TProps> = ({ children }) => {
  useSentryInit(appConfig.sentryDSN)

  return (
    <RootProvider>
      <I18nManager>
        <SnackProvider>{children}</SnackProvider>
      </I18nManager>
    </RootProvider>
  )
}
