import * as React from 'react'
import styled from 'styled-components'

import {
  getContrastShadeFor,
  TColorKey,
  THEME,
  TPaddingProps,
} from '../../../designSystem'
import { Colors } from '../../../styling'
import { Button } from '../../Button/components/Button'
import { useFlatSelectCtx } from '../contexts/FlatSelectProvider'

type TButtonProps = Pick<
  PropsOf<typeof Button>,
  'gridColumn' | 'disabled' | keyof TPaddingProps
>

type TStyledProps = {
  textColor: string
}

type TProps<TOption> = NoChildren &
  TButtonProps & {
    value: TOption
    selectedColor?: TColorKey
    isSelected?: boolean
  }

const StyledLabel = styled.span<TStyledProps>`
  font-size: 1rem;
  color: ${({ textColor }) => textColor};
`

/**
 * single button of the FlatSelect representing one of possible options
 * can be marked as selected or disabled
 */
export const FlatSelectOption = <TOption,>({
  value,
  isSelected = false,
  selectedColor = `primary`,
  ...buttonProps
}: TProps<TOption>) => {
  const { onChange, renderLabel } = useFlatSelectCtx()

  // TODO update once FG color system is sorted out
  const bgColor: TColorKey = isSelected ? selectedColor : `white`
  const fgShade = getContrastShadeFor(THEME[bgColor].normal)
  const textColorValue =
    fgShade === `light` ? Colors.white.pure : Colors.black.pure

  return (
    <Button bgColor={bgColor} onClick={() => onChange(value)} {...buttonProps}>
      <StyledLabel textColor={textColorValue}>{renderLabel(value)}</StyledLabel>
    </Button>
  )
}
