import { Instance, types } from 'mobx-state-tree'

import {
  TCreditsFundsTransferTypeEnum,
  TFCreditsFundsTransfer,
  TQStatsCredits,
} from '../../../../graph/generated'
import { BaseModel } from '../../../../models/BaseModel'
import {
  TCreditStatisticsRefunds,
  TCreditStatisticsSummary,
  TCreditStatisticsTopUps,
} from '../typings/creditStatistics.types'

// GQL data types
type TGqlCreditStatistics = TQStatsCredits['statistics']

// UI data types
type TCreditStatisticsData = {
  data: Nullable<TGqlCreditStatistics>
  transfers: RoA<TFCreditsFundsTransfer>
  orders: TGqlCreditStatistics['finishedOrders']['orders']
  groupedTransfers: Nullable<
    Record<TCreditsFundsTransferTypeEnum, TFCreditsFundsTransfer[]>
  >
  summary: Nullable<TCreditStatisticsSummary>
  topUps: Nullable<TCreditStatisticsTopUps>
  refunds: Nullable<TCreditStatisticsRefunds>
}

type TVolatileProps = TCreditStatisticsData & {
  currency: string
  selectedSection: `summary` | `topUps` | `refunds`
}

export const CreditStatisticsModel = BaseModel.named(`CreditStatistics`)
  .props({
    // potentially, this could be used to give users a chance to show/hide event the values where no expense/fee is set
    // right now they are just hidden without an option to show them
    hideUnset: types.optional(types.boolean, true),
  })

  .volatile<TVolatileProps>(() => ({
    currency: ``,
    data: null,
    transfers: [],
    orders: [],
    groupedTransfers: null,
    summary: null,
    topUps: null,
    refunds: null,
    selectedSection: `summary`,
  }))

  // some more generic actions
  .actions(self => ({
    setCurrency(currency: string) {
      self.currency = currency
    },

    showSummary() {
      self.selectedSection = `summary`
    },

    showTopUps() {
      self.selectedSection = `topUps`
    },

    showRefunds() {
      self.selectedSection = `refunds`
    },
  }))

  // data transform & save – from GQL Q type to a type required by the UI
  .actions(self => ({
    setCreditsStatistics(statistics: TGqlCreditStatistics) {
      // we are interested in paid transfers only (-> filter out the rest, CANCELLED, PENDING, ...)
      const filteredTransfers =
        statistics.creditFundsTransfers.transfers.filter(
          transfer => transfer.state.enum === `PAID`,
        )

      const filteredOrders = statistics.finishedOrders.orders.filter(
        order => order.orderStateCategory !== `CANCEL`,
      )

      const transfers = groupTransfersByType(filteredTransfers)

      self.data = statistics
      self.orders = filteredOrders
      self.transfers = filteredTransfers
      self.groupedTransfers = transfers

      // we assume that all the transfers have the same currency
      const currency = filteredTransfers[0]?.amount.currency.code

      self.setCurrency(currency)

      this.setTopUps()
      this.setRefunds()
      this.setSummary()
    },

    // credit-transfers summary
    setSummary() {
      const topUps = self.groupedTransfers?.[`TOP_UP`]

      self.summary = {
        transactionCount: self.transfers.length,
        // top-up transfers with order are considered as chip sales
        chipCount:
          topUps?.filter(transaction => transaction.order !== null).length ?? 0,
        // balance is calculated as a difference between top-ups and refunds
        balance:
          self.topUps && self.refunds ? self.topUps.sum - self.refunds.sum : 0,
      }
    },

    setTopUps() {
      const topUps = self.groupedTransfers?.[`TOP_UP`]
      const topUpSum = sumTransfers(topUps)
      const topUpCount = topUps?.length ?? 0

      self.topUps = {
        sum: topUpSum,
        count: topUpCount,
        average: topUpCount ? topUpSum / topUpCount : 0,
      }
    },

    setRefunds() {
      const refunds = self.groupedTransfers?.[`REFUND`]
      const refundSum = sumTransfers(refunds)
      const refundCount = refunds?.length ?? 0

      self.refunds = {
        sum: refundSum,
        count: refundCount,
        average: refundCount ? refundSum / refundCount : 0,
      }
    },
  }))

  // methods for data erasing when they are not needed/used anymore
  .actions(self => ({
    invalidateCreditStatistics() {
      self.data = null
    },

    invalidateSummary() {
      self.summary = null
    },

    invalidateTopUps() {
      self.topUps = null
    },

    invalidateRefunds() {
      self.refunds = null
    },

    invalidateAllData() {
      this.invalidateCreditStatistics()
      this.invalidateSummary()
      this.invalidateTopUps()
      this.invalidateRefunds()
    },
  }))

export interface TCreditStatisticsModel
  extends Instance<typeof CreditStatisticsModel> {}

// *** Helper functions & types ***

/**
 * function for grouping transfers by their type (TOP_UP, REFUND, ...),
 */
const groupTransfersByType = (transfers: RoA<TFCreditsFundsTransfer>) => {
  return transfers.reduce((acc, transfer) => {
    const transferType = transfer.type.enum
    const prevTransfers = acc[transferType]

    if (prevTransfers !== undefined) {
      // found some records with this type before -> add this record to them
      prevTransfers.push(transfer)
    } else {
      // this is the first time we met this expense type -> assign this expenses to the new field in the Record
      acc[transferType] = [transfer]
    }

    return acc
  }, {} as Record<TCreditsFundsTransferTypeEnum, TFCreditsFundsTransfer[]>)
}

// sum all the transfer amounts
const sumTransfers = (transfers?: RoA<TFCreditsFundsTransfer>) => {
  if (!transfers) return 0

  return transfers.reduce((acc, transfer) => {
    return acc + transfer.amount.value
  }, 0)
}
