import { SvgIconProps } from '@material-ui/core/SvgIcon'
import * as React from 'react'
import styled from 'styled-components/macro'

const SCALE = `0.8em`

const StyledIcon = styled.div`
  width: 1em;
  height: 1em;
  display: grid;
  place-items: center;
  color: inherit;
`

// unusual typing to comply with the MUI icons

/**
 * icon for foodora external partner,
 * intended to be rendered on foodora brand color background
 */
export const IconFoodora = (props: SvgIconProps): JSX.Element => {
  return (
    <StyledIcon>
      <svg viewBox="0 0 24.077084 43.96468" height={SCALE} width={SCALE}>
        <g transform="translate(35.086399,-136.77227)">
          <path
            d="m -35.086399,166.33631 c 0,-8.8373 0.110026,-15.1917 0.284799,-16.44827 0.712443,-5.12228 3.516465,-9.24883 7.744217,-11.3968 3.456116,-1.75593 9.02292,-2.22201 13.627617,-1.14095 l 2.42045,0.56825 v 4.44813 4.44813 l -1.653646,-0.5342 c -2.018723,-0.65212 -6.404854,-0.7838 -7.960193,-0.23897 -2.045144,0.7164 -3.672591,2.74626 -4.039009,5.03771 l -0.173117,1.08261 h 4.531733 4.531732 v 4.36563 4.36562 h -4.630208 -4.630208 v 9.92188 9.92187 h -5.027083 -5.027084 z"
            style={{ fill: `#ffffff`, strokeWidth: `0.264583` }}
          />
        </g>
      </svg>
    </StyledIcon>
  )
}
