import * as React from 'react'
import styled from 'styled-components/macro'

import { getSpaceValue } from '../../../designSystem'

type TProps = RequiredChildren

const StyledScreen = styled.div`
  display: grid;
  align-content: center;
  gap: ${getSpaceValue(`s16`)};

  height: 90vh;
  width: 100%;
  max-width: 48ch;

  padding: ${getSpaceValue(`s16`)};
  margin: 0 auto;

  > .content {
    display: grid;
    align-content: flex-start;
    gap: ${getSpaceValue(`s16`)};

    min-height: 50vh;
  }
`

export const AppNameScreenLayout: React.FC<TProps> = ({ children }) => {
  return (
    <StyledScreen>
      <div className={`content`}>{children}</div>
    </StyledScreen>
  )
}
