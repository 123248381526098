import { Instance, types } from 'mobx-state-tree'

import { BaseModel } from '../../../models/BaseModel'
import { BillingModel } from '../Billing/models/BillingModel'
import { CreditStatisticsModel } from '../CreditStatistics/models/CreditStatisticsModel'
import { CustomerHistoryModel } from '../CustomerHistory/models/CustomerHistoryModel'
import { OrderRatingModel } from '../OrderRating/models/OrderRatingModel'
import { DriverHistoryModel } from './DriverHistoryModel'

export const StatisticsModel = BaseModel.named('Statistics')
  .props({
    billing: types.optional(BillingModel, {}),
    stockBrandId: types.maybeNull(types.string),
    orderRating: types.optional(OrderRatingModel, {}),
    driverHistory: types.optional(DriverHistoryModel, {}),
    customerHistory: types.optional(CustomerHistoryModel, {}),
    creditStatistics: types.optional(CreditStatisticsModel, {}),
  })

  .volatile(() => ({
    stockSearchInput: '',
  }))

  .actions(self => ({
    setStockBrand(brandId: NullableID) {
      self.stockBrandId = brandId
    },

    setStockFilter(filter: string) {
      self.stockSearchInput = filter
    },
  }))

export interface TStatisticsModel extends Instance<typeof StatisticsModel> {}
