import { useRouterContext } from '@speedlo/hooks'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components/macro'

import { getPulseAnimation } from '../../../helpers/styles/pulseAnimation'
import { cn } from '../../../libs/classnames'
import { ROUTE_ORDER_OVERVIEW } from '../../../screens/order/order.routes'
import { IconExternalDelivery } from '../../../ui/Icons'
import { StatusBarIcon } from '../../../ui/StatusBarIcon'
import { useActiveExternalDeliveriesModel } from '../models/useActiveExternalDeliveriesModel'

type TProps = NoChildren

// bring more attention when some external delivery failed
const pulse = getPulseAnimation({ maxScale: 1.07 })

const StyledStatusBarIcon = styled(StatusBarIcon)`
  &.animated {
    animation-name: ${pulse};
    animation-duration: 5s;
    animation-iteration-count: infinite;
  }
`

/**
 * status bar component that shows the number of active external deliveries
 * and navigates to the order overview (where more detailed view is) when clicked
 */
const ActiveExternalDeliveriesIndicatorComponent: React.FC<TProps> = () => {
  const { history, location } = useRouterContext()
  const {
    openList,
    toggleList,
    shouldRenderExternalDeliveries,
    numberOfActiveExternalDeliveries,
    anyExternalDeliveryRequiresAction,
  } = useActiveExternalDeliveriesModel()

  if (!shouldRenderExternalDeliveries) {
    return null
  }

  const isOnOrderOverview = location.pathname === ROUTE_ORDER_OVERVIEW

  const goToList = () => {
    openList()
    history.push(ROUTE_ORDER_OVERVIEW)
  }

  return (
    <StyledStatusBarIcon
      icon={IconExternalDelivery}
      badgeCount={numberOfActiveExternalDeliveries}
      onClick={isOnOrderOverview ? toggleList : goToList}
      className={cn({ animated: anyExternalDeliveryRequiresAction })}
    />
  )
}

export const ActiveExternalDeliveriesIndicator = observer(
  ActiveExternalDeliveriesIndicatorComponent,
)
