import { WithRoot } from '-app/root'
import debug from 'debug'
import { when } from 'mobx'
import * as React from 'react'
import { Route } from 'react-router-dom'

const log = debug('routing')

type TProps = NoChildren

export const RedirectAfterLogin: React.FC<TProps> = () => (
  <Route
    children={({ location, history }) => (
      <WithRoot
        render={root => {
          if (!root.auth.isAuthenticatedUser) {
            const route = (location.state && location.state.origin) || '/'
            const waitForAuthentication = () => root.auth.isAuthenticated
            const redirectAfterLogin = () => {
              history.replace(route)
            }

            when(waitForAuthentication, redirectAfterLogin)
            log('will redirect to %s when auth', route)
          }

          return null
        }}
      />
    )}
  />
)
