import { Trans } from '@lingui/macro'
import * as React from 'react'

import { getContrastShadeFor, THEME } from '../../../designSystem'
import { Colors } from '../../../styling'
import { Button } from '../../Button'

type TButtonProps = OmitSafe<PropsOf<typeof Button>, 'type' | 'children'>

type TProps = Children &
  TButtonProps & {
    type?: 'confirm' | 'cancel'
    buttonType?: PropsOf<typeof Button>['type']
  }

/**
 * Renders a button inside a modal system.
 * Has presets for confirm and cancel buttons but can be customized.
 */
export const ModalButton: React.FC<TProps> = ({
  type,
  bgColor,
  children,
  buttonType,
  ...buttonProps
}) => {
  const content = React.useMemo(() => {
    if (children) return children
    if (type === 'confirm') return <Trans>Confirm</Trans>
    if (type === 'cancel') return <Trans>Cancel</Trans>
    return null
  }, [children, type])

  const bgColorKey = React.useMemo(() => {
    if (bgColor) return bgColor
    if (type === 'confirm') return 'success'
    if (type === 'cancel') return 'error'
    return 'secondary'
  }, [bgColor, type])

  // TODO update once FG color system is sorted out
  const fgShade = getContrastShadeFor(THEME[bgColorKey].normal)
  const textColorValue =
    fgShade === `light` ? Colors.white.pure : Colors.black.pure

  return (
    <Button {...buttonProps} type={buttonType} bgColor={bgColorKey}>
      <span style={{ color: textColorValue }}>{content}</span>
    </Button>
  )
}
