import * as React from 'react'

import { useMChangePrintRecordState } from '../../../graph/generated'
import { logPrint } from '../../../helpers/logger'
import { useSnackBar } from '../../../hooks/useSnackBar'
import { i18n } from '../../../i18n'
import { TPrintRecord } from '../components/PrintWatch'
import { usePrintWatchModel } from '../models/usePrintWatchModel'

type TPrintRecordInfo = {
  recordId: ID
  printRecord?: TPrintRecord
}

type TOptions = {
  printSuccessNotification: (recordInfo: TPrintRecordInfo) => React.ReactNode
}

/**
 * encapsulate methods handling the results of print requests or the printing itself
 */
export const useHandlePrintResult = ({
  printSuccessNotification,
}: TOptions) => {
  const { enqueueError, enqueueSuccess } = useSnackBar()
  const [mChangePrintRecordState] = useMChangePrintRecordState()

  const {
    addFailedRecord,
    markRecordAsDone,
    recordPrintingFailed,
    markRecordAsRequested,
  } = usePrintWatchModel()

  /**
   * print requested successfully (record added to the operating system queue)
   */
  const onPrintRequested = React.useCallback(
    (recordId: ID) => {
      markRecordAsRequested(recordId)
      logPrint(`print requested`, recordId)
    },
    [markRecordAsRequested],
  )

  /**
   * printing could not be requested (eg. the printer is not ready)
   */
  const onPrintRequestFailed = React.useCallback(
    (printRecord: TPrintRecord) => {
      addFailedRecord(printRecord)
      logPrint(`print request FAILED`, printRecord.id)
    },
    [addFailedRecord],
  )

  /**
   * the record was successfully printed
   */
  const onPrintSuccess = React.useCallback(
    async (
      recordId: ID,
      shouldAlert: boolean = false,
      printRecord?: TPrintRecord,
    ) => {
      // order matters here ?
      markRecordAsDone(recordId)

      // pass the information to the BE
      try {
        await mChangePrintRecordState({
          variables: {
            recordId,
            newState: `PRINTED`,
          },
        })

        logPrint(`print successful`, recordId)

        if (shouldAlert) {
          enqueueSuccess(printSuccessNotification({ printRecord, recordId }))
        }
      } catch (error) {}
    },
    [
      enqueueSuccess,
      markRecordAsDone,
      mChangePrintRecordState,
      printSuccessNotification,
    ],
  )

  /**
   * requested print record was not printed for some reason
   */
  const onPrintFailed = React.useCallback(
    (recordId: ID, errors: RoA<string>) => {
      // order matters here ?
      recordPrintingFailed(recordId)

      enqueueError(i18n.t`Printing error: ${errors}`)

      // TODO FIXME
      // here should be an option to reprint the failed record but only the ID is stored in the model, not the whole record

      logPrint(`print failed`, recordId, errors)
    },
    [enqueueError, recordPrintingFailed],
  )

  return {
    onPrintFailed,
    onPrintSuccess,
    onPrintRequested,
    onPrintRequestFailed,
  }
}
