import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { Box } from '../../../../atoms/Box/Box'
import { getSpaceValue } from '../../../../designSystem'
import { TFNotification } from '../../../../graph/generated'
import { Colors } from '../../../../styling'
import { Text } from '../../../../ui/Typography'
import { NotificationFooter } from './NotificationFooter'
import { NotificationHeader } from './NotificationHeader'

type TProps = NoChildren & TFNotification

/**
 * A card for notification.
 */
const NotificationComponent: React.FC<TProps> = ({
  content,
  isRead,
  ...props
}) => {
  const bgColor = isRead ? Colors.grey.dark : Colors.grey.darkish

  return (
    <Box bgcolor={bgColor} padding={getSpaceValue(`s16`)} position={`relative`}>
      <NotificationHeader isRead={isRead} {...props} />
      <Text color={Colors.grey.lightish} fontSize={`14px`}>
        {content}
      </Text>
      <NotificationFooter isRead={isRead} {...props} />
    </Box>
  )
}

export const Notification = observer(NotificationComponent)
