import styled from 'styled-components/macro'

import {
  borders,
  BordersProps,
  display,
  DisplayProps,
  flexbox,
  FlexboxProps,
  palette,
  PaletteProps,
  positions,
  PositionsProps,
  sizing,
  SizingProps,
  spacing,
  SpacingProps,
  StyleFunction,
} from '@material-ui/system'

import { GridProps, gridSystem } from './grid'

// this type is taken from mui lib where it's not exported
export type TSimpleStyleFunction<PropKey extends keyof any> = StyleFunction<
  Partial<Record<PropKey, any>>
>

export type TBoxProps = BordersProps &
  DisplayProps &
  FlexboxProps &
  PaletteProps &
  PositionsProps &
  SizingProps &
  SpacingProps &
  GridProps & {
    gap?: string
  }

export const Box = styled.div<TBoxProps>`
  ${borders}
  ${display}
  ${flexbox}
  ${palette}
  ${positions}
  ${sizing}
  ${spacing}
  ${gridSystem}

  gap: ${({ gap }) => gap};
`

// custom preset boxes
export const GridBox = styled(Box)`
  display: grid;
`

/**
 * Regular customizable div displayed as flex.
 */
export const FlexBox = styled(Box)`
  display: flex;
`

export const GridBoxBreakable = styled(Box)`
  display: grid;

  @media print {
    display: block;
  }
`

export const GridCenteredBox = styled(Box)`
  display: grid;
  justify-content: center;
  align-items: center;
`

export const GridColumnBox = styled(Box)`
  display: grid;
  grid-auto-flow: column;
`

export const OverflowBox = styled(Box)`
  overflow: auto;
`

export const OverflowGridBox = styled(Box)`
  overflow: auto;
  display: grid;
`

export const FullPageBox = styled(Box)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

export const FullContainerBox = styled(Box)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

export const StickyBox = styled(Box)`
  position: sticky;
`
