import { Trans } from '@lingui/macro'
import * as React from 'react'
import styled from 'styled-components/macro'

import { GridBox } from '../../../../atoms/Box/Box'
import { IconLine } from '../../../../atoms/IconLine'
import { TFOrderForExternalDelivery } from '../../../../graph/generated'
import { formatTimeWithoutTodayDate } from '../../../../helpers/formats'
import { getOrderDestination } from '../../../../helpers/order/getOrderDestination'
import { getOrderNumber } from '../../../../helpers/order/getOrderNumber'
import {
  IconDestination,
  IconExternalDelivery,
  IconHandoverTime,
} from '../../../../ui/Icons'
import { Modal, MODAL_SPACES } from '../../../../ui/Modal'
import { HandoverTimeBadge } from '../../../handoverTime'

type TOrder = TFOrderForExternalDelivery

type TProps = NoChildren & {
  order: TOrder
  deliveryProvider: TOrder['availableExternalDeliveries'][number]
}

const StyledOrderInfo = styled.section`
  display: grid;
  grid-row-gap: ${MODAL_SPACES.sm};
  margin-top: ${MODAL_SPACES.md};
`

const StyledHandoverTime = styled.div`
  /* this controls the size of the HandoverTimeBadge as it’s done in em unit */
  font-size: 2rem;
  grid-row: 1 / 3;
  grid-column: 2;
`

/**
 * order summary section of external delivery creation modal
 */
export const ExternalDeliveryOrderSummary: React.FC<TProps> = ({
  order,
  deliveryProvider,
}) => {
  return (
    <GridBox gridTemplateColumns={`1fr auto`} gridColumnGap={MODAL_SPACES.md}>
      <Modal.Title>
        <Trans>External delivery for order n. {getOrderNumber(order)}</Trans>
      </Modal.Title>

      <StyledHandoverTime>
        <HandoverTimeBadge order={order} />
      </StyledHandoverTime>

      <StyledOrderInfo>
        <IconLine icon={IconExternalDelivery}>{deliveryProvider.code}</IconLine>
        <IconLine icon={IconDestination}>
          {getOrderDestination(order).label}
        </IconLine>
        {order.deliverAt && (
          <IconLine icon={IconHandoverTime}>
            <Trans>Deliver at time</Trans>{' '}
            {formatTimeWithoutTodayDate(order.deliverAt)}
          </IconLine>
        )}
      </StyledOrderInfo>
    </GridBox>
  )
}
