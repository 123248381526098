import * as React from 'react'

import { Colors } from '../../styling'

type TProps = NoChildren

/**
 * once the JS loads & we know the app env
 * change the app bg (set on the body element in `public/index.html`)
 */
export const EnvAppBg: React.FC<TProps> = () => {
  React.useEffect(() => {
    document.body.style.background = Colors.appBg
  }, [])

  return null
}
