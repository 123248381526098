import { Instance, types } from 'mobx-state-tree'

import { FilterInputModel } from '../../../components/FilterInput/FilterInputModel'
import { BaseModel } from '../../../models/BaseModel'
import { RM } from '../../../tools/ramda'
import {
  DEFAULT_IMPORT,
  DEFAULT_QUANTITY,
  DEFAULT_RAW_QUANTITY,
  TransferItem,
} from './StockTransferItem'

const IMPORT_QUANTITY = 0

export type TInitItem = {
  id: string
  max: number
  quantity: number
}

export const StockTransferModel = BaseModel.named('StockTransfer')
  .props({
    transferItems: types.map(TransferItem),
    filter: types.optional(FilterInputModel, {}),
  })

  .volatile(() => ({
    importMode: false,
    targetBranchId: null as NullableID,
  }))

  .views(self => ({
    get isImportMode() {
      return self.importMode === true
    },

    get isTransferMode() {
      return self.importMode === false
    },

    get targetId(): NullableID {
      return self.targetBranchId !== self.root.settings.branchId
        ? self.targetBranchId
        : null
    },

    get hasTarget() {
      return this.targetId !== null
    },

    get sourceId(): ID {
      return self.root.settings.branchId
    },

    get isImportingAll() {
      const shouldNotImportSome = Array.from(self.transferItems.values()).find(
        RM.propEq('import', false),
      )

      return self.transferItems.size > 0 && !shouldNotImportSome
    },

    isImportingItem(itemId: ID) {
      const item = self.transferItems.get(itemId)

      return item ? item.import : DEFAULT_IMPORT
    },

    getItemRawQuantity(itemId: ID) {
      const item = self.transferItems.get(itemId)

      return item ? item.rawQuantity : DEFAULT_RAW_QUANTITY
    },

    getItemTransferQuantity(itemId: ID) {
      const item = self.transferItems.get(itemId)

      return item ? item.quantity : DEFAULT_QUANTITY
    },

    getItemLimit(itemId: ID) {
      const item = self.transferItems.get(itemId)

      return item ? item.max : 0
    },

    isItemQuantityValid(itemId: ID) {
      const item = self.transferItems.get(itemId)

      return item ? item.isQuantityValid : false
    },

    get itemsForTransfer() {
      return Array.from(self.transferItems.values())
        .filter(item =>
          this.isImportMode
            ? item.import === true
            : item.isQuantityValidForTransfer,
        )
        .map(item =>
          this.isImportMode ? { ...item, quantity: IMPORT_QUANTITY } : item,
        )
    },

    get hasInvalidItem() {
      const invalidItem = Array.from(self.transferItems.values()).find(
        RM.propEq('isQuantityValid', false),
      )

      return Boolean(invalidItem)
    },
  }))

  .actions(self => ({
    setTargetId(branchId: NullableID) {
      self.targetBranchId = branchId
    },

    initItems(items: RoA<TInitItem>) {
      this.clearItems()

      items.forEach(item => {
        self.transferItems.put(item)
      })
    },

    toggleImportAll() {
      const newValue = !self.isImportingAll

      self.transferItems.forEach(item => (item.import = newValue))
    },

    toggleImportItem(itemId: ID) {
      const item = self.transferItems.get(itemId)

      if (item) {
        self.transferItems.put({ ...item, import: !item.import })
      }
    },

    setQuantity(itemId: ID, quantity: string) {
      const item = self.transferItems.get(itemId)

      if (item) {
        item.setRawQuantity(quantity)
      }
    },

    clearTransferState() {
      self.transferItems.forEach(item => {
        item.reset()
      })
    },

    clearItems() {
      self.transferItems.clear()
    },

    toggleImportMode() {
      self.importMode = !self.importMode
    },
  }))

export interface TStockTransferModel
  extends Instance<typeof StockTransferModel> {}
