import { isDefined } from '../../../helpers/isDefined'
import { useGetOrderExternalDelivery } from './useGetOrderExternalDelivery'

/**
 * returns true if the order has active external delivery
 */
export const useHasOrderExternalDelivery = (orderId: ID) => {
  const externalDelivery = useGetOrderExternalDelivery(orderId)

  return isDefined(externalDelivery)
}
