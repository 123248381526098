import { differenceInMinutes } from 'date-fns'
import * as React from 'react'

import { useConfirmModal } from '../../../components/Modals/useConfirmModal'
import {
  useMChangePrintRecordState,
  useQPastPrintRecordsLazyQuery,
  useSPrintRecords,
} from '../../../graph/generated'
import { logPrint } from '../../../helpers/logger'
import { useOnConnectionRestored } from '../../../hooks/useOnConnectionRestored'
import { i18n } from '../../../i18n'
import { TPrintRecord } from '../components/PrintWatch'
import { usePrintWatchModel } from '../models/usePrintWatchModel'

type TOptions = {
  requestPrint: (printRecord: TPrintRecord) => void
  requestPrintResult: (printRecordId: ID) => void
}

const UNKNOWN_MAC = `unknown` // not used anyway as in such case the Q & S are skipped

// Watch logic:
// - for watched printers, Q the print records user might have missed
// - connect a S for new print records that will come – this S needs to run above the whole app on all pages!
// - when we get a print record, try printing it (if the record is older, ask user if he still wants to print it, rather than printing it immediately)
export const usePrintWatch = ({
  requestPrint,
  requestPrintResult,
}: TOptions) => {
  const refQueriedPastRecords = React.useRef(false)
  const [qPastPrintRecords] = useQPastPrintRecordsLazyQuery()
  const [mUpdatePrintRecordState] = useMChangePrintRecordState()
  const [printOldRecordsModal, presentModal, dismissModal] = useConfirmModal()

  const { pcInfo, canPrint, availablePrinters, requestedRecordIds } =
    usePrintWatchModel()

  // watch all printers that are both registered on BE for specified branches AND connected to this PC
  const watchedPrinterIds = React.useMemo(() => {
    return availablePrinters?.map(printer => printer.id) ?? []
  }, [availablePrinters])

  // variables used in both the Q and the S
  const printWatchVariables = {
    watchedPrinterIds,
    thisPcMAC: pcInfo?.macAddress ?? UNKNOWN_MAC,
  }

  const skipWatch =
    !canPrint ||
    !pcInfo?.macAddress ||
    !availablePrinters ||
    availablePrinters.length === 0

  const cancelPrintRecord = React.useCallback(
    async (record: TPrintRecord) => {
      try {
        await mUpdatePrintRecordState({
          variables: {
            recordId: record.id,
            newState: `CANCELED`,
          },
        })
      } catch (error) {}
    },
    [mUpdatePrintRecordState],
  )

  // Q for the past print records that has not been printed yet
  // these might be records created during app reload or at any downtime
  // new records will be handled via S bellow
  const queryAndHandleRecords = React.useCallback(async () => {
    const result = await qPastPrintRecords(printWatchVariables)

    const recordsToPrint = result?.printRecords

    logPrint(`received via Q`, recordsToPrint)

    if (!recordsToPrint || recordsToPrint.length === 0) {
      return
    }

    // filter out records that were already requested before (eg. before the app reload) but they are not yet printed
    const notYetRequested = recordsToPrint.filter(record => {
      return !requestedRecordIds.includes(record.id)
    })

    // for those that were already requested before but we do not know they results, re-ask the printing service
    requestedRecordIds.forEach(async requestedRecordId => {
      requestPrintResult(requestedRecordId)
    })

    if (notYetRequested.length === 0) {
      return
    }

    // split those that should be printed based on how old are they
    const { olderRecords, recordsToAutoPrint } =
      separateOlderRecords(notYetRequested)

    // newer record should be printed immediately
    recordsToAutoPrint.forEach(record => {
      requestPrint(record)
    })

    if (olderRecords.length === 0) {
      return
    }

    // for older records, let user decide if they should be printed or canceled
    presentModal({
      confirmText: i18n.t`Print`,
      cancelText: i18n.t`Discard`,
      label: i18n.t`${olderRecords.length} older print requests appeared. What do you want to do with them?`,
      onCancel: () => {
        dismissModal()

        // discard all of the old records
        olderRecords.forEach(record => {
          cancelPrintRecord(record)
        })
      },
      onConfirm: () => {
        dismissModal()

        // print all of the old records
        olderRecords.forEach(record => {
          requestPrint(record)
        })
      },
    })
  }, [
    presentModal,
    dismissModal,
    requestPrint,
    cancelPrintRecord,
    qPastPrintRecords,
    requestedRecordIds,
    requestPrintResult,
    printWatchVariables,
  ])

  // fire the `queryAndHandleRecords` only once on the app load and only if we have all of the required data
  React.useEffect(() => {
    if (skipWatch || refQueriedPastRecords.current) {
      return
    }

    refQueriedPastRecords.current = true

    queryAndHandleRecords()
  }, [queryAndHandleRecords, skipWatch])

  // when the user gets the internet back, re-run the `queryAndHandleRecords` to load missed print records
  useOnConnectionRestored(queryAndHandleRecords)

  // connect the S that will listen for any future print records
  useSPrintRecords({
    variables: printWatchVariables,
    skip: skipWatch,
    onSubscriptionData({ subscriptionData }) {
      // on new print record, ask printing service to print it
      const printRecord = subscriptionData.data?.updatedPrintRecord.printRecord

      logPrint(`received via S`, printRecord)

      if (!printRecord) {
        return
      }

      requestPrint(printRecord)
    },
  })

  // just for logging purposes
  React.useEffect(() => {
    logPrint(`watched printers`, availablePrinters)
  }, [availablePrinters])

  return { printOldRecordsModal }
}

// records older than this are not printed automatically
// instead, user must confirm the print request (or discard the record) in presented modal
const oldRecordsThreshold = 5 // minutes

// separate older record that should be presented to the user and the newer ones that should be printed automatically
const separateOlderRecords = (printRecords: RoA<TPrintRecord>) => {
  const recordsToAutoPrint = printRecords.filter(record => {
    const recordAgeInMinutes = differenceInMinutes(
      new Date(),
      new Date(record.createdAt),
    )

    return recordAgeInMinutes <= oldRecordsThreshold
  })

  const olderRecords = printRecords.filter(record => {
    const recordAgeInMinutes = differenceInMinutes(
      new Date(),
      new Date(record.createdAt),
    )

    return recordAgeInMinutes > oldRecordsThreshold
  })

  return { recordsToAutoPrint, olderRecords }
}
