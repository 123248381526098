import { FlatSelectCustomInput } from './components/FlatSelectCustomInput'
import { FlatSelectOption } from './components/FlatSelectOption'
import { FlatSelectOptions } from './components/FlatSelectOptions'
import { FlatSelectProvider } from './contexts/FlatSelectProvider'

/**
 * FlatSelect is a generic select component that can be used to select from
 * predefined options.
 *
 * @example
 * // basic usage
 * <FlatSelect.Provider value={selectedValue} onChange={onSelectChange}>
 *   <FlatSelect.Options options={SOME_OPTIONS} />
 * </FlatSelect.Provider>
 *
 * // with special option
 * const isSpecialSelected = selectedValue === SPECIAL_VALUE
 *
 * <FlatSelect.Provider value={isSpecialSelected ? null : selectedValue} onChange={onSelectChange}>
 *   <FlatSelect.Options options={SOME_OPTIONS}>
 *     <FlatSelect.Option value={SPECIAL_VALUE} isSelected={isSpecialSelected} gridColumn={`span 2`}>
 *   </FlatSelect.Options>
 * </FlatSelect.Provider>
 *
 * // with custom input
 * <FlatSelect.Provider value={selectedValue} onChange={onSelectChange}>
 *   <FlatSelect.Options options={SOME_OPTIONS} />
 *   <FlatSelect.CustomInput>
 * </FlatSelect.Provider>
 *
 */
export const FlatSelect = {
  Provider: FlatSelectProvider,
  Options: FlatSelectOptions,
  Option: FlatSelectOption,
  CustomInput: FlatSelectCustomInput,
}

export { FLAT_SELECT_CONFIG } from './config/flatSelect.config'
