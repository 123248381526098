import { Instance, SnapshotIn, types } from 'mobx-state-tree'

import { LoginProviderModel } from '../screens/login/models/LoginProviderModel'
import { BaseModel } from './BaseModel'

export const AuthModel = BaseModel.named('Auth')
  .props({
    userId: types.maybeNull(types.string),
    token: types.maybeNull(types.string),
    provider: types.optional(LoginProviderModel, {}),
  })

  .views(self => ({
    get isAuthenticated() {
      return self.token !== null
    },

    get isAuthenticatedUser() {
      return this.isAuthenticated && self.userId !== null
    },
  }))

  .actions(self => ({
    authenticate(token: string, userId: string) {
      self.token = token
      self.userId = userId
      self.log('authenticated with id %s', userId)
    },

    invalidate() {
      self.token = null
      self.userId = null
      self.log('invalidated authentication')
    },
  }))

export interface TAuthModel extends Instance<typeof AuthModel> {}
export interface TAuthModelProps extends SnapshotIn<typeof AuthModel> {}
