import {
  MutationHookOptions as ApolloMutationHookOptions,
  useMutation as useApolloMutation,
} from '@apollo/react-hooks'
import { ApolloOperationError, useApolloDefender } from '@speedlo/graphql'
import * as React from 'react'

// reexport because code-gen takes the type from the same file as the hook
export type MutationHookOptions<TData, TVariables> = ApolloMutationHookOptions<
  TData,
  TVariables
>

/**
 * Apollo Client’s `useMutation` wrapper with custom error handling
 */
export const useMutation: typeof useApolloMutation = (mutation, options) => {
  const [mutate, result] = useApolloMutation(mutation, options)
  const { onError } = useApolloDefender()

  // add automatic error handling on each M call
  const mutateWithErrorHandle = React.useCallback<typeof mutate>(
    options => {
      return mutate(options).catch(error => {
        onError(new ApolloOperationError(error, mutation, options?.variables))
        throw error
      })
    },
    [mutate, mutation, onError],
  )

  return [mutateWithErrorHandle, result]
}
