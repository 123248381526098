import { TOrderOriginEnum } from '../../graph/generated'
import { i18n } from '../../i18n'

export const getOnlineOrdersDescription = () => {
  return i18n.t`orders that were not created by the staff or imported from external services (Wolt, Foodora, etc.)`
}

type TOrderOriginOrder = {
  orderOrigin: {
    enum: TOrderOriginEnum
  }
}

/**
 * as of now, it returns true if the order is not from staff or external service,
 * but it should probably be updated to return true if the order is from
 * our customer-faced app source (so no for admin or wolt but yes for kiosk)
 */
export const isOnlineOrder = ({ orderOrigin }: TOrderOriginOrder) => {
  return (
    orderOrigin.enum !== `STAFF` &&
    orderOrigin.enum !== `STAFF_POS` &&
    orderOrigin.enum !== `DAME_JIDLO` &&
    orderOrigin.enum !== `WOLT` &&
    orderOrigin.enum !== `BOLT`
  )
}
