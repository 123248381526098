import { Instance, SnapshotIn, types } from 'mobx-state-tree'

import { TFCompanyBranch, TQUserBootstrap } from '../graph/generated'
import { BaseModel } from '../models/BaseModel'
import { idProp, idPropEq, RM } from '../tools/ramda'

type TAccessFields = Defined<TQUserBootstrap['access']['queries']['fields']>
type TPermissions = TQUserBootstrap['permissions']
type TUser = TQUserBootstrap['user']
type TRole = TUser['roles'][number]

type TUserAccess = {
  queries: Set<string>
  mutations: Set<string>
  subscriptions: Set<string>
}

export const UserModel = BaseModel.named('User')
  .props({
    language: types.maybeNull(types.string),
  })

  .volatile(() => ({
    isLoaded: false,
    id: null as Nullable<ID>,
    phone: null as Nullable<string>,
    maskedEmail: null as Nullable<string>,
    access: null as Nullable<TUserAccess>,
    brands: [] as RoA<TFCompanyBranch['brand']>,
    branches: [] as RoA<TFCompanyBranch>,
    roles: null as Nullable<RoA<TRole>>,
    permissions: {
      canCancelOrders: false,
      canCreateOrder: false,
    } as TPermissions,
  }))

  .views(self => ({
    get branchesId() {
      return self.branches.map(idProp)
    },

    get defaultBranch() {
      if (self.branches.length === 0) {
        throw new Error('No user branches available to get defaultBranchId')
      }

      return self.branches[0]
    },

    get hasSingleBranch() {
      return self.branches.length === 1
    },

    findBranchById(branchId: ID) {
      return self.branches.find(idPropEq(branchId))
    },

    filterBranches(predicate: (value: TFCompanyBranch) => boolean) {
      return self.branches.filter(predicate)
    },

    canQuery(queryName: string) {
      return (
        self.access !== null && self.access.queries.has(queryName.toLowerCase())
      )
    },

    canMutate(mutationName: string) {
      return (
        self.access !== null &&
        self.access.mutations.has(mutationName.toLowerCase())
      )
    },

    get isReady(): boolean {
      return self.isLoaded || !self.root.auth.isAuthenticated
    },

    branchesFromBrand(brandId: ID) {
      return self.branches.filter(branch => {
        return branch.brand.id === brandId
      })
    },
  }))

  .actions(self => ({
    invalidate() {
      self.isLoaded = false
      self.language = null
      self.branches = []
      self.brands = []
      self.roles = []
      self.access = null
      self.phone = null
      self.maskedEmail = null
    },

    applyUserData(user: TUser) {
      self.id = user.id
      self.roles = user.roles
      self.phone = user.phone
      self.maskedEmail = user.maskedEmail
      self.branches = user.branches
      self.brands = RM.uniqBy(
        RM.prop('id'),
        user.branches.map(RM.prop('brand')),
      )
    },

    applyPermissions(permissions: TPermissions) {
      self.permissions = permissions
    },

    applyAccess(access: TQUserBootstrap['access']) {
      const makeSet = (fields: TAccessFields) =>
        new Set(fields.map(RM.compose(RM.toLower, RM.prop('name'))))

      self.access = {
        queries: makeSet(access.queries.fields ? access.queries.fields : []),
        mutations: makeSet(
          access.mutations && access.mutations.fields
            ? access.mutations.fields
            : [],
        ),
        subscriptions: makeSet(
          access.subscriptions && access.subscriptions.fields
            ? access.subscriptions.fields
            : [],
        ),
      }
    },

    finishBootstrap() {
      self.isLoaded = true
    },

    setLanguage(lang: string) {
      self.language = lang
    },

    setBranches(userBranches: RoA<TFCompanyBranch>) {
      self.branches = userBranches
    },
  }))

export interface TUserModel extends Instance<typeof UserModel> {}
export interface TUserModelProps extends SnapshotIn<typeof UserModel> {}
