import { sortCollection } from '../../../../helpers/sort'

type TSortTagBase = {
  label: Nullable<string>
}

/**
 * helper fnc for sorting product tags alphabetically ASC
 */
export const sortTagsAlphabetically = <TProductTag extends TSortTagBase>(
  productTags: RoA<TProductTag>,
) => {
  return sortCollection(productTags, (tagA, tagB) => {
    if (!tagA.label || !tagB.label) {
      return 0
    }

    return tagA.label.localeCompare(tagB.label, `cs`)
  })
}
