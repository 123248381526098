import { Colors } from '../../../styling'

type TOrderSeverity = 'unknown' | 'canceled' | 'normal' | 'warning' | 'critical'

type TResult = {
  bgColor: TColor // order severity color (red for critical, orange for warning, green for normal)
  textColor: TColor // contra color to the bgColor
  severity: TOrderSeverity // order severity based on remaining time until handover
}

type TOptions = {
  isCanceledOrder?: boolean
  minutesUntilHandover: Nullable<number>
}

const WARNING_MINUTE_THRESHOLD = 20
const CRITICAL_MINUTE_THRESHOLD = 5

/**
 * returns order severity and colors for remaining handover time
 */
export const getHandoverTimeSeverity = ({
  isCanceledOrder,
  minutesUntilHandover,
}: TOptions): TResult => {
  if (isCanceledOrder) {
    return {
      severity: 'canceled',
      bgColor: Colors.grey.medium,
      textColor: Colors.white.pure,
    }
  }

  if (minutesUntilHandover === null) {
    return {
      severity: 'unknown',
      bgColor: Colors.grey.medium,
      textColor: Colors.white.pure,
    }
  }

  if (minutesUntilHandover <= CRITICAL_MINUTE_THRESHOLD) {
    return {
      severity: 'critical',
      bgColor: Colors.red.main,
      textColor: Colors.white.pure,
    }
  }

  if (minutesUntilHandover <= WARNING_MINUTE_THRESHOLD) {
    return {
      severity: 'warning',
      bgColor: Colors.orange.main,
      textColor: Colors.white.pure,
    }
  }

  return {
    severity: 'normal',
    bgColor: Colors.green.main,
    textColor: Colors.white.pure,
  }
}
