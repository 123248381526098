import { OpenInNew } from '@material-ui/icons'
import * as React from 'react'

import { Icon } from '../../../atoms/Icon'
import { getSpaceValue, TSpace } from '../../../designSystem'
import { isDefined } from '../../../helpers/isDefined'
import { Text } from '../../Typography'
import { LinkStyled, TLinkProps } from './Link'

type TIconProps = OmitSafe<
  PropsOf<typeof Icon>,
  'children' | 'className' | 'onClick'
>

type TProps = TIconProps &
  TLinkProps & {
    gap?: TSpace
  }

/**
 * Customizable link with icon at the end divided by scalable gap.
 */
export const LinkWithIcon: React.FC<TProps> = ({
  text,
  color,
  children,
  colorHover,
  gap = `s4`,
  icon = OpenInNew,
  ...props
}) => {
  const iconSize = text
    ? getSeparatedSize(text.fontSize)
    : getSeparatedSize(`1rem`)
  const iconColor = color ? color : `inherit`

  return (
    <LinkStyled color={color} colorHover={colorHover} {...props}>
      <Text color={color} marginRight={getSpaceValue(gap)} {...text}>
        {children}
      </Text>
      <Icon icon={icon} color={iconColor} {...iconSize} />
    </LinkStyled>
  )
}

//* HELPERS
/**
 * Icon for some reason needs fontSizes separated as fontSize (number) and its unit.
 * So until better Icon is created, this helper transform normal fontSize to Icon format.
 */
const getSeparatedSize = (fontSize?: string) => {
  if (!isDefined(fontSize))
    return {
      fontSize: 1,
      fontSizeUnit: 'rem',
    }
  const numericPart = parseFloat(fontSize)
  const unitPart = fontSize.replace(numericPart.toString(), '').trim()
  return {
    fontSize: numericPart,
    fontSizeUnit: unitPart,
  }
}
