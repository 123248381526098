import * as React from 'react'

import { GridBox, GridColumnBox } from '../../../atoms/Box/Box'
import { MODAL_SEPARATOR_CONFIG, MODAL_SPACES } from '../config/modal.config'

type TGridProps = PropsOf<typeof GridBox>
type TProps = RequiredChildren &
  TGridProps & {
    withSeparator?: boolean
  }

const SEPARATOR_BORDER = `${MODAL_SEPARATOR_CONFIG.size} ${MODAL_SEPARATOR_CONFIG.color} solid`

/**
 * ModalFooter is a component that is used to render the footer of a modal
 * that usually contains action buttons and maybe some message.
 * It’s not based on MUI component (there isn’t any) but just on a GridBox.
 */
export const ModalFooter: React.FC<TProps> = ({
  children,
  withSeparator = false,
  ...gridProps
}) => {
  return (
    <GridColumnBox
      padding={MODAL_SPACES.lg}
      paddingTop={MODAL_SPACES.md}
      borderTop={withSeparator ? SEPARATOR_BORDER : 'unset'}
      alignItems={`center`}
      gridAutoColumns={`1fr auto`}
      gridTemplateRows={`100%`} // Safari
      {...gridProps}
    >
      {children}
    </GridColumnBox>
  )
}
