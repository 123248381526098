// loose autocomplete because theoretically anything can be passed into that env
type TAppStage =
  | 'dev'
  | 'stage'
  | 'stageBeta'
  | 'prod'
  | 'speedlopay'
  | (string & {})

const stage = process.env.REACT_APP_STAGE as TAppStage

const isDev = stage === 'dev'
const isStage = stage === 'stage'
const isStageBeta = stage === 'stageBeta'
const isProd = stage === 'prod'
const isSpeedlopay = stage === 'speedlopay'

/**
 * returns variables related to the app stage,
 * such as isDev, isStage, isProd, etc.,
 * all based on the REACT_APP_STAGE env variable
 */
export const APP_STAGE = {
  stage,

  isDev,
  isStage,
  isStageBeta,
  isProd,
  isSpeedlopay,

  isNotProd: !isProd && !isSpeedlopay,
}
