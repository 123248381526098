import * as React from 'react'
import styled from 'styled-components/macro'

import { FONT_WEIGHTS } from '../../../designSystem'
import { Colors } from '../../../styling'

type TStyledProps = {
  textAlign?: 'left' | 'center' | 'right'
}

type TProps = NoChildren & TStyledProps

const StyledHeadline = styled.h1<TStyledProps>`
  font-size: 2.75rem;
  color: ${Colors.speedlo.main};
  text-align: ${({ textAlign = `left` }) => textAlign};

  strong {
    font-weight: ${FONT_WEIGHTS.bold};
  }
`

export const AppNameScreenHeadline: React.FC<TProps> = styledProps => {
  return (
    <StyledHeadline {...styledProps}>
      <strong>speedlo</strong> admin
    </StyledHeadline>
  )
}
