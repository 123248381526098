import { Instance, types } from 'mobx-state-tree'

import {
  TFBillingExpense,
  TOperatingCostTypeEnum,
  TOrderOriginEnum,
  TQBillingExpenseSummary,
  TQBillingNotificationExpenses,
  TQBillingOverview,
  TQBillingRevenue,
} from '../../../../graph/generated'
import { i18n } from '../../../../i18n'
import { BaseModel } from '../../../../models/BaseModel'
import { RM } from '../../../../tools/ramda'
import {
  TBillingExpenseDetailSection,
  TBillingExpensesForOrigin,
  TBillingExpenseSummary,
  TBillingNotificationExpenseRecord,
  TBillingNotificationExpenses,
  TBillingOverview,
  TBillingRevenueSummary,
  TOrderOriginOrOther,
} from '../typings/billing.types'

// GQL data types
type TGqlBillingOverview = TQBillingOverview
type TGqlRevenueSummary = TQBillingRevenue['paymentsStats']
type TGqlExpenseSummary = TQBillingExpenseSummary['feeStats'][number]
type TGqlNotificationExpenses =
  TQBillingNotificationExpenses['costStats'][number]
type TGqlCommissionFees = TFBillingExpense
type TGqlDeliveryExpenses = TFBillingExpense
type TGqlPaymentExpenses = TFBillingExpense

// UI data types
type TBillingData = {
  overview: Nullable<TBillingOverview>
  revenue: Nullable<TBillingRevenueSummary>
  expenseSummary: Nullable<TBillingExpenseSummary>
  commissionFees: Nullable<RoA<TBillingExpenseDetailSection>>
  paymentExpenses: Nullable<RoA<TBillingExpenseDetailSection>>
  deliveryExpenses: Nullable<RoA<TBillingExpenseDetailSection>>
  notificationExpenses: Nullable<RoA<TBillingNotificationExpenses>>
}

type TVolatileProps = TBillingData & {
  currency: string
  overviewRevenueHighlighted: boolean
  overviewExpensesHighlighted: boolean
  selectedSection: `expenses` | `revenue`
}

export const BillingModel = BaseModel.named(`Billing`)
  .props({
    // potentially, this could be used to give users a chance to show/hide event the values where no expense/fee is set
    // right now they are just hidden without an option to show them
    hideUnset: types.optional(types.boolean, true),
  })

  .volatile<TVolatileProps>(() => ({
    currency: ``,
    revenue: null,
    overview: null,
    expenseSummary: null,
    commissionFees: null,
    paymentExpenses: null,
    deliveryExpenses: null,
    notificationExpenses: null,
    selectedSection: `expenses`,
    overviewRevenueHighlighted: false,
    overviewExpensesHighlighted: false,
  }))

  .views(self => ({
    // returns whether the branch reached it’s commission limit (if set)
    // used to highligh which value is actually billed
    get reachedFeeLimit() {
      if (!self.expenseSummary) {
        return false
      }

      if (!self.expenseSummary.commissionLimit) {
        return false
      }

      return (
        self.expenseSummary.commission >= self.expenseSummary.commissionLimit
      )
    },
  }))

  // some more generic actions
  .actions(self => ({
    setClientCurrency(currency: string) {
      self.currency = currency
    },

    showExpenses() {
      self.selectedSection = `expenses`
    },

    showRevenue() {
      self.selectedSection = `revenue`
    },

    highlightOverviewRevenue(highlighted: boolean) {
      self.overviewRevenueHighlighted = highlighted
    },

    highlightOverviewExpenses(highlighted: boolean) {
      self.overviewExpensesHighlighted = highlighted
    },
  }))

  // data transform & save – from GQL Q type to a type required by the UI
  .actions(self => ({
    // BillingOverview
    setBillingOverview(billingOverview: TGqlBillingOverview) {
      const holdBySpeedlo = billingOverview.paymentStats.holdBySpeedlo.value
      const totalExpenses =
        billingOverview.feeStats[0]?.totalExpenses.value ?? 0

      const returnValue = holdBySpeedlo - totalExpenses

      self.overview = {
        returnValue,
        holdBySpeedlo,
        totalExpenses,
      }
    },

    // BillingExpenses (summary)
    setExpenseSummary(expenseSummary: TGqlExpenseSummary) {
      self.expenseSummary = {
        payments: expenseSummary.payments.value,
        billedFee: expenseSummary.billedFee.value,
        commission: expenseSummary.commission.value,
        deliveries: expenseSummary.deliveries.value,
        notifications: expenseSummary.notifications.value,
        speedloServices: expenseSummary.speedloServices.value,
        commissionLimit: expenseSummary.commissionLimit.value || null, // replace 0 (= no limit) with null
        total: expenseSummary.total.value,
        totalWithVat: expenseSummary.totalWithVat.value,
      }
    },

    // BillingRevenue (summary)
    setRevenueSummary(revenue: TGqlRevenueSummary) {
      self.revenue = {
        holdBySpeedlo: revenue.holdBySpeedlo.value,
        prePaidOrderCount: revenue.prePaidOrderCount,
        prePaidRevenue: revenue.prePaidRevenue.value,
        postPaidOrderCount: revenue.postPaidOrderCount,
        postPaidRevenue: revenue.postPaidRevenue.value,
      }
    },

    // BillingPaymentExpenses (detail)
    setPaymentExpenses(expenses: RoA<TGqlPaymentExpenses>) {
      // group payment expenses by its type (payment gate)
      const expensesByType = groupExpensesByType(expenses)

      // for each payment gate prepare the table data
      const paymentTypesExpenses = RM.mapObjIndexed(typeExpenses => {
        // group payment gate expenses by order origin
        const typeExpensesByOrigin = transformExpensesOfType(typeExpenses)

        return {
          expensesByOrigin: typeExpensesByOrigin,
          typeTotal: reduceTypeTotal(typeExpensesByOrigin), // calculate total value for the whole table (payment gate)
          typeLabel: typeExpenses[0]?.info.expenseType.label ?? `???`, // table (payment gate) label
        }
      }, expensesByType)

      // save the data as an array (each item contains data for a specific payment gate)
      self.paymentExpenses = Array.from(Object.values(paymentTypesExpenses))
    },

    // BillingDeliveryExpenses (detail)
    setDeliveryExpenses(expenses: RoA<TGqlDeliveryExpenses>) {
      // group payment expenses by its type (delivery type – messenger, pickup, ...)
      const expensesByType = groupExpensesByType(expenses)

      // for each delivery type prepare the table data
      const deliveryTypesExpenses = RM.mapObjIndexed(typeExpenses => {
        // group delivery type expenses by order origin
        const typeExpensesByOrigin = transformExpensesOfType(typeExpenses)

        return {
          expensesByOrigin: typeExpensesByOrigin,
          typeTotal: reduceTypeTotal(typeExpensesByOrigin), // calculate total value for the whole table (delivery type)
          typeLabel: typeExpenses[0]?.info.expenseType.label ?? `???`, // table (delivery type) label
        }
      }, expensesByType)

      // save the data as an array (each item contains data for a specific delivery type)
      self.deliveryExpenses = Array.from(Object.values(deliveryTypesExpenses))
    },

    // Billing(Commission)FeeExpenses (detail)
    setCommissionFees(commissionFees: RoA<TGqlCommissionFees>) {
      // commission fees are just of one typ (BASIC)
      // all of the expenses will be grouped into one table (rows by origin)
      const feesByOrigin = transformExpensesOfType(commissionFees)

      // to keep the typing consistent across the sections, wrap the fees in an array
      // (= data for one detail table)
      self.commissionFees = [
        {
          // this table is without headline -> just fill the values with nulls and it won’t get rendered
          typeLabel: null,
          typeTotal: null,
          expensesByOrigin: feesByOrigin,
        },
      ]
    },

    // BillingNotificationExpenses (detail)
    setNotificationExpenses(expenses: RoA<TGqlNotificationExpenses>) {
      // notification expenses have a bit simplified table (just SMS count and total expenses per origin)
      // that’s why we need to use a specific transform function (using different reduce function under the hood)
      const expensesByOrigin = transformNotificationExpenses(expenses)

      // to keep the typing consistent across the sections, wrap the notification expenses in an array
      // (= data for one detail table)
      self.notificationExpenses = [
        {
          typeLabel: i18n.t`SMS`,
          expensesByOrigin,
        },
      ]
    },
  }))

  // methods for data erasing when they are not needed/used anymore
  .actions(self => ({
    invalidateBillingOverview() {
      self.overview = null
    },

    invalidateExpenseSummary() {
      self.expenseSummary = null
    },

    invalidateRevenueSummary() {
      self.revenue = null
    },

    invalidateNotificationExpenses() {
      self.notificationExpenses = null
    },

    invalidatePaymentExpenses() {
      self.paymentExpenses = null
    },

    invalidateCommissionFees() {
      self.commissionFees = null
    },

    invalidateAllData() {
      this.invalidateRevenueSummary()
      this.invalidateBillingOverview()
      this.invalidateCommissionFees()
      this.invalidatePaymentExpenses()
      this.invalidateExpenseSummary()
      this.invalidateNotificationExpenses()
    },
  }))

export interface TBillingModel extends Instance<typeof BillingModel> {}

// *** Helper functions & types ***

// base type that records in `groupExpensesByType` has to extend
type TRecordWithExpenseTypeBase = {
  info: {
    expenseType: {
      enum: TOperatingCostTypeEnum
    }
  }
}

/**
 * function for grouping expense records by the expense type (payment gate, delivery type, ...)
 */

const groupExpensesByType = <TRecord extends TRecordWithExpenseTypeBase>(
  records: RoA<TRecord>,
) => {
  // reduce records into a Record where the records are assigned by their expense type (payment gate, delivery type, ...)
  return records.reduce((acc, expenseRecord) => {
    const expenseType = expenseRecord.info.expenseType.enum
    const previousExpenses = acc[expenseType]

    if (previousExpenses !== undefined) {
      // found some records with this type before -> add this record to them
      previousExpenses.push(expenseRecord)
    } else {
      // this is the first time we met this expense type -> assign this expenses to the new field in the Record
      acc[expenseType] = [expenseRecord]
    }

    return acc
  }, {} as Record<TOperatingCostTypeEnum, TRecord[]>)
}

// base type that the records in `annotateEmptyOrigin` has to extend (taken from GQL)
type TRecordBaseWithNullableOrigin = {
  orderOrigin: Nullable<{
    label: string
    enum: TOrderOriginEnum
  }>
}

/**
 * function for assigning records with orderOrigin === null to the `OTHER` fake origin
 * use in Array.map
 */
const annotateEmptyOrigin = <TRecord extends TRecordBaseWithNullableOrigin>({
  orderOrigin,
  ...expenseRecord
}: TRecord) => {
  const origin: TOrderOriginOrOther = orderOrigin?.enum ?? `OTHER`
  const originLabel: string = orderOrigin?.label ?? i18n.t`other`

  return {
    ...expenseRecord,
    orderOrigin: {
      enum: origin,
      label: originLabel,
    },
  }
}

// base type that the records in `groupByOrigin` has to extend
// basically the output of the `annotateEmptyOrigin`
type TRecordBaseWithOrigin = {
  orderOrigin: {
    label: string
    enum: TOrderOriginOrOther
  }
}

// type for an object where keys are of a type `TOrderOriginOrOther`
// and each key has assigned an array of records with that origin
type TRecordsByOrigin<TRecord> = Record<TOrderOriginOrOther, TRecord[]>

/**
 * used for grouping expense records by the order origin
 * produces an object where keys are of type `TOrderOriginOrOther` and values are associated record arrays
 */
const groupByOrigin = <TRecord extends TRecordBaseWithOrigin>(
  records: RoA<TRecord>,
) => {
  return records.reduce((acc, expenseRecord) => {
    const origin = expenseRecord.orderOrigin.enum
    const previousValue = acc[origin]

    if (previousValue !== undefined) {
      // found some records with this origin before -> add this record to them
      previousValue.push(expenseRecord)
    } else {
      // this is the first time we met this origin -> assign this expenses to the new field in the Record
      acc[origin] = [expenseRecord]
    }

    return acc
  }, {} as TRecordsByOrigin<TRecord>)
}

// function for reducing collection of `TSourceRecord` into one `TReducedRecord`
// used inside a Array.reduce
type TRecordReducerFunction<TSourceRecord, TReducedRecord> = (
  acc: Nullable<TReducedRecord>,
  currentRecord: TSourceRecord,
) => TReducedRecord

/**
 * reduces records of each origin using the provided reduceFunction
 */
const reduceOriginRecords =
  <TSourceRecord extends TRecordBaseWithOrigin, TReducedRecord>(
    reduceFunction: TRecordReducerFunction<TSourceRecord, TReducedRecord>,
  ) =>
  (
    recordsByOrigin: TRecordsByOrigin<TSourceRecord>, // format comment
  ) => {
    return RM.mapObjIndexed(originExpenseRecords => {
      // reduce the expense records with the same origin using the provided reduceFunction
      return originExpenseRecords.reduce(
        reduceFunction,
        null as Nullable<TReducedRecord>,
      )
    }, recordsByOrigin)
  }

// type for replacing Nullable<...> type of `orderOrigin` in GQL types with the one from `TRecordBaseWithOrigin`
// => typing the output of the `annotateEmptyOrigin`
type TWithCorrectedOrigin<TRecord> = Omit<TRecord, 'orderOrigin'> &
  TRecordBaseWithOrigin

/**
 * TRecordReducerFunction for most of the expense sections (that use TFBillingExpense)
 * used to transform an array of GQL type (~ TFBillingExpense) records with the same origin
 * into a single record of the UI type (TBillingExpensesForOrigin)
 */
const reduceExpenses: TRecordReducerFunction<
  TWithCorrectedOrigin<TFBillingExpense>,
  TBillingExpensesForOrigin
> = (acc, currentRecord): TBillingExpensesForOrigin => {
  const origin = currentRecord.orderOrigin.enum
  const originLabel = currentRecord.orderOrigin.label

  const orderCount = currentRecord.orderCount
  const fixedCoef = currentRecord.settings?.fixedCoef ?? 0
  const fixedTotal = currentRecord.fixedTotal.value

  const orderRevenue = currentRecord.orderRevenue.value
  const percentageCoef = currentRecord.settings?.percentageCoef ?? 0
  const percentageTotal = currentRecord.percentageTotal.value

  const originTotal = fixedTotal + percentageTotal

  if (acc !== null) {
    // not the first record -> add the values that should be summed up to the previous ones
    return {
      origin,
      originLabel,
      fixedCoef,
      orderCount: acc.orderCount + orderCount,
      fixedTotal: acc.fixedTotal + fixedTotal,
      percentageCoef,
      orderRevenue: acc.orderRevenue + orderRevenue,
      percentageTotal: acc.percentageTotal + percentageTotal,
      originTotal: acc.originTotal + originTotal,
    }
  }

  // this is the first record in the array – replace `null` acc with the transformed data
  return {
    origin,
    originLabel,
    orderCount,
    fixedCoef,
    fixedTotal,
    percentageCoef,
    orderRevenue,
    percentageTotal,
    originTotal,
  }
}

/**
 * TRecordReducerFunction for notification expenses that has to be prepared in a different way
 * used to transform an array of GQL type (~ TGqlNotificationExpenses) records
 * into a single record of the UI type (TBillingNotificationExpenseRecord)
 */
const reduceNotificationExpenses: TRecordReducerFunction<
  TWithCorrectedOrigin<TGqlNotificationExpenses>,
  TBillingNotificationExpenseRecord
> = (acc, currentRecord): TBillingNotificationExpenseRecord => {
  const origin = currentRecord.orderOrigin.enum
  const originLabel = currentRecord.orderOrigin.label
  const smsCount = currentRecord.notificationCount
  const smsTotal = currentRecord.totalSum.value

  if (acc !== null) {
    // not the first record -> add the values that should be summed up to the previous ones
    return {
      origin,
      originLabel,
      smsCount: acc.smsCount + smsCount,
      smsTotal: acc.smsTotal + smsTotal,
    }
  }

  // this is the first record in the array – replace `null` acc with the transformed data
  return {
    origin,
    smsCount,
    smsTotal,
    originLabel,
  }
}

// base type that the records in `sumTotals` has to extend
type TRecordWithTotalsBase = {
  originTotal: number
}

/**
 * calculates the overall total for records of certain expense type (payment gate, delivery type, fee, ...)
 * grouped by their origin by adding their originTotal prop
 * used to calculate total values for the whole BillingExpenseDetailTable
 */
const reduceTypeTotal = <TRecord extends TRecordWithTotalsBase>(
  recordsOfTypeByOrigin: RoA<TRecord>,
) => {
  return recordsOfTypeByOrigin.reduce((acc, { originTotal }) => {
    return acc + originTotal
  }, 0)
}

/**
 * the whole process of transforming the records of specific type (payment gate, delivery type, fee, ...)
 * from GQL type to UI type
 * specific reduceFunction can be provided (eg. for notification section)
 * uses most of the helper functions above
 */
const transformExpensesOfTypeGeneric =
  <TSourceRecord extends TRecordBaseWithNullableOrigin, TResultRecord>(
    recordReduceFunction: TRecordReducerFunction<
      TWithCorrectedOrigin<TSourceRecord>,
      TResultRecord
    >,
  ) =>
  (
    typeExpenses: RoA<TSourceRecord>, // format comment
  ) => {
    // 1. replace "missing" (null) order origins with `OTHER` fake origins
    const annotatedExpenses = typeExpenses.map(annotateEmptyOrigin)
    // 2. group expense records by their common origins
    const expensesByOrigin = groupByOrigin(annotatedExpenses)
    // 3. transform GQL types to UI type and sum up the values (= prepare data for table rows)
    const summedUpExpenses =
      reduceOriginRecords(recordReduceFunction)(expensesByOrigin)
    // 4. transform the Record into an array (each item contains data for a single table row, a single origin)
    const expensesInArray = Array.from(Object.values(summedUpExpenses))
    // 5. filter out origins with no records (should be mostly just for type definitions)
    const nonEmptyExpensesByOrigin = expensesInArray
      .filter(expensesByOrigin => expensesByOrigin !== null)
      .map(expensesByOrigin => expensesByOrigin!)

    return nonEmptyExpensesByOrigin
  }

// specific versions of transformExpensesOfTypeGeneric (above) with predefined reduceFunctions
const transformExpensesOfType = transformExpensesOfTypeGeneric(reduceExpenses)
const transformNotificationExpenses = transformExpensesOfTypeGeneric(
  reduceNotificationExpenses,
)
