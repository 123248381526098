import { Instance } from 'mobx-state-tree'

import { BaseModel } from '../../../models/BaseModel'

/**
 * model with all feature flags active in the app,
 * add new features that you want to preview here
 *
 * @example
 * newOrderStyles: types.optional(
    getFeaturePreviewModel({ defaultValue: true }),
    {},
  ),
 */
export const FeaturePreviewsModel = BaseModel.named('FeaturePreviews').props({})

export interface TFeaturePreviewsModel
  extends Instance<typeof FeaturePreviewsModel> {}
