import { TFOrderForNumber } from '../../graph/generated'

type TOrderNumberType = 'orderNumber' | 'id' | 'any'

/**
 * returns order number in preferred format,
 * by default returns orderNumber if it exists, otherwise returns id,
 * but can be forced to return id or orderNumber
 *
 * orderNumber is a short-term ID used in real-time scenarios (like kitchen or order overview),
 * id is a long, unique ID used in long-term views (like order history or statistics)
 */
export const getOrderNumber = (
  order: TFOrderForNumber,
  type: TOrderNumberType = 'any',
) => {
  switch (type) {
    case 'any':
      return getOrderNumberDefined(order)

    case 'orderNumber':
      return order.orderNumber !== null ? String(order.orderNumber) : null

    case 'id':
      return order.id

    default:
      return null
  }
}

/**
 * returns orderNumber if it exists, otherwise returns id
 */
export const getOrderNumberDefined = (order: TFOrderForNumber) => {
  return String(order.orderNumber ?? order.id)
}
