import { ApolloProvider } from '@speedlo/graphql'
import * as React from 'react'

import { createApolloClient } from '../../../graph/createClient'
import { APP_STAGE } from '../../../helpers/appStage'
import { persistModel } from '../../../helpers/persistModel'
import { LoadingPage } from '../../../pages/LoadingPage'
import { createContext } from '../../../tools/createContext'
import { RootModel, TRootModel } from '../models/RootModel'

const [RootContextProvider, useRoot] = createContext<TRootModel>(`Root`)

type TProps = Children

/**
 * provider of the root model – all global state in the app,
 * includes the GQL apollo provider as well
 */
export const RootProvider: React.FC<TProps> = ({ children }) => {
  const [isInit, setInit] = React.useState(false)
  const [root] = React.useState(() => RootModel.create())

  const [client] = React.useState(() => {
    return createApolloClient(root)
  })

  React.useEffect(() => {
    if (APP_STAGE.isDev) {
      // @ts-ignore
      window.root = root
    }

    Promise.all(root.persistedModels.map(persistModel)).then(() => {
      setInit(true)
    })
  }, [root])

  if (!isInit) {
    return <LoadingPage />
  }

  return (
    <ApolloProvider client={client}>
      <RootContextProvider value={root}>
        {React.Children.only(children)}
      </RootContextProvider>
    </ApolloProvider>
  )
}

export { RootContextProvider }
export { useRoot }
