import useAudio from 'react-use/lib/useAudio'
import { HTMLMediaProps } from 'react-use/lib/util/createHTMLMediaHook'

export const useAudioPlayer = (options: HTMLMediaProps) => {
  const [audio, state, controls] = useAudio({
    loop: false,
    autoPlay: false,
    ...options,
  })

  return [audio, controls, state] as const
}
