import styled from 'styled-components/macro'

import { Spacing } from '../styling'

type TStyledProps = {
  top?: number
  left?: number
  right?: number
  bottom?: number
  className?: string
  margin?: string | number
}

export const MarginBox = styled('div')<TStyledProps>`
  margin: ${({ margin, top = 0, bottom = 0, left = 0, right = 0 }) => {
    if (typeof margin === 'string') {
      return margin
    }

    if (typeof margin === 'number') {
      return `${margin}${Spacing.DefaultSizeUnit}`
    }

    return `${top}${Spacing.DefaultSizeUnit} ${right}${Spacing.DefaultSizeUnit} ${bottom}${Spacing.DefaultSizeUnit} ${left}${Spacing.DefaultSizeUnit}`
  }};
`
