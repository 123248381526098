import * as React from 'react'

type TBaseProps = {
  isOpen: boolean
}

export const useModalControl = <TModalProps extends TBaseProps>(
  ModalComponent: React.FC<TModalProps>,
) => {
  const [modalElement, setModalElement] = React.useState<React.ReactNode>(null)

  const dismissModal = React.useCallback(() => setModalElement(null), [])

  const presentModal = React.useCallback(
    (modalProps: Omit<PropsOf<typeof ModalComponent>, 'isOpen'>) => {
      setModalElement(
        React.createElement(ModalComponent, {
          ...modalProps,
          isOpen: true,
        } as TModalProps),
      )
    },
    [ModalComponent],
  )

  return [modalElement, presentModal, dismissModal] as const
}
