import * as React from 'react'
import ReactResponsive from 'react-responsive'

export const mobileBreakpoint = 600
export const mobileSmallBreakpoint = 375
export const desktopBreakpoint = 1300

export const Desktop = props => (
  <ReactResponsive {...props} minWidth={mobileBreakpoint} />
)
export const Mobile = props => (
  <ReactResponsive {...props} maxWidth={mobileBreakpoint} />
)

export const queries = {
  mobile: `only screen and (max-width: ${mobileBreakpoint}px)`,
  smallMobile: `only screen and (max-width: ${mobileSmallBreakpoint}px)`,
  largerThanMobile: `only screen and (min-width: ${mobileBreakpoint + 1}px)`,
  smallerThanDesktop: `only screen and (max-width: ${desktopBreakpoint}px)`,
  minWidth: (minWidthPx: number) =>
    `only screen and (min-width: ${minWidthPx}px)`,
  maxWidth: (maxWidthPx: number) =>
    `only screen and (max-width: ${maxWidthPx}px)`,
}

export function useResponsive() {
  const [state, setState] = React.useState({
    isMobile: false,
    isSmallMobile: false,
    isDesktop: false,
    isTablet: true,
  })

  React.useLayoutEffect(() => {
    update() // initial set

    window.addEventListener('resize', update)
    return () => {
      window.removeEventListener('resize', update)
    }

    function update() {
      const { innerWidth } = window

      const isMobile = innerWidth <= mobileBreakpoint
      const isSmallMobile = innerWidth <= mobileSmallBreakpoint
      const isDesktop = innerWidth >= desktopBreakpoint
      const isTablet = !isMobile && !isDesktop

      setState({ isMobile, isSmallMobile, isDesktop, isTablet })
    }
  }, [])

  return state
}
