import Color from 'color'

import { TColorShade } from '../colors'

/**
 * normalize the value into <0, 1> interval
 */
const normalizeValue = (value: number) => {
  let normalizedValue = value

  if (normalizedValue < 0) {
    normalizedValue = 0
  } else if (normalizedValue > 1) {
    normalizedValue = 1
  }

  return normalizedValue
}

// check if the color is defined in a way that it can’t be manipulated
const isUndefinable = (color: string) => {
  return isTransparent(color) || color === `inherit` || color === `transparent`
}

/**
 * Decides what color shade (dark/light) would have a better contrast with provided color.
 * Typically used for deducing text color from the background
 */
export const getContrastShadeFor = (color: string): TColorShade => {
  if (isUndefinable(color)) return 'light'

  // return Color(color).isDark() ? 'light' : 'dark'
  return Color(color).luminosity() < 0.4 ? 'light' : 'dark'
}

const GREYISH_COLOR_MAX_SATURATION = 15

/**
 * Decides if the color is kind of grey or colorful
 */
export const getIsGreyishColor = (color: string): boolean => {
  if (isUndefinable(color)) return false

  return Color(color).saturationv() < GREYISH_COLOR_MAX_SATURATION
}

/**
 * decides if the bg color could have reversed fg shade on it
 * eg. for intentional low contrast disabled state
 * or if it would be too unreadable/invisible
 */
export const canReverseShade = (color: string) => {
  if (isUndefinable(color)) return false

  const luminosity = Color(color).luminosity()

  // if the bg color is too dark or too light, reversed shade would be way too unreadable
  if (luminosity > 0.8) return false
  if (luminosity < 0.2) return false

  return true
}

/**
 * Returns the opposite color shade (dark/light) than the one you passed in
 */
export const reverseShade = (shade: TColorShade): TColorShade => {
  return shade === 'dark' ? 'light' : 'dark'
}

/**
 * Make the provided color (more) opaque
 * @param inputColor the base color
 * @param inputOpacity the required alpha
 */
export const makeTransparent = (
  inputColor: string,
  inputOpacity: number = 1,
) => {
  const opacity = normalizeValue(inputOpacity)

  return Color(inputColor).alpha(opacity).hsl().string()
}

/**
 * Make the provided color more light
 * @param inputColor
 * @param ratio
 */
export const lighten = (inputColor: string, ratio: number = 0) => {
  const color = Color(inputColor)
  // black color has lightness of 0 and since `lighten` does multiply this value by provided ratio
  // any ratio * 0 = 0 -> set lightness to at least 1 so it can be multiplied
  const notBlack = color.lightness() === 0 ? color.lightness(1) : color

  return notBlack.lighten(ratio).hsl().string()
}

/**
 * Make the provided color more dark
 * @param inputColor
 * @param ratio
 */
export const darken = (inputColor: string, ratio: number = 0) => {
  return Color(inputColor).darken(ratio).hsl().string()
}

// transform hover color change ratio value based on luminosity
// because light color should be changed less than darker colors
const modifyRatioForLuminosity = (ratio: number, luminosity: number) => {
  switch (true) {
    case luminosity === 0:
      // 0
      return ratio * 70
    case luminosity < 0.01:
      // 0 - 0.01
      return ratio * 10
    case luminosity < 0.05:
      // 0.01 - 0.05
      return ratio * 3
    case luminosity < 0.1:
      // 0.05 - 0.2
      return ratio * 2
    case luminosity < 0.7:
      // 0.2 - 0.7
      return ratio
    case luminosity < 0.8:
      // 0.7 - 0.8
      return ratio / 2
    case luminosity < 0.95:
      // 0.8 - 0.95
      return ratio / 3
    case luminosity <= 1:
      // 0.95 - 1
      return ratio / 6
    default:
      return ratio
  }
}

/**
 * Derive hover color from the provided color
 * @param inputColor
 */
export const createHoverColor = (inputColor: string, ratio: number = 0.16) => {
  const luminosity = Color(inputColor).luminosity()
  const isVeryDark = luminosity < 0.05
  const dynamicRatio = modifyRatioForLuminosity(ratio, luminosity)

  const hoverColor = isVeryDark
    ? lighten(inputColor, dynamicRatio)
    : darken(inputColor, dynamicRatio)

  return roundHslValues(hoverColor)
}

const CHANNEL_WHITE = 255

/**
 * Make the provided color more white (take that, Coca-cola)
 * @param inputColor
 * @param inputLight
 */
export const whiten = (inputColor: string, inputLight: number = 0) => {
  const color = Color(inputColor)
  const light = normalizeValue(inputLight)

  // newRed = oldRed + light * (255 - oldRed)
  const newColorChannels = color
    .rgb()
    .array()
    .map(originalColorChannel => {
      return (
        originalColorChannel + light * (CHANNEL_WHITE - originalColorChannel)
      )
    })

  return Color.rgb(newColorChannels).hsl().string()
}

/**
 * returns whether the color is fully transparent
 */
export const isTransparent = (inputColor: string) => {
  return Color(inputColor).alpha() === 0
}

/**
 * returns whether the color is partially transparent
 */
export const hasAlpha = (inputColor: string) => {
  return Color(inputColor).alpha() < 1
}

/**
 * transforms provided color string to HSL(A) format
 */
export const toHsl = (inputColor: string) => {
  return Color(inputColor).hsl().string()
}

/**
 * transforms provided color string to HEX format
 */
export const toHex = (inputColor: string) => {
  return Color(inputColor).hex()
}

/**
 * returns HSL(A) color with rounded hue, saturation, lightness and alpha
 */
export const roundHslValues = (inputColor: string) => {
  const color = Color(inputColor)

  const hue = Math.round(color.hue())
  const saturation = Math.round(color.saturationl())
  const lightness = Math.round(color.lightness())
  const alpha = Math.round(color.alpha() * 100) / 100 // 2 decimal places

  return alpha < 1
    ? `hsla(${hue}, ${saturation}%, ${lightness}%, ${alpha})`
    : `hsl(${hue}, ${saturation}%, ${lightness}%)`
}
