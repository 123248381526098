import { Instance, types } from 'mobx-state-tree'

import { FilterInputModel } from '../../../components/FilterInput/FilterInputModel'
import { TFCompanyBranch, TLanguageEnum } from '../../../graph/generated'
import { BaseModel } from '../../../models/BaseModel'
import { RecipeListSortModel } from './RecipeListSortModel'

export const MenuModel = BaseModel.named('Menu')
  .props({
    filter: types.optional(FilterInputModel, {}),
    language: types.maybeNull(types.string),
    recipeListSort: types.optional(RecipeListSortModel, {}),
  })

  .volatile(() => ({
    recipeListScroll: 0,
    categoryListScroll: 0,
    categoryFilterId: null as NullableID,
  }))

  .views(self => ({
    get branchId(): ID {
      return self.root.settings.branchId
    },

    get branch(): TFCompanyBranch | undefined {
      return self.root.user.findBranchById(this.branchId)
    },

    get currentLanguage() {
      const lang = self.language as Nullable<TLanguageEnum>
      const currentBranchSupportsLang = lang
        ? this.supportedLanguages.includes(lang)
        : false

      if (lang && currentBranchSupportsLang) {
        return lang
      }

      return this.defaultLanguage
    },

    get defaultLanguage() {
      return this.branch!.defaultLanguage
    },

    get supportedLanguages() {
      const branch = this.branch

      return branch ? branch.supportedLanguages : []
    },

    get isDefaultLanguageSelected() {
      return this.defaultLanguage === this.currentLanguage
    },
  }))

  .actions(self => ({
    setLanguage(language: string) {
      self.language = language
    },

    setCategoryFilter(categoryId: NullableID) {
      self.categoryFilterId = categoryId
    },

    setRecipeListScroll(scrollTop: number) {
      self.recipeListScroll = scrollTop
    },

    setCategoryListScroll(scrollTop: number) {
      self.categoryListScroll = scrollTop
    },
  }))

export interface TMenuModel extends Instance<typeof MenuModel> {}
