import { Button, Snackbar } from '@material-ui/core'
import { SnackbarProps } from '@material-ui/core/Snackbar'
import { SECOND, useTimer } from '@speedlo/hooks'
import { addMilliseconds, formatDistanceStrict } from 'date-fns'
import * as React from 'react'

import { i18n } from '../../i18n'
import { snackStyles } from '../contexts/SnackProvider'

type TProps = NoChildren &
  OmitSafe<SnackbarProps, 'open'> & {
    onCancel(): void
    onConfirm(): void
    autoHideDuration: number
  }

export const AppUpdateToast: React.FC<TProps> = ({
  onCancel,
  onConfirm,
  ...otherProps
}) => {
  const now = useTimer({ interval: SECOND, enabled: true })

  const [endTime] = React.useState(() =>
    addMilliseconds(now, otherProps.autoHideDuration),
  )

  const remainingSeconds = formatDistanceStrict(endTime, now)

  React.useEffect(() => {
    if (now >= endTime) {
      onConfirm()
    }
  }, [now, endTime, onConfirm])

  return (
    <Snackbar
      open
      disableWindowBlurListener
      className={snackStyles?.variantInfo}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      message={
        <span data-testid="message">{i18n.t`Application will be restarted in ${remainingSeconds} to apply available update.`}</span>
      }
      action={[
        <Button
          key="later"
          size="small"
          role="button"
          color="secondary"
          onClick={onCancel}
          data-testid="postpone-button"
        >
          {i18n.t`Postpone update`}
        </Button>,
        <Button
          size="small"
          key="confirm"
          role="button"
          color="primary"
          onClick={onConfirm}
          data-testid="confirm-button"
        >
          {i18n.t`Restart now`}
        </Button>,
      ]}
      {...otherProps}
    />
  )
}
