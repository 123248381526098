import { TRoleEnum } from '../../graph/generated'
import { RM } from '../../tools/ramda'

// admin-like roles that has access to all branches in the system
// might be useful when restricting some more expensive Qs without bothering our clients
const ALMIGHTY_ROLES: RoA<TRoleEnum> = [
  `ADMIN`,
  `EDITOR`,
  `SUPPORT`,
  `OPERATOR`,
  `SUPER_ADMIN`,
]

type TBaseRole = {
  enum: TRoleEnum
}

/**
 * returns true if user is too powerful and should be restricted from time to time
 */
export const isAlmightyUser = (userRoles: RoA<TBaseRole>) => {
  const userRoleEnum = userRoles.map(role => role.enum)
  const userAlmightyRoles = RM.intersection(userRoleEnum, ALMIGHTY_ROLES)
  const hasAlmightyRole = userAlmightyRoles.length > 0

  return hasAlmightyRole
}
