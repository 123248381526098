import { getSpaceValue, getSpaceValuePx, TSpace } from './space'

/**
 * key that can be used as padding property
 */
export type TPadding = TSpace

/**
 * returns a value for provided padding key, in requested unit
 */
export const getPaddingValue = getSpaceValue

/**
 * returns a value for provided padding key as a number in pixels
 */
export const getPaddingValuePx = getSpaceValuePx
