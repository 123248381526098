import {
  OptionsObject,
  useSnackbar as useSnackbarOrig,
  VariantType,
} from 'notistack'
import * as React from 'react'

type TSnackbarOptions = Omit<OptionsObject, 'variant'>

const successVariant = { variant: 'success' as VariantType }
const errorVariant = { variant: 'error' as VariantType }
const warningVariant = { variant: 'warning' as VariantType }
const infoVariant = { variant: 'info' as VariantType }

export function useSnackBar(options?: TSnackbarOptions) {
  const { enqueueSnackbar } = useSnackbarOrig()
  // save options object into ref to prevent recreating callbacks bellow on options change
  const optionRef = React.useRef(options)

  return {
    enqueueSnackbar,
    enqueueSuccess: React.useCallback(
      (successMessage: React.ReactNode) =>
        enqueueSnackbar(successMessage, {
          ...successVariant,
          ...optionRef.current,
        }),
      [enqueueSnackbar],
    ),
    enqueueError: React.useCallback(
      (errorMessage: React.ReactNode) =>
        enqueueSnackbar(errorMessage, {
          ...errorVariant,
          ...optionRef.current,
        }),
      [enqueueSnackbar],
    ),
    enqueueWarning: React.useCallback(
      (warningMessage: React.ReactNode) =>
        enqueueSnackbar(warningMessage, {
          ...warningVariant,
          ...optionRef.current,
        }),
      [enqueueSnackbar],
    ),
    enqueueInfo: React.useCallback(
      (infoMessage: React.ReactNode) =>
        enqueueSnackbar(infoMessage, { ...infoVariant, ...optionRef.current }),
      [enqueueSnackbar],
    ),
  }
}
