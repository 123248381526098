import * as React from 'react'
import styled from 'styled-components/macro'

type THtmlProps = OmitSafe<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>,
  'ref'
>

type TProps = RequiredChildren & THtmlProps

const StyledItalic = styled.i`
  font-style: italic;
`

/**
 * i HTML element setting the font-style to italic
 */
export const Italic: React.FC<TProps> = ({ children, ...htmlProps }) => {
  return <StyledItalic {...htmlProps}>{children}</StyledItalic>
}
