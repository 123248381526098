import { createHoverColor } from '../utils/colorManipulationUtils'
import { COLORS_VALUES } from './colors'
import { TTheme } from './types'

/**
 * theme color system used for bg colors in the app
 */
export const THEME: TTheme = {
  primary: {
    normal: COLORS_VALUES.dark.primaryBg,
    hover: createHoverColor(COLORS_VALUES.dark.primaryBg),
    disabled: COLORS_VALUES.dark.primaryBg,
  },

  primaryShade1: {
    normal: COLORS_VALUES.dark.primaryBgL010,
    hover: createHoverColor(COLORS_VALUES.dark.primaryBgL010),
    disabled: COLORS_VALUES.dark.primaryBgL010,
  },
  primaryShade2: {
    normal: COLORS_VALUES.dark.primaryBgL020,
    hover: createHoverColor(COLORS_VALUES.dark.primaryBgL020),
    disabled: COLORS_VALUES.dark.primaryBgL020,
  },
  primaryShade3: {
    normal: COLORS_VALUES.dark.primaryBgL030,
    hover: createHoverColor(COLORS_VALUES.dark.primaryBgL030),
    disabled: COLORS_VALUES.dark.primaryBgL030,
  },
  primaryShade4: {
    normal: COLORS_VALUES.dark.primaryBgD020,
    hover: createHoverColor(COLORS_VALUES.dark.primaryBgD020),
    disabled: COLORS_VALUES.dark.primaryBgD020,
  },

  primaryContra1: {
    normal: COLORS_VALUES.dark.primaryBgL090,
    hover: createHoverColor(COLORS_VALUES.dark.primaryBgL090),
    disabled: COLORS_VALUES.dark.primaryBgL090,
  },

  input: {
    normal: COLORS_VALUES.dark.primaryBgL020,
    hover: createHoverColor(COLORS_VALUES.dark.primaryBgL020),
    disabled: COLORS_VALUES.dark.primaryBgL010,
  },

  success: {
    normal: COLORS_VALUES.green.full,
    hover: createHoverColor(COLORS_VALUES.green.full),
    disabled: COLORS_VALUES.green.faded,
  },
  error: {
    normal: COLORS_VALUES.red.full,
    hover: createHoverColor(COLORS_VALUES.red.full),
    disabled: COLORS_VALUES.red.faded,
  },
  warning: {
    normal: COLORS_VALUES.yellow.full,
    hover: createHoverColor(COLORS_VALUES.yellow.full),
    disabled: COLORS_VALUES.yellow.faded,
  },
  secondary: {
    normal: COLORS_VALUES.speedlo.full,
    hover: createHoverColor(COLORS_VALUES.speedlo.full),
    disabled: COLORS_VALUES.speedlo.faded,
  },

  white: {
    normal: COLORS_VALUES.white.o100,
    hover: createHoverColor(COLORS_VALUES.white.o100),
    disabled: COLORS_VALUES.white.o050,
  },
}
