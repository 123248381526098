import { useRoot } from '-app/root'
import * as React from 'react'
import { Route, RouteProps } from 'react-router-dom'

import { NotFoundPage } from '../pages/NotFoundPage'
import { RedirectToLogin } from './RedirectToLogin'

type TProps = RouteProps & {
  lazy?: boolean
  redirect?: boolean
}

export const SecureRoute: React.FC<TProps> = ({
  lazy = true,
  redirect = true,
  ...props
}) => {
  const { auth } = useRoot()

  if (!auth.isAuthenticatedUser) {
    return redirect ? <RedirectToLogin /> : <NotFoundPage />
  }

  if (props.component && typeof props.component === 'object') {
    const { component, path, exact } = props

    return <Route path={path} exact={exact} component={component} />
  }

  return <Route {...props} />
}
