import * as React from 'react'
import { Modal, MODAL_SPACES } from '../../../../ui/Modal'
import { Trans } from '@lingui/macro'
import { GridBox } from '../../../../atoms/Box/Box'
import { IconLine } from '../../../../atoms/IconLine'
import { getSpaceValue, FONT_WEIGHTS } from '../../../../designSystem'
import styled from 'styled-components/macro'
import { IconExternalDelivery, IconId, IconWarning } from '../../../../ui/Icons'
import { Sentry } from '@speedlo/sentry'
import { Colors } from '../../../../styling'
import { useEstimateExternalDeliveryCtx } from '../../contexts/EstimateExternalDeliveryProvider'
import { useCreateExternalDeliveryCtx } from '../../contexts/CreateExternalDeliveryProvider'

type TProps = NoChildren

const lowContrastColor = Colors.grey.darkish

const StyledInfo = styled(GridBox)`
  color: ${lowContrastColor};

  h3 {
    font-weight: ${FONT_WEIGHTS.medium};
  }
`

/**
 * Content of external delivery creation modal when external delivery cannot be requested.
 *
 * - Shows error messages (translated & raw) and order details.
 * - Reports raw error to Sentry.
 */
export const ExternalDeliveryUnavailable: React.FC<TProps> = () => {
  const { onClose, order, deliveryProvider } = useCreateExternalDeliveryCtx()
  const { estimates } = useEstimateExternalDeliveryCtx()

  const refWasReported = React.useRef(false)

  const { id: orderId, orderNumber } = order
  const primaryOrderId = orderNumber ?? orderId
  const secondaryOrderId = orderNumber ? orderId : null

  // Report raw error to Sentry
  React.useEffect(() => {
    if (refWasReported.current) return
    if (!estimates) return

    Sentry.withScope(scope => {
      scope.setExtra('orderId', order.id)
      scope.setExtra('deliveryProvider', deliveryProvider.code)

      Sentry.captureMessage(
        `External delivery unavailable: ${estimates.rawErrorMessage}`,
      )
    })

    refWasReported.current = true
  }, [deliveryProvider.code, estimates, order.id])

  if (!estimates) {
    return null
  }

  const { errorMessage, rawErrorMessage } = estimates

  return (
    <>
      <Modal.Content>
        <Modal.Title>
          <Trans>External delivery cannot be requested</Trans>
        </Modal.Title>

        <GridBox gridRowGap={getSpaceValue(`s16`)} marginY={MODAL_SPACES.sm}>
          {errorMessage && <p>{errorMessage}</p>}

          <StyledInfo gridRowGap={getSpaceValue(`s8`)}>
            <h3>
              <Trans>More information</Trans>
            </h3>

            <GridBox gridRowGap={getSpaceValue(`s4`)}>
              <IconLine iconColor={lowContrastColor} icon={IconId}>
                {primaryOrderId} {secondaryOrderId && `[${secondaryOrderId}]`}
              </IconLine>
              <IconLine
                iconColor={lowContrastColor}
                icon={IconExternalDelivery}
              >
                {deliveryProvider.code}
              </IconLine>
              {rawErrorMessage && (
                <IconLine iconColor={lowContrastColor} icon={IconWarning}>
                  {rawErrorMessage}
                </IconLine>
              )}
            </GridBox>
          </StyledInfo>
        </GridBox>
      </Modal.Content>

      <Modal.Footer>
        <Modal.Actions columns={`1fr`}>
          <Modal.Button bgColor={`error`} onClick={onClose}>
            <Trans>Close</Trans>
          </Modal.Button>
        </Modal.Actions>
      </Modal.Footer>
    </>
  )
}
