import { observer } from 'mobx-react-lite'
import * as React from 'react'

import {
  TQNotificationsVariables,
  useQNotifications,
} from '../../../graph/generated'
import { isDefined } from '../../../helpers/isDefined'
import { useUserModel } from '../../../hooks/useUserModel'
import { useNotificationsModel } from '../hooks/useNotificationsModel'

type TProps = NoChildren

/**
 * Loads user's notifications.
 */
export const NotificationsLoader = observer<TProps>(() => {
  const { id } = useUserModel()
  const { setUnreadNotificationsCount, setNotifications } =
    useNotificationsModel()

  const variables = React.useMemo<TQNotificationsVariables | undefined>(() => {
    if (!id) return undefined
    return { userId: id }
  }, [id])

  const { data } = useQNotifications({ variables, skip: !variables })

  if (!data || !data.user.inAppNotifications.news) return null

  const notificationsCount = data.user.unreadInAppNotificationsCount
  setUnreadNotificationsCount(notificationsCount)

  const filtered = data.user.inAppNotifications.news.filter(isDefined)
  const notifications = filtered.map(item => item.node).filter(isDefined)

  setNotifications(notifications)

  return null
})
