import * as React from 'react'

import { useResponsive } from '../../../app/Responsive'
import { FlatSelect } from '../../FlatSelect'

type TFlatSelectOptions = OmitSafe<
  PropsOf<typeof FlatSelect.Options>,
  'options'
>

type TProps = Children &
  TFlatSelectOptions & {
    options: RoA<number>
  }

/**
 * renders FlatSelect buttons for minute select options,
 * sets some style defaults
 */
export const MinuteSelectOptions: React.FC<TProps> = ({
  children,
  options,
  ...flatSelectOptions
}) => {
  const { isMobile } = useResponsive()

  return (
    <FlatSelect.Options
      options={options}
      paddingVertical={isMobile ? `s8` : `s12`}
      minBtnWidth={isMobile ? `5rem` : `5.5rem`}
      {...flatSelectOptions}
    >
      {children}
    </FlatSelect.Options>
  )
}
