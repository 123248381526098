import * as React from 'react'

/**
 * Utility hook that will remember previous result and
 * return it next time when next data are being fetched.
 *
 * The function to retrieve stashed data is returned which initially will
 * return `null` until some data are fetched for the first time.
 *
 * Function is always recreated so don't use it as dependency for effects,
 * memo or callbacks, use it in outer scope and depend on data directly.
 *
 * The resolver function needs to be supplied which gets `data` and should either return
 * whole `data` (the `RM.identity` is really useful) or directly select some field.
 * The resolver is executed only when `data` is available thus no checks are necessary.
 *
 * Optionally, second argument `shouldSkip` can be specified denoting that query was skipped.
 * That will forcefully clear previous cache and return `null`.
 *
 * @param loading
 * @param data
 */
export const useLoadingStash = <TData>(
  loading: boolean,
  data: TData | undefined,
) => {
  const cached = React.useRef<unknown | null>(null)

  const buildResult = <TResult = TData>(
    resolver: (data: TData) => TResult,
    shouldSkip: boolean = false,
  ): TResult | null => {
    if (shouldSkip) {
      cached.current = null
      return null
    }

    if (loading) {
      return cached.current as TResult | null
    }

    if (data === undefined) {
      return null
    }

    const next = resolver(data)
    cached.current = next
    return next
  }

  return buildResult
}
