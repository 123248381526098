import { types } from 'mobx-state-tree'

import { parseNumber } from '../../../helpers/numeral'

export const DEFAULT_IMPORT = false
export const DEFAULT_QUANTITY = null
export const DEFAULT_RAW_QUANTITY = ''
export const MIN_TRANSFER_QUANTITY = 0
export const DEFAULT_MAX = Number.MAX_SAFE_INTEGER

export const TransferItem = types
  .model('TransferItem', {
    id: types.identifier,
    max: types.optional(types.number, DEFAULT_MAX),
    import: types.optional(types.boolean, DEFAULT_IMPORT),
    rawQuantity: types.optional(types.string, DEFAULT_RAW_QUANTITY),
  })
  .views(self => ({
    get quantity() {
      if (self.rawQuantity.length === 0) {
        return DEFAULT_QUANTITY
      }

      const parsedQuantity = parseNumber(self.rawQuantity)

      return Number.isNaN(parsedQuantity) ? DEFAULT_QUANTITY : parsedQuantity
    },

    get isQuantityValid() {
      if (this.quantity === DEFAULT_QUANTITY) {
        // valid only if there is nothing in the input
        return self.rawQuantity.length === 0
      }

      const amountUnderflow = this.quantity < MIN_TRANSFER_QUANTITY
      const amountOverflow = this.quantity > self.max

      return !amountUnderflow && !amountOverflow
    },

    get isQuantityValidForTransfer() {
      return (
        this.isQuantityValid && this.quantity !== null && this.quantity !== 0
      )
    },
  }))

  .actions(self => ({
    setRawQuantity(rawQuantity: string) {
      self.rawQuantity = rawQuantity.trimLeft()
    },

    reset() {
      self.rawQuantity = DEFAULT_RAW_QUANTITY
      self.import = DEFAULT_IMPORT
    },
  }))
