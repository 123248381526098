import { SPACES_SEMATIC, SPACES_VALUES } from './spaceValues'

const PX_IN_REM = 16

/**
 * CSS unit in which the space can be returned
 */
export type TSpaceUnit = 'px' | 'rem' | 'em'
/**
 * key of semantic spaces
 */
export type TSpaceSemantic = keyof typeof SPACES_SEMATIC
/**
 * key of semantic spaces or key of space values
 */
export type TSpace = TSpaceSemantic | keyof typeof SPACES_VALUES

/**
 * returns value for provided space key, in requested unit
 */
export const getSpaceValue = (space: TSpace, unit: TSpaceUnit = 'rem') => {
  const value = getSpaceValuePx(space)

  switch (unit) {
    case 'px':
      return `${value}px`
    case 'em':
      return `${value / PX_IN_REM}em`
    case 'rem':
    default:
      return `${value / PX_IN_REM}rem`
  }
}

/**
 * returns value for provided space key as a number in pixels
 */
export const getSpaceValuePx = (space: TSpace) => {
  const spaces = { ...SPACES_VALUES, ...SPACES_SEMATIC }
  return spaces[space]
}
