import * as React from 'react'
import styled from 'styled-components/macro'

import { TPaddingProps } from '../../../designSystem'
import { FLAT_SELECT_CONFIG } from '../config/flatSelect.config'
import { useFlatSelectCtx } from '../contexts/FlatSelectProvider'
import { FlatSelectOption } from './FlatSelectOption'

type TButtonOptions = Pick<
  PropsOf<typeof FlatSelectOption>,
  'selectedColor' | keyof TPaddingProps
>

type TStyledProps = {
  minBtnWidth?: string
  maxWidth?: string
}

type TProps<TOption> = Children &
  TStyledProps &
  TButtonOptions & {
    maxBtnWidth?: string
    options: RoA<TOption>
  }

const StyledFlatSelect = styled.div<TStyledProps>`
  display: grid;
  /* make all rows the same height */
  grid-auto-rows: 1fr;
  gap: ${FLAT_SELECT_CONFIG.optionGap};
  /* fit as many cols as possible, wrap the rest */
  grid-template-columns: ${({ minBtnWidth = `3rem` }) =>
    `repeat(auto-fit, minmax(${minBtnWidth}, 1fr))`};

  max-width: ${({ maxWidth = `100%` }) => `min(${maxWidth}, 100%)`};
`

/**
 * renders select buttons for options
 * marks the selected option and calls provided callback on selection change
 */
export const FlatSelectOptions = <TOption,>({
  options,
  children,
  maxWidth,
  minBtnWidth,
  maxBtnWidth,
  ...buttonProps
}: TProps<TOption>) => {
  const { getOptionKey, value: selectedValue } = useFlatSelectCtx()

  const maxSelectWidth = maxBtnWidth
    ? `calc(${options.length} * ${maxBtnWidth})`
    : maxWidth

  return (
    <StyledFlatSelect minBtnWidth={minBtnWidth} maxWidth={maxSelectWidth}>
      {options.map(value => (
        <FlatSelectOption
          {...buttonProps}
          value={value}
          key={getOptionKey(value)}
          isSelected={
            selectedValue !== null
              ? getOptionKey(value) === getOptionKey(selectedValue)
              : false
          }
        />
      ))}

      {/* children can be used to render some additional content, eg. options that are of a different type */}
      {children}
    </StyledFlatSelect>
  )
}
