import * as React from 'react'

type TOptions = Children & {
  open: boolean
  animationTimeMs?: number
  closedSize?: number
}

const NO_DELAY = 0

export const useAnimatedHeight = ({
  open,
  children,
  closedSize = 0,
  animationTimeMs = 400,
}: TOptions) => {
  const shadowOpenTimeout = React.useRef<number | null>(null)
  const contentRef = React.useRef<HTMLDivElement | null>(null)

  const [containerHeight, setContainerHeight] = React.useState(0)
  const [shadowOpen, setShadowOpen] = React.useState(true)

  const updateContainer = React.useCallback(
    (open: boolean) => {
      // wait for the children’s content to be painted
      window.requestAnimationFrame(() => {
        if (!contentRef.current) {
          return
        }

        const contentHeight = open
          ? contentRef.current.scrollHeight
          : closedSize
        setContainerHeight(contentHeight)
      })
    },
    [closedSize],
  )

  // react to window resize
  React.useLayoutEffect(() => {
    window.addEventListener('resize', () => updateContainer(open))

    return () => {
      window.removeEventListener('resize', () => updateContainer(open))
    }
  }, [open, updateContainer])

  // on open change, after a delay update shadowOpen too
  React.useEffect(() => {
    if (shadowOpenTimeout.current) {
      clearTimeout(shadowOpenTimeout.current)
    }

    const delay = open ? NO_DELAY : animationTimeMs

    shadowOpenTimeout.current = window.setTimeout(() => {
      setShadowOpen(open)
    }, delay)
  }, [animationTimeMs, open, updateContainer])

  // initial container update
  React.useEffect(() => {
    updateContainer(open)
  }, [children, contentRef, open, shadowOpen, updateContainer])

  return { contentRef, containerHeight, shadowOpen }
}
