import './enableDebug'
import './tools/array-flat-polyfill'
import 'tippy.js/animations/shift-away-subtle.css'
import 'tippy.js/dist/tippy.css'

import * as React from 'react'
import { render } from 'react-dom'

import { AppMain } from './app/AppMain'
import { AppProvider } from './app/AppProvider'
import { GlobalStyles } from './app/GlobalStyles'
import { LoadingPage } from './pages/LoadingPage'

const Main: React.FC = () => {
  return (
    <React.Suspense fallback={<LoadingPage />}>
      <>
        <GlobalStyles />
        <AppProvider>
          <AppMain />
        </AppProvider>
      </>
    </React.Suspense>
  )
}

const rootElement = document.getElementById('root')
render(<Main />, rootElement)
