import { useRoot } from '-app/root'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

import {
  TQActiveExternalDeliveriesVariables,
  TSActiveExternalDeliveriesVariables,
  useQActiveExternalDeliveriesLazyQuery,
  useSActiveExternalDeliveries,
} from '../../../graph/generated'
import { APP_STAGE } from '../../../helpers/appStage'
import { isAlmightyUser } from '../../../helpers/user/isAlmightyUser'
import { useCanMutate } from '../../../hooks/useAccessGuard'
import { EXTERNAL_DELIVERY_OPERATION } from '../config/externalDelivery.config'
import { useActiveExternalDeliveriesModel } from '../models/useActiveExternalDeliveriesModel'

type TVariables = TQActiveExternalDeliveriesVariables &
  TSActiveExternalDeliveriesVariables

type TProps = NoChildren

/**
 * This component is responsible for loading and updating the active external deliveries.
 * It’s rendered at the root of the app, so it’s always present.
 * It does not load the deliveries in PROD for admins, as it would be too expensive.
 */
const ActiveExternalDeliveriesLoaderComponent: React.FC<TProps> = () => {
  const { user, auth } = useRoot()

  const {
    isLoaded,
    setIsLoaded,
    processDeliveryUpdate,
    setActiveExternalDeliveries,
    setUserHandlesExternalDeliveries,
  } = useActiveExternalDeliveriesModel()

  const canHandlesExternalDeliveries = useCanMutate(EXTERNAL_DELIVERY_OPERATION)
  const [qActiveExternalDeliveries] = useQActiveExternalDeliveriesLazyQuery()

  const variables = React.useMemo<TVariables>(
    () => ({ branchIds: user.branchesId }),
    [user],
  )

  // for admins in PROD this Q would be too expensive (as they have access to all of the branches)
  const noUserLogged = auth.token === null
  const userIsAdmin = isAlmightyUser(user.roles ?? [])
  const doNotLoad = noUserLogged || (APP_STAGE.isProd && userIsAdmin)

  // load initial state on app render
  // store whether the user can handle external deliveries (controls related UI elements)
  // and external deliveries that are currently active
  React.useEffect(() => {
    if (doNotLoad || !canHandlesExternalDeliveries) return

    const load = async () => {
      const result = await qActiveExternalDeliveries(variables)

      if (!result) return

      setIsLoaded(true)
      setActiveExternalDeliveries(result.externalDeliveries)
      setUserHandlesExternalDeliveries(canHandlesExternalDeliveries)
    }

    load()
  }, [
    doNotLoad,
    variables,
    setIsLoaded,
    setUserHandlesExternalDeliveries,
    qActiveExternalDeliveries,
    setActiveExternalDeliveries,
    canHandlesExternalDeliveries,
  ])

  // connect update on data change
  useSActiveExternalDeliveries({
    variables,
    skip: !isLoaded,
    onSubscriptionData: ({ subscriptionData }) => {
      if (!subscriptionData.data?.update.externalDelivery) return

      processDeliveryUpdate(subscriptionData.data.update.externalDelivery)
    },
  })

  return null
}

export const ActiveExternalDeliveriesLoader = observer(
  ActiveExternalDeliveriesLoaderComponent,
)
