import numeral from 'numeral'

export async function changeNumeralLocale(lang: string) {
  let locale = lang
  const numeralUnsupportedLocales = ['zu', 'af']

  if (numeralUnsupportedLocales.includes(lang)) {
    // replace locale with default if numeral js doesn’t support it
    locale = 'en'
  }

  if (locale === 'uk') {
    locale = 'uk-ua'
  }

  if (locale === 'en') {
    numeral.locale(locale)
    return
  }

  await import(
    /* webpackMode: "lazy", webpackChunkName: "numeral-[index]" */
    `numeral/locales/${locale}`
  )
  numeral.locale(locale)
}

const startsOrEndsWithSeparator = /^[.,]+.*|.*[.,]+$/

/**
 * Parse number according used locale rules.
 */
export function parseNumber(value: unknown) {
  if (typeof value === 'number') {
    return value
  }

  if (typeof value !== 'string') {
    return NaN
  }

  const trimmed = value.trim()

  if (trimmed.length === 0) {
    return NaN
  }

  const normalized = normalizeNumber(trimmed)

  if (startsOrEndsWithSeparator.test(normalized)) {
    return NaN
  }

  return Number(normalized)
}

/**
 * Will utilize current locale to normalize string as a number to a format
 * that can be correctly recognized by parsing functions
 */
export function normalizeNumber(number: string) {
  const { decimal, thousands } = numeral.localeData().delimiters

  return number
    .replace(new RegExp(`\\${thousands}`, 'g'), '')
    .replace(new RegExp(`\\${decimal}`), '.')
}

export type LocaleNumber = string

/**
 * Rounds the value to the number of decimal places
 */
export function roundNumber(value: number, places: number = 0) {
  const roundCoef = 10 ** places

  return Math.round(value * roundCoef) / roundCoef
}

export { numeral }
