import { AppNameScreenHeadline } from './components/AppNameScreenHeadline'
import { AppNameScreenLayout } from './components/AppNameScreenLayout'
import { AppNameScreenMessage } from './components/AppNameScreenMessage'

/**
 * layout and components for screens where app name is used as a headline
 */
export const AppNameScreen = {
  Layout: AppNameScreenLayout,
  Headline: AppNameScreenHeadline,
  Message: AppNameScreenMessage,
}
