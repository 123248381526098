import styled, { css } from 'styled-components/macro'

import Color from 'color'

import { Colors, Radiuses, Spacing } from '../styling'

type TStyledProps = {
  color?: string
  padding?: number | string
  minHeight?: number
  textColor?: string
  stretchToParent?: boolean
  width?: string
}

export const Card = styled.div<TStyledProps>`
  background: ${getBackgroundColor};
  color: ${getTextColor};
  border-radius: ${Radiuses.Card};
  padding: ${getPadding};
  min-height: ${getMinHeight};
  position: relative;
  width: ${getWidth};

  ${applyStretchToParentStyle};
`

function getPadding({ padding = 0.5 }: TStyledProps) {
  return typeof padding === 'number' ? `${padding}rem` : padding
}

function getMinHeight({ minHeight = 0 }: TStyledProps) {
  return minHeight + Spacing.DefaultSizeUnit
}

function getBackgroundColor({ color }: TStyledProps) {
  return Color(color || Colors.greyBlue.main).string()
}

function getTextColor(props: TStyledProps) {
  return props.textColor || Colors.black.main
}

function getWidth(props: TStyledProps) {
  return props.width || `auto`
}

function applyStretchToParentStyle({ stretchToParent = false }: TStyledProps) {
  if (!stretchToParent) {
    return ''
  }

  return css`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  `
}
