import * as React from 'react'
import styled from 'styled-components/macro'

import { Spacing } from '../styling'
import { MarginBox } from './MarginBox'

type TProps = Children & {
  width?: number
  height?: number
  margin?: string
  widthUnit?: string
  className?: string
  heightUnit?: string
}

export type TSizerProps = RequiredChildren & TProps

const widthProperty = ({
  width,
  widthUnit = Spacing.DefaultSizeUnit,
}: TProps) => {
  if (width === undefined) {
    return ''
  } else {
    return `width: ${width}${widthUnit}`
  }
}

const heightProperty = ({
  height,
  heightUnit = Spacing.DefaultSizeUnit,
}: TProps) => {
  if (height === undefined) {
    return ''
  } else {
    return `height: ${height}${heightUnit}`
  }
}

const StyledSizer = styled('div')`
  ${widthProperty};
  ${heightProperty};
`

export const Sizer: React.FC<TProps> = ({
  width,
  height,
  margin,
  children,
  className,
  widthUnit = Spacing.DefaultSizeUnit,
  heightUnit = Spacing.DefaultSizeUnit,
  ...props
}) => {
  const wrap = (
    <StyledSizer
      width={width}
      height={height}
      className={className}
      widthUnit={widthUnit}
      heightUnit={heightUnit}
      {...props}
    >
      {children}
    </StyledSizer>
  )

  if (margin === undefined) {
    return wrap
  }

  return <MarginBox margin={margin}>{wrap}</MarginBox>
}
