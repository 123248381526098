import { Instance } from 'mobx-state-tree'

import {
  TFProductTagInList,
  TQProductTagList,
} from '../../../../graph/generated'
import { BaseModel } from '../../../../models/BaseModel'
import { RM } from '../../../../tools/ramda'
import {
  TProductTagCategory,
  TProductTagInList,
} from '../typings/productTags.types'
import { sortTagsAlphabetically, splitProductTags } from '../utils'

// available filters in the ProductTagListForm
type TFilter = {
  brandId: ID
}

type TVolatileProps = {
  filter: Nullable<TFilter> // this is the actual form state for which the queries in this page are run
  loadedForBrand: Nullable<ID> // brand that the product tags was loaded for (current state of the filter when the Q was fired)
  systemProductTags: Nullable<RoA<TProductTagInList>>
  companyProductTags: Nullable<RoA<TProductTagInList>>
}

export const ProductTagModel = BaseModel.named(`ProductTag`)
  .volatile<TVolatileProps>(() => ({
    companyProductTags: null,
    systemProductTags: null,
    loadedForBrand: null,
    filter: null,
  }))

  .views(self => ({
    get loadedTagCategories() {
      if (self.systemProductTags === null) {
        return null
      }

      // get all unique tag categories from system tags
      // because company tags should have the same tags (or miss some)
      // so they can be used as possible options in the list filter
      const systemTagCategories = self.systemProductTags.map(tag => {
        return tag.category
      })

      const uniqueCategories = RM.uniqWith(
        (tagA, tagB) => tagA?.enum === tagB?.enum,
        systemTagCategories,
      )

      return uniqueCategories.filter(category =>
        Boolean(category),
      ) as RoA<TProductTagCategory>
    },

    // returns if the data for the list was already loaded
    // so that next load can be skipped
    get hasProductTagList() {
      // each company should have access to system-wide tags & they should be always defined
      return self.systemProductTags !== null
    },
  }))

  // data save – from GQL Q into model
  .actions(self => ({
    // load list of product tags
    setProductTags(queryData: TQProductTagList, loadedForBrand: ID) {
      self.loadedForBrand = loadedForBrand

      const { productTags, relations } = queryData

      const sortedProductTags = sortTagsAlphabetically(productTags)
      const { companyProductTags, systemProductTags } =
        splitProductTags(sortedProductTags)

      // filter out inherited relations (should be duplicate)
      const ownRelations = relations.filter(relation => !relation.inherited)

      // combine tags with number of relations it’s bound to
      const companyTagsWithRelations = companyProductTags.map(tag => {
        const tagRelations = ownRelations.filter(relation => {
          return relation.recipeTag.id === tag.id
        })

        return { ...tag, relationCount: tagRelations.length }
      })

      const systemTagsWithRelations = systemProductTags.map(tag => {
        const tagRelations = ownRelations.filter(relation => {
          return relation.recipeTag.id === tag.id
        })

        return { ...tag, relationCount: tagRelations.length }
      })

      self.companyProductTags = companyTagsWithRelations
      self.systemProductTags = systemTagsWithRelations
    },

    // only company tags can be created
    addProductTag(newTag: TFProductTagInList) {
      if (!self.companyProductTags) {
        return
      }

      self.companyProductTags = sortTagsAlphabetically([
        ...self.companyProductTags,
        extendProductTag(newTag),
      ])
    },

    // only company tags can be updated
    replaceProductTag(updatedTag: TFProductTagInList) {
      if (!self.companyProductTags) {
        return
      }

      const updatedTags = self.companyProductTags.map(tag => {
        return tag.id === updatedTag.id ? extendProductTag(updatedTag) : tag
      })

      self.companyProductTags = sortTagsAlphabetically(updatedTags)
    },

    // only company tags can be removed
    removeProductTag(removedTagId: ID) {
      if (!self.companyProductTags) {
        return
      }

      const updatedTags = self.companyProductTags.filter(tag => {
        return tag.id !== removedTagId
      })

      self.companyProductTags = updatedTags
    },
  }))

  // methods for data erasing when they are not needed/used anymore
  .actions(self => ({
    invalidateProductTagList() {
      self.companyProductTags = null
      self.systemProductTags = null
      self.loadedForBrand = null
    },

    invalidateAllData() {
      this.invalidateProductTagList()
    },
  }))

  // update list filter
  .actions(self => ({
    updateFilter(newFilterValue: TFilter) {
      const nothingChanged = RM.equals(self.filter, newFilterValue)

      if (nothingChanged) {
        return
      }

      // clear old data – some queries might not run if they have some data in model (by choice)
      self.invalidateAllData()
      self.filter = newFilterValue
    },
  }))

export interface TProductTagModel extends Instance<typeof ProductTagModel> {}

const extendProductTag = (tag: TFProductTagInList): TProductTagInList => ({
  ...tag,
  relationCount: 0,
})
