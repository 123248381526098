import * as React from 'react'

import { Box } from '../../atoms/Box/Box'
import { StyledAnimatedHeight } from './StyledAnimatedHeight'
import { useAnimatedHeight } from './useAnimatedHeight'

type TProps = Children & {
  open: boolean
  animationTimeMs?: number
  closedSize?: number
}

export const AnimatedHeight: React.FC<TProps> = ({
  open,
  children,
  closedSize = 0,
  animationTimeMs = 400,
}) => {
  const { contentRef, shadowOpen, containerHeight } = useAnimatedHeight({
    open,
    animationTimeMs,
    children,
    closedSize,
  })

  return (
    <StyledAnimatedHeight
      animationTimeMs={animationTimeMs}
      height={containerHeight}
    >
      <Box ref={contentRef}>{shadowOpen && children}</Box>
    </StyledAnimatedHeight>
  )
}
