import { getPaddingValue, TPadding } from './padding'
import { TSpaceUnit } from './space'

/**
 * props or options related to element padding
 */
export type TPaddingProps = {
  // general value
  padding?: TPadding
  // direction values
  paddingVertical?: TPadding
  paddingHorizontal?: TPadding
  // side values
  paddingTop?: TPadding
  paddingRight?: TPadding
  paddingBottom?: TPadding
  paddingLeft?: TPadding
}

/**
 * returns a value for top padding of an element
 * based on provided padding props,
 * prefers `side` > `direction` > `general` value
 */
export const getPaddingTop = (
  { paddingTop, paddingVertical, padding }: TPaddingProps,
  unit?: TSpaceUnit,
) => {
  const paddingTopKey = paddingTop ?? paddingVertical ?? padding
  return paddingTopKey ? getPaddingValue(paddingTopKey, unit) : undefined
}

/**
 * returns a value for right padding of an element
 * based on provided padding props,
 * prefers `side` > `direction` > `general` value
 */
export const getPaddingRight = (
  { paddingRight, paddingHorizontal, padding }: TPaddingProps,
  unit?: TSpaceUnit,
) => {
  const paddingRightKey = paddingRight ?? paddingHorizontal ?? padding
  return paddingRightKey ? getPaddingValue(paddingRightKey, unit) : undefined
}

/**
 * returns a value for bottom padding of an element
 * based on provided padding props,
 * prefers `side` > `direction` > `general` value
 */
export const getPaddingBottom = (
  { paddingBottom, paddingVertical, padding }: TPaddingProps,
  unit?: TSpaceUnit,
) => {
  const paddingBottomKey = paddingBottom ?? paddingVertical ?? padding
  return paddingBottomKey ? getPaddingValue(paddingBottomKey, unit) : undefined
}

/**
 * returns a value for left padding of an element
 * based on provided padding props,
 * prefers `side` > `direction` > `general` value
 */
export const getPaddingLeft = (
  { paddingLeft, paddingHorizontal, padding }: TPaddingProps,
  unit?: TSpaceUnit,
) => {
  const paddingLeftKey = paddingLeft ?? paddingHorizontal ?? padding
  return paddingLeftKey ? getPaddingValue(paddingLeftKey, unit) : undefined
}

/**
 * returns values of padding for all sides of an element
 * based on provided padding props,
 * prefers `side` > `direction` > `general` value for each side
 */
export const getPaddingSideValues = (paddingProps: TPaddingProps) => {
  const paddingTop = getPaddingTop(paddingProps)
  const paddingRight = getPaddingRight(paddingProps)
  const paddingBottom = getPaddingBottom(paddingProps)
  const paddingLeft = getPaddingLeft(paddingProps)

  return { paddingTop, paddingRight, paddingBottom, paddingLeft }
}
