import { isDefined } from '../../../helpers/isDefined'
import { TOrderRating, TOrderRatings } from '../typings/orderRating.typings'

export const getRatingsSummary = (ratings: Nullable<TOrderRatings>) => {
  if (!ratings) return null
  if (ratings.length === 0) return null

  const definedRatings = ratings.map(rating => rating.value).filter(isDefined)
  const ratingsSum = definedRatings.reduce(addValues, 0)

  const ratingMean = ratingsSum / Math.max(definedRatings.length, 1)
  const ratingValue = Math.round(ratingMean)

  const hasSomeDescription = ratings.some(hasDescription)
  const hasMultipleRatings = definedRatings.length > 1

  return {
    ratingValue,
    hasSomeAdditionalInfo: hasSomeDescription || hasMultipleRatings,
  }
}

const addValues = (acc: number, ratingValue: number) => acc + ratingValue

const hasDescription = (rating: TOrderRating) => {
  return rating.description !== null && rating.description.length > 0
}
