import { Plural } from '@lingui/macro'
import * as React from 'react'

type TProps = NoChildren & {
  value: number
}

/**
 * extracted component (to keep in as a single translation key)
 * rendering translated label "X minute(s)"
 */
export const Minutes: React.FC<TProps> = ({ value }) => {
  return (
    <Plural
      value={value}
      zero="# minutes"
      one="# minute"
      few="# minutes"
      other="# minutes"
    />
  )
}
