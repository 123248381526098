import * as React from 'react'
import styled from 'styled-components/macro'
import { MODAL_SPACES } from '../../../../ui/Modal'
import { useEstimateExternalDeliveryCtx } from '../../contexts/EstimateExternalDeliveryProvider'
import {
  IconExternalDeliveryPickupTime,
  IconHandoverTime,
  IconExternalDeliveryEstimatedFee,
} from '../../../../ui/Icons'
import { Trans } from '@lingui/macro'
import { IconLine } from '../../../../atoms/IconLine'
import { formatTimeWithoutTodayDate } from '../../../../helpers/formats'
import { Loading } from '../../../../atoms/Loading'
import { i18n } from '../../../../i18n'

type TProps = NoChildren

const StyledOrderInfo = styled.section`
  display: grid;
  grid-row-gap: ${MODAL_SPACES.sm};
  margin-top: ${MODAL_SPACES.md};
`

/**
 * Displays external delivery estimates (pickup time, delivery time, delivery fee)
 * as a summary section. Displays a loading message while loading.
 */
export const ExternalDeliveryEstimates: React.FC<TProps> = () => {
  const { estimates, isLoading } = useEstimateExternalDeliveryCtx()

  if (isLoading) {
    return <Loading text={i18n.t`loading external delivery summary...`} />
  }

  if (!estimates) {
    return null
  }

  const { estimatedDeliveryFee, estimatedDeliveryTime, estimatedPickupTime } =
    estimates

  return (
    <StyledOrderInfo>
      {estimatedPickupTime && (
        <IconLine icon={IconExternalDeliveryPickupTime}>
          <Trans>Closest courier pickup time</Trans>{' '}
          {formatTimeWithoutTodayDate(estimatedPickupTime)}
        </IconLine>
      )}

      {estimatedDeliveryTime && (
        <IconLine icon={IconHandoverTime}>
          <Trans>Estimated delivery time </Trans>{' '}
          {formatTimeWithoutTodayDate(estimatedDeliveryTime)}
        </IconLine>
      )}

      {estimatedDeliveryFee && (
        <IconLine icon={IconExternalDeliveryEstimatedFee}>
          <Trans>External delivery fee</Trans>{' '}
          {estimatedDeliveryFee.formattedValue}
        </IconLine>
      )}
    </StyledOrderInfo>
  )
}
