import { keyframes } from 'styled-components'

type TOptions = {
  maxScale?: number
}

/**
 * css animation keyframes for a double pulse animation
 */
export const getPulseAnimation = (options?: TOptions) => {
  const maxScale = options?.maxScale ?? 1.06

  const pulse = keyframes`
    0% { scale: 1; }
    4% { scale: ${maxScale}; }
    8% { scale: 0.95; }
    12% { scale: ${maxScale}; }
    16% { scale: 1; }
    /* the rest is just a long pause before next iteration */
  `

  return pulse
}
