import { DialogContent } from '@material-ui/core'
import styled from 'styled-components/macro'

import { MODAL_SPACES } from '../config/modal.config'

type TPadding = keyof typeof MODAL_SPACES

type TProps = {
  paddingTop?: TPadding
  paddingBottom?: TPadding
  overflow?: 'visible' | 'hidden' | 'auto' | 'unset'
}

/**
 * ModalContent is a component that is used to render the main content part of a modal.
 */
export const ModalContent = styled(DialogContent)<TProps>`
  &.MuiDialogContent-root {
    overflow: ${({ overflow }) => overflow};
    padding: 0 ${MODAL_SPACES.lg};
    padding-top: ${({ paddingTop = 'none' }) => MODAL_SPACES[paddingTop]};
    padding-bottom: ${({ paddingBottom = 'none' }) =>
      MODAL_SPACES[paddingBottom]};
  }

  /* when modal does not have header/footer, set outer padding on content by default  */
  &.MuiDialogContent-root:first-child {
    padding-top: ${({ paddingTop = 'lg' }) => MODAL_SPACES[paddingTop]};
  }

  &.MuiDialogContent-root:last-child {
    padding-bottom: ${({ paddingBottom = 'lg' }) =>
      MODAL_SPACES[paddingBottom]};
  }
`
