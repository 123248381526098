import styled from 'styled-components/macro'

import { Dialog } from '@material-ui/core'

import { THEME } from '../../../designSystem'

const SIZES = {
  sm: '30rem',
  md: '40rem',
  lg: '60rem',
  full: '90vw',
}

type TSize = keyof typeof SIZES

type TProps = {
  size?: TSize
  fitContent?: boolean
  overflow?: 'visible' | 'hidden' | 'auto' | 'unset'
}

const DEFAULT_SIZE: TSize = 'full'

/**
 * ModalWrapper is a component that is used to render the wrapping element of a modal.
 * It can control the size of the modal and the overflow of the content.
 */
export const ModalWrapper = styled(Dialog)<TProps>`
  .MuiPaper-root {
    background: ${THEME.primaryContra1.normal};
    overflow: ${({ overflow }) => overflow};

    width: ${({ fitContent = false }) => (fitContent ? `fit-content` : `90vw`)};
    max-width: ${({ size = DEFAULT_SIZE }) => SIZES[size]};
  }
`
