import { Trans } from '@lingui/macro'
import * as React from 'react'
import styled from 'styled-components/macro'

import { FONT_WEIGHTS, getSpaceValue } from '../../../designSystem'
import { getPulseAnimation } from '../../../helpers/styles/pulseAnimation'
import { Colors } from '../../../styling'
import { useAcknowledgeExternalDeliveryFail } from '../hooks/useAcknowledgeExternalDeliveryFail'
import { TActiveExternalDelivery } from '../types/externalDelivery.types'
import { getExternalDeliveryStatusCategory } from '../utils/getExternalDeliveryStatusCategory'

type TProps = NoChildren & {
  delivery: TActiveExternalDelivery
}

// bring more attention to the button
const pulse = getPulseAnimation()

const StyledButton = styled.button`
  user-select: none;
  cursor: pointer;
  appearance: none;
  border: none;

  outline-color: ${Colors.red.main};
  padding: ${getSpaceValue(`s4`)};
  font-weight: ${FONT_WEIGHTS.boldSemi};

  animation-name: ${pulse};
  animation-duration: 5s;
  animation-iteration-count: infinite;

  &:hover {
    animation-play-state: paused;
    text-decoration: underline;
  }
`

/**
 * button to acknowledge a failed external delivery by the user,
 * sends an M to the backend to update the status
 */
export const AcknowledgeExternalDeliveryFailButton: React.FC<TProps> = ({
  delivery,
}) => {
  const {
    modalElement, //
    acknowledgeFail,
  } = useAcknowledgeExternalDeliveryFail(delivery)

  const statusCategory = getExternalDeliveryStatusCategory(delivery)

  if (statusCategory !== 'FAILED') {
    return null
  }

  return (
    <>
      <StyledButton onClick={acknowledgeFail}>
        <Trans>More</Trans>
      </StyledButton>
      {modalElement}
    </>
  )
}
