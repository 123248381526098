import { TFOrderForDestination } from '../../graph/generated'
import { i18n } from '../../i18n'
import { formatAddress } from '../formats'

type TResult = {
  type: 'SECTOR' | 'DELIVERY' | 'PICKUP' | 'IN_HOUSE' | 'UNKNOWN'
  label: string
}

/**
 * Returns the destination of the order,
 * which is either the sector name, delivery address or some pickup label
 */
export const getOrderDestination = (order: TFOrderForDestination): TResult => {
  if (order.sector) {
    return {
      type: 'SECTOR',
      label: order.sector.name,
    }
  }

  if (order.warePriceType.enum === `INHOUSE`) {
    return {
      type: 'IN_HOUSE',
      label: i18n.t`In house`,
    }
  }

  if (order.deliveryType?.enum === `PICKUP`) {
    return {
      type: 'PICKUP',
      label: i18n.t`Personal pickup`,
    }
  }

  if (order.deliveryType?.enum === `MESSENGER`) {
    const userInputAddress = order.address.original ?? ``
    const hasUserInputAddress = userInputAddress.trim().length > 0

    return {
      type: 'DELIVERY',
      label: hasUserInputAddress
        ? userInputAddress
        : formatAddress(order.address),
    }
  }

  return {
    type: 'UNKNOWN',
    label: i18n.t`Unknown place`,
  }
}
