/**
 * all possible space (padding or margin) values in px,
 * basically multiplies of 4 up to a number chosen by design team
 */
export const SPACES_VALUES = {
  s0: 0,
  s4: 4,
  s8: 8,
  s12: 12,
  s16: 16,
  s20: 20,
  s24: 24,
  s28: 28,
  s32: 32,
  s36: 36,
  s40: 40,
  s44: 44,
  s48: 48,
  s52: 52,
  s56: 56,
  s60: 60,
  s64: 64,
  s68: 68,
  s72: 72,
  s76: 76,
  s80: 80,
  s84: 84,
  s88: 88,
  s92: 92,
  s96: 96,
  s100: 100,
  s104: 104,
  s108: 108,
  s112: 112,
  s116: 116,
  s120: 120,
  s124: 124,
  s128: 128,
  s132: 132,
  s136: 136,
  s140: 140,
  s144: 144,
  s148: 148,
  s152: 152,
  s156: 156,
  s160: 160,
  s164: 164,
  s168: 168,
} as const

/**
 * semantically renamed values from above
 * to keep some places of the DS consistent,
 * extend whenever design team does so
 */
export const SPACES_SEMATIC = {
  paragraphSpacing: SPACES_VALUES.s28,
  buttonPadding: SPACES_VALUES.s12,
} as const
