/**
 * color values – used to define colors in the color system,
 * do not use directly, use theme build on top of it
 */
export const COLORS_VALUES = {
  red: {
    full: `hsla(0, 79%, 63%, 1)`,
    faded: `hsla(0, 79%, 73%, 1)`,
  },
  green: {
    full: `hsla(143, 72%, 37%, 1)`,
    faded: `hsla(143, 72%, 57%, 1)`,
  },
  yellow: {
    full: `hsla(36, 87%, 50%, 1)`,
    faded: `hsla(36, 87%, 70%, 1)`,
  },
  speedlo: {
    full: `hsla(8, 76%, 63%, 1)`,
    faded: `hsla(8, 76%, 78%, 1)`,
  },

  white: {
    o100: `hsla(0, 0%, 100%, 1)`,
    o090: `hsla(0, 0%, 100%, 0.9)`,
    o080: `hsla(0, 0%, 100%, 0.8)`,
    o070: `hsla(0, 0%, 100%, 0.7)`,
    o060: `hsla(0, 0%, 100%, 0.6)`,
    o050: `hsla(0, 0%, 100%, 0.5)`,
    o040: `hsla(0, 0%, 100%, 0.4)`,
    o030: `hsla(0, 0%, 100%, 0.3)`,
    o020: `hsla(0, 0%, 100%, 0.2)`,
    o010: `hsla(0, 0%, 100%, 0.1)`,
  },
  black: {
    o100: `hsla(0, 0%, 0%, 1)`,
    o090: `hsla(0, 0%, 0%, 0.9)`,
    o080: `hsla(0, 0%, 0%, 0.8)`,
    o070: `hsla(0, 0%, 0%, 0.7)`,
    o060: `hsla(0, 0%, 0%, 0.6)`,
    o050: `hsla(0, 0%, 0%, 0.5)`,
    o040: `hsla(0, 0%, 0%, 0.4)`,
    o030: `hsla(0, 0%, 0%, 0.3)`,
    o020: `hsla(0, 0%, 0%, 0.2)`,
    o010: `hsla(0, 0%, 0%, 0.1)`,
  },

  dark: {
    primaryBg: `hsla(211, 28%, 19%, 1)`,

    primaryBgL010: `hsla(210, 18%, 27%, 1)`,
    primaryBgL020: `hsla(210, 12%, 35%, 1)`,
    primaryBgL030: `hsla(212, 9%, 43%, 1)`,
    primaryBgL040: `hsla(212, 7%, 51%, 1)`,
    primaryBgL050: `hsla(212, 6%, 59%, 1)`,
    primaryBgL060: `hsla(210, 6%, 67%, 1)`,
    primaryBgL065: `hsla(0, 0%, 74%, 1)`,
    primaryBgL070: `hsla(210, 6%, 76%, 1)`,
    primaryBgL080: `hsla(216, 6%, 84%, 1)`,
    primaryBgL090: `hsla(220, 7%, 92%, 1)`,
    primaryBgL100: `hsla(0, 0%, 100%, 1)`,

    primaryBgD010: `hsla(213, 28%, 17%, 1)`,
    primaryBgD020: `hsla(210, 29%, 15%, 1)`,
    primaryBgD030: `hsla(212, 28%, 13%, 1)`,
    primaryBgD040: `hsla(212, 30%, 11%, 1)`,
    primaryBgD050: `hsla(210, 29%, 9%, 1)`,
    primaryBgD060: `hsla(210, 26%, 7%, 1)`,
    primaryBgD070: `hsla(210, 29%, 5%, 1)`,
    primaryBgD080: `hsla(216, 26%, 4%, 1)`,
    primaryBgD090: `hsla(200, 33%, 2%, 1)`,
    primaryBgD100: `hsla(0, 0%, 0%, 1)`,

    primaryText020: `hsla(210, 12%, 35%, 1)`,
    primaryText030: `hsla(212, 9%, 43%, 1)`,
    primaryText040: `hsla(212, 7%, 51%, 1)`,
    primaryText050: `hsla(212, 6%, 59%, 1)`,
    primaryText060: `hsla(210, 6%, 67%, 1)`,
    primaryText070: `hsla(210, 6%, 76%, 1)`,
    primaryText080: `hsla(216, 6%, 84%, 1)`,
    primaryText090: `hsla(220, 7%, 92%, 1)`,
    primaryText095: `hsla(0, 0%, 95%, 1)`,
    primaryText100: `hsla(0, 0%, 100%, 1)`,

    overlayBg: `hsla(0, 0%, 0%, 0.85)`,
  },
}
