import * as React from 'react'

import { GridCenteredBox } from '../../../atoms/Box/Box'
import { Spinner } from '../../../components/Spinner'
import { APP_STAGE } from '../../../helpers/appStage'
import { Colors } from '../../../styling'
import { Modal } from '../../../ui/Modal'
import { convertHtmlToPng } from '../utils/convertHtmlToPng'

type TProps = NoChildren & {
  isOpen: boolean
  onClose: () => void
  printContentBase64: string
}

const contentWidth = `400px`

// show print content preview in testing environment (dev or stage) in modal so that the tester can verify the result
export const DevPrintContentPreviewModal: React.FC<TProps> = ({
  isOpen,
  onClose,
  printContentBase64,
}) => {
  const [imageSrc, setImageSrc] = React.useState<string | null | undefined>(
    undefined,
  )

  // convert the HTML print content to a IMG src (whenever the print content changes)
  React.useEffect(() => {
    if (APP_STAGE.isProd) {
      return
    }

    const convertImage = async () => {
      const result = await convertHtmlToPng({
        requiredImageWidth: 650,
        base64Html: printContentBase64,
      })

      if (!result.isSuccess) {
        setImageSrc(null)
        return
      }

      setImageSrc(result.base64Image)
    }

    convertImage()
  }, [printContentBase64])

  if (APP_STAGE.isProd) {
    return null
  }

  const loading = imageSrc === undefined
  const convertFailed = imageSrc === null

  return (
    <Modal.Wrapper open={isOpen} onBackdropClick={onClose}>
      {loading && (
        <GridCenteredBox height={`20rem`} width={contentWidth}>
          <Spinner color={Colors.black.main} />
        </GridCenteredBox>
      )}
      {convertFailed && (
        <GridCenteredBox height={`20rem`} width={contentWidth}>
          Ops. Something is broken ¯\_(ツ)_/¯
        </GridCenteredBox>
      )}
      {imageSrc && (
        <img
          alt={``}
          style={{ width: contentWidth }}
          src={`data:image/png;base64, ${imageSrc}`}
        />
      )}
    </Modal.Wrapper>
  )
}
