type TCompareFunction<TCollectionAItem, TCollectionBItem> = (
  itemFromCollectionA: TCollectionAItem,
  itemFromCollectionB: TCollectionBItem,
) => boolean

/**
 * returns items from the first collectionA, that has an equivalent in the collectionB
 * compareFunction is used to find those equivalents
 */
export const intersectionWith =
  <TCollectionAItem, TCollectionBItem>(
    compareFunction: TCompareFunction<TCollectionAItem, TCollectionBItem>,
  ) =>
  (
    collectionA: RoA<TCollectionAItem>,
    collectionB: Nullable<RoA<TCollectionBItem>>,
  ) => {
    return collectionA.filter(itemFromCollectionA => {
      return (
        collectionB &&
        collectionB.find(itemFromCollectionB => {
          return compareFunction(itemFromCollectionA, itemFromCollectionB)
        })
      )
    })
  }

/**
 * returns items from the first collectionA, that has NOT an equivalent in the collectionB
 * compareFunction is used to find those equivalents
 */
export const notIntersectionWith =
  <TCollectionAItem, TCollectionBItem>(
    compareFunction: TCompareFunction<TCollectionAItem, TCollectionBItem>,
  ) =>
  (
    collectionA: RoA<TCollectionAItem>,
    collectionB: Nullable<RoA<TCollectionBItem>>,
  ) => {
    return collectionA.filter(itemFromCollectionA => {
      return (
        collectionB &&
        !collectionB.find(itemFromCollectionB => {
          return compareFunction(itemFromCollectionA, itemFromCollectionB)
        })
      )
    })
  }
