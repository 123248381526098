import Color from 'color'

import { APP_STAGE } from './helpers/appStage'
import { RM } from './tools/ramda'

/**
 * background color of the whole app
 * returns different color for different app environments
 */
export const getEnvAppBgColor = () => {
  switch (APP_STAGE.stage) {
    case `stage`:
    case `stageBeta`:
      return `hsl(332, 29%, 18%)`
    case `dev`:
      return `hsl(120, 1%, 11%)`
    case `speedlopay`:
    case `prod`:
    case `local`:
    default:
      return `hsl(212, 29%, 18%)`
  }
}

/**
 * color of the main screen navigation button
 * returns different color for different app environments
 */
export const getEnvNavButtonBgColor = () => {
  switch (APP_STAGE.stage) {
    case `stage`:
    case `stageBeta`:
      return Colors.red.dark
    case `dev`:
      return Colors.green.dark
    case `speedlopay`:
    case `prod`:
    case `local`:
    default:
      return Colors.speedlo.main
  }
}

export const Colors = {
  black: {
    pure: 'hsl(0, 0%, 0%)',
    main: 'hsl(180, 3%, 19%)',
  },
  white: {
    pure: 'hsl(0, 0%, 100%)',
    main: 'hsl(180, 20%, 95%)',
  },
  grey: {
    lightest: 'hsl(210, 11%, 93%)',
    light: 'hsl(220, 10%, 88%)',
    lightish: 'hsl(210, 10%, 77%)',
    medium: 'hsl(212, 10%, 63%)',
    darkMedium: 'hsl(211, 10%, 48%)',
    darkish: 'hsl(213, 16%, 34%)',
    dark: 'hsl(212, 27%, 27%)',
    // disabledLight: '',
    // disabledDark: '',
  },
  red: {
    light: 'hsl(0, 100%, 83%)',
    main: 'hsl(360, 82%, 63%)',
    dark: 'hsl(358, 44%, 49%)',
    // dark: '#D92C2C',
    // dark: '#AC1414',
    // dark: '#A60020',
  },
  green: {
    // light: '#72C77E',
    light: 'hsl(134, 52%, 78%)',
    main: 'hsl(160, 69%, 42%)',
    dark: 'hsl(145, 59%, 38%)',
  },
  blue: {
    light: 'hsl(194, 100%, 50%)',
    main: 'hsl(210, 100%, 36%)',
    dark: 'hsl(220, 100%, 23%)',
    darkest: 'hsl(218, 100%, 25%)',
  },
  greyBlue: {
    light: 'hsl(0, 0%, 94%)',
    main: 'hsl(207, 9%, 79%)',
    dark: 'hsl(203, 12%, 65%)',
  },
  yellow: {
    light: 'hsl(62, 88%, 53%)',
    main: 'hsl(47, 98%, 66%)',
    darkish: 'hsl(44, 100%, 47%)',
    dark: 'hsl(40, 77%, 46%)',
  },
  orange: { main: 'hsl(18, 81%, 62%)' },
  violet: { main: 'hsl(231, 23%, 40%)' },
  maroon: { main: 'hsl(338, 27%, 35%)' },
  turquoise: { main: 'hsl(183, 98%, 24%)' },
  speedlo: {
    main: 'hsl(8, 76%, 63%)',
    secondary: `hsl(226, 63%, 20%)`,
  },

  // colors taken from external sources
  // like integrated services
  external: {
    foodora: `hsl(335, 87%, 47%)`,
    wolt: `hsl(198, 100%, 44%)`,
    bolt: `hsl(151, 58%, 46%)`,
  },

  beColors: {
    yellow: 'hsl(47, 98%, 66%)',
    magenta: 'hsl(340, 63%, 52%)',
    yellowGreen: 'hsl(84, 40%, 56%)',
    green: 'hsl(140, 100%, 30%)',
    turquoise: 'hsl(175, 100%, 30%)',
    blue: 'hsl(220, 90%, 55%)',
    red: 'hsl(0, 79%, 38%)',
    camel: 'hsl(32, 44%, 60%)',
  },

  // #1ca7c4 bright turquoise
  // #009a8e turquoise
  // #98bc62 yellow green
  // #7cdac2 bermuda
  // #d2386c magenta
  // #bd5560 watermelon
  // #d0e7dd mint
  // #6c8679 jade
  // #b44649 tomato red
  // #c59b6b camel
  // #f9dcd8 icy pink
  // #e0d2b7

  transparent: 'hsla(0, 0%, 0%, 0)',

  appBg: getEnvAppBgColor(),
  iconColor: getEnvAppBgColor(),
} as const

export const applyAlphaToColor = RM.curry(
  (color: TColor, alphaValue: number) => {
    if (color === Colors.transparent) {
      return color
    }

    return Color(color).alpha(alphaValue).string()
  },
)

export enum Radiuses {
  Card = '0.2rem',
  Button = '0.2rem',
  TextComponent = '0.25rem',
  rounded = `50ch`,
  medium = `10px`,
}

export const Shadows = {
  light: '0px 0px 4px 0px rgba(0, 0, 0, 0.1)',
  lightBottom: '0px 2px 4px 0px rgba(0, 0, 0, 0.2)',
  lightHover: '0px 0px 20px 0px rgba(0, 0, 0, 0.1)',
  medium: '0px 0px 6px 0px rgba(0, 0, 0, 0.3)',
  heavyBottom: `0px 16px 13px -15px rgba(0, 0, 0, 0.5)`,
  longBottom: `0px 4px 8px 0px rgba(0, 0, 0, 0.05)`,
} as const

const privateSpacing = {
  appButtonHeight: 3.4375,
  appHeaderHeight: 4.4375,
  appSizeUnit: 'rem',
}

// use these values for consistent margins, paddings, gaps etc.
const spaceGaps = {
  extraSmall: '0.25rem',
  small: '0.5rem',
  smallMedium: '0.7rem',
  medium: '1rem',
  large: '1.5rem',
  extraLarge: '3rem',
}

export const Spacing = {
  MarginAroundFormComponents: '0.1rem',
  Small: '0.25rem',
  Middle: '0.5rem',
  LayoutGridCellMargin: spaceGaps.medium,
  ButtonHeight: privateSpacing.appButtonHeight,
  ButtonHeightWithUnit: `${privateSpacing.appButtonHeight}${privateSpacing.appSizeUnit}`,
  DefaultInputWidth: 20,
  DefaultLabelWidth: 7,
  DefaultSizeUnit: privateSpacing.appSizeUnit,
  ButtonColumnWidthPx: 55,
  ButtonColumnMobileWidthPx: 35,
  PageHeaderHeight: privateSpacing.appHeaderHeight,
  PageHeaderHeightWithUnit: `${privateSpacing.appHeaderHeight}${privateSpacing.appSizeUnit}`,
  PageHeaderVerticalPadding:
    (privateSpacing.appHeaderHeight - privateSpacing.appButtonHeight) / 2,
  PageHeaderVerticalPaddingWithUnit: `${
    (privateSpacing.appHeaderHeight - privateSpacing.appButtonHeight) / 2
  }${privateSpacing.appSizeUnit}`,

  formElementHeight: '2.375rem',
  formElementVerticalGap: spaceGaps.extraSmall,

  dashboardHeaderGap: spaceGaps.extraLarge,

  ...spaceGaps,
} as const

export const FontSizes = {
  InfoMessageFontSize: '1.375rem',
  CompactCard: '0.95rem',
  CompactCardBig: '1rem',
}

export const getPxInRem = () => {
  // ! FIXME
  return 16
}
