import { i18n } from '../../../i18n'
import { TOrderRating, TOrderRatings } from '../typings/orderRating.typings'

export const processRatings = (
  ratings: Nullable<TOrderRatings>,
): Nullable<TOrderRatings> => {
  if (!ratings) return null
  if (ratings.length === 0) return null

  return ratings.map(rating => ({
    ...rating,
    type: transformLabel(rating.type),
    // ratings are stored multiplied by 10 (3 stars will be stored as 30)
    value: rating.value ? Math.round(rating.value / 10) : 0,
    description: rating.description ? rating.description.trim() : null,
  }))
}

const transformLabel = (
  ratingType: TOrderRating['type'],
): TOrderRating['type'] => {
  switch (ratingType.enum) {
    case 'RATING':
      return { ...ratingType, code: i18n.t`Rating` }
    case 'RATING_DELIVERY':
      return { ...ratingType, code: i18n.t`Order delivery` }
    case 'RATING_FOOD':
      return { ...ratingType, code: i18n.t`Product` }
    case 'RATING_ORDER':
      return { ...ratingType, code: i18n.t`Order process` }
    default:
      return ratingType
  }
}
