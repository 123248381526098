import { css } from 'styled-components/macro'

type TSingeDimensionOptions = {
  unit?: string
  minSize: number
  maxSize: number
  minBreakpoint: number
  maxBreakpoint: number
}

/**
 * use this to generate styles for responsive font size (based on viewport width)
 * - until the viewport width reaches minBreakpoint, the font size will be minSize
 * - for viewport width between minBreakpoint (inc) & maxBreakpoint (exc), the media query with dynamic font size (calc) takes effect
 *    -> the font will grow from minSize (on minBreakpoint) to maxSize (on maxBreakpoint)
 * - for the viewport width of maxBreakpoint and above, the font size stays on the maxSize
 */
export const responsiveFontByWidth = ({
  minSize,
  maxSize,
  unit = 'px',
  minBreakpoint,
  maxBreakpoint,
}: TSingeDimensionOptions) => css`
  font-size: ${minSize}${unit};

  @media screen and (min-width: ${minBreakpoint}${unit}) {
    --font-diff: ${maxSize - minSize};
    --breakpoint-diff: ${maxBreakpoint - minBreakpoint};
    --over-min-breakpoint: calc(100vw - ${minBreakpoint}${unit});

    /* minSize + (max - min) * (100vw - minBreakpoint) / (maxBreakpoint - minBreakpoint) */
    font-size: calc(
      ${minSize}${unit} + var(--font-diff) * (var(--over-min-breakpoint) / var(--breakpoint-diff))
    );
  }

  @media screen and (min-width: ${maxBreakpoint}${unit}) {
    font-size: ${maxSize}${unit};
  }
`

type TMultiDimensionOptions = {
  unit?: string
  minSize: number
  maxSize: number
  minWidthBreakpoint: number
  maxWidthBreakpoint: number
  minHeightBreakpoint: number
  maxHeightBreakpoint: number
}

/**
 * use this to generate styles for responsive font size (based on both viewport width AND height)
 * the principle is similar to the one described for responsiveFontByWidth, but this one uses both dimensions
 * - for viewport with width bellow minWidthBreakpoint OR height bellow minHeightBreakpoint, the font size will be minSize
 * - for viewport with width above maxWidthBreakpoint AND height above maxHeightBreakpoint, the font size will be maxSize
 * - for every other scenario (width above min AND height above min AND NOT (width above max AND height above max))
 *    -> the dynamic font size is calculated for both dimension breakpoints (width and height)
 *    -> the lower value is used (it should always fit)
 */
export const responsiveFont = ({
  minSize,
  maxSize,
  unit = 'px',
  minWidthBreakpoint,
  maxWidthBreakpoint,
  minHeightBreakpoint,
  maxHeightBreakpoint,
}: TMultiDimensionOptions) => css`
  font-size: ${minSize}${unit};

  @media screen and (min-width: ${minWidthBreakpoint}${unit}), screen and (min-height: ${minHeightBreakpoint}${unit}) {
    --font-diff: ${maxSize - minSize};
    --width-breakpoint-diff: ${maxWidthBreakpoint - minWidthBreakpoint};
    --height-breakpoint-diff: ${maxHeightBreakpoint - minHeightBreakpoint};

    --width-over-min-breakpoint: calc(100vw - ${minWidthBreakpoint}${unit});
    --height-over-min-breakpoint: calc(100vh - ${minHeightBreakpoint}${unit});

    /* minSize + (maxSize - minSize) * (100vw - minWidthBreakpoint) / (maxWidthBreakpoint - minWidthBreakpoint) */
    --by-width-font-size: calc(${minSize}${unit} + var(--font-diff) * (var(--width-over-min-breakpoint) / var(--width-breakpoint-diff)));
    /* minSize + (maxSize - minSize) * (100vh - minHeightBreakpoint) / (maxHeightBreakpoint - minHeightBreakpoint) */
    --by-height-font-size: calc(${minSize}${unit} + var(--font-diff) * (var(--height-over-min-breakpoint) / var(--height-breakpoint-diff)));

    --calculated-font-size: min(var(--by-width-font-size), var(--by-height-font-size));

    /* guard for the minSize – without this it would "underflow" on eg. really long but thin screen */
    font-size: max(var(--calculated-font-size), ${minSize}${unit});
  }

  @media screen and (min-width: ${maxWidthBreakpoint}${unit}) and (min-height: ${maxHeightBreakpoint}${unit}) {
    font-size: ${maxSize}${unit};
  }
`
