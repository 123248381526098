import styled from 'styled-components/macro'

import {
  borders,
  BordersProps,
  palette,
  PaletteProps,
  positions,
  PositionsProps,
  spacing,
  SpacingProps,
  typography,
  TypographyProps,
} from '@material-ui/system'

import { FONT_FAMILIES, FONT_WEIGHTS } from '../../../designSystem'

// TODO - use designSystem props, no material-ui
export type TTextProps = TypographyProps &
  PaletteProps &
  SpacingProps &
  PositionsProps &
  BordersProps & {
    textDecorationLine?: string
    textDecorationColor?: TColor
  }

/**
 * General customizable text component.
 * !If another prop is needed, please add a customizable option
 * !for example like the text decorations are done
 */
export const Text = styled.p<TTextProps>`
  ${palette}
  ${positions}
  ${spacing}
  ${typography}
  ${borders}

  font-weight: ${({ fontWeight = FONT_WEIGHTS.regular }) => fontWeight};
  line-height: ${({ lineHeight = 1.4 }) => lineHeight};
  text-decoration-line: ${({ textDecorationLine }) => textDecorationLine};
  text-decoration-color: ${({ textDecorationColor }) => textDecorationColor};
  font-family: ${({ fontFamily = FONT_FAMILIES.primary }) => fontFamily};
  font-style: ${({ fontStyle = `normal` }) => fontStyle};
`
