import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { TFPrintRecord } from '../../../graph/generated'
import { useDevPrintContentPreviewModal } from '../devTools/useDevPrintContentPreviewModal'
import { useLoadRegisteredPrinters } from '../hooks/useLoadRegisteredPrinters'
import { usePrintingService } from '../hooks/usePrintingService'
import { usePrintWatch } from '../hooks/usePrintWatch'
import { PrintFailedModal } from './PrintRequestFailedModal'

export type TPrintRecord = TFPrintRecord

type TProps = NoChildren

type TPrintNotification = FirstParam<
  typeof usePrintingService
>['printSuccessNotification']

// setup a watch for print records (on app load)
// Watch logic:
// - Q printers registered on BE for branches of this user
// - get connected printers
// - get the intersection (registered AND connected = watched printers)
// - for watched printers, Q the print records user might have missed
// - connect a S for new print records that will come – this S needs to run above the whole app on all pages!
// - when we get a print record, try printing it via the printing service
//    - if the record is older, ask user if he still wants to print it, rather than printing it immediately
// - once the printing service returns an information that the record was successfully printed, pass that info to the BE
const PrintWatchComponent: React.FC<TProps> = () => {
  useLoadRegisteredPrinters()

  const { previewModal, showPrintPreview } = useDevPrintContentPreviewModal()

  const printSuccessNotification: TPrintNotification = ({
    recordId,
    printRecord,
  }) => {
    if (!printRecord) {
      return `Printed record ${recordId}`
    }

    return (
      <span onClick={() => showPrintPreview(printRecord.renderer.content)}>
        {`Printed record ${printRecord.id} on ${printRecord.printer.nameLabel}`}
      </span>
    )
  }

  // get the printing service api combined with the handling methods
  const { requestPrint, requestPrintResult } = usePrintingService({
    printSuccessNotification,
  })

  // hook the printing service to the Q & S that deliver print records from BE (and also local model for already requested records)
  const { printOldRecordsModal } = usePrintWatch({
    requestPrint,
    requestPrintResult,
  })

  return (
    <>
      {printOldRecordsModal}
      {previewModal}
      <PrintFailedModal requestPrint={requestPrint} />
    </>
  )
}

export const PrintWatch = observer(PrintWatchComponent)
