import { Instance, types } from 'mobx-state-tree'

import { TFCompanyBranch } from '../../../graph/generated'
import { BaseModel } from '../../../models/BaseModel'
import { BusinessHoursModel } from '../../businesshours/models/BusinessHoursModel'
import { EmployeeModel } from '../../businessManagement/employees/models/EmployeeModel'
import { ProductTagModel } from '../../businessManagement/productTags/models/ProductTagModel'
import { MenuModel } from '../../menu/models/MenuModel'
import { PrintSettingsModel } from '../PrintSettings/models/PrintSettingsModel'
import { AppSettingsModel } from './AppSettingsModel'

export const SettingsModel = BaseModel.named('Settings')
  .props({
    menu: types.optional(MenuModel, {}),
    selectedBranchId: types.maybe(types.string),
    employees: types.optional(EmployeeModel, {}),
    productTags: types.optional(ProductTagModel, {}),
    appSettings: types.optional(AppSettingsModel, {}),
    printSettings: types.optional(PrintSettingsModel, {}),
    businessHours: types.optional(BusinessHoursModel, {}),
  })

  .views(self => ({
    get branch(): TFCompanyBranch {
      let useBranch: Nullable<TFCompanyBranch> = null

      if (self.selectedBranchId) {
        useBranch = self.root.user.findBranchById(self.selectedBranchId) || null
      }

      return useBranch || self.root.user.defaultBranch
    },

    get branchId(): ID {
      return self.selectedBranchId || self.root.user.defaultBranch.id
    },
  }))

  .actions(self => ({
    invalidate() {
      self.selectedBranchId = undefined
    },

    selectBranch(branchId: ID) {
      self.selectedBranchId = branchId
    },
  }))

export interface TSettingsModel extends Instance<typeof SettingsModel> {}
