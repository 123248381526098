import styled from 'styled-components/macro'

import { getSpaceValue } from '../../../designSystem'
import { Colors } from '../../../styling'
import { TSeparatorProps } from '../separator.types'

/**
 * solid line separator
 * adjustable vertical margin and color
 */
export const SeparatorSolid = styled.div<TSeparatorProps>`
  margin: ${({ verticalSpace = `s0` }) => `${getSpaceValue(verticalSpace)} 0`};

  background-color: ${({ color = Colors.grey.darkMedium }) => `${color}`};

  height: ${({ height = `1px` }) => `${height}`};
`
