import { Instance, types } from 'mobx-state-tree'

import { sortCollection } from '../../../helpers/sort'
import { BaseModel } from '../../../models/BaseModel'
import { RM } from '../../../tools/ramda'
import { TActiveExternalDelivery } from '../types/externalDelivery.types'
import { getExternalDeliveryStatusCategory } from '../utils/getExternalDeliveryStatusCategory'

type TVolatileProps = {
  isLoaded: boolean
  userHandlesExternalDeliveries: boolean
  deliveryMap: Map<ID, TActiveExternalDelivery>
  activeExternalDeliveries: RoA<TActiveExternalDelivery>
}

/**
 * Model for the activeExternalDeliveries segment
 * storing an array with activeExternalDeliveries (global state) and flags for UI purposes
 * with some control methods
 */
export const ActiveExternalDeliveriesModel = BaseModel.named(
  `ActiveExternalDeliveries`,
)
  .props({
    isListOpen: types.optional(types.boolean, false),
  })

  .volatile<TVolatileProps>(() => ({
    isLoaded: false,
    deliveryMap: new Map(),
    activeExternalDeliveries: [],
    userHandlesExternalDeliveries: false,
  }))

  // getters for computed values
  .views(self => ({
    get shouldRenderExternalDeliveries() {
      return self.userHandlesExternalDeliveries && self.isLoaded
    },

    get numberOfActiveExternalDeliveries() {
      return self.activeExternalDeliveries.length
    },

    get anyExternalDeliveryRequiresAction() {
      return self.activeExternalDeliveries
        .map(getExternalDeliveryStatusCategory)
        .includes('FAILED')
    },

    // ! this is not reactive because the Map reference stays the same
    // it’s used to prevent lookup in an array each time
    // but it should not be used by itself – use `useGetOrderExternalDelivery` instead
    getOrderExternalDelivery(orderId: ID) {
      return self.deliveryMap.get(orderId)
    },
  }))

  // simple flag updating functions, called on initial load
  .actions(self => ({
    setIsLoaded(isLoaded: boolean) {
      self.isLoaded = isLoaded
    },

    setUserHandlesExternalDeliveries(isUsed: boolean) {
      self.userHandlesExternalDeliveries = isUsed
    },
  }))

  // list control functions
  .actions(self => ({
    openList() {
      self.isListOpen = true
    },

    closeList() {
      self.isListOpen = false
    },

    toggleList() {
      self.isListOpen = !self.isListOpen
    },
  }))

  // actions for setting and updating the list state
  .actions(self => ({
    updateDeliveries(deliveries: RoA<TActiveExternalDelivery>) {
      self.activeExternalDeliveries = sortCollection(
        deliveries,
        sortByStatusAndTime,
      )
    },

    setActiveExternalDeliveries(deliveries: RoA<TActiveExternalDelivery>) {
      this.updateDeliveries(deliveries)
      self.deliveryMap = new Map()

      deliveries.forEach(delivery => {
        self.deliveryMap.set(delivery.order.id, delivery)
      })
    },

    addDelivery(delivery: TActiveExternalDelivery) {
      const updatedDeliveries = [...self.activeExternalDeliveries, delivery]
      this.updateDeliveries(updatedDeliveries)
      self.deliveryMap.set(delivery.order.id, delivery)
    },

    removeDelivery(delivery: TActiveExternalDelivery) {
      const updatedDeliveries = RM.reject(
        RM.idPropEq(delivery.id),
        self.activeExternalDeliveries,
      )

      // no need to re-sort on remove
      self.activeExternalDeliveries = updatedDeliveries
      self.deliveryMap.delete(delivery.order.id)
    },

    replaceDelivery(replacedDelivery: TActiveExternalDelivery) {
      const updatedDeliveries = self.activeExternalDeliveries.map(delivery => {
        return delivery.id === replacedDelivery.id ? replacedDelivery : delivery
      })

      this.updateDeliveries(updatedDeliveries)
      self.deliveryMap.set(replacedDelivery.order.id, replacedDelivery)
    },

    processDeliveryUpdate(delivery: TActiveExternalDelivery) {
      const isDone = delivery.status.enum === 'FINISHED'
      const shouldBeRemoved = delivery.seen || isDone

      if (shouldBeRemoved) {
        this.removeDelivery(delivery)
        return
      }

      // prettier-ignore
      const isUpdate = self.activeExternalDeliveries.some(RM.idPropEq(delivery.id))

      if (isUpdate) {
        this.replaceDelivery(delivery)
        return
      }

      this.addDelivery(delivery)
    },
  }))

  // service functions
  .actions(self => ({
    invalidate() {
      self.isLoaded = false
      self.userHandlesExternalDeliveries = false
      self.activeExternalDeliveries = []
      self.deliveryMap = new Map()
    },
  }))

export interface TActiveExternalDeliveriesModel
  extends Instance<typeof ActiveExternalDeliveriesModel> {}

// * HELPERS

// put failed deliveries first, then sort by time
const sortByStatusAndTime = (
  deliveryA: TActiveExternalDelivery,
  deliveryB: TActiveExternalDelivery,
) => {
  const deliveryAStatus = getExternalDeliveryStatusCategory(deliveryA)
  const deliveryBStatus = getExternalDeliveryStatusCategory(deliveryB)

  const isDeliveryAFailed = deliveryAStatus === 'FAILED'
  const isDeliveryBFailed = deliveryBStatus === 'FAILED'

  if (isDeliveryAFailed && !isDeliveryBFailed) {
    return -1
  }

  if (!isDeliveryAFailed && isDeliveryBFailed) {
    return 1
  }

  const deliveryASortTime = getDeliverySortTime(deliveryA)
  const deliveryBSortTime = getDeliverySortTime(deliveryB)

  return deliveryASortTime.getTime() - deliveryBSortTime.getTime()
}

// same logic used when computing handover time
// the new Date is here just to ensure the Date type, `deliverAt` should be always defined at this point
const getDeliverySortTime = ({ order }: TActiveExternalDelivery) => {
  return order.driverWillPickupAt ?? order.deliverAt ?? new Date()
}
