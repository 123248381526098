import * as React from 'react'
import styled from 'styled-components/macro'

import { Icon } from '../../../atoms/Icon'
import { getRadiusValue, getSpaceValue, TSpace } from '../../../designSystem'
import { getContraColor } from '../../../helpers/styles/colorAdjustments'
import { TActiveExternalDelivery } from '../types/externalDelivery.types'
import {
  getExternalDeliveryColor,
  getExternalDeliveryIcon,
} from '../utils/getExternalDeliveryStatusCategory'

type TStyledProps = {
  bgColor: string
  horizontalSpace: TSpace
}

type TProps = NoChildren & {
  noLabel?: boolean
  horizontalSpace?: TSpace
  withExternalDeliveryIcon?: boolean
  status: TActiveExternalDelivery['status']
}

const StyledExternalDeliveryStatus = styled.div<TStyledProps>`
  display: flex;
  align-items: center;
  gap: ${({ horizontalSpace }) => getSpaceValue(horizontalSpace)};
  padding: ${({ horizontalSpace }) =>
    `${getSpaceValue(`s4`)} ${getSpaceValue(horizontalSpace)}`};

  border-radius: ${getRadiusValue(`r4`)};
  background: ${({ bgColor }) => bgColor};
  color: ${({ bgColor }) => getContraColor(bgColor)};

  width: fit-content;
`

/**
 * renders a badge with the current status of the external delivery,
 * with corresponding icon and color,
 * can be forced to use the external delivery icon for all statuses
 */
export const ExternalDeliveryStatus: React.FC<TProps> = ({
  noLabel = false,
  horizontalSpace = `s4`,
  ...props
}) => {
  return (
    <StyledExternalDeliveryStatus
      horizontalSpace={horizontalSpace}
      bgColor={getExternalDeliveryColor(props)}
    >
      <Icon icon={getExternalDeliveryIcon(props)} fontSize={1.2} />
      {!noLabel && props.status.label}
    </StyledExternalDeliveryStatus>
  )
}
