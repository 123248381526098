import { ModalActions } from './components/ModalActions'
import { ModalButton } from './components/ModalButton'
import { ModalContent } from './components/ModalContent'
import { ModalFooter } from './components/ModalFooter'
import { ModalHeader } from './components/ModalHeader'
import { ModalSeparator } from './components/ModalSeparator'
import { ModalSectionTitle, ModalTitle } from './components/ModalTitle'
import { ModalWrapper } from './components/ModalWrapper'

/**
 * component set for presenting content in a Modal view
 *
 * @example
 * // basic usage
 * <Modal.Wrapper>
 *   <Modal.Header>
 *     <Modal.Title>Modal Title</Modal.Title>
 *   </Modal.Header>
 *
 *   <Modal.Content>
 *     <Modal.SectionTitle>Section Title</Modal.SectionTitle>
 *     <p>Modal Content...</p>
 *
 *     <Modal.Separator />
 *
 *     <Modal.SectionTitle>Section Title</Modal.SectionTitle>
 *     <p>More Content...</p>
 *    </Modal.Content>
 *
 *   <Modal.Footer>
 *     <Modal.Actions>
 *       <Modal.Button type={`cancel`} />
 *       <Modal.Button type={`confirm`} />
 *     </Modal.Actions>
 *   </Modal.Footer>
 * </Modal.Wrapper>
 */
export const Modal = {
  Wrapper: ModalWrapper,
  Header: ModalHeader,
  Title: ModalTitle,
  SectionTitle: ModalSectionTitle,
  Content: ModalContent,
  Footer: ModalFooter,
  Actions: ModalActions,
  Button: ModalButton,
  Separator: ModalSeparator,
}

export { MODAL_SPACES } from './config/modal.config'
