import { Plural, Trans } from '@lingui/macro'
import {
  CancelRounded,
  ErrorRounded,
  HelpRounded,
  PriorityHighRounded,
} from '@material-ui/icons'
import * as React from 'react'

import { Icon } from '../../../atoms/Icon'
import { Minutes } from '../../../components/Minutes'
import { Colors } from '../../../styling'

type TResult = {
  output: React.ReactNode // shortish remaining time output (used in kitchen header)
  outputShortLength: number // short output character length -> for eg using smaller font for longer output
  outputFull: React.ReactNode // long remaining time output (hours & minutes) (used in kitchen header)
  outputShort: React.ReactNode // short remaining time output (used in time badges on map and in order list)
}

type TOptions = {
  isCanceledOrder?: boolean
  minutesUntilHandover: Nullable<number>
  iconSize?: PropsOf<typeof Icon>['fontSize']
  iconSizeUnit?: PropsOf<typeof Icon>['fontSizeUnit']
}

const MINUTES_IN_HOUR = 60
const MAX_POSITIVE_HOURS = 9
const MAX_POSITIVE_MINUTES = 120
const MAX_NEGATIVE_MINUTES = -99

/**
 * returns JSX output and the output character length (for font-size purposes) for the remaining handover time
 */
export const getHandoverTimeOutput = ({
  iconSize,
  iconSizeUnit,
  isCanceledOrder,
  minutesUntilHandover,
}: TOptions): TResult => {
  if (isCanceledOrder) {
    return {
      output: '?',
      outputShortLength: 1,
      outputFull: <Trans>Order canceled</Trans>,
      outputShort: (
        <Icon
          fontSize={iconSize}
          icon={CancelRounded}
          color={Colors.white.pure}
          fontSizeUnit={iconSizeUnit}
        />
      ),
    }
  }

  if (minutesUntilHandover === null) {
    return {
      output: '?',
      outputFull: '???',
      outputShortLength: 1,
      outputShort: (
        <Icon
          icon={HelpRounded}
          fontSize={iconSize}
          color={Colors.white.pure}
          fontSizeUnit={iconSizeUnit}
        />
      ),
    }
  }

  const hours = Math.trunc(minutesUntilHandover / MINUTES_IN_HOUR)
  const minutes = minutesUntilHandover % MINUTES_IN_HOUR

  const outputFull = getFullOutput(hours, minutes)

  // for over 120 minutes display hours
  if (minutesUntilHandover > MAX_POSITIVE_MINUTES) {
    // for over 9 hours show just 9+
    const numberOfHours = Math.min(hours, MAX_POSITIVE_HOURS)

    return {
      outputFull,
      outputShortLength: 3,
      output: (
        <Plural
          value={numberOfHours}
          zero="#+ hours"
          one="#+ hour"
          few="#+ hours"
          other="#+ hours"
        />
      ),
      outputShort: (
        <>
          {numberOfHours}+<Trans description="hours">h</Trans>
        </>
      ),
    }
  }

  // for bellow -99 minutes show !
  if (minutesUntilHandover < MAX_NEGATIVE_MINUTES) {
    return {
      outputFull,
      outputShortLength: 1,
      output: (
        <Icon
          fontSize={iconSize}
          color={Colors.red.main}
          icon={PriorityHighRounded}
          fontSizeUnit={iconSizeUnit}
        />
      ),
      outputShort: (
        <Icon
          fontSize={iconSize}
          icon={ErrorRounded}
          color={Colors.white.pure}
          fontSizeUnit={iconSizeUnit}
        />
      ),
    }
  }

  const outputShortLength = String(minutesUntilHandover).length
  const positiveMinuteValue = Math.abs(minutesUntilHandover)

  const minuteLabel = (
    <Plural
      value={positiveMinuteValue}
      zero="# mins"
      one="# min"
      few="# mins"
      other="# mins"
    />
  )

  // for < -99, -1 > use the proper minus sign
  if (minutesUntilHandover < 0) {
    return {
      outputFull,
      outputShortLength,
      output: <>&minus;{minuteLabel}</>,
      outputShort: <>&minus;{positiveMinuteValue}</>,
    }
  }

  // for < 0, 120 > return actual minutes
  return {
    outputFull,
    outputShortLength,
    output: minuteLabel,
    outputShort: positiveMinuteValue,
  }
}

const getFullOutput = (hours: number, minutes: number) => {
  const positiveHours = Math.abs(hours)
  const positiveMinutes = Math.abs(minutes)
  const isNegativeTime = hours < 0 || minutes < 0

  const hoursLabel = (
    <Plural
      value={positiveHours}
      zero="# hours"
      one="# hour"
      few="# hours"
      other="# hours"
    />
  )

  const minutesLabel = <Minutes value={positiveMinutes} />

  const prefix = isNegativeTime ? <>&minus;</> : ''

  if (hours === 0) {
    return (
      <>
        {prefix}
        {minutesLabel}
      </>
    )
  }

  if (minutes === 0) {
    return (
      <>
        {prefix}
        {hoursLabel}
      </>
    )
  }

  return (
    <>
      {prefix}
      {hoursLabel} {minutesLabel}
    </>
  )
}
