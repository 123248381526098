import { Trans } from '@lingui/macro'
import * as React from 'react'

import { GridBox } from '../../../atoms/Box/Box'
import { FlatSelect } from '../../FlatSelect'

type TProps = RequiredChildren & {
  value: Nullable<number>
  onChange: (value: number) => void
}

/**
 * provides context for MinuteSelect components,
 * adds a label custom renderer
 */
export const MinuteSelectProvider: React.FC<TProps> = ({
  children,
  value,
  onChange,
}) => {
  return (
    <FlatSelect.Provider
      value={value}
      onChange={onChange}
      renderLabel={renderLabel}
    >
      {children}
    </FlatSelect.Provider>
  )
}

// * HELPERS

const renderLabel = (value: number) => {
  if (typeof value === `string`) return value

  return (
    <GridBox>
      <span>{value}</span>
      <span>
        <Trans>min</Trans>
      </span>
    </GridBox>
  )
}
