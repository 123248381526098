import { Sentry } from '@speedlo/sentry'
import { intercept } from 'mobx'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { useQUserBootstrap } from '../../graph/generated'
import { formatFullName } from '../../helpers/formats'
import { RM } from '../../tools/ramda'
import { useRoot } from '../root'

type TProps = NoChildren

export const UserBootstrap = observer<TProps>(() => {
  const { auth: authModel, user: userModel } = useRoot()

  const { data } = useQUserBootstrap({
    variables: { userId: authModel.userId! },
    skip: !authModel.isAuthenticatedUser,
  })

  // UserModel needs to be reset whenever auth user changes
  React.useEffect(() =>
    intercept(authModel, 'userId', change => {
      userModel.invalidate()
      return change
    }),
  )

  React.useEffect(() => {
    if (userModel.isLoaded) {
      return
    }

    if (!authModel.userId) {
      userModel.finishBootstrap()
      Sentry.configureScope(scope => {
        scope.setUser({
          id: undefined,
          username: undefined,
        })

        scope.setExtra('roles', [])
        scope.setExtra('branches', [])
      })
    }

    if (authModel.isAuthenticatedUser) {
      if (!data) {
        return
      }

      // ? Should be removed when BE drops language caching
      if (userModel.language === null) {
        userModel.setLanguage(data.user.language.rawCode)
      }

      userModel.applyUserData(data.user)
      userModel.applyAccess(data.access)
      userModel.applyPermissions(data.permissions)
      userModel.finishBootstrap()

      Sentry.configureScope(scope => {
        scope.setUser({
          id: authModel.userId!,
          username: formatFullName(data.user),
        })
        scope.setExtras({
          roles: data.user.roles.map(RM.prop('name')),
          branches: data.user.branches.map(RM.prop('id')),
        })
      })
    }
  })

  return null
})
