import { Instance } from 'mobx-state-tree'

import { TFEmployee, TQEmployeeFormConfig } from '../../../../graph/generated'
import { formatFullName } from '../../../../helpers/formats'
import { sortCollection } from '../../../../helpers/sort'
import { BaseModel } from '../../../../models/BaseModel'
import { RM } from '../../../../tools/ramda'
import { TEmployee, TEmployeeRole, TLanguage } from '../typings/employees.types'
import { isSuspendedRole } from '../utils/utils'

type TVolatileProps = {
  loadedForBranches: RoA<ID> // branches that the employees was loaded for (current state of the filter when the Q was fired)
  isCreatingEmployee: boolean
  employees: Nullable<RoA<TEmployee>>
  editedEmployee: Nullable<TEmployee>
  availableLanguages: Nullable<RoA<TLanguage>> // languages supported as user languages in our system
  assignableRoles: Nullable<RoA<TEmployeeRole>> // roles that can be assigned to the employees by the currently logged user
}

export const EmployeeModel = BaseModel.named(`Employee`)
  .volatile<TVolatileProps>(() => ({
    employees: null,
    editedEmployee: null,
    assignableRoles: null,
    loadedForBranches: [],
    availableLanguages: null,
    isCreatingEmployee: false,
  }))

  .views(self => ({
    get loadedEmployeesRoles() {
      if (self.employees === null) {
        return null
      }

      // get all unique roles from all of the employees in the list
      // so they can be used as possible options in the list filter
      const employeeRoles = [...self.employees].flatMap(employee => {
        return employee.roles
      })

      const uniqueRoles = RM.uniqWith(
        (roleA, roleB) => roleA.enum === roleB.enum,
        employeeRoles,
      )

      return uniqueRoles
    },

    get isEditingEmployee() {
      return self.editedEmployee !== null
    },
  }))

  // data transform & save – from GQL Q type to a type required by the UI
  .actions(self => ({
    // load list of employees
    setEmployees(
      employees: ReadonlyArray<TFEmployee>,
      loadedForBranches: RoA<ID>,
    ) {
      self.loadedForBranches = loadedForBranches

      const formatEmployee = formatEmployeeForBranches(loadedForBranches)
      const formattedEmployees = employees.map(formatEmployee)
      const sortedEmployees = sortActiveFirstByName(formattedEmployees)

      self.employees = sortedEmployees
    },

    // add employee locally after creating it
    addEmployee(employee: TFEmployee) {
      if (!self.employees) {
        return
      }

      const employeeWithoutDefaultRoles = {
        ...employee,
        roles: employee.roles.filter(rejectDefaultRole),
      }

      const newEmployee = formatEmployeeForBranches(self.loadedForBranches)(
        employeeWithoutDefaultRoles,
      )

      const sortedEmployees = sortActiveFirstByName([
        ...self.employees,
        newEmployee,
      ])

      self.employees = sortedEmployees
    },

    // replace employee locally after editing it
    replaceEmployee(employee: TFEmployee) {
      if (!self.employees) {
        return
      }

      const updatedEmployee = formatEmployeeForBranches(self.loadedForBranches)(
        employee,
      )

      const updatedEmployees = self.employees.map(employee => {
        if (employee.id === updatedEmployee.id) {
          return {
            ...updatedEmployee,
            roles: updatedEmployee.roles.filter(rejectDefaultRole),
          }
        }

        return employee
      })

      self.employees = updatedEmployees
    },

    saveFormConfig(config: TQEmployeeFormConfig) {
      self.assignableRoles =
        config.user.settings.canAssignRoles.filter(rejectDefaultRole)
      self.availableLanguages = config.languages
    },
  }))

  // actions for controlling the create/edit modal
  .actions(self => ({
    startCreatingEmployee() {
      self.isCreatingEmployee = true
    },

    setEditingEmployee(employee: TEmployee) {
      self.editedEmployee = employee
    },

    closeCreatingOrEditingModal() {
      self.isCreatingEmployee = false
      self.editedEmployee = null
    },
  }))

  // methods for data erasing when they are not needed/used anymore
  .actions(self => ({
    invalidateEmployeeList() {
      self.employees = null
      self.loadedForBranches = []
    },

    invalidateModalData() {
      self.isCreatingEmployee = false
      self.editedEmployee = null
      self.assignableRoles = null
      self.availableLanguages = null
    },

    invalidateAllData() {
      this.invalidateEmployeeList()
      this.invalidateModalData()
    },
  }))

export interface TEmployeeModel extends Instance<typeof EmployeeModel> {}

// find out if the employee has an active relationship with a branch
const activeEmployment = (employee: TFEmployee) => (branchId: ID) => {
  const activeBranchEmployment = employee.employments.find(employment => {
    return employment.branch.id === branchId && !employment.inactive
  })

  return Boolean(activeBranchEmployment)
}

// transform GQL employee to the UI type
const formatEmployeeForBranches =
  (
    filteredForBranches: RoA<ID>, //
  ) =>
  (
    employee: TFEmployee, //
  ): TEmployee => {
    const {
      id,
      email,
      phone,
      roles,
      login,
      language,
      lastName,
      firstName,
      employments,
    } = employee

    const hasActiveEmployment = activeEmployment(employee)

    return {
      id,
      email,
      phone,
      login,
      language,
      lastName,
      firstName,
      employments,
      fullName: formatFullName(employee),
      // every user has a USER role, it’s pointless to present it
      roles: employee.roles.filter(rejectDefaultRole),
      isSuspended: Boolean(roles.find(isSuspendedRole)),
      // if user has not an active employment in any of the filtered branches, mark him somehow later (eg. sort last bellow)
      hasActiveEmployment: filteredForBranches.some(hasActiveEmployment),
    }
  }

// make employees active for at least one of the filtered branches appear before all of those that are inactive for all of the branches
// inside of these two groups, sort employees alphabetically
const sortActiveFirstByName = (employees: RoA<TEmployee>) => {
  return sortCollection(employees, (employeeA, employeeB) => {
    const justAActive =
      employeeA.hasActiveEmployment && !employeeB.hasActiveEmployment
    const justBActive =
      employeeB.hasActiveEmployment && !employeeA.hasActiveEmployment

    if (justAActive) {
      return -1
    }

    if (justBActive) {
      return 1
    }

    // both active or both inactive – sort alphabetically
    return employeeA.fullName.localeCompare(employeeB.fullName, `cs`)
  })
}

const rejectDefaultRole = (role: TEmployeeRole) => {
  return role.enum !== `USER`
}
