import {
  AccountCircleOutlined,
  AddRounded,
  AlarmOnRounded,
  AlarmRounded,
  Album,
  CallMissedOutgoingRounded,
  CancelRounded,
  CheckCircleOutlineRounded,
  CheckCircleRounded,
  ChevronLeftRounded,
  ChevronRightRounded,
  CloseRounded,
  ComputerRounded,
  CreditCardRounded,
  DepartureBoardRounded,
  DirectionsCarRounded,
  DirectionsRunRounded,
  EditRounded,
  EmojiTransportationRounded,
  ErrorRounded,
  GroupRounded,
  HelpRounded,
  HomeRounded,
  HowToRegRounded,
  InfoOutlined,
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
  LabelImportantRounded,
  MailOutlineRounded,
  MenuRounded,
  MonetizationOnOutlined,
  NewReleasesRounded,
  NoteRounded,
  PersonRounded,
  PhoneRounded,
  PinDropRounded,
  PrintRounded,
  RemoveCircleRounded,
  RemoveRounded,
  Sms,
  StarBorderRounded,
  StarHalfRounded,
  StarRounded,
  StoreRounded,
  SwapHorizontalCircleRounded,
  UpdateRounded,
  VisibilityOffRounded,
  VisibilityRounded,
  WarningRounded,
} from '@material-ui/icons'

export const IconBranch = StoreRounded

export const IconCoins = MonetizationOnOutlined
export const IconCard = CreditCardRounded
export * from './components/IconPaidByCard'
export * from './components/IconWalletCredits'

export const IconNote = NoteRounded
export const IconCustomer = PersonRounded
export const IconPhone = PhoneRounded
export const IconEmail = MailOutlineRounded
export const IconOrderOrigin = LabelImportantRounded
export const IconPriority = StarRounded
export const IconOnTime = AlarmRounded
export const IconDestination = PinDropRounded
export const IconOrderTransfer = CallMissedOutgoingRounded
export const IconAppliedRules = NewReleasesRounded
export const IconHandoverTime = AlarmOnRounded
export const IconHandoverTimeChange = UpdateRounded
export const IconCreatedBy = AccountCircleOutlined
export * from './components/IconAgeControl'

export const IconCustomerHistory = GroupRounded

export const IconRating = StarHalfRounded
export const IconRatingFull = StarRounded
export const IconRatingEmpty = StarBorderRounded

export const IconOrderReceived = HowToRegRounded
export const IconOrderPreparing = UpdateRounded
export const IconOrderReady = DepartureBoardRounded
export const IconOrderOnWay = DirectionsCarRounded
export const IconOrderFinished = CheckCircleOutlineRounded

export const IconDriver = DirectionsCarRounded
export const IconDriverHistory = DirectionsCarRounded
export const IconExternalDelivery = EmojiTransportationRounded
export const IconExternalDeliveryProcessing = SwapHorizontalCircleRounded
export const IconExternalDeliverySuccess = CheckCircleRounded
export const IconExternalDeliveryFailed = ErrorRounded
export const IconExternalDeliveryPickupTime = DepartureBoardRounded
export const IconExternalDeliveryEstimatedFee = MonetizationOnOutlined

export const IconReprint = PrintRounded

export const IconWarning = WarningRounded
export const IconUnknownStatus = HelpRounded
export const IconInfoMessage = InfoOutlined
export const IconMenuOpen = MenuRounded
export const IconMenuClose = CloseRounded

export const IconEditOrderGroup = EditRounded

export const IconChevronRight = ChevronRightRounded
export const IconChevronLeft = ChevronLeftRounded
export const IconChevronUp = KeyboardArrowUpRounded
export const IconChevronDown = KeyboardArrowDownRounded
export const IconHome = HomeRounded
export const IconPlus = AddRounded
export const IconMinus = RemoveRounded

export const IconAppError = WarningRounded

export const IconListItemDone = CheckCircleRounded
export const IconListItemNotDone = CancelRounded
export const IconListItemDisabled = RemoveCircleRounded

export const IconVisibilityOn = VisibilityRounded
export const IconVisibilityOff = VisibilityOffRounded

export const IconHandoverTypeDelivery = DirectionsCarRounded
export const IconHandoverTypePickup = DirectionsRunRounded
export const IconHandoverTypeInHouse = StoreRounded

export const IconCashRegister = ComputerRounded

export const IconsSms = Sms
export const IconZone = Album

export * from './components/IconBolt'
export * from './components/IconWolt'
export * from './components/IconFoodora'

export * from './components/IconId'
