import * as React from 'react'
import styled from 'styled-components/macro'

import {
  getContrastShadeFor,
  getPaddingValue,
  getRadiusValue,
  getShadow,
  TColorKey,
  THEME,
} from '../../../designSystem'
import { isDefined } from '../../../helpers/isDefined'
import { i18n } from '../../../i18n'
import { cn } from '../../../libs/classnames'
import { Colors } from '../../../styling'
import { useFlatSelectCtx } from '../contexts/FlatSelectProvider'

type TStyledProps = {
  bgColor: TColorKey
  focusColor: TColorKey
  textColor: string
  gridColumn?: string
}

type TProps = NoChildren & {
  placeholder?: string
  label?: React.ReactNode
  type?: 'text' | 'number'
  selectedColor?: TColorKey
  disabled?: boolean
  gridColumn?: string
}

const StyledInput = styled.div<TStyledProps>`
  cursor: text;

  box-shadow: ${getShadow(`button`)};
  border-radius: ${getRadiusValue(`button`)};

  /* by default, take the full row */
  grid-column: ${({ gridColumn = `-1 / 1` }) => gridColumn};

  display: grid;
  align-items: center;
  justify-content: center;
  /* center input and the label */
  grid-template-columns: 1fr 1fr;

  background-color: ${({ bgColor }) => THEME[bgColor].normal};
  color: ${({ textColor }) => textColor};
  padding: ${getPaddingValue(`buttonPadding`)};

  input {
    appearance: none;
    border: none;
    width: 100%;
    text-align: right;
    /* use the same colors as the wrapper */
    background-color: transparent;
    color: inherit;

    /* hide outline on inner input */
    &:focus {
      outline: none;
    }
  }

  /* show outline on the wrapper */
  &:focus-within {
    outline: 2px solid ${({ focusColor }) => THEME[focusColor].normal};
  }

  &:hover:not(.disabled) {
    box-shadow: ${getShadow(`buttonHover`)};
    background-color: ${({ bgColor }) => THEME[bgColor].hover};
  }

  .disabled {
    cursor: not-allowed;
    background-color: ${({ bgColor }) => THEME[bgColor].disabled};
  }

  /* hide numeric controls https://stackoverflow.com/a/4298216 */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`

/**
 * renders a custom input for FlatSelect that allows user to enter a custom value,
 * calls an update callback from FlatSelectProvider on value change,
 * ! usable only with primitive option types (string/number)
 */
export const FlatSelectCustomInput: React.FC<TProps> = ({
  label,
  type = `text`,
  gridColumn,
  disabled = false,
  selectedColor = `primary`,
  placeholder = i18n.t`custom value`,
}) => {
  const refInput = React.useRef<HTMLInputElement>(null)
  const { onChange, value, isCustomValue } = useFlatSelectCtx()

  const isSelected = isDefined(value) && value !== `` && isCustomValue

  const bgColor: TColorKey = isSelected ? selectedColor : `white`
  const fgShade = getContrastShadeFor(THEME[bgColor].normal)
  const textColorValue =
    fgShade === `light` ? Colors.white.pure : Colors.black.pure

  const setValueAndUpdate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value

    if (type === `text` || newValue === ``) {
      onChange(newValue, { isCustomValue: true })
      return
    }

    onChange(Number(newValue), { isCustomValue: true })
  }

  const focusInput = () => {
    refInput.current?.focus()
  }

  return (
    <StyledInput
      bgColor={bgColor}
      onClick={focusInput}
      gridColumn={gridColumn}
      focusColor={selectedColor}
      textColor={textColorValue}
      className={cn({ disabled })}
    >
      <input
        type={type}
        ref={refInput}
        disabled={disabled}
        placeholder={placeholder}
        onChange={setValueAndUpdate}
        value={isSelected ? String(value) : ``}
      />
      <span>{label}</span>
    </StyledInput>
  )
}
