import { getIsOrderOnTime } from '../../../helpers/order/getIsOrderOnTime'
import { TOrderForHandoverTime } from '../typings/handoverTime.types'
import { getHandoverTimeOutput } from '../utils/getHandoverTimeOutput'
import { getHandoverTimeSeverity } from '../utils/getHandoverTimeSeverity'
import { useHandoverTimeMinutes } from './useHandoverTimeMinutes'

type TOptions = {
  order: TOrderForHandoverTime
  fakeHandedOverTime?: Nullable<Date>
  iconSize?: FirstParam<typeof getHandoverTimeOutput>['iconSize']
  iconSizeUnit?: FirstParam<typeof getHandoverTimeOutput>['iconSizeUnit']
}

/**
 * returns a handover time related info about the order
 * eg. its severity (how close it is to expected handover time)
 * and time output in various formats
 */
export const useOrderHandoverTime = ({
  order,
  iconSize = 2,
  iconSizeUnit,
  fakeHandedOverTime,
}: TOptions) => {
  const {
    deliverAt,
    deliveredAt,
    cancelRequested,
    orderStateCategory,
    driverWillPickupAt,
  } = order

  const isOrderOnTime = getIsOrderOnTime(order)
  const isCanceledOrder =
    orderStateCategory === `CANCEL` || Boolean(cancelRequested)

  const handoverTime = driverWillPickupAt ?? deliverAt
  const handedOverTime = fakeHandedOverTime ?? deliveredAt

  const { minutesUntilHandover } = useHandoverTimeMinutes({
    isCanceledOrder,
    handedOverTime,
    handoverTime,
  })

  const orderSeverity = getHandoverTimeSeverity({
    minutesUntilHandover,
    isCanceledOrder,
  })

  const handoverTimeOutput = getHandoverTimeOutput({
    minutesUntilHandover,
    isCanceledOrder,
    iconSizeUnit,
    iconSize,
  })

  return {
    ...handoverTimeOutput,
    ...orderSeverity,
    handoverTime,
    handedOverTime,
    isOrderOnTime,
    isCanceledOrder,
    minutesUntilHandover,
  }
}
