import { types } from 'mobx-state-tree'

import { BaseModel } from './BaseModel'

export const AudioModel = BaseModel.named('Audio')
  .props({
    userEnabled: types.optional(types.boolean, true),
  })

  .volatile(() => ({
    canPlay: null as Nullable<boolean>,
    audioBlocked: false,
  }))

  .views(self => ({
    get enabled() {
      return self.userEnabled && self.canPlay && !self.audioBlocked
    },

    get askForAudio() {
      return self.userEnabled && self.canPlay === false && !self.audioBlocked
    },
  }))

  .actions(self => ({
    setUserEnabled(enabled: boolean) {
      self.userEnabled = enabled
    },

    setCanPlay(canPlay: boolean = true) {
      self.canPlay = canPlay
    },

    blockAudio() {
      self.audioBlocked = true
    },
  }))
