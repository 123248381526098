import * as React from 'react'
import styled from 'styled-components/macro'

import { MODAL_SEPARATOR_CONFIG, MODAL_SPACES } from '../config/modal.config'

type TMargin = keyof typeof MODAL_SPACES
type TDirection = 'horizontal' | 'vertical'
type TMarginSide = 'before' | 'after' | 'both' | 'none'

type TStyledProps = {
  margin: string
  marginSide: TMarginSide
}

type TProps = NoChildren & {
  margin?: TMargin
  direction?: TDirection
  marginSide?: TMarginSide
}

const HorizontalSeparator = styled.hr<TStyledProps>`
  border: none;
  background: ${MODAL_SEPARATOR_CONFIG.color};

  width: 100%;
  height: ${MODAL_SEPARATOR_CONFIG.size};

  margin-top: ${({ margin, marginSide }) =>
    hasMarginBefore(marginSide) ? margin : 'unset'};
  margin-bottom: ${({ margin, marginSide }) =>
    hasMarginAfter(marginSide) ? margin : 'unset'};
`

const VerticalSeparator = styled.hr<TStyledProps>`
  border: none;
  background: ${MODAL_SEPARATOR_CONFIG.color};

  height: 100%;
  width: ${MODAL_SEPARATOR_CONFIG.size};

  margin-left: ${({ margin, marginSide }) =>
    hasMarginBefore(marginSide) ? margin : 'unset'};
  margin-right: ${({ margin, marginSide }) =>
    hasMarginAfter(marginSide) ? margin : 'unset'};
`

/**
 * Renders a separator inside a modal system.
 * It can be horizontal or vertical and it can have a margin on one or both sides.
 */
export const ModalSeparator: React.FC<TProps> = ({
  margin = 'md',
  marginSide = 'both',
  direction = 'horizontal',
}) => {
  const marginValue = MODAL_SPACES[margin]

  if (direction === 'horizontal') {
    return <HorizontalSeparator margin={marginValue} marginSide={marginSide} />
  }

  return <VerticalSeparator margin={marginValue} marginSide={marginSide} />
}

// * HELPERS

const hasMarginBefore = (marginSide: TMarginSide) => {
  return marginSide === 'before' || marginSide === 'both'
}

const hasMarginAfter = (marginSide: TMarginSide) => {
  return marginSide === 'after' || marginSide === 'both'
}
