import { SvgIconProps } from '@material-ui/core/SvgIcon'
import * as React from 'react'
import styled from 'styled-components/macro'

import { FONT_WEIGHTS } from '../../../designSystem'

const StyledIcon = styled.div`
  text-align: center;
  width: 1em;
  height: 1em;
  line-height: 1;
  color: inherit;
  font-weight: ${FONT_WEIGHTS.medium};
`

// unusual typing to comply with the MUI icons
export const IconId = (props: SvgIconProps): JSX.Element => {
  return <StyledIcon>#</StyledIcon>
}
