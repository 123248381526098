import { DialogTitle } from '@material-ui/core'
import styled from 'styled-components/macro'

import {
  FONT_FAMILIES,
  FONT_WEIGHTS,
  getMarginValue,
  TMargin,
} from '../../../designSystem'
import { MODAL_SPACES } from '../config/modal.config'

type TTitleProps = {
  centered?: boolean
  marginBottom?: TMargin
}

/**
 * Renders main title of the modal, usually in the header.
 */
export const ModalTitle = styled(DialogTitle)<TTitleProps>`
  &.MuiDialogTitle-root {
    padding: 0px;
  }

  &,
  > h2,
  h2 {
    /* MUI Roboto override */
    font-family: ${FONT_FAMILIES.primary};
    font-weight: ${FONT_WEIGHTS.bold};
    line-height: 1.2;
    font-size: 1.5rem;
    margin-bottom: ${({ marginBottom = `s0` }) => getMarginValue(marginBottom)};
    text-align: ${({ centered }) => (centered ? `center` : `left`)};
  }
`

/**
 * Renders title of modal sections, usually in the content part.
 */
export const ModalSectionTitle = styled.h3<TTitleProps>`
  font-weight: ${FONT_WEIGHTS.boldSemi};
  line-height: 1.2;
  font-size: 1.125rem;

  text-align: ${({ centered }) => (centered ? `center` : `left`)};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? getMarginValue(marginBottom) : MODAL_SPACES.sm};
`
