import * as React from 'react'

type TProps = NoChildren

// the classNames here comes from "/public/loadingApp.css"
export const LoadingPage: React.FC<TProps> = () => {
  return (
    <div className="loading-spinner">
      <div className="loading-container">
        <div className="circles-container">
          <div className="circles">
            <div className="circle-top"></div>
            <div className="circle-top"></div>
            <div className="circle-top"></div>
            <div className="circle-top"></div>
            <div className="circle-top"></div>
            <div className="circle-top"></div>
            <div className="circle-top"></div>
            <div className="circle-top"></div>
          </div>
          <div className="fade"></div>
        </div>
        <div className="bouncy-logo">
          <img
            src={`${process.env.PUBLIC_URL}/ee_100.png`}
            alt="speedlo logo"
            className="logo"
          />
        </div>
      </div>
    </div>
  )
}
