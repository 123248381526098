import { CloseRounded } from '@material-ui/icons'
import { css } from 'emotion'
import { OptionsObject, SnackbarProvider } from 'notistack'
import * as React from 'react'

import { Icon } from '../../atoms/Icon'
import { FONT_FAMILIES } from '../../designSystem'
import { Colors, FontSizes } from '../../styling'

type TProps = Children

const successStyle = css`
  background: ${Colors.green.main} !important;
  font-size: ${FontSizes.InfoMessageFontSize} !important;
`
const errorStyle = css`
  background: ${Colors.red.main};
  font-size: ${FontSizes.InfoMessageFontSize} !important;
`
const warningStyle = css`
  background: ${Colors.orange.main};
  font-size: ${FontSizes.InfoMessageFontSize} !important;
`
const snackbarStyles = css`
  /* classes taken from HTML inspect */
  .MuiSnackbarContent-root {
    flex-wrap: nowrap;
    font-family: ${FONT_FAMILIES.primary};

    .MuiSnackbarContent-message span {
      font-size: 1rem;
      line-height: 1.5;
    }
  }
`

export const snackStyles: PropsOf<typeof SnackbarProvider>['classes'] = {
  variantSuccess: successStyle,
  variantInfo: successStyle,
  variantError: errorStyle,
  variantWarning: warningStyle,
  root: snackbarStyles,
}

export const SnackProvider: React.FC<TProps> = ({ children }) => {
  const refSnackProvider = React.createRef<any>()

  const dismissSnack = (key: OptionsObject['key']) => () => {
    if (key === undefined) {
      return
    }

    // the closeSnackbar method is missing in the ref type (hence the "any" ref above)
    // but should be usable according to this example: https://iamhosseindhv.com/notistack/demos#action-for-all-snackbars
    refSnackProvider.current?.closeSnackbar(key)
  }

  return (
    <SnackbarProvider
      maxSnack={3}
      // preventDuplicate
      classes={snackStyles}
      ref={refSnackProvider}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      action={key => <Icon icon={CloseRounded} onClick={dismissSnack(key)} />}
    >
      {children}
    </SnackbarProvider>
  )
}
