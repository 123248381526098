import { Instance, types } from 'mobx-state-tree'

import { TFCompanyBranch, TZoneTypeEnum } from '../../../graph/generated'
import { BaseModel } from '../../../models/BaseModel'
import { RM } from '../../../tools/ramda'
import { TBranch, TBrand } from '../zones.types'
import { ZoneMapModel } from './ZoneMapModel'

type TVolatileProps = {
  zoneTypes: RoA<TZoneTypeEnum>
}

export const ZoneModel = BaseModel.named('Zone')
  .props({
    map: types.optional(ZoneMapModel, {}),
    brandId: types.maybeNull(types.string),
    branchId: types.maybeNull(types.string),
  })

  .volatile<TVolatileProps>(() => ({
    zoneTypes: [`AREA`, `RETURN`],
  }))

  .actions(self => ({
    invalidate() {
      self.brandId = null
      self.branchId = null
    },
  }))

  .views(self => ({
    get branch(): Nullable<TBranch> {
      return (
        (self.branchId &&
          self.root.user.branches.find(RM.propEq('id', self.branchId))) ||
        null
      )
    },

    get brand(): Nullable<TBrand> {
      return (
        (self.brandId &&
          self.root.user.brands.find(RM.propEq('id', self.brandId))) ||
        null
      )
    },

    get hasBranchFilter() {
      return this.branch !== null
    },

    get branchFilterId(): NullableID {
      return this.branch === null ? null : this.branch.id
    },

    get branchFilterPredicate() {
      if (this.branch) {
        return RM.propEq('id', this.branch.id)
      }

      return this.brand === null
        ? RM.F
        : RM.pathSatisfies(RM.equals(this.brand.id), ['brand', 'id'])
    },

    get zoneFilterPredicate() {
      if (this.branch !== null) {
        return RM.pathSatisfies(RM.equals(this.branch.id), [
          'companyBranch',
          'id',
        ])
      }

      return this.brand === null
        ? () => false
        : RM.pathSatisfies(RM.equals(this.brand.id), [
            'companyBranch',
            'brand',
            'id',
          ])
    },

    get branches(): RoA<TFCompanyBranch> {
      return self.root.user.branches
    },
  }))

  .actions(self => ({
    setBranchFilter(branch: TBranch) {
      self.branchId = branch.id
      self.brandId = branch.brand.id
    },

    setBrandFilter(brand: TBrand, brandBranches: TBranch[]) {
      self.brandId = brand.id

      if (!self.branch || self.branch.brand.id !== brand.id) {
        self.branchId =
          (brandBranches.length === 1 && RM.head(brandBranches).id) || null
      }
    },

    setZoneTypes(types: TZoneTypeEnum[]) {
      self.zoneTypes = types
    },

    clearBranchFilter() {
      self.branchId = null
    },

    clearBrandFilter() {
      self.brandId = null
      self.branchId = null
    },
  }))

export interface TZoneModel extends Instance<typeof ZoneModel> {}
