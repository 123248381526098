import { getHandoverTimeOutput } from '../utils/getHandoverTimeOutput'
import { getHandoverTimeSeverity } from '../utils/getHandoverTimeSeverity'
import { useHandoverTimeMinutes } from './useHandoverTimeMinutes'

type TOptions = {
  createTime: Date
  iconSize?: FirstParam<typeof getHandoverTimeOutput>['iconSize']
}

/**
 * returns a handover time related info about the sector (recipes)
 * eg. its severity (how close it is to expected handover time)
 * and time output in various formats
 */
export const useSectorRecipesHandoverTime = ({
  createTime,
  iconSize = 2,
}: TOptions) => {
  // for sector cards we want to start at 0 and count down
  // this will better communicate how long is the customer waiting for the order
  const handoverTime = createTime

  const { minutesUntilHandover } = useHandoverTimeMinutes({
    handoverTime,
  })

  const orderSeverity = getHandoverTimeSeverity({
    minutesUntilHandover,
  })

  const handoverTimeOutput = getHandoverTimeOutput({
    minutesUntilHandover,
    iconSize,
  })

  return {
    ...handoverTimeOutput,
    ...orderSeverity,
    handoverTime,
    isOrderOnTime: false, // kept here just for compatibility reasons (with useOrderHandoverTime)
    minutesUntilHandover,
  }
}
