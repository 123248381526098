import { Instance } from 'mobx-state-tree'

import { TQOrderRatings } from '../../../../graph/generated'
import { formatDate } from '../../../../helpers/formats'
import { getGroupItemsByHash } from '../../../../helpers/groupItems'
import { sortCollection } from '../../../../helpers/sort'
import { BaseModel } from '../../../../models/BaseModel'
import { getRatingsSummary } from '../../../../segments/orderRating/utils/getRatingSummary'
import { processRatings } from '../../../../segments/orderRating/utils/processRatings'
import {
  TOrderRatingsOrder,
  TOrderRatingTimelineDay,
} from '../typings/orderRatings.types'

type TGqlOrderRating = TQOrderRatings['stats']['orders']['list'][number]

type TVolatileProps = {
  orders: Nullable<RoA<TOrderRatingsOrder>>
  filteredRatings: RoA<number>
}

// bind to ORDER_RATING_CONFIG.maxValue?
// kept like this for now to be more explicit/clear
const INIT_RATING_COUNTS: Record<number, number> = {
  1: 0,
  2: 0,
  3: 0,
  4: 0,
  5: 0,
}

const ALL_RATINGS_FILTER = Object.keys(INIT_RATING_COUNTS).map(key =>
  Number(key),
)

export const OrderRatingModel = BaseModel.named(`OrderRating`)
  .volatile<TVolatileProps>(() => ({
    orders: null,
    filteredRatings: ALL_RATINGS_FILTER,
  }))

  // views for OrderRatingSummary
  .views(self => ({
    get orderCount() {
      return self.orders?.length ?? 0
    },

    get ratingCounts() {
      if (!self.orders) return null

      const ratingCounts = { ...INIT_RATING_COUNTS }

      self.orders.forEach(order => {
        const ratingValue = order.ratingSummary?.ratingValue

        if (ratingValue === undefined) return
        if (typeof ratingCounts[ratingValue] !== 'number') return

        ratingCounts[ratingValue] = ratingCounts[ratingValue] + 1
      })

      return ratingCounts
    },

    isFiltered(ratingValue: number) {
      return self.filteredRatings.includes(ratingValue)
    },

    get noRatingAvailable() {
      return self.orders === null || self.orders.length === 0
    },
  }))

  .views(self => ({
    get ordersForTimeline() {
      if (!self.orders) {
        return null
      }

      const selectedOrders = self.orders.filter(order => {
        return (
          order.ratingSummary &&
          self.filteredRatings.includes(order.ratingSummary.ratingValue)
        )
      })

      const ordersByDate = groupByDate(selectedOrders)
      const timeline = ordersByDate.map<TOrderRatingTimelineDay>(date => ({
        date: getOrderDate(date.firstItem),
        events: date.items,
      }))

      return timeline
    },
  }))

  // actions for storing the data
  .actions(self => ({
    setOrders(orders: RoA<TGqlOrderRating>) {
      const ordersWithRating = orders.filter(order => {
        return order.ratings !== null && order.ratings.length > 0
      })

      const ordersWithSummary = ordersWithRating.map(order => {
        const ratings = processRatings(order.ratings)

        return {
          ...order,
          ratings,
          ratingSummary: getRatingsSummary(ratings),
        }
      })

      const sortedOrders = sortCollection(ordersWithSummary, sortByAccepted)
      self.orders = sortedOrders
    },
  }))

  // actions for OrderRatingSummary
  .actions(self => ({
    toggleRatingFilter(toggledValue: number) {
      if (self.filteredRatings.includes(toggledValue)) {
        self.filteredRatings = self.filteredRatings.filter(value => {
          return value !== toggledValue
        })

        return
      }

      self.filteredRatings = [...self.filteredRatings, toggledValue]
    },

    showAllRatings() {
      self.filteredRatings = ALL_RATINGS_FILTER
    },
  }))

  // methods for data erasing when they are not needed/used anymore
  .actions(self => ({
    invalidateAllData() {
      self.orders = null
    },
  }))

export interface TOrderRatingModel extends Instance<typeof OrderRatingModel> {}

// * HELPERS

const getOrderDate = (order: TOrderRatingsOrder) =>
  order.acceptedAt ?? order.createdAt

const sortByAccepted = (
  orderA: TOrderRatingsOrder,
  orderB: TOrderRatingsOrder,
) => {
  return getOrderDate(orderA) < getOrderDate(orderB) ? 1 : -1
}

const getOrderDateString = (order: TOrderRatingsOrder) =>
  formatDate(getOrderDate(order))
const groupByDate = getGroupItemsByHash(getOrderDateString)
