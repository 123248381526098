/**
 *  This file was generated via `npx @speedlo/config`.
 *  Do NOT alter this file – all changes will be overridden!
 */
import * as R from 'ramda'

export const idProp = R.prop('id')
export const idPropEq = R.propEq('id')

export const RM = { ...R, idProp, idPropEq }
