import { Notifications } from '@material-ui/icons'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { PopoverContainer } from '../../../ui/Popover'
import { StatusBarIcon } from '../../../ui/StatusBarIcon'
import { useNotificationsModel } from '../hooks/useNotificationsModel'
import { NotificationsPopover } from './Notification/NotificationsPopover'

type TProps = NoChildren

/**
 * Icon for notifications with unread notifications counter.
 * On click opens a popup with all notifications.
 */
const NotificationsIndicatorComponent: React.FC<TProps> = () => {
  const { unreadNotificationsCount } = useNotificationsModel()

  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <PopoverContainer>
      <StatusBarIcon
        onClick={() => setIsOpen(prev => !prev)}
        icon={Notifications}
        badgeCount={unreadNotificationsCount}
      />
      {isOpen && <NotificationsPopover isOpen={isOpen} />}
    </PopoverContainer>
  )
}

export const NotificationsIndicator = observer(NotificationsIndicatorComponent)
