import * as React from 'react'

import { i18n } from '../../../i18n'
import { FlatSelect } from '../../FlatSelect'

type TFlatSelectProps = Pick<
  PropsOf<typeof FlatSelect.CustomInput>,
  'gridColumn'
>

type TProps = NoChildren & TFlatSelectProps

/**
 * custom input for minute select,
 * renders a number input with a label
 */
export const MinuteSelectCustomInput: React.FC<TProps> = flatSelectProps => {
  return (
    <FlatSelect.CustomInput
      type={`number`}
      label={i18n.t`min`}
      placeholder={`123`}
      {...flatSelectProps}
    />
  )
}
