import { TWeekDayEnum } from '../../graph/generated'

const timeSpan = 'TIME_SPAN'
const fullDay = 'FULL_DAY'

export const SPECIFIC_BUSINESS_HOURS_MODES = {
  timeSpan,
  fullDay,
  all: [timeSpan, fullDay],
}

export type TSpecificBusinessHoursMode =
  typeof SPECIFIC_BUSINESS_HOURS_MODES['all'][number]

export type TBusinessHour = {
  id: ID
  openAt: Date
  closeAt: Date
  weekDay: TWeekDayEnum
}

export type TSpecificBusinessHoursFormValues = {
  date: Date
  note: string
  openAt: Date
  closeAt: Date
}
