import { useRoot } from '-app/root'
import * as React from 'react'

import { useQRegisteredPrintersLite } from '../../../graph/generated'
import { APP_STAGE } from '../../../helpers/appStage'
import { isAlmightyUser } from '../../../helpers/user/isAlmightyUser'
import { usePrintWatchModel } from '../models/usePrintWatchModel'

// load printers registered for this user’s branches & save them into the model
export const useLoadRegisteredPrinters = () => {
  const { user, auth } = useRoot()
  const { setRegisteredPrinters } = usePrintWatchModel()

  // for admins in PROD this Q would be too expensive (as they have access to all of the branches)
  // and also pointless (because they usually don’t want to print anything)
  // so lets just skip it & replace the result with an empty array in the effect bellow
  const noUserLogged = auth.token === null
  const userIsAdmin = isAlmightyUser(user.roles ?? [])
  const doNotLoadPrinters = noUserLogged || (APP_STAGE.isProd && userIsAdmin)

  const { getStash } = useQRegisteredPrintersLite({
    variables: { companyBranchIds: user.branchesId },
    skip: doNotLoadPrinters,
  })

  const registeredPrinters = getStash(data => data.printers)

  React.useEffect(() => {
    if (!registeredPrinters) {
      return
    }

    if (doNotLoadPrinters) {
      setRegisteredPrinters([])
    }

    setRegisteredPrinters(registeredPrinters)
  }, [registeredPrinters, setRegisteredPrinters, doNotLoadPrinters])
}
