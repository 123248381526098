import {
  SubscriptionHookOptions as ApolloSubscriptionHookOptions,
  useSubscription as useApolloSubscription,
} from '@apollo/react-hooks'
import { useApolloDefenderReporter } from '@speedlo/graphql'

// reexport because code-gen takes the type from the same file as the hook
export type SubscriptionHookOptions<TData, TVariables> =
  ApolloSubscriptionHookOptions<TData, TVariables>

/**
 * Apollo Client’s `useSubscription` wrapper with custom error handling
 */
export const useSubscription: typeof useApolloSubscription = (
  query,
  options,
) => {
  const result = useApolloSubscription(query, options)
  useApolloDefenderReporter(result.error, query, options?.variables)

  return result
}
