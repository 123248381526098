import { Context, OperationVariables } from '@apollo/react-common'
import {
  LazyQueryHookOptions as ApolloLazyQueryHookOptions,
  useLazyQuery as useApolloLazyQuery,
} from '@apollo/react-hooks'
import { useApolloDefenderReporter } from '@speedlo/graphql'
import * as React from 'react'

import { DEFAULT_Q_POLICY } from './apolloHooks.config'
import { useLoadingStash } from './useLoadingStash'

type TQueryDocument = Parameters<typeof useApolloLazyQuery>[0]
type LazyQueryExecute<TData, TVariables> = (
  variables?: TVariables,
  context?: Context,
) => Promise<Nullable<TData>>

// reexport because code-gen takes the type from the same file as the hook
export type LazyQueryHookOptions<TData, TVariables> =
  ApolloLazyQueryHookOptions<TData, TVariables>

/**
 * Apollo Client’s `useLazyQuery` wrapper with custom error handling
 * and some additional features (getStash, return Promise, ...)
 */
export const useLazyQuery = <TData = any, TVariables = OperationVariables>(
  query: TQueryDocument,
  options: LazyQueryHookOptions<TData, TVariables>,
) => {
  const [executeQuery, result] = useApolloLazyQuery<TData, TVariables>(query, {
    fetchPolicy: DEFAULT_Q_POLICY,
    ...options,
  })

  useApolloDefenderReporter(result.error, query, result.variables)

  // * Small hack as the official useLazyQuery's execution function does not return
  // * promise with result which is useful in some case. Could be dropped eventually.

  const resolveRef =
    React.useRef<
      (value?: Nullable<TData> | PromiseLike<Nullable<TData>>) => void
    >()

  React.useEffect(() => {
    if (result.called && !result.loading && resolveRef.current) {
      resolveRef.current(result.data || null)
      resolveRef.current = undefined
    }
  }, [result.data, result.loading, result.called])

  const queryLazily = React.useCallback<LazyQueryExecute<TData, TVariables>>(
    (variables, context) => {
      executeQuery({ ...context, variables })

      return new Promise(resolve => {
        resolveRef.current = resolve
      })
    },
    [executeQuery],
  )

  // * ---

  const getStash = useLoadingStash<TData>(result.loading, result.data)

  const wrappedResult = {
    ...result,
    getStash,
    get hasData() {
      return this.data !== undefined
    },
    get hasError() {
      return this.error !== undefined
    },
  }

  return [queryLazily, wrappedResult] as const
}
