import * as React from 'react'

import styled from 'styled-components/macro'

import { SvgIconProps } from '@material-ui/core/SvgIcon'

const StyledIcon = styled.div`
  position: relative;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  border: 0.1em solid currentColor;

  display: flex;
  align-items: center;
  justify-content: center;

  p {
    position: relative;
    font-size: 0.5em;
    font-weight: 600;
    color: currentColor;
  }
`

// unusual typing to comply with the MUI icons
/**
 * renders an age control icon - a circle with an 18 inside
 */
export const IconAgeControl = (props: SvgIconProps): JSX.Element => {
  return (
    <StyledIcon>
      <p>18</p>
    </StyledIcon>
  )
}
