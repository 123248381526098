import * as React from 'react'

import { SizerButton } from '../../../atoms/SizerButton'
import { TouchButton } from '../../../molecules/TouchButton'
import { Colors } from '../../../styling'
import { IconExternalDelivery } from '../../../ui/Icons'
import { useActiveExternalDeliveriesModel } from '../models/useActiveExternalDeliveriesModel'

type TProps = NoChildren

/**
 * button that opens/closes the active external deliveries list,
 * rendered over the order map
 */
export const ToggleActiveExternalDeliveryListButton: React.FC<TProps> = () => {
  const { toggleList } = useActiveExternalDeliveriesModel()

  return (
    <SizerButton>
      <TouchButton
        onClick={toggleList}
        icon={IconExternalDelivery}
        color={Colors.blue.darkest}
      />
    </SizerButton>
  )
}
