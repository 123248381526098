import { Instance } from 'mobx-state-tree'

import { BaseModel } from '../../../models/BaseModel'
import {
  TDriverHistoryOrderState,
  TDriverHistoryOrderWithState,
} from '../molecules/DriverHistoryMapOrder'
import { TDriverHistoryOrder } from '../organisms/DriverHistoryContext'

type TVolatileProps = {
  showAllOrders: boolean
  showBranchOnMap: boolean
  selectedTime: Nullable<Date>
  highlightedOrder: Nullable<TDriverHistoryOrderWithState>
}

export const DriverHistoryModel = BaseModel.named(`DriverHistory`)
  .volatile<TVolatileProps>(() => ({
    selectedTime: null,
    showAllOrders: true,
    showBranchOnMap: true,
    highlightedOrder: null,
  }))

  .views(self => ({
    getOrderState(order: TDriverHistoryOrder): TDriverHistoryOrderState {
      return getOrderStateInTime(order, self.selectedTime)
    },

    addStateToOrder(order: TDriverHistoryOrder): TDriverHistoryOrderWithState {
      const state = getOrderStateInTime(order, self.selectedTime)

      switch (state) {
        case `canceled`:
        case `delivered`:
        case `future-order`:
          return {
            ...order,
            state,
            fakeHandedOverTime: order.deliveredAt,
          }
        case `being-delivered`:
        case `on-way`:
        case `assigned-to-driver`:
        case `received-by-company`:
          return {
            ...order,
            state,
            // so we want to show updating number as the user slide the timeline
            // (while the order is being worked on or delivered)
            // and the logic is basically the same as in useOrderHandoverTime (useHandoverTimeMinutes)
            // but instead of comparing the `deliverAt` time to the `deliveredAt` time,
            // we can fake deliveredAt with the time user currently selected on the slider
            fakeHandedOverTime: self.selectedTime,
          }
      }
    },
  }))

  .actions(self => ({
    toggleShowAllOrders() {
      self.showAllOrders = !self.showAllOrders
    },

    toggleShowBranchOnMap() {
      self.showBranchOnMap = !self.showBranchOnMap
    },

    closeOrderDetail() {
      self.highlightedOrder = null
    },

    openOrderDetail(order: TDriverHistoryOrderWithState) {
      self.highlightedOrder = order
    },

    setSelectedTime(selectedTime: Date) {
      self.selectedTime = selectedTime
    },

    clearSelectedTime() {
      self.selectedTime = null
    },
  }))

export interface TDriverHistoryModel
  extends Instance<typeof DriverHistoryModel> {}

// return the state the order is (was) in at the selectedTime
const getOrderStateInTime = (
  order: TDriverHistoryOrder,
  selectedTime: Nullable<Date>,
): TDriverHistoryOrderState => {
  if (!selectedTime) {
    return `future-order`
  }

  const wasCanceled = order.canceledAt && order.canceledAt < selectedTime
  if (wasCanceled) {
    return `canceled`
  }

  const wasFinished = order.deliveredAt && order.deliveredAt < selectedTime
  if (wasFinished) {
    return `delivered`
  }

  const isBeingDelivered =
    order.beingDeliveredAt && order.beingDeliveredAt < selectedTime
  if (isBeingDelivered) {
    return `being-delivered`
  }

  const isOnWay = order.onWayAt && order.onWayAt < selectedTime
  if (isOnWay) {
    return `on-way`
  }

  const wasAssigned = order.assignedAt && order.assignedAt < selectedTime
  if (wasAssigned) {
    return `assigned-to-driver`
  }

  const wasAccepted = order.acceptedAt && order.acceptedAt < selectedTime
  if (wasAccepted) {
    return `received-by-company`
  }

  return `future-order`
}
