import styled from 'styled-components/macro'

import * as React from 'react'

import { Box } from '../../../atoms/Box/Box'
import { getSpaceValue } from '../../../designSystem'
import { TPopoverProps } from '../popover.types'
import { StyledPopover } from './StyledPopover'

export const PopoverContainer = styled.div`
  position: relative;
`

export const Popover: React.FC<TPopoverProps> = ({
  children,
  maxHeight,
  popoverHeader,
  itemsGap = `s8`,
  isFixed = false,
  isScrollable = false,
  isFlexDisplay = false,
  flexDirection = `column`,
  ...props
}) => {
  const overflow = isScrollable ? `auto` : null
  const position = isFixed ? `fixed` : undefined
  const display = isFlexDisplay ? `flex` : undefined
  const gapValue = getSpaceValue(itemsGap)
  return (
    <StyledPopover position={position} {...props}>
      {popoverHeader}

      <Box
        display={display}
        gap={gapValue}
        flexDirection={flexDirection}
        overflow={overflow}
        maxHeight={maxHeight}
      >
        {children}
      </Box>
    </StyledPopover>
  )
}
