import * as React from 'react'
import { Provider as GoodByeProvider } from 'react-goodbye'
import { BrowserRouter } from 'react-router-dom'

type TProps = Children

export const Router: React.FC<TProps> = ({ children }) => (
  <GoodByeProvider>
    {({ handleGetUserConfirm }) => (
      <BrowserRouter getUserConfirmation={handleGetUserConfirm}>
        {children}
      </BrowserRouter>
    )}
  </GoodByeProvider>
)
