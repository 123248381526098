import {
  TFProductTagTranslations,
  TInputRecipeTagTranslation,
  TLanguageEnum,
} from '../../../../graph/generated'

/**
 * helper fnc for transforming loaded product tag translation
 * into a format that is required by the form/TranslatedInput/create or update M
 */
export const transformTagTranslation = (
  translation: TFProductTagTranslations['translations'][number],
): TInputRecipeTagTranslation => {
  return {
    label: translation.label,
    language: translation.language.enum,
  }
}

/**
 * helper fnc for creating an empty product tag translation
 * used when translating a new language that was not translated before
 */
export const createProductTagTranslation = (
  language: TLanguageEnum,
): TInputRecipeTagTranslation => ({
  language,
  label: ``,
})
