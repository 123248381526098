import * as React from 'react'
import { Modal } from '../../../../ui/Modal'
import { ExternalDeliveryOrderSummary } from './ExternalDeliveryOrderSummary'
import { CreateExternalDeliveryActions } from './CreateExternalDeliveryActions'
import { useCreateExternalDeliveryCtx } from '../../contexts/CreateExternalDeliveryProvider'

type TProps = NoChildren & {
  externalDeliveryEstimates?: React.ReactNode
}

/**
 * content for external delivery creation modal,
 * contains order summary (modal content) and action buttons,
 *
 * external delivery **without pickup time** is created here,
 * for external delivery with pickup time see CreateExternalDeliveryWithPickupTime.tsx
 */
export const CreateExternalDelivery: React.FC<TProps> = ({
  externalDeliveryEstimates,
}) => {
  const { onClose, order, deliveryProvider } = useCreateExternalDeliveryCtx()

  return (
    <>
      <Modal.Content>
        <ExternalDeliveryOrderSummary
          order={order}
          deliveryProvider={deliveryProvider}
        />

        {externalDeliveryEstimates && (
          <>
            <Modal.Separator />
            {externalDeliveryEstimates}
          </>
        )}
      </Modal.Content>

      <CreateExternalDeliveryActions
        onClose={onClose}
        orderId={order.id}
        deliveryProvider={deliveryProvider.enum}
      />
    </>
  )
}
