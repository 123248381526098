import { getSpaceValuePx, TSpace } from '../spaces'
import { RADIUSES_SEMATIC, RADIUSES_VALUES } from './radiusValues'

/**
 * key of semantic radiuses
 */
export type TRadiusSemantic = keyof typeof RADIUSES_SEMATIC
/**
 * key of semantic radiuses or key of radius values
 */
export type TRadius = TRadiusSemantic | keyof typeof RADIUSES_VALUES

const getRadiusValueWithFallback = (radius: TRadius) => {
  const radiuses = { ...RADIUSES_VALUES, ...RADIUSES_SEMATIC }
  return radiuses[radius]
}

/**
 * returns value for provided radius key in related unit (px, %, vh)
 */
export const getRadiusValue = (radius: TRadius) => {
  const radiusValue = getRadiusValueWithFallback(radius)

  return typeof radiusValue === 'string' ? radiusValue : `${radiusValue}px`
}

/**
 * returns value for provided radius key as a number in pixels
 */
export const getRadiusValuePx = (radius: TRadius) => {
  const radiusValue = getRadiusValueWithFallback(radius)

  return typeof radiusValue === 'string' ? 0 : radiusValue
}

type TRelationDirection = 'outer' | 'inner'

/**
 * returns related inner/outer radius based on initial radius and space between the two
 */
export const getRelatedRadius = (
  radius: TRadius,
  space: TSpace | number,
  direction: TRelationDirection = 'inner',
) => {
  const radiusValue = getRadiusValueWithFallback(radius)

  // for non-px values, return the same value
  if (typeof radiusValue === 'string') return radiusValue

  // for px values, subtract/add spaceValue
  const spaceValue = typeof space === 'number' ? space : getSpaceValuePx(space)
  const relatedValue =
    direction === 'inner'
      ? Math.max(RADIUSES_SEMATIC.none, radiusValue - spaceValue)
      : radiusValue + spaceValue

  return `${relatedValue}px`
}
