import { Trans } from '@lingui/macro'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components/macro'

import { useMChangePrintRecordState } from '../../../graph/generated'
import { Modal } from '../../../ui/Modal'
import { usePrintWatchModel } from '../models/usePrintWatchModel'
import { TPrintRecord } from './PrintWatch'

export type TProps = NoChildren & {
  requestPrint: (printRecord: TPrintRecord) => void
}

const StyledModalContent = styled(Modal.Content)`
  display: grid;
  max-width: 40rem;
  align-items: center;
`

const PrintFailedModalComponent: React.FC<TProps> = ({ requestPrint }) => {
  const [mUpdatePrintRecordState] = useMChangePrintRecordState()
  const { failedRecords, removeFailedRecord } = usePrintWatchModel()

  const cancelPrintRecords = React.useCallback(() => {
    failedRecords.forEach(record => {
      removeFailedRecord(record.id)

      mUpdatePrintRecordState({
        variables: {
          recordId: record.id,
          newState: `FAILURE`,
        },
      })
    })
  }, [failedRecords, mUpdatePrintRecordState, removeFailedRecord])

  const retryPrintRequests = React.useCallback(() => {
    failedRecords.forEach(record => {
      removeFailedRecord(record.id)
      requestPrint(record)
    })
  }, [failedRecords, removeFailedRecord, requestPrint])

  return (
    <Modal.Wrapper
      size={`sm`}
      disableBackdropClick
      open={failedRecords.length > 0}
    >
      <Modal.Header>
        <Modal.Title>
          <Trans>Print request was unsuccessful</Trans>!
        </Modal.Title>
      </Modal.Header>

      <StyledModalContent>
        <Trans>
          {failedRecords.length} of the print records couldn’t be added to the
          print queue. Would you like to try requesting them again or mark them
          as failed?
        </Trans>
      </StyledModalContent>

      <Modal.Footer>
        <Modal.Actions>
          <Modal.Button type={`cancel`} onClick={cancelPrintRecords}>
            <Trans>Discard</Trans>
          </Modal.Button>
          <Modal.Button type={`confirm`} onClick={retryPrintRequests}>
            <Trans>Request again</Trans>
          </Modal.Button>
        </Modal.Actions>
      </Modal.Footer>
    </Modal.Wrapper>
  )
}

export const PrintFailedModal = observer(PrintFailedModalComponent)
