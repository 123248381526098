import { Sentry } from '@speedlo/sentry'
import { getParent, Instance } from 'mobx-state-tree'

import { appConfig } from '../../../config'
import { i18n } from '../../../i18n'
import { BaseModel } from '../../../models/BaseModel'
import { TLoginProviderModel } from './LoginProviderModel'

// prettier-ignore
const getLoginUrl = ({ username, password }: Dictionary<string>) => {
  return `${appConfig.endpoint}/users/token?username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`
}

export const PasswordLoginModel = BaseModel.named('PasswordLogin')
  .volatile(() => ({
    errorCode: '',
    errorMessage: '',
  }))

  .views(self => ({
    get hasFailed() {
      return Boolean(self.errorCode)
    },
  }))

  .actions(self => ({
    setFailure(code: string, message: string) {
      self.errorCode = code
      self.errorMessage = message
    },

    clearState() {
      // reset just the code because that’s used for the `hasFailed` check
      // keep the previous message to prevent UI flickering (old message -> empty message -> new message)
      self.errorCode = ''
    },

    setSuccess(token: string, userId: string) {
      const provider = getParent<TLoginProviderModel>(self)
      provider.authenticate(token, userId)
    },
  }))

  .actions(self => ({
    async requestToken(username: string, password: string) {
      self.clearState()

      const url = getLoginUrl({ username, password })
      const fetch: TFetch = window.fetch

      try {
        const response = await fetch(url, {
          headers: {
            Accept: 'application/json',
          },
        })

        const payload = await response.json()
        self.log('received payload %j', payload)

        if (response.status !== 200) {
          self.setFailure(payload.code, payload.error)
          self.log('token request failed for username %s', username)
        } else {
          self.setSuccess(payload.token, String(payload.userId))
          self.log(
            'token request success for username %s [%s]',
            username,
            payload.userId,
          )
        }
      } catch (err) {
        self.setFailure(
          'FAIL',
          i18n.t`Cannot login right now, please try again later`,
        )

        Sentry.withScope(scope => {
          scope.setExtra('username', username)
          scope.setLevel(Sentry.Severity.Debug)
          Sentry.captureException(err)
        })
      }
    },
  }))

export interface TPasswordLoginModel
  extends Instance<typeof PasswordLoginModel> {}
