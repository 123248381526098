import { autorun, observable } from 'mobx'
import { Observer } from 'mobx-react-lite'
import * as React from 'react'
import { Route, RouteProps } from 'react-router-dom'
import styled from 'styled-components'

import { appConfig } from '../../config'
import { FONT_WEIGHTS } from '../../designSystem'
import { responsiveFont } from '../../helpers/styles/responsiveFont'
import { Colors } from '../../styling'
import { RM } from '../../tools/ramda'

type TProps = NoChildren & {
  title: string
}

const TitleStyled = styled.h1`
  color: ${Colors.greyBlue.main};
  font-weight: ${FONT_WEIGHTS.boldSemi};
  margin: 0px;
  white-space: nowrap;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.9);

  ${responsiveFont({
    minSize: 16,
    maxSize: 24,
    minWidthBreakpoint: 400,
    maxWidthBreakpoint: 1300,
    minHeightBreakpoint: 500,
    maxHeightBreakpoint: 1000,
  })}
`

const observableTitles = observable.array<string>([])

autorun(() => {
  const lastTitle = Array.from(observableTitles).pop()

  if (lastTitle) {
    document.title = `${lastTitle} :: ${appConfig.pageTitleSuffix}`
  } else {
    document.title = appConfig.pageTitleSuffix
  }
})

const PageTitleDisplay: React.FC = () => (
  <Observer>
    {() => <TitleStyled>{observableTitles.join(' - ')}</TitleStyled>}
  </Observer>
)

export class PageTitle extends React.Component<TProps> {
  static Route = PageTitleRoute
  static Display = PageTitleDisplay

  componentDidMount() {
    observableTitles.push(this.props.title)
  }

  componentDidUpdate(prevProps: TProps) {
    const idx = observableTitles.findIndex(RM.equals(prevProps.title))

    if (idx >= 0) {
      observableTitles.splice(idx, 1, this.props.title)
    }
  }

  componentWillUnmount() {
    observableTitles.remove(this.props.title)
  }

  render() {
    return null
  }
}

function PageTitleRoute({ title, ...props }: TProps & RouteProps) {
  return <Route {...props} render={() => <PageTitle title={title} />} />
}
