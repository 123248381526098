/**
 * shadows rendered outside its box
 */
export const OUTER_BOX_SHADOWS = {
  none: `unset`,
  light: `0px 0px 4px 0px rgba(0, 0, 0, 0.1)`,
  lightLong: `0px 0px 4px 0px rgba(0, 0, 0, 0.1), 0px 20px 50px -15px rgba(0, 0, 0, 0.3)`,
  lightSharp: `0px 0px 3px 0px rgba(0, 0, 0, 0.3)`,
  medium: `0px 0px 8px 1px rgba(0, 0, 0, 0.1)`,
  mediumLong: `0px 0px 2px 0px rgba(0, 0, 0, 0.25), 0px 20px 30px -15px rgba(0, 0, 0, 0.3)`,
  bottom: `0px 5px 10px 0px rgba(0, 0, 0, 0.15)`,
  bottomLong: `0px 9px 15px 2px rgba(0, 0, 0, 0.09)`,
  topLong: `0px -9px 15px 2px rgba(0, 0, 0, 0.09)`,
} as const

/**
 * shadows rendered inside its box
 */
export const INNER_BOX_SHADOWS = {
  none: `unset`,
  innerLightSharp: `inset 0px 0px 5px -1px rgba(0, 0, 0, 0.3)`,
  innerMedium: `inset 0px 0px 12px 0px rgba(0, 0, 0, 0.2)`,
  innerMediumVertical: `inset 0px 10px 12px -10px rgba(0, 0, 0, 0.2), inset 0px -10px 12px -10px rgba(0, 0, 0, 0.2)`,
} as const

/**
 * semantically renamed values from above
 * to keep some places of the DS consistent,
 * extend whenever design team does so
 */
export const BOX_SHADOWS_SEMANTIC = {
  input: OUTER_BOX_SHADOWS.light,
  card: OUTER_BOX_SHADOWS.light,
  button: OUTER_BOX_SHADOWS.light,
  buttonHover: OUTER_BOX_SHADOWS.medium,
} as const
