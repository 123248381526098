import { TExternalDeliveryStateEnum } from '../../../graph/generated'
import { Colors } from '../../../styling'
import {
  IconExternalDelivery,
  IconExternalDeliveryFailed,
  IconExternalDeliveryProcessing,
  IconExternalDeliverySuccess,
  IconUnknownStatus,
} from '../../../ui/Icons'

type TDelivery = {
  status: {
    enum: TExternalDeliveryStateEnum
  }
}

/**
 * maps TExternalDeliveryStateEnum to a status category used by the UI
 * grouping some statuses that are represented in the same way
 */
export const getExternalDeliveryStatusCategory = (delivery: TDelivery) => {
  switch (delivery.status.enum) {
    case 'REQUESTED':
      return 'PROCESSING'

    case 'ACCEPTED':
    case 'FINISHED':
      return 'SUCCESS'

    case 'REJECTED':
    case 'CANCELED':
      return 'FAILED'

    default:
      return 'DEFAULT'
  }
}

/**
 * maps TExternalDeliveryStateEnum to a color
 */
export const getExternalDeliveryColor = ({ status }: TDelivery) => {
  const statusCategory = getExternalDeliveryStatusCategory({ status })

  switch (statusCategory) {
    case 'PROCESSING':
      return Colors.blue.main

    case 'SUCCESS':
      return Colors.green.dark

    case 'FAILED':
      return Colors.red.main

    default:
      return Colors.grey.darkMedium
  }
}

type TIconOptions = TDelivery & {
  withExternalDeliveryIcon?: boolean
}

/**
 * maps TExternalDeliveryStateEnum to an icon
 */
export const getExternalDeliveryIcon = ({
  status,
  withExternalDeliveryIcon = false,
}: TIconOptions) => {
  if (withExternalDeliveryIcon) return IconExternalDelivery

  const statusCategory = getExternalDeliveryStatusCategory({ status })

  switch (statusCategory) {
    case 'PROCESSING':
      return IconExternalDeliveryProcessing

    case 'SUCCESS':
      return IconExternalDeliverySuccess

    case 'FAILED':
      return IconExternalDeliveryFailed

    default:
      return IconUnknownStatus
  }
}
