import { getParent, Instance, types } from 'mobx-state-tree'

import { TAuthModel } from '../../../models/AuthModel'
import { BaseModel } from '../../../models/BaseModel'
import { PasswordLoginModel } from './PasswordLoginModel'

export const LoginProviderModel = BaseModel.named('LoginProvider')
  .props({
    passwordLogin: types.optional(PasswordLoginModel, {}),
  })

  .actions(self => ({
    authenticate(token: string, userId: string) {
      const auth = getParent<TAuthModel>(self)
      auth.authenticate(token, userId)
    },
  }))

export interface TLoginProviderModel
  extends Instance<typeof LoginProviderModel> {}
