type TSortFunction<TItem> = (itemA: TItem, itemB: TItem) => number

export const sortCollection = <TItem>(
  collection: RoA<TItem>,
  sortFunction: TSortFunction<TItem>,
) => {
  const sortCopy = [...collection]

  sortCopy.sort(sortFunction)

  return sortCopy
}
