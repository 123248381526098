import { useRoot } from '-app/root'

/**
 * use to get access to the activeExternalDeliveries model
 */
export const useActiveExternalDeliveriesModel = () => {
  const { activeExternalDeliveries } = useRoot()

  return activeExternalDeliveries
}
