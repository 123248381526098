import styled from 'styled-components/macro'

import { getSpaceValue } from '../../../designSystem'
import { Colors } from '../../../styling'
import { TSeparatorProps } from '../separator.types'

export const SeparatorDashed = styled.div<TSeparatorProps>`
  --height: ${({ height = `1px` }) => `${height}`};
  --color: ${({ color = Colors.greyBlue.main }) => `${color}`};

  padding: ${({ verticalSpace = `s16` }) =>
    `${getSpaceValue(verticalSpace)} 0`};

  height: var(--height);
  background-position: center;
  background-size: 25px var(--height);
  background-repeat: repeat-x;

  background-image: linear-gradient(
    to left,
    var(--color) 50%,
    rgba(255, 255, 255, 0) 0%
  );
`
