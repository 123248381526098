import * as React from 'react'

import { createContext } from '../../../tools/createContext'

type TChangeOptions = {
  isCustomValue?: boolean
}

type TContext<TOption> = {
  value: Nullable<TOption>
  isCustomValue: boolean
  getOptionKey: (value: TOption) => string
  renderLabel: (value: TOption) => React.ReactNode
  onChange: (value: TOption, options?: TChangeOptions) => void
}

// ! this `any` is here to be able to use this context in a generic way
// it can cause problems when you could pass a value of different type to the component (eg. FlatSelectButtons)
// than what the closest provider works with
// but that can be prevented by the developer
// if you think it's a problem, you can rework this and pass all TOption related props only to the provider
// and provider it from here
const [Provider, useFlatSelectCtx] = createContext<TContext<any>>(`FlatSelect`)

type TOptionalKeys<TOption> = ExtractSafe<
  keyof TContext<TOption>,
  'renderLabel' | 'getOptionKey'
>
type TRequiredProps<TOption> = OmitSafe<
  TContext<TOption>,
  TOptionalKeys<TOption> | 'isCustomValue'
>
type TOptionalProps<TOption> = {
  [Key in TOptionalKeys<TOption>]?: TContext<TOption>[Key]
}

type TProps<TOption> = RequiredChildren &
  TRequiredProps<TOption> &
  TOptionalProps<TOption>

/**
 * provides context like value, onChange, getOptionKey, renderLabel to FlatSelect components
 */
export const FlatSelectProvider = <TOption,>({
  value,
  onChange,
  children,
  renderLabel = option => String(option),
  getOptionKey = option => String(option),
}: TProps<TOption>) => {
  const [isCustomValue, setIsCustomValue] = React.useState(false)

  const onValueChange = React.useCallback<TContext<TOption>['onChange']>(
    (value, options) => {
      const isCustomValue = options?.isCustomValue ?? false

      onChange(value)
      setIsCustomValue(isCustomValue)
    },
    [onChange],
  )

  const context = React.useMemo<TContext<TOption>>(() => {
    return {
      value,
      renderLabel,
      getOptionKey,
      isCustomValue,
      onChange: onValueChange,
    }
  }, [getOptionKey, isCustomValue, onValueChange, renderLabel, value])

  return <Provider value={context}>{children}</Provider>
}

export { useFlatSelectCtx }
