import { PrintRounded } from '@material-ui/icons'
import { useRouterContext } from '@speedlo/hooks'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { useCanMutate } from '../../../hooks/useAccessGuard'
import { ROUTE_SETTINGS_PRINT } from '../../../screens/settings/settings.routes'
import { StatusBarIcon } from '../../../ui/StatusBarIcon'
import { usePrintWatchModel } from '../models/usePrintWatchModel'

type TProps = NoChildren

const PrintWatchIndicatorComponent: React.FC<TProps> = () => {
  const { history } = useRouterContext()
  const { availablePrinters, canPrint } = usePrintWatchModel()
  const canEditPrintSettings = useCanMutate(`createCompanyBranchTriggerPrinter`)

  if (!canPrint || !availablePrinters) {
    return null
  }

  return (
    <StatusBarIcon
      icon={PrintRounded}
      badgeCount={availablePrinters.length}
      onClick={
        canEditPrintSettings
          ? () => history.push(ROUTE_SETTINGS_PRINT)
          : undefined
      }
    />
  )
}

export const PrintWatchIndicator = observer(PrintWatchIndicatorComponent)
