import { addMilliseconds, differenceInMilliseconds } from 'date-fns'
import { types } from 'mobx-state-tree'

import { BaseModel } from './BaseModel'

type TPeriodOptions = {
  minutes?: number
  seconds?: number
  milliseconds?: number
}

const ONE_SECOND = 1000 // ms
const ONE_MINUTE = 60 * ONE_SECOND // ms

/**
 * Used to track cooldown (for requesting text message with confirmation code)
 */
export const CooldownModel = BaseModel.named('Cooldown')
  .props({
    targetTime: types.maybeNull(types.Date),
  })

  .views(self => ({
    get remainingMilliseconds() {
      const now = new Date()
      const remainingMilliseconds = differenceInMilliseconds(
        self.targetTime ?? now,
        now,
      )

      return remainingMilliseconds
    },

    get isInCooldown() {
      return this.remainingMilliseconds > 0
    },
  }))

  .actions(self => ({
    setTargetTime({
      minutes = 0,
      seconds = 0,
      milliseconds = 0,
    }: TPeriodOptions) {
      const now = new Date()

      // get cooldown total period in milliseconds
      // converts minutes and seconds to milliseconds by multiplying it by the number of milliseconds in a minute/second
      const periodMilliseconds =
        minutes * ONE_MINUTE + seconds * ONE_SECOND + milliseconds

      self.targetTime = addMilliseconds(now, periodMilliseconds)
    },

    clearTargetTime() {
      self.targetTime = null
    },

    invalidate() {
      this.clearTargetTime()
    },
  }))
