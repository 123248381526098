import React from 'react'
import styled from 'styled-components/macro'

import {
  getMarginSideValues,
  getPaddingSideValues,
  getRadiusCornerValues,
  getShadow,
  TColorKey,
  THEME,
  TMarginProps,
  TPaddingProps,
  TRadiusProps,
  TRadiusSemantic,
  TShadow,
} from '../../../designSystem'
import { getContraColor } from '../../../helpers/styles/colorAdjustments'

type TRadiuses = ReturnType<typeof getRadiusCornerValues>
type TPaddings = ReturnType<typeof getPaddingSideValues>
type TMargins = ReturnType<typeof getMarginSideValues>

type TPrivateStyledProps = TRadiuses &
  TPaddings &
  TMargins & {
    shadow: TShadow
    hoverShadow: TShadow
    bgColor: TColorKey
  }

type TPublicStyledProps = {
  width?: string
  height?: string
  minWidth?: string
  minHeight?: string
  gridColumn?: string
}

type TStyledProps = TPrivateStyledProps & TPublicStyledProps
type THtmlButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>

type TProps = RequiredChildren &
  THtmlButtonProps &
  TPublicStyledProps &
  TRadiusProps<TRadiusSemantic> &
  TPaddingProps &
  TMarginProps & {
    shadow?: TShadow
    className?: string
    hoverShadow?: TShadow
    bgColor?: TColorKey
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  }

const StyledButton = styled.button<TStyledProps>`
  border-top-left-radius: ${({ borderTopLeftRadius }) => borderTopLeftRadius};
  border-top-right-radius: ${({ borderTopRightRadius }) =>
    borderTopRightRadius};
  border-bottom-right-radius: ${({ borderBottomRightRadius }) =>
    borderBottomRightRadius};
  border-bottom-left-radius: ${({ borderBottomLeftRadius }) =>
    borderBottomLeftRadius};

  padding-top: ${({ paddingTop }) => paddingTop};
  padding-left: ${({ paddingLeft }) => paddingLeft};
  padding-right: ${({ paddingRight }) => paddingRight};
  padding-bottom: ${({ paddingBottom }) => paddingBottom};

  margin-top: ${({ marginTop }) => marginTop};
  margin-left: ${({ marginLeft }) => marginLeft};
  margin-right: ${({ marginRight }) => marginRight};
  margin-bottom: ${({ marginBottom }) => marginBottom};

  width: ${({ width }) => width};
  height: ${({ height }) => height};
  min-width: ${({ minWidth }) => minWidth};
  min-height: ${({ minHeight }) => minHeight};

  position: relative;
  user-select: none;
  cursor: pointer;
  appearance: none;
  border: none;
  transition: all 0.2s;

  background-color: ${({ bgColor }) => THEME[bgColor].normal};
  box-shadow: ${({ shadow }) => getShadow(shadow)};
  color: ${({ bgColor }) => getContraColor(THEME[bgColor].normal)};

  grid-column: ${({ gridColumn = 'unset' }) => gridColumn};

  &:hover:enabled {
    background-color: ${({ bgColor }) => THEME[bgColor].hover};
    box-shadow: ${({ hoverShadow }) => getShadow(hoverShadow)};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ bgColor }) => THEME[bgColor].disabled};
  }

  @media print {
    opacity: 0;

    &:hover:enabled {
      opacity: 1;
    }
  }
`

/**
 * base button component with configurable radius, padding, margin, shadow, background color,
 * content styles should be handled by children
 */
export const Button = React.forwardRef<HTMLButtonElement, TProps>(
  (
    {
      radius = 'button',
      radiusTop,
      radiusLeft,
      radiusRight,
      radiusBottom,
      radiusTopLeft,
      radiusTopRight,
      radiusBottomLeft,
      radiusBottomRight,

      padding = 'buttonPadding',
      paddingVertical,
      paddingHorizontal,
      paddingTop,
      paddingLeft,
      paddingRight,
      paddingBottom,

      margin,
      marginVertical,
      marginHorizontal,
      marginTop,
      marginLeft,
      marginRight,
      marginBottom,

      children,
      className,
      shadow = 'button',
      hoverShadow = 'buttonHover',
      bgColor = `secondary`,
      disabled = false,

      ...styledProps
    },
    ref,
  ) => {
    const paddings = getPaddingSideValues({
      padding,
      paddingVertical,
      paddingHorizontal,
      paddingTop,
      paddingLeft,
      paddingRight,
      paddingBottom,
    })
    const margins = getMarginSideValues({
      margin,
      marginVertical,
      marginHorizontal,
      marginTop,
      marginLeft,
      marginRight,
      marginBottom,
    })
    const radiuses = getRadiusCornerValues({
      radius,
      radiusTop,
      radiusLeft,
      radiusRight,
      radiusBottom,
      radiusTopLeft,
      radiusTopRight,
      radiusBottomLeft,
      radiusBottomRight,
    })

    return (
      <StyledButton
        {...radiuses}
        {...paddings}
        {...margins}
        type={`button`}
        shadow={shadow}
        bgColor={bgColor}
        className={className}
        disabled={disabled}
        hoverShadow={hoverShadow}
        {...styledProps}
        ref={ref}
      >
        {children}
      </StyledButton>
    )
  },
)
