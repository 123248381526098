import { Checkbox, createMuiTheme, MuiThemeProvider } from '@material-ui/core'
import { Lens, RadioButtonUncheckedOutlined } from '@material-ui/icons'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { FlexBox } from '../../../../atoms/Box/Box'
import { Icon } from '../../../../atoms/Icon'
import { FONT_WEIGHTS, getSpaceValue } from '../../../../designSystem'
import { TFNotification } from '../../../../graph/generated'
import { Colors } from '../../../../styling'
import { Text } from '../../../../ui/Typography'
import { useUpdateNotifications } from '../../hooks/useUpdateNotifications'

type TProps = NoChildren & Pick<TFNotification, 'title' | 'id' | 'isRead'>

const ICON_SIZE = 12

const theme = createMuiTheme({
  overrides: {
    MuiCheckbox: {
      root: {
        position: `absolute`,
        right: getSpaceValue(`s8`),
        top: getSpaceValue(`s8`),
      },
    },
  },
})

/**
 * Component for single notification header.
 * Displays title and checkbox for changing if the notification was read.
 */
const NotificationHeaderComponent: React.FC<TProps> = ({
  id,
  title,
  isRead,
}) => {
  const { markSingleRead } = useUpdateNotifications()

  const trimmedTitle = trimDate(title)

  const toggleIsRead = () => {
    markSingleRead({ id, isRead: !isRead })
  }

  return (
    <MuiThemeProvider theme={theme}>
      <FlexBox justifyContent={`space-between`}>
        <Text
          fontSize={`16px`}
          fontWeight={FONT_WEIGHTS.boldSemi}
          marginBottom={getSpaceValue(`s4`)}
          color={Colors.white.main}
        >
          {trimmedTitle}
        </Text>
        <Checkbox
          onClick={toggleIsRead}
          checked={!isRead}
          icon={
            <Icon
              fontSize={ICON_SIZE}
              fontSizeUnit={`px`}
              icon={RadioButtonUncheckedOutlined}
              color={Colors.grey.lightish}
            />
          }
          checkedIcon={
            <Icon
              fontSize={ICON_SIZE}
              fontSizeUnit={`px`}
              icon={Lens}
              color={Colors.speedlo.main}
            />
          }
        />
      </FlexBox>
    </MuiThemeProvider>
  )
}

export const NotificationHeader = observer(NotificationHeaderComponent)

//* HELPERS

/**
 * Function to trim date from title.
 * Most of the notifications from notion contain [date]
 * at the beginning of the title.
 */
const trimDate = (title: string) => {
  // regex to find date in square brackets
  // checks if [] contain at least one number and dot or slash
  // for example for dates like 23.2.23 or 23/2/2023
  const dateRegex = /^\[[^\]]*[0-9]+[^\]]*([./])[^\]]*\]/

  // replace date with empty string
  return title.replace(dateRegex, '')
}
