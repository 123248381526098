import { ActiveExternalDeliveriesModel } from '-segments/externalDelivery/models/ActiveExternalDeliveriesModel'
import { FeaturePreviewsModel } from '-segments/featurePreview/models/FeaturePreviewsModel'
import { PrintWatchModel } from '-segments/printWatch/models/PrintWatchModel'
import { Instance, SnapshotIn, types } from 'mobx-state-tree'

import { OrderGroupModel } from '../../../components/OrderGroups/models/OrderGroupModel'
import { AudioModel } from '../../../models/AudioModel'
import { AuthModel } from '../../../models/AuthModel'
import { BaseModel } from '../../../models/BaseModel'
import { ConfigModel } from '../../../models/ConfigModel'
import { CooldownModel } from '../../../models/CooldownModel'
import { UserModel } from '../../../models/UserModel'
import { CreateOrderModel } from '../../../screens/createorder/models/CreateOrderModel'
import { HeaderTextModel } from '../../../screens/headerText/models/HeaderTextModel'
import { OrderModel } from '../../../screens/order/models/OrderModel'
import { StatisticsModel } from '../../../screens/overview/models/StatisticsModel'
import { SettingsModel } from '../../../screens/settings/models/SettingsModel'
import { StockModel } from '../../../screens/stock/models/StockModel'
import { ZoneModel } from '../../../screens/zones/models/ZoneModel'
import { NotificationsModel } from '../../../segments/notifications'

// ! Models CAN’T be reexported from index.ts files!
// you can’t even mention a file that has model in it there, even if you try to reexport just a type or a function from it
// you will get the following error once that index file (in combination with path aliases I thing) gets imported anywhere
// that’s the reason why Models are imported here directly from their files and nothing except for Models is exported from those files

// Error: [mobx-state-tree] expected mobx-state-tree type as argument 1, got undefined instead

// more info here:
// https://stackoverflow.com/questions/69944164/error-mobx-state-tree-expected-mobx-state-tree-type-as-argument-1-got-undefi
// https://github.com/infinitered/ignite-bowser/issues/382

export const RootModel = BaseModel.named('Root')
  .props({
    auth: types.optional(AuthModel, {}),
    user: types.optional(UserModel, {}),
    zone: types.optional(ZoneModel, {}),
    audio: types.optional(AudioModel, {}),
    stock: types.optional(StockModel, {}),
    order: types.optional(OrderModel, {}),
    config: types.optional(ConfigModel, {}),
    settings: types.optional(SettingsModel, {}),
    cooldown: types.optional(CooldownModel, {}),
    headerText: types.optional(HeaderTextModel, {}),
    statistics: types.optional(StatisticsModel, {}),
    orderGroup: types.optional(OrderGroupModel, {}),
    printWatch: types.optional(PrintWatchModel, {}),
    createOrder: types.optional(CreateOrderModel, {}),
    notifications: types.optional(NotificationsModel, {}),
    featurePreviews: types.optional(FeaturePreviewsModel, {}),
    activeExternalDeliveries: types.optional(ActiveExternalDeliveriesModel, {}),
  })

  .views(self => ({
    get persistedModels() {
      return [
        self.auth,
        self.user,
        self.zone,
        self.audio,
        self.order,
        self.settings,
        self.cooldown,
        self.statistics,
        self.orderGroup,
        self.printWatch,
        self.headerText,
        self.createOrder,
        self.notifications,
        self.featurePreviews,
        self.activeExternalDeliveries,
      ]
    },
  }))

  .actions(self => ({
    invalidate() {
      self.auth.invalidate()
      self.user.invalidate()
      self.zone.invalidate()
      self.order.invalidate()
      self.cooldown.invalidate()
      self.settings.invalidate()
      self.headerText.invalidate()
      self.createOrder.invalidate()
      self.activeExternalDeliveries.invalidate()
    },
  }))

export interface TRootModel extends Instance<typeof RootModel> {}
export interface TRootModelProps extends SnapshotIn<typeof RootModel> {}
