import { useRoot } from '-app/root'
import * as React from 'react'
import useEvent from 'react-use/lib/useEvent'

export function useAudioCapabilityCheck() {
  const { audio: audioModel } = useRoot()

  const setCanPlay = React.useCallback(() => {
    audioModel.setCanPlay()
  }, [audioModel])

  useEvent('keydown', setCanPlay)
  useEvent('mousedown', setCanPlay)
  useEvent('touchstart', setCanPlay)

  React.useEffect(() => {
    if (audioModel.canPlay) {
      return
    }

    const audio = document.createElement('audio')
    const maybePromise = audio.play()

    if (maybePromise !== undefined && maybePromise !== null) {
      // the promise sometimes won’t neither resolve or reject
      // -> setCanPlay false in these cases after some delay to present the confirm modal
      const defaultCantPlayTimer = setTimeout(() => {
        audioModel.setCanPlay(false)
      }, 500)

      maybePromise
        .then(() => {
          clearTimeout(defaultCantPlayTimer)
          setCanPlay()
        })
        .catch(err => {
          clearTimeout(defaultCantPlayTimer)
          if (err.name !== 'NotAllowedError') {
            // some other unrecoverable error, no sounds can play
            audioModel.blockAudio()
          } else {
            audioModel.setCanPlay(false)
          }
        })
    } else if (!audio.paused) {
      setCanPlay()
    }
  }, [setCanPlay, audioModel])
}
