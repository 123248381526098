import * as React from 'react'
import styled from 'styled-components'

import { Text, TTextProps } from '../../Typography'

const TEXT_DECORATION_COLOR = `rgba(0, 0, 0, 0.08)`
const TEXT_DECORATION_HOVER = `rgba(0, 0, 0, 0.5)`

type TLinkStyledProps = {
  disabled?: boolean
  color?: TColor
  colorHover?: TColor
}

export const LinkStyled = styled.a<TLinkStyledProps>`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  display: flex;
  align-items: center;
  width: fit-content;

  color: inherit;

  text-decoration: ${({ disabled }) => (disabled ? '' : 'underline')};
  text-decoration-color: ${({ color = TEXT_DECORATION_COLOR }) => color};

  &:hover {
    text-decoration-color: ${({
      disabled,
      colorHover = TEXT_DECORATION_HOVER,
    }) => (disabled ? '' : colorHover)};
  }

  &:visited {
    text-decoration-color: ${({ color = TEXT_DECORATION_COLOR }) => color};
  }
`
type TLinkStyled = PropsOf<typeof LinkStyled>

export type TLinkProps = TLinkStyled & {
  text?: OmitSafe<TTextProps, 'color'>
}

/**
 * Customizable link component.
 */
export const Link: React.FC<TLinkProps> = ({
  text,
  color,
  children,
  colorHover,
  ...props
}) => {
  return (
    <LinkStyled color={color} colorHover={colorHover} {...props}>
      <Text color={color} {...text}>
        {children}
      </Text>
    </LinkStyled>
  )
}
