import { TDeliveryTypeEnum } from '../../../graph/generated'

const DELIVERY_OPTIONS = [20, 30, 45, 60, 75, 90, 105, 120]
const PICKUP_OPTIONS = [10, 20, 30, 45, 60, 75, 90]

/**
 * Returns the options for the handover time select
 * based on the delivery type
 */
export const getHandoverTimeOptions = (
  handoverType: Nullable<TDeliveryTypeEnum>,
) => {
  switch (handoverType) {
    case 'PICKUP':
      return PICKUP_OPTIONS
    case 'MESSENGER':
    default:
      return DELIVERY_OPTIONS
  }
}
