import * as React from 'react'

/**
 * creates React context object boilerplate
 * returns the contextProvider and useContext tuple (with check that the useContext is used only inside the provider)
 * ! the `undefined` value is used as default for now so this value can’t be used as a valid context value!
 */
export const createContext = <TContextValue>(
  name: string,
  defaultValue?: TContextValue,
) => {
  const context = React.createContext<TContextValue | undefined>(
    defaultValue ?? undefined,
  )

  const useContext = () => {
    const contextValue = React.useContext(context)

    // if the contextValue is undefined it means it’s from the default above and the provider was not rendered
    // ? replace the undefined with some JS Symbol to support `undefined` as a valid context value?
    if (contextValue === undefined) {
      throw new Error(`${name}Provider is missing from the tree`)
    }

    return contextValue
  }

  /**
   * __this version is UNSAFE because it does not check that the corresponding Provider is rendered – consider using the safe version when possible__
   */
  const useContextUNSAFE = () => {
    return React.useContext(context)
  }

  return [context.Provider, useContext, useContextUNSAFE] as const
}
