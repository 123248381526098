import * as React from 'react'

import { Modal } from '../../../ui/Modal'
import { TCreateExternalDeliveryProps } from '../types/externalDelivery.types'
import { CreateExternalDeliveryContent } from './CreateExternalDeliveryContent/CreateExternalDeliveryContent'
import { EstimateExternalDeliveryProvider } from '../contexts/EstimateExternalDeliveryProvider'
import { CreateExternalDeliveryProvider } from '../contexts/CreateExternalDeliveryProvider'

type TProps = TCreateExternalDeliveryProps &
  Pick<PropsOf<typeof CreateExternalDeliveryContent>, 'withPickupTime'>

export const CreateExternalDeliveryModal: React.FC<TProps> = ({
  isOpen,
  onClose,
  order,
  deliveryProvider,
  withPickupTime,
}) => {
  return (
    <Modal.Wrapper size={`md`} open={isOpen} onClose={onClose}>
      <CreateExternalDeliveryProvider
        deliveryProvider={deliveryProvider}
        onClose={onClose}
        order={order}
      >
        <EstimateExternalDeliveryProvider
          deliveryProvider={deliveryProvider}
          order={order}
        >
          <CreateExternalDeliveryContent withPickupTime={withPickupTime} />
        </EstimateExternalDeliveryProvider>
      </CreateExternalDeliveryProvider>
    </Modal.Wrapper>
  )
}
