import styled from 'styled-components/macro'

import { borders, shadows, sizing } from '@material-ui/system'

import { getRadiusValue, getShadow, getSpaceValue } from '../../../designSystem'
import { Colors } from '../../../styling'
import { TStyledPopover } from '../popover.types'

export const StyledPopover = styled.div<TStyledPopover>`
  ${shadows}
  ${borders}
  ${sizing}

  position: ${({ position = `absolute` }) => position};
  overflow: ${({ overflow }) => overflow};

  bottom: ${({ bottom }) => bottom};
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  left: ${({ left }) => left};

  background-color: ${({ bgColor = Colors.white.pure }) => bgColor};
  padding: ${({ padding = `s8` }) => getSpaceValue(padding)};

  border-radius: ${({ borderRadius = getRadiusValue(`r4`) }) => borderRadius};
  box-shadow: ${({ boxShadow = getShadow(`medium`) }) => boxShadow};
`
