import styled from 'styled-components/macro'

type TStyledProps = {
  height: number
  animationTimeMs: number
}

export const StyledAnimatedHeight = styled.div<TStyledProps>`
  overflow: hidden;
  height: ${({ height }) => `${height}px`};
  transition: height ${({ animationTimeMs }) => `${animationTimeMs}ms`}
    ease-in-out;
`
