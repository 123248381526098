import * as React from 'react'
import styled from 'styled-components/macro'

import { queries } from '../app/Responsive'
import { Spacing } from '../styling'
import { createContext } from '../tools/createContext'

type TProps = Children

const StyledFormGrid = styled.div`
  display: grid;
  grid-template-columns: 0.1fr [desc] auto [content] 1fr;
  grid-template-areas: '. desc content';
  grid-auto-flow: row dense;
  grid-row-gap: ${Spacing.formElementVerticalGap};

  @media ${queries.mobile} {
    grid-template-columns: 1fr;
    grid-template-areas: 'content';
  }
`

// any type/value would do, we just need to know if we're in a grid or not
const [Provider, , useGridContextUnsafe] = createContext<boolean>(`FormGrid`)

export const FormGrid: React.FC<TProps> = ({ children }) => {
  return (
    <StyledFormGrid>
      <Provider value={true}>{children}</Provider>
    </StyledFormGrid>
  )
}

export const useFormGridCheck = () => {
  // use unsafe version because we do want to be able to use this outside of the provider as well
  return useGridContextUnsafe() !== undefined
}
