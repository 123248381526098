import { HOUR, MINUTE, SECOND, useInterval } from '@speedlo/hooks'
import { updateWorker } from '@speedlo/tools'
import { useObservable, useObserver } from 'mobx-react-lite'
import * as React from 'react'

import { APP_STAGE } from '../../helpers/appStage'
import { logUpdate } from '../../helpers/logger'
import { AppUpdateToast } from './AppUpdateToast'

type TProps = NoChildren & {
  onRestart?(): void
  autoUpdateDelay?: number
  postponeTimeout?: number
  updateCheckInterval?: number
  serviceWorker?: ServiceWorkerContainer
}

export const AppUpdateNotification: React.FC<TProps> = ({
  postponeTimeout = 2 * MINUTE,
  autoUpdateDelay = 20 * SECOND,
  onRestart = () => window.location.assign('/'),
  serviceWorker = window.navigator.serviceWorker,
  updateCheckInterval = APP_STAGE.isProd ? 1 * HOUR : 5 * MINUTE,
}) => {
  const updateWorkerRef = React.useRef<ServiceWorker | null>(null)
  const registrationWorkerRef = React.useRef<ServiceWorkerRegistration | null>(
    null,
  )

  const state = useObservable({ updatePending: false, applyingUpdate: false })

  useInterval(() => {
    if (registrationWorkerRef.current) {
      logUpdate('checking...')
      registrationWorkerRef.current.update().catch(() => {
        // don't really care if check fails
      })
    }
  }, updateCheckInterval)

  React.useEffect(() => {
    const onMessage = (event: MessageEvent) => {
      if (event.data === 'update-applied') {
        logUpdate('restarting to apply...')
        onRestart()
      }
    }

    if (serviceWorker) {
      serviceWorker.addEventListener('message', onMessage)

      return () => {
        serviceWorker.removeEventListener('message', onMessage)
      }
    } else {
      logUpdate('serviceWorker is not supported')

      return () => {
        /* noop */
      }
    }
  }, [onRestart, serviceWorker])

  React.useEffect(() => {
    if (window.navigator.userAgent.indexOf('; wv') > 0) {
      // disable service worker on POS devices
      updateWorker.unregister()

      return
    }

    const init = updateWorker.register({
      onInit: reg => {
        registrationWorkerRef.current = reg
      },
      onUpdate: sw => {
        updateWorkerRef.current = sw
        state.updatePending = true
        logUpdate('new update is available')
      },
    })
    // service worker is normally using load event, but since this
    // done after the load, it has to be triggered manually
    init()
  }, [state])

  const onCancel = React.useCallback(() => {
    logUpdate('user postponed')
    state.updatePending = false

    setTimeout(() => {
      logUpdate('reminding update to user again')
      state.updatePending = true
    }, postponeTimeout)
  }, [postponeTimeout, state])

  const onConfirm = React.useCallback(() => {
    state.updatePending = false

    if (updateWorkerRef.current) {
      logUpdate('user confirmed')
      state.applyingUpdate = true
      updateWorkerRef.current.postMessage('apply-update')
    }
  }, [state])

  if (state.applyingUpdate && process.env.NODE_ENV !== 'test') {
    throw new Promise(() => {
      // Suspense will catch it and show loader while the update is being applied
    })
  }

  return useObserver(() =>
    state.updatePending ? (
      <AppUpdateToast
        onCancel={onCancel}
        onConfirm={onConfirm}
        autoHideDuration={autoUpdateDelay}
      />
    ) : null,
  )
}
