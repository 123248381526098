import { useRoot } from '-app/root'
import { Catalog, setupI18n } from '@lingui/core'
import { I18nProvider } from '@lingui/react'
import { useReactionNow } from '@speedlo/hooks'
import { DETECTORS, LocaleResolver, TRANSFORMERS } from 'locales-detector'
import * as React from 'react'

import { appConfig } from './config'
import { logLang } from './helpers/logger'
import { changeNumeralLocale } from './helpers/numeral'
import { changeDFLocale } from './tools/datefns'

export const i18n = setupI18n()

export const I18nManager: React.FC = ({ children }) => {
  const { user } = useRoot()
  const [, forceUpdate] = React.useState(0)

  useReactionNow(
    () => user.language,
    lang => {
      const language = lang || detectLanguage()

      setupLanguage(language).then(success => {
        if (success) {
          forceUpdate(i => i + 1)
        }
      })
    },
  )

  return (
    <I18nProvider i18n={i18n}>{React.Children.only(children)}</I18nProvider>
  )
}

export function detectLanguage() {
  const resolver = new LocaleResolver(
    [new DETECTORS.NavigatorDetector()],
    [new TRANSFORMERS.LanguageOnlyTransformer()],
  )
  const languages = resolver.getLocales()

  logLang(`detected languages ${languages.join('; ')}`)

  const supported = languages.find(loc =>
    appConfig.supportedLanguages.includes(loc),
  )

  return supported || appConfig.fallbackLanguage
}

function setupLanguage(lang: string) {
  if (!appConfig.supportedLanguages.includes(lang)) {
    logLang(`language ${lang} is not supported`)

    return Promise.resolve(false)
  }

  return Promise.all([
    loadCatalog(lang),
    changeDFLocale(lang),
    changeNumeralLocale(lang),
  ]).then(() => {
    i18n.activate(lang)
    logLang(`setup done for ${lang}`)

    return true
  })
}

async function loadCatalog(lang: string) {
  let catalog: Catalog

  if (process.env.NODE_ENV !== 'production') {
    // prettier-ignore
    catalog = await import(
      /* webpackMode: "lazy", webpackChunkName: "i18n-[index]" */
      `@lingui/loader!./locale/${lang}/messages.po`
    )
  } else {
    // prettier-ignore
    catalog = await import(
      /* webpackMode: "lazy", webpackChunkName: "i18n-[index]" */
      `./locale/${lang}/messages.js`
    )
  }

  i18n.load({ [lang]: catalog })
  logLang(`loaded catalog ${lang}`)
}
