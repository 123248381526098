import * as React from 'react'
import styled from 'styled-components/macro'

import { FONT_WEIGHTS } from '../../../designSystem'

type THtmlProps = OmitSafe<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>,
  'ref'
>

type TProps = RequiredChildren & THtmlProps

const StyledBold = styled.strong`
  font-weight: ${FONT_WEIGHTS.boldSemi};
`

/**
 * strong element setting the font-weight to bold
 */
export const Bold: React.FC<TProps> = ({ children, ...htmlProps }) => {
  return <StyledBold {...htmlProps}>{children}</StyledBold>
}
