import * as React from 'react'

import { APP_STAGE } from '../../../helpers/appStage'
import { logPrint } from '../../../helpers/logger'
import { useAppSettings } from '../../../screens/settings/models/useAppSettings'
import { TPrintRecord } from '../components/PrintWatch'
import { usePrintWatchModel } from '../models/usePrintWatchModel'
import { useHandlePrintResult } from './useHandlePrintResult'
import {
  mockedConnectedPrinters,
  mockedSuccessfulPrintingResponse,
  mockedUnsuccessfulPrintingResponse,
  useMockedPc,
  useSpeedloPrinter,
} from './useSpeedloPrinter'

type TOptions = FirstParam<typeof useHandlePrintResult>

/**
 * hooks speedlo printer logic from `useSpeedloPrinter` to handlers from `useHandlePrintResult`
 */
export const usePrintingService = ({ printSuccessNotification }: TOptions) => {
  const { mockedPC } = useMockedPc()
  const refAvailabilityChecked = React.useRef(false)
  const { printSuccessful, simulatePrintService } = useAppSettings()

  const {
    pcInfo,
    canPrint,
    setPcInfo,
    setCanPrint,
    connectedPrinters,
    setConnectedPrinters,
  } = usePrintWatchModel()

  const {
    fetchPcInfo,
    getPrintResult,
    postPrintRequest,
    fetchConnectedPrinters,
  } = useSpeedloPrinter()

  const {
    onPrintFailed,
    onPrintSuccess,
    onPrintRequested,
    onPrintRequestFailed,
  } = useHandlePrintResult({ printSuccessNotification })

  const isTestingSuccess = printSuccessful
  const allowTestMode = APP_STAGE.isNotProd && simulatePrintService
  const [isTestMode, setIsTestMode] = React.useState(false)

  // check if printing service is available (or simulate it in the testMode)
  React.useEffect(() => {
    if (refAvailabilityChecked.current) {
      return
    }

    // test request on one of the print service APIs
    // it would throw if the service is not available
    const checkPrintitkoStatus = async () => {
      try {
        await fetchPcInfo()
        setCanPrint(true)
      } catch (error) {
        logPrint(`Printitko not found – printing service is not running!`)

        if (allowTestMode && mockedPC !== null) {
          logPrint(`running in test mode`)

          setIsTestMode(true)
          setCanPrint(true)

          return
        }

        setCanPrint(false)
      }
    }

    refAvailabilityChecked.current = true
    checkPrintitkoStatus()
  }, [mockedPC, fetchPcInfo, allowTestMode, canPrint, setCanPrint])

  // if print service status was checked & the PC info was not loaded yet, load it
  React.useEffect(() => {
    // printing service availability not decided yet or pc info already loaded
    if (canPrint === null || pcInfo !== null) {
      return
    }

    if (isTestMode) {
      if (!mockedPC) {
        throw new Error(`No mocked PC set & testing (should never happen)`)
      }

      setPcInfo(mockedPC)
      return
    }

    // printing not available
    if (canPrint === false) {
      return
    }

    const loadPcInfo = async () => {
      try {
        const computerInfo = await fetchPcInfo()
        setPcInfo(computerInfo)
      } catch (error) {}
    }

    loadPcInfo()
  }, [fetchPcInfo, isTestMode, mockedPC, pcInfo, canPrint, setPcInfo])

  // if print service status was checked & the connected printers were not loaded yet, load it
  React.useEffect(() => {
    if (canPrint === null || connectedPrinters !== null) {
      return
    }

    if (isTestMode) {
      setConnectedPrinters(mockedConnectedPrinters)
      return
    }

    if (canPrint === false) {
      return
    }

    const loadConnectedPrinters = async () => {
      try {
        const connectedPrinters = await fetchConnectedPrinters()

        logPrint(`connected printers`, connectedPrinters)
        setConnectedPrinters(connectedPrinters)
      } catch (error) {}
    }

    loadConnectedPrinters()
  }, [
    canPrint,
    isTestMode,
    connectedPrinters,
    setConnectedPrinters,
    fetchConnectedPrinters,
  ])

  const simulatePrintRequest = React.useCallback(
    (printRecord: TPrintRecord) => {
      const printResult = isTestingSuccess
        ? mockedSuccessfulPrintingResponse
        : mockedUnsuccessfulPrintingResponse

      if (printResult.isSuccessful) {
        onPrintSuccess(printRecord.id, true, printRecord)
      } else {
        onPrintFailed(printRecord.id, printResult.errors)
      }
    },
    [onPrintFailed, onPrintSuccess, isTestingSuccess],
  )

  const requestPrintResult = React.useCallback(
    async (printRecordId: ID) => {
      const printResult = await getPrintResult(printRecordId)

      if (printResult.isSuccessful) {
        onPrintSuccess(printRecordId)
      } else {
        onPrintFailed(printRecordId, []) // TODO errors
      }
    },
    [getPrintResult, onPrintFailed, onPrintSuccess],
  )

  const requestPrint = React.useCallback(
    async (printRecord: TPrintRecord) => {
      if (!canPrint) {
        return
      }

      if (isTestMode) {
        simulatePrintRequest(printRecord)
        return
      }

      try {
        const printRequestResult = await postPrintRequest(printRecord)

        if (printRequestResult.isSuccessful) {
          onPrintRequested(printRecord.id)
          requestPrintResult(printRecord.id)
        } else {
          onPrintRequestFailed(printRecord)
        }
      } catch (error) {
        onPrintRequestFailed(printRecord)
      }
    },
    [
      canPrint,
      isTestMode,
      postPrintRequest,
      onPrintRequested,
      requestPrintResult,
      simulatePrintRequest,
      onPrintRequestFailed,
    ],
  )

  return { requestPrint, requestPrintResult }
}
