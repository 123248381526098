import { RM } from '../tools/ramda'

export function isPartialMatch(searchValue: string) {
  if (!searchValue) {
    return RM.T
  }

  const search = searchValue.toLocaleLowerCase()

  return (propValue: string) => {
    if (!propValue) {
      return true
    }

    return propValue.toLocaleLowerCase().includes(search)
  }
}
