import { TConnectedPrinter, TRegisteredPrinterLite } from '-segments/printWatch'

import { TPrinterTypeEnum } from '../../../../graph/generated'

// TODO make more robust by combining more stuff to get unique but stale value?
export const getPrinterId = (connectedPrinter: TConnectedPrinter) => {
  return connectedPrinter.printerId
}

export const comparePrinters = <TPrinter extends TRegisteredPrinterLite>(
  registeredPrinter: TPrinter,
  connectedPrinter: TConnectedPrinter,
) => {
  return getPrinterId(connectedPrinter) === registeredPrinter.MAC
}

export const KIOSK_PRINTER_TYPE: TPrinterTypeEnum = 'KIOSK'

type TPrinterWithType = {
  printerType: {
    enum: TPrinterTypeEnum
  }
}

export const isKiosk = (printer: TPrinterWithType) => {
  return printer.printerType.enum === KIOSK_PRINTER_TYPE
}
