import * as React from 'react'

import { Spacing } from '../styling'
import { Sizer, TSizerProps } from './Sizer'

type TProps = RequiredChildren & TSizerProps

export const SizerButton: React.FC<TProps> = ({ children, ...props }) => {
  return (
    <Sizer
      width={Spacing.ButtonHeight}
      height={Spacing.ButtonHeight}
      {...props}
    >
      {children}
    </Sizer>
  )
}
