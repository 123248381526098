import { TRootModel } from '-app/root'
import {
  createAuthLink,
  createDateParserLink,
  createDefaultApolloClient,
  createLogLink,
  createNetworkLink,
  createOfflineLink,
  createRetryLink,
} from '@speedlo/graphql'

import { appConfig } from '../config'
import { APP_STAGE } from '../helpers/appStage'

export function createApolloClient(root: TRootModel) {
  return createDefaultApolloClient(
    createAuthLink({
      enableTokenCache: false,
      getToken: () => root.auth.token!,
      onInvalidToken: () => root.auth.invalidate(),
    }),

    createLogLink({ enabled: APP_STAGE.isNotProd }),
    createRetryLink(),
    createOfflineLink(),
    createDateParserLink(getResolvers()),
    // Warning: When using upload in the mutation, the batching has to be disabled!
    createNetworkLink({ endpoint: appConfig.endpoint, useUploads: true }),
  )
}

function getResolvers() {
  return {
    CompanyBranch: ['willOpenAt', 'willCloseAt'],
    BusinessHour: ['specificDate'],
    BusinessHourInterval: ['start', 'end', 'openAt', 'closeAt'],
    FinancialReport: ['reportDatetime'],
    GeoPositionWithTimestamp: ['timestamp'],
    Order: [
      'createdAt',
      'acceptedAt',
      'deliverAt',
      'deliveredAt',
      'deliverAtWorst',
      'delayedDeliveryTime',
      'driverWillPickupAt',
    ],
    ExternalDeliveryEstimate: ['estimatedPickupTime', 'estimatedDeliveryTime'],
    OrderStateCategoryRelation: ['createdAt'],
    SectorRecipe: ['createdAt'],
    OrderProcessTime: ['expiresAt'],
    InAppNotification: ['createdAt'],
  }
}
