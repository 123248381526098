export const ROUTE_OVERVIEW_DASHBOARD = '/overview'
export const ROUTE_OVERVIEW_CLOSURE = `${ROUTE_OVERVIEW_DASHBOARD}/closure`
export const ROUTE_OVERVIEW_CLOSURE_HISTORY = `${ROUTE_OVERVIEW_CLOSURE}/history`
export const ROUTE_OVERVIEW_STATS_SALES = `${ROUTE_OVERVIEW_DASHBOARD}/sales`
export const ROUTE_OVERVIEW_STATS_CREDITS = `${ROUTE_OVERVIEW_DASHBOARD}/credits`
export const ROUTE_OVERVIEW_STATS_ORDERS = `${ROUTE_OVERVIEW_DASHBOARD}/orders`
export const ROUTE_OVERVIEW_STATS_BILLING = `${ROUTE_OVERVIEW_DASHBOARD}/billing`
export const ROUTE_OVERVIEW_ORDER_RATING = `${ROUTE_OVERVIEW_DASHBOARD}/order-ratings`
export const ROUTE_OVERVIEW_CUSTOMER_HISTORY = `${ROUTE_OVERVIEW_DASHBOARD}/customer`
export const ROUTE_OVERVIEW_STATS_CUSTOMERS = `${ROUTE_OVERVIEW_DASHBOARD}/customers`
export const ROUTE_OVERVIEW_STATS_STOCK = `${ROUTE_OVERVIEW_DASHBOARD}/stock`
export const ROUTE_OVERVIEW_DRIVER_HISTORY = `${ROUTE_OVERVIEW_DASHBOARD}/driver-history`
