export type TGroupedItem<TOriginalItem> = {
  id: string
  count: number
  items: TOriginalItem[]
  firstItem: TOriginalItem
}

export const getGroupItemsByHash = <TOriginalItem>(
  getUniqueHash: (item: TOriginalItem) => string,
) => (originalItems: RoA<TOriginalItem>) => {
  const resultItemMap = new Map<ID, TGroupedItem<TOriginalItem>>()

  // go through all of the original items
  originalItems.forEach(item => {
    // build an unique item hash
    const orderItemHash = getUniqueHash(item)

    const previousRecipeItemsInMap = resultItemMap.get(orderItemHash)
    const recipeNotInMap = !previousRecipeItemsInMap

    if (recipeNotInMap) {
      // this is the first item with this hash – add it to the map as new record
      resultItemMap.set(orderItemHash, {
        count: 1,
        items: [item],
        firstItem: item,
        id: orderItemHash,
      })

      return
    }

    // this hash was already added before – increment the count and add this order item to the items array of that cart item in the map
    resultItemMap.set(orderItemHash, {
      ...previousRecipeItemsInMap!,
      count: previousRecipeItemsInMap!.count + 1,
      items: [...previousRecipeItemsInMap!.items, item],
    })
  })

  // convert the map back to array
  const resultItemArray = Array.from(resultItemMap.values())

  return resultItemArray
}
