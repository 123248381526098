import { Router } from '-routing'
import { ActiveExternalDeliveriesLoader } from '-segments/externalDelivery'
import { PrintWatch } from '-segments/printWatch'
import { useReleaseCabinet } from '@speedlo/tools/dist/useReleaseCabinet'
import { Observer } from 'mobx-react-lite'
import * as React from 'react'

import { ConnectionWarning } from '../atoms/ConnectionWarning'
import { useSnackBar } from '../hooks/useSnackBar'
import { LoadingPage } from '../pages/LoadingPage'
import { Routing } from '../routes/Routing'
import { NotificationsLoader } from '../segments/notifications'
import { AppUpdateNotification } from './components/AppUpdateNotification'
import { DefaultErrorHandler } from './components/DefaultErrorHandler'
import { EnvAppBg } from './components/EnvAppBg'
import { UserBootstrap } from './components/UserBootstrap'
import { useRoot } from './root'

export const AppMain: React.FC = () => {
  const { user } = useRoot()

  const { enqueueInfo } = useSnackBar()
  useReleaseCabinet(enqueueInfo)

  return (
    <DefaultErrorHandler>
      <EnvAppBg />
      <UserBootstrap />
      <AppUpdateNotification />
      <ConnectionWarning />
      <React.Suspense fallback={<LoadingPage />}>
        <Router>
          <Observer>
            {() =>
              user.isReady ? (
                <>
                  <PrintWatch />
                  <NotificationsLoader />
                  <ActiveExternalDeliveriesLoader />
                  <Routing />
                </>
              ) : (
                <LoadingPage />
              )
            }
          </Observer>
        </Router>
      </React.Suspense>
    </DefaultErrorHandler>
  )
}
