import { useRoot } from '-app/root'
import { SECOND, useAutorun, useInterval } from '@speedlo/hooks'
import * as React from 'react'

import { useAudioCapabilityCheck } from './useAudioCapabilityCheck'
import { useAudioPlayer } from './useAudioPlayer'

type TOptions = {
  soundUrl: string
  shouldPlay: boolean
  intervalSec: number
  forcePlayNow?: boolean
}

export function useIntervalSound({
  soundUrl,
  shouldPlay,
  intervalSec,
  forcePlayNow = false,
}: TOptions) {
  useAudioCapabilityCheck()

  const { audio: audioModel } = useRoot()
  const [audio, controls] = useAudioPlayer({
    src: soundUrl,
  })

  const playIfYouCan = React.useCallback(() => {
    if (shouldPlay && audioModel.enabled) {
      controls.volume(0.5)
      controls.play()
    }
    // * Ignoring controls as they are not properly memoized, but mostly using refs
    // * so it shouldn't matter if it becomes stale.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldPlay, audioModel])

  const [startTimer, stopTimer] = useInterval(
    playIfYouCan,
    intervalSec * SECOND,
  )

  const stopNow = React.useCallback(() => {
    controls.pause()
    controls.seek(0)
    stopTimer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stopTimer])

  React.useEffect(() => {
    if (shouldPlay) {
      startTimer()
    } else {
      stopNow()
    }
  }, [shouldPlay, startTimer, stopNow])

  useAutorun(() => {
    if (!audioModel.enabled) {
      stopNow()
    }
  })

  React.useEffect(() => {
    if (forcePlayNow) {
      playIfYouCan()
    }
  }, [forcePlayNow, playIfYouCan])

  return audio
}
