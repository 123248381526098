import * as React from 'react'
import { createContext } from '../../../tools/createContext'
import { TCreateExternalDeliveryProps } from '../types/externalDelivery.types'

type TContext = OmitSafe<TCreateExternalDeliveryProps, 'isOpen' | 'children'>

const [
  Provider, //
  useCreateExternalDeliveryCtx,
] = createContext<TContext>(`CreateExternalDelivery`)

type TProps = RequiredChildren & TContext

/**
 * Context provider for external delivery creation modal.
 */
export const CreateExternalDeliveryProvider: React.FC<TProps> = ({
  children,
  ...contextValue
}) => {
  return <Provider value={contextValue}>{children}</Provider>
}

export { useCreateExternalDeliveryCtx }
