import { getSpaceValue, getSpaceValuePx, TSpace, TSpaceUnit } from './space'

/**
 * key that can be used as margin property
 */
export type TMargin = TSpace | 'auto'

/**
 * returns a value for provided margin key, in requested unit,
 * supports `auto` value as well
 */
export const getMarginValue = (margin: TMargin, unit?: TSpaceUnit) => {
  return margin === 'auto' ? margin : getSpaceValue(margin, unit)
}

/**
 * returns a value for provided margin key as a number in pixels,
 * returns 0 for `auto`
 */
export const getMarginValuePx = (margin: TMargin) => {
  return margin === 'auto' ? 0 : getSpaceValuePx(margin)
}
