import * as React from 'react'
import { Route, Switch } from 'react-router-dom'

import { LoadingPage } from '../pages/LoadingPage'
import { NotFoundPage } from '../pages/NotFoundPage'
import { ROUTE_CUSTOMER_SCREEN_SETUP } from '../screens/customerscreen/customerscreen.routes'
import {
  ROUTE_MAIN,
  ROUTE_SUPPORT,
} from '../screens/dashboard/dashboard.routes'
import { ROUTE_KITCHEN } from '../screens/kitchen/kitchen.routes'
import {
  ROUTE_AUTH_LOGIN,
  ROUTE_AUTH_LOGOUT,
  ROUTE_AUTH_RECOVERY,
  ROUTE_AUTH_REGISTER,
  ROUTE_AUTH_RESET_PASSWORD,
} from '../screens/login/login.routes'
import { ROUTE_ORDER_OVERVIEW } from '../screens/order/order.routes'
import { ROUTE_OVERVIEW_DASHBOARD } from '../screens/overview/overview.routes'
import { ROUTE_SETTINGS } from '../screens/settings/settings.routes'
import { ROUTE_STOCK } from '../screens/stock/stock.routes'
import { LogRoute } from './LogRoute'
import { RedirectAfterLogin } from './RedirectAfterLogin'
import { RedirectAfterLogout } from './RedirectAfterLogout'
import { SecureRoute } from './SecureRoute'

const PasswordLoginRoute = React.lazy(
  () => import('../screens/login/routes/PasswordLoginRoute'),
)

const PasswordChangeRoute = React.lazy(
  () => import('../screens/login/routes/PasswordChangeRoute'),
)

const PasswordResetRoute = React.lazy(
  () => import('../screens/login/routes/PasswordResetRoute'),
)

const MainDashboardPage = React.lazy(
  () => import('../screens/dashboard/pages/MainDashboardPage'),
)

const OrderRouting = React.lazy(
  () => import('../screens/order/routes/OrderRouting'),
)

const KitchenRouting = React.lazy(
  () => import('../screens/kitchen/routes/KitchenRouting'),
)

const SettingsRouting = React.lazy(
  () => import('../screens/settings/routes/SettingsRouting'),
)

const OverviewRouting = React.lazy(
  () => import('../screens/overview/routes/OverviewRouting'),
)

const StockRouting = React.lazy(
  () => import('../screens/stock/routes/StockRouting'),
)

const SupportPage = React.lazy(
  () => import('../screens/dashboard/pages/SupportPage'),
)

const CustomerScreenRouting = React.lazy(
  () => import('../screens/customerscreen/routes/CustomerScreenRouting'),
)

export const Routing: React.FC = () => (
  <React.Suspense fallback={<LoadingPage />}>
    <LogRoute />
    <RedirectAfterLogin />
    <Switch>
      <SecureRoute path={ROUTE_MAIN} exact component={MainDashboardPage} />
      <Route path={ROUTE_AUTH_LOGIN} component={PasswordLoginRoute} />
      <Route
        exact
        component={PasswordResetRoute}
        path={ROUTE_AUTH_RESET_PASSWORD}
      />
      <Route
        exact
        component={PasswordChangeRoute}
        path={`${ROUTE_AUTH_RECOVERY}/:token`}
      />
      <Route
        exact
        component={PasswordChangeRoute}
        path={`${ROUTE_AUTH_REGISTER}/:token`}
      />
      <SecureRoute
        component={OverviewRouting}
        path={ROUTE_OVERVIEW_DASHBOARD}
      />
      <SecureRoute path={ROUTE_STOCK} component={StockRouting} />
      <SecureRoute path={ROUTE_ORDER_OVERVIEW} component={OrderRouting} />
      <SecureRoute path={ROUTE_KITCHEN} component={KitchenRouting} />
      <SecureRoute
        component={CustomerScreenRouting}
        path={ROUTE_CUSTOMER_SCREEN_SETUP}
      />
      <SecureRoute path={ROUTE_SETTINGS} component={SettingsRouting} />
      <Route path={ROUTE_SUPPORT} component={SupportPage} />
      <Route path={ROUTE_AUTH_LOGOUT} component={RedirectAfterLogout} />
      <Route component={NotFoundPage} />
    </Switch>
  </React.Suspense>
)
