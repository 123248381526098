import * as React from 'react'

import { i18n } from '../../i18n'
import { Modal } from '../../ui/Modal'

export type TProps = NoChildren & {
  label: string
  isOpen: boolean
  cancelText?: string
  onCancel: () => void
  confirmText?: string
  onConfirm: () => void
  message?: React.ReactNode
  size?: PropsOf<typeof Modal.Wrapper>['size']
}

export const ConfirmModal: React.FC<TProps> = ({
  label,
  isOpen,
  message,
  onCancel,
  onConfirm,
  cancelText = i18n.t`Cancel`,
  confirmText = i18n.t`Confirm`,
  size = `sm`,
}) => {
  return (
    <Modal.Wrapper size={size} open={isOpen} onClose={onCancel}>
      <Modal.Header>
        {/* Modal.Title would be way to big here */}
        <Modal.SectionTitle>{label}</Modal.SectionTitle>
      </Modal.Header>

      {message && <Modal.Content>{message}</Modal.Content>}

      <Modal.Footer>
        <Modal.Actions>
          <Modal.Button type={`cancel`} onClick={onCancel}>
            {cancelText}
          </Modal.Button>
          <Modal.Button type={`confirm`} onClick={onConfirm}>
            {confirmText}
          </Modal.Button>
        </Modal.Actions>
      </Modal.Footer>
    </Modal.Wrapper>
  )
}
