import { compose, PropsFor, style } from '@material-ui/system'

import { TSimpleStyleFunction } from './Box'

type TGridOptions =
  | 'gridArea'
  | 'gridAutoColumns'
  | 'gridAutoFlow'
  | 'gridAutoRows'
  | 'gridColumnEnd'
  | 'gridColumnGap'
  | 'gridColumnStart'
  | 'gridColumn'
  | 'gridGap'
  | 'gridRowEnd'
  | 'gridRowGap'
  | 'gridRowStart'
  | 'gridRow'
  | 'gridTemplateAreas'
  | 'gridTemplateColumns'
  | 'gridTemplateRows'
  | 'gridTemplate'
  | 'grid'

const gridOptionNames: RoA<TGridOptions> = [
  'gridArea',
  'gridAutoColumns',
  'gridAutoFlow',
  'gridAutoRows',
  'gridColumnEnd',
  'gridColumnGap',
  'gridColumnStart',
  'gridColumn',
  'gridGap',
  'gridRowEnd',
  'gridRowGap',
  'gridRowStart',
  'gridRow',
  'gridTemplateAreas',
  'gridTemplateColumns',
  'gridTemplateRows',
  'gridTemplate',
  'grid',
]

const gridOptions = gridOptionNames.map(option => style({ prop: option }))

export const gridSystem = compose(...gridOptions) as TSimpleStyleFunction<
  TGridOptions
>

export type GridProps = PropsFor<typeof gridSystem>
