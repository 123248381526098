/**
 * all possible radius values in px (or as string),
 * basically multiplies of 4 chosen by design team
 */
export const RADIUSES_VALUES = {
  r2: 2,
  r4: 4,
  r8: 8,
  r16: 16,
  r32: 32,
  r48: 48,
  r64: 64,
  pill: `50vh`,
  circle: `50%`,
} as const

/**
 * semantically renamed values from above
 * to keep some places of the DS consistent,
 * extend whenever design team does so
 */
export const RADIUSES_SEMATIC = {
  none: RADIUSES_VALUES.r2,
  card: RADIUSES_VALUES.r8,
  button: RADIUSES_VALUES.r4,
  input: RADIUSES_VALUES.r4,
} as const
