import { Instance, types } from 'mobx-state-tree'

import { gpsToLatLng, gpsToLatLngLit } from '../helpers/gps'
import { BaseModel } from '../models/BaseModel'

type TVolatileProps = {
  mapRef: Nullable<google.maps.Map>
}

const GPSCzechia = {
  latitude: 49.8037633,
  longitude: 15.4749126,
}

export const MapModel = BaseModel.named('Map')
  .props({
    zoom: types.optional(types.number, 12),
    latitude: types.optional(types.number, GPSCzechia.latitude),
    longitude: types.optional(types.number, GPSCzechia.longitude),
  })

  .volatile<TVolatileProps>(self => ({
    mapRef: null,
  }))

  .views(self => ({
    get isReady() {
      return self.mapRef !== null
    },

    get center() {
      return gpsToLatLngLit(self)
    },
  }))

  .actions(self => ({
    setMapRef(ref: google.maps.Map) {
      self.mapRef = ref
    },

    updateZoom() {
      if (self.mapRef) {
        self.zoom = self.mapRef.getZoom()
      }
    },

    updatePosition() {
      if (self.mapRef) {
        const center = self.mapRef.getCenter()
        self.latitude = center.lat()
        self.longitude = center.lng()
      }
    },

    panTo(gps: GeoPoint) {
      if (self.mapRef) {
        self.mapRef.panTo(gpsToLatLng(gps))
      }
    },
  }))

export interface TMapModel extends Instance<typeof MapModel> {}
