import debug from 'debug'

import { APP_STAGE } from './appStage'

// @ts-ignore
debug.useColors = () => !APP_STAGE.env

export const logMain = debug('main')
export const logDebug = debug('debug')
export const logAuth = debug('auth')
export const logPersistence = debug('persistence')
export const logBootstrap = debug('bootstrap')
export const logAccess = debug('access')
export const logGraph = debug('graph')
export const logForm = debug('form')
export const logSentry = debug('sentry')
export const logLang = debug('lang')
export const logUpdate = debug('update')
export const logSound = debug('sound')
export const logPrint = debug('print')
// tslint:disable-next-line:no-console
export const logRouting = console.debug
// tslint:disable-next-line:no-console
export const logTest = console.log
// tslint:disable-next-line:no-console
export const logError = console.error

// https://github.com/debug-js/debug#browser-support
