import { Instance, types } from 'mobx-state-tree'

import { BaseModel } from '../../../models/BaseModel'

type TVolatileProps = {
  isCreatingGroup: boolean
  editingGroupId: Nullable<ID>
}

export const OrderGroupModel = BaseModel.named('OrderGroup')
  .props({
    lastColorId: types.maybeNull(types.string), // last color used on this browser for new order group – used to rotate teh colors
  })

  .volatile<TVolatileProps>(() => ({
    editingGroupId: null,
    isCreatingGroup: false,
  }))

  .views(self => ({
    get isEditingGroup() {
      return self.editingGroupId !== null
    },
  }))

  .actions(self => ({
    startCreatingGroup() {
      self.isCreatingGroup = true
    },

    setEditingGroup(orderGroupId: ID) {
      self.editingGroupId = orderGroupId
    },

    closeCreatingOrEditingOrderGroup() {
      self.isCreatingGroup = false
      self.editingGroupId = null
    },

    setLastUsedColor(colorId?: ID) {
      if (colorId) {
        self.lastColorId = colorId
      }
    },
  }))

export interface TMapModel extends Instance<typeof OrderGroupModel> {}
