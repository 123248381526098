import * as React from 'react'
import styled, { css } from 'styled-components/macro'

import { getRadiusValue, TRadius } from '../../../designSystem'
import { cn } from '../../../libs/classnames'
import { Colors } from '../../../styling'
import { useOrderHandoverTime } from '../hooks/useOrderHandoverTime'
import { TOrderForHandoverTime } from '../typings/handoverTime.types'

type TStyledProps = {
  radius: TRadius
  bgColor: TColor
  textColor: TColor
}

type TProps = NoChildren & {
  radius?: TRadius
  onClick?: () => void
  onTimeHighlight?: boolean
  order: TOrderForHandoverTime
}

const BADGE_SIZE = 2

export const onTimeOrderBadgeStyles = css`
  border: var(--box-spacing) solid ${Colors.white.main};
  box-shadow: 0 0 0 3px ${Colors.orange.main};
`

const StyledHandoverTimeBadge = styled.div<TStyledProps>`
  --box-spacing: 3px;
  --dimensions: calc(${BADGE_SIZE}em + 2 * var(--box-spacing));

  display: grid;
  place-items: center;

  background: ${({ bgColor }) => bgColor};
  color: ${({ textColor }) => textColor};

  width: var(--dimensions);
  height: var(--dimensions);
  border-radius: ${({ radius }) => getRadiusValue(radius)};

  line-height: 1;
  font-size: 1em;
  white-space: nowrap;
  overflow: hidden;

  /* background-clip: padding-box; Safari */

  &.on-time {
    ${onTimeOrderBadgeStyles}
  }

  &.small-font span {
    font-size: 0.875em;
  }

  &.clickable {
    cursor: pointer;
  }
`

export const HandoverTimeBadge: React.FC<TProps> = ({
  order,
  onClick,
  radius = `pill`,
  onTimeHighlight = true,
}) => {
  const {
    bgColor, //
    textColor,
    outputShort,
    isOrderOnTime,
    outputShortLength,
  } = useOrderHandoverTime({
    order,
    iconSizeUnit: 'em',
    iconSize: BADGE_SIZE,
  })

  return (
    <StyledHandoverTimeBadge
      radius={radius}
      bgColor={bgColor}
      onClick={onClick}
      textColor={textColor}
      className={cn(
        { 'on-time': onTimeHighlight && isOrderOnTime },
        { clickable: Boolean(onClick) },
        { 'small-font': outputShortLength > 2 },
      )}
    >
      <span>{outputShort}</span>
    </StyledHandoverTimeBadge>
  )
}
