import { Trans } from '@lingui/macro'
import { CloseRounded } from '@material-ui/icons'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components/macro'

import { Card } from '../../../atoms/Card'
import { Icon } from '../../../atoms/Icon'
import { AnimatedHeight } from '../../../components/AnimatedHeight/AnimatedHeight'
import { getSpaceValue, THEME } from '../../../designSystem'
import { getContraColor } from '../../../helpers/styles/colorAdjustments'
import { SectionMessage, SubSectionHeadline } from '../../../ui/Typography'
import { useActiveExternalDeliveriesModel } from '../models/useActiveExternalDeliveriesModel'
import { ActiveExternalDeliveryCard } from './ActiveExternalDeliveryCard'

type TProps = NoChildren

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: ${getSpaceValue(`s16`)};
  padding-bottom: ${getSpaceValue(`s8`)};
`

const StyledContent = styled.section`
  display: flex;
  gap: ${getSpaceValue(`s8`)};

  overflow: auto;
  width: 100%;
  padding: ${getSpaceValue(`s16`)};
  padding-top: ${getSpaceValue(`s8`)};
`

/**
 * list of external deliveries that are currently active for one of the user branches
 */
const ActiveExternalDeliveryListComponent: React.FC<TProps> = () => {
  const {
    closeList, //
    isListOpen,
    activeExternalDeliveries,
  } = useActiveExternalDeliveriesModel()

  const hasSomeDelivery = activeExternalDeliveries.length > 0

  return (
    <AnimatedHeight open={isListOpen} animationTimeMs={250}>
      <Card color={THEME.white.normal} padding={getSpaceValue(`s0`)}>
        <StyledHeader>
          <SubSectionHeadline marginBottom={`s0`}>
            <Trans>External delivery orders</Trans>
          </SubSectionHeadline>

          <Icon
            fontSize={1.2}
            icon={CloseRounded}
            onClick={closeList}
            color={getContraColor(THEME.white.normal)}
          />
        </StyledHeader>

        <StyledContent>
          {!hasSomeDelivery && (
            <SectionMessage fontSize={`1rem`} marginTop={`s0`}>
              <Trans>No external delivery is active at this time</Trans>
            </SectionMessage>
          )}

          {activeExternalDeliveries.map(delivery => (
            <ActiveExternalDeliveryCard key={delivery.id} delivery={delivery} />
          ))}
        </StyledContent>
      </Card>
    </AnimatedHeight>
  )
}

export const ActiveExternalDeliveryList = observer(
  ActiveExternalDeliveryListComponent,
)
