type TOrder = {
  delayedDeliveryTime: Nullable<Date>
}

/**
 * returns true if the order is on specific time
 */
export const getIsOrderOnTime = (order: TOrder) => {
  return Boolean(order.delayedDeliveryTime)
}
