import { css } from 'styled-components'

/**
 * makes text uppercase and smaller
 */
export const petiteCaps = css`
  /* font-variant-caps: all-petite-caps with larger font size was used previously  */
  /* but that did mess special characters (ř) in Safari */
  font-size: 0.75rem;
  text-transform: uppercase;
`
