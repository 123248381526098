import { MINUTE, useTimer } from '@speedlo/hooks'
import { addMinutes } from 'date-fns'
import * as React from 'react'

/**
 * logic related to the external delivery courier pickup time,
 * use in combination with ExternalDeliveryPickupTime
 */
export const useExternalDeliveryPickupTime = () => {
  const now = useTimer({ enabled: true, interval: MINUTE })
  const [minutes, setMinutes] = React.useState<Nullable<number>>(null)

  const closestPickupTime = React.useMemo(() => {
    // add 2 minutes if selected value is 0
    // just to be sure that the time is not in the past when it’s sent to the backend
    // it gets updated every minute anyway
    return minutes !== null ? addMinutes(now, Math.max(minutes, 2)) : null
  }, [minutes, now])

  return { minutes, closestPickupTime, setMinutes }
}
