import * as React from 'react'
import styled, { keyframes } from 'styled-components/macro'

import { Colors } from '../styling'

const spinnerSizes = {
  small: `1rem`,
  medium: `2rem`,
  large: `3rem`,
}

type TStyledProps = {
  size: string
  color: string
}

type TProps = NoChildren & {
  color?: string
  size?: keyof typeof spinnerSizes
}

const spin = keyframes`
  100% {
    transform: rotate(1turn);
  }
`

const StyledSpinner = styled.div<TStyledProps>`
  --spinner-dimensions: ${({ size }) => size};

  width: var(--spinner-dimensions);
  height: var(--spinner-dimensions);

  animation: ${spin} 1s infinite cubic-bezier(0.46, 0.05, 0.52, 0.9);

  border-radius: 50%;
  border-bottom: 3px solid ${({ color }) => color};
`

export const Spinner: React.FC<TProps> = ({
  size = `medium`,
  color = Colors.white.main,
}) => {
  return <StyledSpinner size={spinnerSizes[size]} color={color} />
}
