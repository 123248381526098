import debug from 'debug'
import * as React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { setTimeout } from 'timers'

import { ROUTE_AUTH_LOGIN } from '../screens/login/login.routes'

type TProps = NoChildren & {
  clearDataInMemory?: boolean
}

const log = debug('routing')

export const RedirectToLogin: React.FC<TProps> = ({
  clearDataInMemory = false,
}) => (
  <Route
    path={ROUTE_AUTH_LOGIN}
    children={({ match, location }) => {
      if (match) {
        return null
      }

      log('redirecting to login...')

      if (clearDataInMemory) {
        // clear the data in browser memory by reloading the whole app the hard way
        // also wait for the persistance to be cleared first, otherwise the user would be logged again
        // FIXME ideally should be bound to AuthModel invalidation & persistence, not set time
        setTimeout(() => {
          window.location.href = `${window.location.origin}${ROUTE_AUTH_LOGIN}`
        }, 200)

        return null
      }

      return (
        <Redirect
          to={{
            pathname: ROUTE_AUTH_LOGIN,
            state: { origin: location.pathname },
          }}
        />
      )
    }}
  />
)
