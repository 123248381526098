import produce from 'immer'
import { Instance, types } from 'mobx-state-tree'

import { FilterInputModel } from '../../../../components/FilterInput/FilterInputModel'
import { BaseModel } from '../../../../models/BaseModel'
import { RM } from '../../../../tools/ramda'
import {
  TFlattenInventoryItem,
  TFlattenStockInventory,
  TInventoryItemSort,
  TStockInventory,
  TVarianceReason,
} from '../typings/stockInventoryTypes'

export const StockInventoryModel = BaseModel.named('StockInventory')
  .props({
    filter: types.optional(FilterInputModel, {}),
  })

  .volatile(() => ({
    sortDirectionAsc: true,
    finishingInventory: false,
    takenInventoryId: null as Nullable<ID>,
    sortField: 'label' as TInventoryItemSort,
    varianceReasons: [] as RoA<TVarianceReason>,
    itemBeingChecked: null as Nullable<TFlattenInventoryItem>,
    inventoryInProgress: null as Nullable<TFlattenStockInventory>,
  }))

  .views(self => ({
    get hasInventoryInProgress() {
      return self.inventoryInProgress !== null
    },

    get hasTakenInventoryId() {
      return self.takenInventoryId !== null
    },

    get isCheckingItem() {
      return self.itemBeingChecked !== null
    },

    get isFinishingInventory() {
      return self.finishingInventory
    },

    get inventoryInProgressItemsSorted() {
      if (!self.inventoryInProgress) {
        return []
      }

      const getSortField = getItemField(self.sortField)
      const sortDirection = self.sortDirectionAsc ? RM.ascend : RM.descend

      const sortItems = RM.sortWith<TFlattenInventoryItem>([
        RM.ascend(wasItemChecked),
        sortDirection(getSortField),
      ])

      return sortItems(self.inventoryInProgress.items)
    },
  }))

  .actions(self => ({
    setInventoryInProgress(inventory: TStockInventory) {
      const flattenInventoryItems = inventory.items.map(inventoryItem => ({
        ...inventoryItem,
        code: inventoryItem.stockItem.code,
        label: inventoryItem.stockItem.label,
      }))

      self.inventoryInProgress = { ...inventory, items: flattenInventoryItems }
    },

    clearInventoryInProgress() {
      self.inventoryInProgress = null
    },

    setTakenInventoryId(inventoryId: ID) {
      self.takenInventoryId = inventoryId
    },

    clearTakenInventoryId() {
      self.takenInventoryId = null
    },

    setItemBeingChecked(itemId: ID) {
      if (self.inventoryInProgress) {
        const inventoryItem = self.inventoryInProgress.items.find(({ id }) => {
          return id === itemId
        })

        self.itemBeingChecked = inventoryItem || null
      }
    },
    clearItemBeingChecked() {
      self.itemBeingChecked = null
    },

    setSortField(sortField: TInventoryItemSort) {
      if (self.sortField !== sortField) {
        self.sortField = sortField
        self.sortDirectionAsc = true

        return
      }

      // this sortField was already selected, just toggle sort direction
      self.sortDirectionAsc = !self.sortDirectionAsc
    },

    setVarianceReasons(varianceReasons: RoA<TVarianceReason>) {
      self.varianceReasons = varianceReasons
    },

    updateInventoryItem(item: TFlattenInventoryItem) {
      if (!self.inventoryInProgress) {
        return
      }

      const changedItemIndex = self.inventoryInProgress.items.findIndex(
        ({ id }) => id === item.id,
      )

      if (changedItemIndex < 0) {
        return
      }

      self.inventoryInProgress = produce(
        self.inventoryInProgress,
        draftInventory => {
          draftInventory.items[changedItemIndex] = item
        },
      )
    },

    startFinishingInventory() {
      self.finishingInventory = true
    },

    stopFinishingInventory() {
      self.finishingInventory = false
    },

    clearItemFilter() {
      self.filter.setValue('')
    },
  }))

export interface TStockInventoryModel
  extends Instance<typeof StockInventoryModel> {}

function wasItemChecked(item: TFlattenInventoryItem) {
  return item.finalQuantity !== null
}

function getItemField(sortField: TInventoryItemSort) {
  const checkOwnProperty = (item: TFlattenInventoryItem) => {
    if (item.hasOwnProperty(sortField)) {
      return item[sortField]
    }

    return item.label
  }

  return checkOwnProperty
}
