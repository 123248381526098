import * as React from 'react'
import { createContext } from '../../../tools/createContext'
import { useQEstimateExternalDelivery } from '../../../graph/generated'
import { TCreateExternalDeliveryProps } from '../types/externalDelivery.types'

type TData = Defined<ReturnType<typeof useQEstimateExternalDelivery>['data']>

type TContext = {
  isLoading: boolean
  isError: boolean
  estimates: Nullable<TData['estimates']>
}

const [
  Provider, //
  useEstimateExternalDeliveryCtx,
] = createContext<TContext>(`EstimateExternalDelivery`)

type TProps = RequiredChildren &
  Pick<TCreateExternalDeliveryProps, 'deliveryProvider' | 'order'>

/**
 * Provides external delivery estimates, their loading state and error state.
 */
export const EstimateExternalDeliveryProvider: React.FC<TProps> = ({
  children,
  order,
  deliveryProvider,
}) => {
  const { data, loading, error } = useQEstimateExternalDelivery({
    variables: { orderId: order.id, deliveryProvider: deliveryProvider.enum },
  })

  const contextValue = React.useMemo<TContext>(() => {
    if (loading) {
      return { isLoading: true, isError: false, estimates: null }
    }

    if (error || !data?.estimates) {
      return { isLoading: false, isError: true, estimates: null }
    }

    return { isLoading: false, isError: false, estimates: data.estimates }
  }, [data, error, loading])

  return <Provider value={contextValue}>{children}</Provider>
}

export { useEstimateExternalDeliveryCtx }
