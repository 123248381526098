import { Instance } from 'mobx-state-tree'

import { TFNotification } from '../../../graph/generated'
import { BaseModel } from '../../../models/BaseModel'

export type TReadNotification = {
  id: ID
  isRead?: boolean
}

/**
 * Model for user's notifications.
 */
export const NotificationsModel = BaseModel.named('Notifications')
  .volatile(() => ({
    unreadNotificationsCount: 0,
    notifications: [] as RoA<TFNotification>,
  }))

  .views(self => ({
    /**
     * Returns an array with ids of unread notifications.
     */
    get unreadNotificationsIds() {
      const unreadNotifications = self.notifications.filter(
        notification => !notification.isRead,
      )
      const unreadIds = unreadNotifications.map(notification => notification.id)
      return unreadIds
    },
  }))

  .actions(self => ({
    /**
     * Sets the count of unread notifications.
     */
    setUnreadNotificationsCount(count: number) {
      self.unreadNotificationsCount = count
    },
    /**
     * Sets the user's notifications.
     */
    setNotifications(notifications: RoA<TFNotification>) {
      self.notifications = notifications
    },
    /**
     * Updates isRead field on notifications with provided ids.
     */
    updateReadNotifications(ids: RoA<ID>) {
      const notifications = self.notifications
      const updated = notifications.map(notification => {
        if (ids.includes(notification.id)) {
          self.unreadNotificationsCount--
          return {
            ...notification,
            isRead: true,
          }
        }
        return notification
      })
      self.notifications = updated
    },
    /**
     * Updates isRead field on a single notification.
     */
    updateReadNotification({ id, isRead = true }: TReadNotification) {
      const notifications = self.notifications
      const updated = notifications.map(notification => {
        if (notification.id === id) {
          isRead
            ? self.unreadNotificationsCount--
            : self.unreadNotificationsCount++
          return {
            ...notification,
            isRead,
          }
        }
        return notification
      })
      self.notifications = updated
    },
  }))

export interface NotificationsModel
  extends Instance<typeof NotificationsModel> {}
