import {
  ApolloDefender,
  ApolloOperationError,
  isChunkError,
  isNetworkUnreachableError,
} from '@speedlo/graphql'
import { flushSentry, Sentry } from '@speedlo/sentry'
import { SillyErrorBoundary } from '@speedlo/tools'
import { isApolloError } from 'apollo-client'
import * as React from 'react'

import { logError } from '../../helpers/logger'
import { useSnackBar } from '../../hooks/useSnackBar'
import { i18n } from '../../i18n'
import { ErrorPage } from '../../pages/ErrorPage'
import { ServiceUnreachablePage } from '../../pages/ServiceUnreachablePage'

type ErrorKind = 'none' | 'serviceUnreachable' | 'otherError'

type TProps = Children

const makeError = (message: string) =>
  `${message} ${i18n.t`Our team was notified about the issue.`}`

export const DefaultErrorHandler: React.FC<TProps> = ({ children }) => {
  const { enqueueWarning, enqueueError } = useSnackBar()

  const [errorKind, setErrorKind] = React.useState<ErrorKind>('none')

  const onRetry = React.useCallback(() => {
    setErrorKind('none')
  }, [])

  const onUnhandledError = React.useCallback((error: Error) => {
    if (process.env.NODE_ENV === 'development') {
      logError(error.message)
    }

    if (isChunkError(error)) {
      Sentry.withScope(scope => {
        scope.setLevel(Sentry.Severity.Debug)
        Sentry.captureException(error)
      })

      flushSentry().then(() => window.location.reload())

      return
    }
    if (isApolloError(error) && isNetworkUnreachableError(error)) {
      setErrorKind('serviceUnreachable')

      return
    }

    Sentry.captureException(error)
    setErrorKind('otherError')
  }, [])

  const onNetworkError = React.useCallback(
    (error: Error, isUnreachable: boolean) => {
      if (isUnreachable) {
        setErrorKind('serviceUnreachable')
      } else {
        if (process.env.NODE_ENV === 'development') {
          logError(error)
        }

        enqueueError(
          makeError(
            i18n.t`Unexpected error occured in communication with service.`,
          ),
        )
      }
    },
    [enqueueError],
  )

  const onOperationError = React.useCallback(
    (error: ApolloOperationError) => {
      logError(error.operationName, error.message)

      enqueueError(
        makeError(i18n.t`Unexpected error occured while sending a request.`),
      )
    },
    [enqueueError],
  )

  const onUserErrors = React.useCallback(
    (messages: RoA<string>) => {
      messages.forEach(enqueueWarning)
    },
    [enqueueWarning],
  )

  const render = () => {
    switch (errorKind) {
      case 'serviceUnreachable': {
        return <ServiceUnreachablePage onRetry={onRetry} />
      }

      case 'otherError': {
        return <ErrorPage onFeedback={showFeedback} />
      }

      default: {
        return children
      }
    }
  }

  return (
    <ApolloDefender
      onUserErrors={onUserErrors}
      onNetworkError={onNetworkError}
      onOperationError={onOperationError}
    >
      <SillyErrorBoundary onError={onUnhandledError}>
        {render()}
      </SillyErrorBoundary>
    </ApolloDefender>
  )
}

function showFeedback() {
  Sentry.showReportDialog({
    labelClose: i18n.t`Close`,
    title: i18n.t`Unexpected error in the app`,
  })
}
