import { DialogActions } from '@material-ui/core'
import styled from 'styled-components/macro'

import { MODAL_SPACES } from '../config/modal.config'

type TProps = {
  maxWidth?: string
  columns?: string
  fullWidth?: boolean
}

/**
 * ModalActions is a component that is used to render wrap the action buttons in a modal.
 */
export const ModalActions = styled(DialogActions)<TProps>`
  &.MuiDialogActions-root {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: 100%; /* Safari */
    grid-template-columns: ${({ columns = `1fr 1fr` }) => columns};
    grid-gap: ${MODAL_SPACES.sm};
    align-items: center;
    justify-content: flex-end;

    padding: 0px;
    width: ${({ fullWidth = false }) => (fullWidth ? `100%` : `fit-content`)};
    margin-left: ${({ fullWidth = false }) => (fullWidth ? `0px` : `auto`)};
    max-width: ${({ maxWidth = `unset` }) => maxWidth};
  }

  &.MuiDialogActions-spacing > :not(:first-child) {
    margin-left: 0px;
  }
`
