import { Instance, types } from 'mobx-state-tree'

import { isPartialMatch } from '../../helpers/filter'
import { RM } from '../../tools/ramda'

type TPredicateOptions = {
  exactMatch?: string[]
  partialMatch?: string[]
}

export const FilterInputModel = types
  .model('FilterInput')
  .props({
    value: types.optional(types.string, ''),
  })
  .views(self => ({
    getPredicate<T>({ exactMatch = [], partialMatch = [] }: TPredicateOptions) {
      const partialMatchPred = isPartialMatch(self.value)
      const predicates = [
        ...exactMatch.map(prop => [RM.propEq(prop, self.value), RM.T]),
        ...partialMatch.map(prop => [
          RM.propSatisfies(partialMatchPred, prop),
          RM.T,
        ]),
      ]

      return RM.cond(predicates as any) as (item: T) => boolean
    },
    getCodeAndNamePredicate() {
      return this.getPredicate({ partialMatch: ['code', 'nameLabel'] })
    },
  }))
  .actions(self => ({
    setValue(value: string) {
      self.value = value
    },
  }))

export interface TFilterInputModel extends Instance<typeof FilterInputModel> {}
