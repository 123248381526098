import Color from 'color'

import { Colors } from '../../styling'

const CHANNEL_WHITE = 255

// normalize the value into <0, 1> interval
const normalizeValue = (value: number) => {
  let normalizedValue = value

  if (normalizedValue < 0) {
    normalizedValue = 0
  } else if (normalizedValue > 1) {
    normalizedValue = 1
  }

  return normalizedValue
}

export const getContraColor = (
  inputColor: string,
  lightThreshold: number = 0.6,
) => {
  if (inputColor === Colors.transparent) {
    return Colors.black.main
  }

  return Color(inputColor).luminosity() < lightThreshold
    ? Colors.white.main
    : Colors.black.main
}

export const whiten = (inputColor: string, inputLight: number = 0) => {
  const color = Color(inputColor)
  const light = normalizeValue(inputLight)

  // newRed = oldRed + light * (255 - oldRed)
  const newColorChannels = color
    .rgb()
    .array()
    .map(originalColorChannel => {
      return (
        originalColorChannel + light * (CHANNEL_WHITE - originalColorChannel)
      )
    })

  return Color.rgb(newColorChannels)
    .rgb()
    .string()
}
