import { Instance, types } from 'mobx-state-tree'

export const RecipeListSortModel = types
  .model('RecipeListSort')
  .props({
    direction: types.optional(
      types.union(types.literal('asc'), types.literal('desc')),
      'asc',
    ),
    key: types.optional(
      types.union(
        types.literal('nameLabel'),
        types.literal('code'),
        types.literal('internalCode'),
      ),
      'nameLabel',
    ),
  })

  .actions(self => ({
    setSort(newSortKey: `nameLabel` | `code` | `internalCode`) {
      if (self.key === newSortKey) {
        const reversedDirection = self.direction === `asc` ? `desc` : `asc`

        self.direction = reversedDirection
        return
      }

      self.key = newSortKey
      self.direction = `asc`
    },
  }))

export interface TRecipeListSortModel
  extends Instance<typeof RecipeListSortModel> {}
