import * as React from 'react'

import styled from 'styled-components/macro'

import { SvgIconProps } from '@material-ui/core/SvgIcon'

const SCALE = `0.8em`

const StyledIcon = styled.div`
  width: 1em;
  height: 1em;
  display: grid;
  place-items: center;
  color: inherit;
`

// unusual typing to comply with the MUI icons

/**
 * icon for bolt external partner,
 * intended to be rendered on bolt brand color background
 */
export const IconBolt = (props: SvgIconProps): JSX.Element => {
  return (
    <StyledIcon>
      <svg
        height={SCALE}
        width={SCALE}
        viewBox="0 0 151 209"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M86.3989 159.436H49.6064V128.828H86.3989C94.8894 129.038 101.493 136.061 101.284 144.552C101.074 152.623 94.4701 159.227 86.3989 159.436ZM49.6064 49.2684H76.8601C85.3506 49.4781 91.9544 56.5011 91.7448 64.9917C91.5351 73.1679 84.9313 79.6668 76.8601 79.8765H49.6064V49.2684ZM131.472 98.3252C150.131 68.1364 140.801 28.5136 110.508 9.8553C100.34 3.56598 88.7049 0.316492 76.7552 0.21167H0.549805V208.388H86.294C121.829 208.388 150.55 179.562 150.55 144.028C150.55 126.942 143.736 110.485 131.472 98.3252Z"
          style={{ fill: `#ffffff` }}
        />
      </svg>
    </StyledIcon>
  )
}
