import { types } from 'mobx-state-tree'

import { BaseModel } from '../../../models/BaseModel'
import {
  SPECIFIC_BUSINESS_HOURS_MODES,
  TSpecificBusinessHoursMode,
} from '../businesshours.types'

export const BusinessHoursModel = BaseModel.named('BusinessHours')
  .props({
    specificMode: types.optional(
      types.enumeration(SPECIFIC_BUSINESS_HOURS_MODES.all),
      SPECIFIC_BUSINESS_HOURS_MODES.timeSpan,
    ),
  })

  .views(self => ({
    get isTimeSpanMode() {
      return self.specificMode === SPECIFIC_BUSINESS_HOURS_MODES.timeSpan
    },
  }))

  .actions(self => ({
    changeSpecificMode(mode: TSpecificBusinessHoursMode) {
      self.specificMode = mode
    },
  }))
