import * as React from 'react'

import { useMUpdateNotifications } from '../../../graph/generated'
import { TReadNotification } from '../models/NotificationsModel'
import { useNotificationsModel } from './useNotificationsModel'

/**
 * Contains isRead notifications update functions.
 * It is possible to markAllAsRead or set the isRead field of one notification.
 */
export const useUpdateNotifications = () => {
  const {
    unreadNotificationsIds,
    updateReadNotification,
    updateReadNotifications,
  } = useNotificationsModel()

  const [mUpdateNotifications] = useMUpdateNotifications()

  const markAllAsRead = React.useCallback(async () => {
    const { data } = await mUpdateNotifications({
      variables: {
        ids: unreadNotificationsIds,
        isRead: true,
      },
    })
    if (!data) return
    const updatedIds = data.updatedNotifications.map(
      notification => notification.id,
    )
    updateReadNotifications(updatedIds)
  }, [mUpdateNotifications, unreadNotificationsIds, updateReadNotifications])

  const markSingleRead = React.useCallback(
    async ({ id, isRead = false }: TReadNotification) => {
      const { data } = await mUpdateNotifications({
        variables: {
          ids: [id],
          isRead,
        },
      })
      if (!data) return
      const isEmpty = data.updatedNotifications.length === 0
      if (isEmpty) return

      const updatedId = data.updatedNotifications[0].id
      updateReadNotification({
        id: updatedId,
        isRead: isRead,
      })
    },
    [mUpdateNotifications, updateReadNotification],
  )

  return { markAllAsRead, markSingleRead }
}
