import { Trans } from '@lingui/macro'

import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { FlexBox } from '../../../atoms/Box/Box'
import { FONT_WEIGHTS, getSpaceValue } from '../../../designSystem'
import { Colors } from '../../../styling'
import { Link } from '../../../ui/Link'
import { Separator } from '../../../ui/Separators'
import { Text } from '../../../ui/Typography'
import { useNotificationsModel } from '../hooks/useNotificationsModel'
import { useUpdateNotifications } from '../hooks/useUpdateNotifications'

type TProps = NoChildren

/**
 * Component for notifications header.
 * Displays "Notifications" headline,
 * and if there are unread notifications, textButton to mark them read.
 */
const NotificationsHeaderComponent: React.FC<TProps> = () => {
  const { unreadNotificationsCount } = useNotificationsModel()
  const { markAllAsRead } = useUpdateNotifications()

  const hasUnreadNotifications = unreadNotificationsCount !== 0

  return (
    <>
      <FlexBox
        justifyContent={`space-between`}
        alignItems={`center`}
        bgcolor={Colors.grey.dark}
        color={Colors.white.main}
        padding={getSpaceValue(`s16`)}
      >
        {/* TODO - change to heading when the ui component is created  */}
        <Text fontSize={`20px`} fontWeight={FONT_WEIGHTS.bold} lineHeight={1.2}>
          <Trans>Notifications</Trans>
        </Text>
        {hasUnreadNotifications && (
          <Link
            onClick={markAllAsRead}
            color={Colors.white.main}
            colorHover={Colors.white.pure}
            text={{ fontWeight: FONT_WEIGHTS.medium, fontSize: `14px` }}
          >
            <Trans>Mark all as read</Trans>
          </Link>
        )}
      </FlexBox>
      <Separator.Solid color={Colors.grey.darkMedium} />
    </>
  )
}

export const NotificationsHeader = observer(NotificationsHeaderComponent)
