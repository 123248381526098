import * as React from 'react'

import { useModalControl } from '../../../components/Modals/useModalControl'
import { DevPrintContentPreviewModal } from './DevPrintContentPreviewModal'

export const useDevPrintContentPreviewModal = () => {
  const [previewModal, presentPreviewModal, dismissModal] = useModalControl(
    DevPrintContentPreviewModal,
  )

  // when the tester asks for it, present a modal with test result preview
  const showPrintPreview = React.useCallback(
    (printContentBase64: string) => {
      presentPreviewModal({
        printContentBase64,
        onClose: dismissModal,
      })
    },
    [dismissModal, presentPreviewModal],
  )

  return {
    previewModal,
    showPrintPreview,
  }
}
