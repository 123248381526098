import { TRootModel } from '-app/root'
import debug from 'debug'
import { getPath, getRoot, types } from 'mobx-state-tree'

export const BaseModel = types.model().views(self => ({
  get log() {
    return debug(`model${getPath(self)}`)
  },

  get root(): TRootModel {
    return getRoot(self)
  },
}))
