import { OperationVariables } from '@apollo/react-common'
import {
  QueryHookOptions as ApolloQueryHookOptions,
  useQuery as useApolloQuery,
} from '@apollo/react-hooks'
import { useApolloDefenderReporter } from '@speedlo/graphql'

import { DEFAULT_Q_POLICY } from './apolloHooks.config'
import { useLoadingStash } from './useLoadingStash'

type TQueryDocument = Parameters<typeof useApolloQuery>[0]

// reexport because code-gen takes the type from the same file as the hook
export type QueryHookOptions<TData, TVariables> = ApolloQueryHookOptions<
  TData,
  TVariables
>

/**
 * Apollo Client’s `useQuery` wrapper with custom error handling
 * and some additional features (getStash, ...)
 */
export const useQuery = <TData = any, TVariables = OperationVariables>(
  query: TQueryDocument,
  options?: QueryHookOptions<TData, TVariables>,
) => {
  const result = useApolloQuery<TData, TVariables>(query, {
    fetchPolicy: DEFAULT_Q_POLICY,
    ...options,
  })

  useApolloDefenderReporter(result.error, query, options?.variables)

  const getStash = useLoadingStash<TData>(result.loading, result.data)

  return {
    ...result,
    /**
     * Wrapper around `useLoadingStash` to obtain previous result
     * while next one is loading.
     *
     * Can yield `null` initially before first result is fetched.
     *
     * The `skip` argument is taken directly from options and doesn't
     * need to be checked.
     *
     * Can be called multiple times with different resolvers.
     */
    getStash<TResult>(resolver: (data: TData) => TResult) {
      return getStash(resolver, options?.skip)
    },
    get hasData() {
      return this.data !== undefined
    },
    get hasError() {
      return this.error !== undefined
    },
  }
}
