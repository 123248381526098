import { Trans } from '@lingui/macro'
import { WarningTwoTone } from '@material-ui/icons'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import styled from 'styled-components'

import connectionWarningSound from '../assets/connectionWarning.mp3'
import { isNetworkOffline } from '../helpers/offline'
import { useIntervalSound } from '../hooks/useIntervalSound'
import { Colors } from '../styling'
import { Modal } from '../ui/Modal'

type TProps = NoChildren

const MessageStyled = styled.div`
  padding: 1rem;
  background-color: ${Colors.red.main} !important;
  font-size: 2rem;

  & svg {
    position: relative;
    top: 0.3rem;
    padding-right: 0.5rem;
  }
`

export const ConnectionWarning = observer<TProps>(() => {
  const audio = useIntervalSound({
    intervalSec: 30,
    shouldPlay: isNetworkOffline(),
    soundUrl: connectionWarningSound,
  })

  return (
    <>
      {audio}
      <Modal.Wrapper
        size={`md`}
        open={isNetworkOffline()}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        <MessageStyled>
          <WarningTwoTone fontSize="large" />
          <Trans>Internet connection is not available</Trans>
        </MessageStyled>
      </Modal.Wrapper>
    </>
  )
})
