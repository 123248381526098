import { Instance, types } from 'mobx-state-tree'

import { intersectionWith } from '../../../helpers/intersection'
import { BaseModel } from '../../../models/BaseModel'
import { comparePrinters } from '../../../screens/settings/PrintSettings/utils/printSettings.utils'
import { TPrintRecord } from '../components/PrintWatch'
import { TConnectedPrinter, TPcInfo } from '../hooks/useSpeedloPrinter'
import { TRegisteredPrinterLite } from '../types/printWatch.types'

type TVolatileProps = {
  pcInfo: Nullable<TPcInfo>
  canPrint: Nullable<boolean>
  failedRecords: RoA<TPrintRecord>
  /**
   * physical printers connected to the PC
   */
  connectedPrinters: Nullable<RoA<TConnectedPrinter>>
  /**
   * virtual printers registered in our system that the user has access to
   */
  registeredPrinters: Nullable<RoA<TRegisteredPrinterLite>>
}

export const PrintWatchModel = BaseModel.named(`PrintWatch`)
  .props({
    /**
     * print records that were already requested to be printed (via printing service) but we don’t know the print result yet
     */
    requestedRecordIds: types.array(types.string),
  })

  .volatile<TVolatileProps>(() => ({
    pcInfo: null,
    canPrint: null,
    failedRecords: [],
    connectedPrinters: null,
    registeredPrinters: null,
  }))

  .views(self => ({
    /**
     * returns list of printers that are both registered on the BE for one of the user’s branches
     * and also connected to the PC (provided via printing service)
     */
    get availablePrinters() {
      if (self.connectedPrinters === null || self.registeredPrinters === null) {
        return null
      }

      if (
        self.connectedPrinters.length === 0 ||
        self.registeredPrinters.length === 0
      ) {
        return []
      }

      const connectedReadyPrinters = self.connectedPrinters.filter(printer => {
        return !printer.isOffline
      })

      return intersectionWith(comparePrinters)(
        self.registeredPrinters,
        connectedReadyPrinters,
      )
    },
  }))

  .actions(self => ({
    setCanPrint(canPrint: boolean) {
      self.canPrint = canPrint
    },

    setConnectedPrinters(connectedPrinters: RoA<TConnectedPrinter>) {
      self.connectedPrinters = connectedPrinters
    },

    setRegisteredPrinters(registeredPrinters: RoA<TRegisteredPrinterLite>) {
      self.registeredPrinters = registeredPrinters
    },

    setPcInfo(pcInfo: TPcInfo) {
      self.pcInfo = pcInfo
    },
  }))

  .actions(self => ({
    // printing was requested for this record – save it so we won’t ask again
    markRecordAsRequested(requestedRecordId: ID) {
      self.requestedRecordIds.push(requestedRecordId)
    },

    // print request for this record failed – save it & ask user what to do with it
    addFailedRecord(printRecord: TPrintRecord) {
      const alreadyMarked = self.failedRecords.find(record => {
        return record.id === printRecord.id
      })

      if (alreadyMarked) {
        return
      }

      self.failedRecords = [...self.failedRecords, printRecord]
    },

    // user has somehow resolved the failed print record – no need to remember it anymore
    removeFailedRecord(recordId: ID) {
      self.failedRecords = self.failedRecords.filter(record => {
        return record.id !== recordId
      })
    },

    // printing of this record was successful, yay
    markRecordAsDone(doneRecordId: ID) {
      self.requestedRecordIds.remove(doneRecordId)
    },

    // printing of this record was unsuccessful, yay
    recordPrintingFailed(doneRecordId: ID) {
      self.requestedRecordIds.remove(doneRecordId)
    },
  }))

export interface TPrintWatchModel extends Instance<typeof PrintWatchModel> {}
