import { MINUTE, useTimer } from '@speedlo/hooks'
import { differenceInMinutes } from 'date-fns'

type TOptions = {
  isCanceledOrder?: boolean
  handoverTime?: Nullable<Date>
  handedOverTime?: Nullable<Date>
}

/**
 * returns minutes left until expected handover time
 * based on order state and times
 * also uses a _live_ timer to keep this value up to date
 */
export const useHandoverTimeMinutes = ({
  handoverTime,
  handedOverTime,
  isCanceledOrder,
}: TOptions) => {
  const orderNotHandedOrCanceled = !Boolean(handedOverTime || isCanceledOrder)

  const now = useTimer({ interval: MINUTE, enabled: orderNotHandedOrCanceled })

  const minutesUntilHandover = handoverTime
    ? differenceInMinutes(handoverTime, handedOverTime ?? now)
    : null

  return { minutesUntilHandover }
}
