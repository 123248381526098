import { Instance, types } from 'mobx-state-tree'

import { TFCompanyBranch, TLanguageEnum } from '../../../graph/generated'
import { BaseModel } from '../../../models/BaseModel'
import { RM } from '../../../tools/ramda'
import { TBranch, TBrand } from '../headerText.types'

const defaultLanguage = 'CS'
const defaultSupportedLanguages = []

/**
 * Model for promo header text.
 * Contains brandId, branchId and their: promoHeaderText in default language,
 * default language and supported languages.
 */
export const HeaderTextModel = BaseModel.named('HeaderText')
  .props({
    brandId: types.maybeNull(types.string),
    branchId: types.maybeNull(types.string),
  })

  .actions(self => ({
    invalidate() {
      self.brandId = null
      self.branchId = null
    },
  }))

  .views(self => ({
    get branch(): Nullable<TBranch> {
      return (
        (self.branchId &&
          self.root.user.branches.find(RM.propEq('id', self.branchId))) ||
        null
      )
    },

    get brand(): Nullable<TBrand> {
      return (
        (self.brandId &&
          self.root.user.brands.find(RM.propEq('id', self.brandId))) ||
        null
      )
    },

    get brandLanguage(): TLanguageEnum {
      const brand = this.brand
      return brand ? brand.defaultLanguage : defaultLanguage
    },

    get brandSupportedLanguages(): RoA<TLanguageEnum> {
      const brand = this.brand
      return brand ? brand.supportedLanguages : defaultSupportedLanguages
    },

    get branchLanguage(): TLanguageEnum {
      const branch = this.branch
      return branch ? branch.defaultLanguage : defaultLanguage
    },

    get branchSupportedLanguages(): RoA<TLanguageEnum> {
      const branch = this.branch
      return branch ? branch.supportedLanguages : defaultSupportedLanguages
    },

    get brandBranches(): RoA<TFCompanyBranch> {
      const branches = self.root.user.branches
      if (self.brandId === null) return branches
      return branches.filter(RM.pathEq(['brand', 'id'], self.brandId))
    },
  }))

  .actions(self => ({
    setBranchFilter(branch: TBranch) {
      self.branchId = branch.id
      self.brandId = branch.brand.id
    },

    setBrandFilter(brand: TBrand, brandBranches: TBranch[]) {
      self.brandId = brand.id

      if (!self.branch || self.branch.brand.id !== brand.id) {
        self.branchId =
          (brandBranches.length === 1 && RM.head(brandBranches).id) || null
      }
    },

    clearBranchFilter() {
      self.branchId = null
    },

    clearBrandFilter() {
      self.brandId = null
      self.branchId = null
    },
  }))

export interface THeaderTextModel extends Instance<typeof HeaderTextModel> {}
