import * as React from 'react'
import { CreateExternalDelivery } from './CreateExternalDelivery'
import { CreateExternalDeliveryWithPickupTime } from './CreateExternalDeliveryWithPickupTime'

import { ExternalDeliveryUnavailable } from './ExternalDeliveryUnavailable'
import { useEstimateExternalDeliveryCtx } from '../../contexts/EstimateExternalDeliveryProvider'
import { ExternalDeliveryEstimates } from './ExternalDeliveryEstimates'
import { useCreateExternalDeliveryCtx } from '../../contexts/CreateExternalDeliveryProvider'

type TProps = {
  withPickupTime?: boolean
}

/**
 * Content for external delivery creation modal.
 *
 * Handles
 * - loading of external delivery estimates
 * - displaying error messages if external delivery is unavailable
 * - displaying external delivery creation form/confirm modal
 */
export const CreateExternalDeliveryContent: React.FC<TProps> = ({
  withPickupTime,
}) => {
  const { estimates, isError } = useEstimateExternalDeliveryCtx()
  const { onClose } = useCreateExternalDeliveryCtx()

  // Q failed -> we can't create an external delivery, as the M would probably fail too
  // close the modal, the automatic error handling will take care of displaying the error
  if (isError) {
    onClose()
    return null
  }

  //  Q returned an error message within the data -> we can't create an external delivery
  const hasErrorMessage = estimates?.errorMessage || estimates?.rawErrorMessage

  if (hasErrorMessage) {
    return <ExternalDeliveryUnavailable />
  }

  // Q was successful & returned no error message -> we can create an external delivery

  if (withPickupTime) {
    return (
      <CreateExternalDeliveryWithPickupTime
        externalDeliveryEstimates={<ExternalDeliveryEstimates />}
      />
    )
  }

  return (
    <CreateExternalDelivery
      externalDeliveryEstimates={<ExternalDeliveryEstimates />}
    />
  )
}
