import styled from 'styled-components/macro'

import { FONT_WEIGHTS } from '../../../designSystem'
import { Colors } from '../../../styling'

type TStyledProps = {
  textAlign?: 'left' | 'center' | 'right'
  fontSize?: string
}

export const AppNameScreenMessage = styled.p<TStyledProps>`
  color: ${Colors.grey.lightish};
  font-weight: ${FONT_WEIGHTS.medium};
  text-align: ${({ textAlign = `left` }) => textAlign};
  font-size: ${({ fontSize = `1rem` }) => fontSize};
`
