import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { getRadiusValue } from '../../../../designSystem'
import { OUTER_BOX_SHADOWS } from '../../../../designSystem/shadows/boxShadowValues'
import { TFNotification } from '../../../../graph/generated'
import { Colors } from '../../../../styling'
import { Popover } from '../../../../ui/Popover'
import { Separator } from '../../../../ui/Separators'
import { useNotificationsModel } from '../../hooks/useNotificationsModel'
import { NotificationsHeader } from '../NotificationsHeader'
import { Notification } from './Notification'

type TProps = NoChildren & {
  isOpen: boolean
}

/**
 * Icon for notifications with unread notifications counter.
 * On click opens a popup with all notifications.
 */
const NotificationsPopoverComponent: React.FC<TProps> = () => {
  const { notifications } = useNotificationsModel()

  return (
    <Popover
      borderRadius={getRadiusValue(`card`)}
      boxShadow={OUTER_BOX_SHADOWS.bottomLong}
      bgColor={Colors.transparent}
      maxHeight={'50vh'}
      maxWidth={`27rem`}
      popoverHeader={<NotificationsHeader />}
      right={`3px`}
      top={`60px`}
      padding={`s0`}
      overflow={`hidden`}
      isScrollable
      isFixed
    >
      {notifications.map((notification: TFNotification, index: number) => {
        const isFirst = index === 0
        return (
          <React.Fragment key={notification.id}>
            {!isFirst && <Separator.Solid color={Colors.grey.darkMedium} />}
            <Notification {...notification} />
          </React.Fragment>
        )
      })}
    </Popover>
  )
}

export const NotificationsPopover = observer(NotificationsPopoverComponent)
