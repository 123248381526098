import { GroupType } from 'react-select'

import {
  TFProductTagCategory,
  TRecipeTagCategoryTypeEnum,
} from '../../../../graph/generated'
import {
  getGroupItemsByHash,
  TGroupedItem,
} from '../../../../helpers/groupItems'
import { sortCollection } from '../../../../helpers/sort'

type TGroupTagBase = {
  category?: Nullable<TFProductTagCategory>
}

type TGroupOptions = {
  /**
   * option to prefix the group label with something
   * useful when multiple grouped arrays are mixed into one select
   */
  groupLabelPrefix?: string
}

/**
 * groups provided product tags by their category type into SelectControl groups
 * so that the select options would be better organized
 */
export const createProductTagSelectGroups = <TProductTag extends TGroupTagBase>(
  productTags: RoA<TProductTag>,
  options?: TGroupOptions,
) => {
  // group tags by their category type
  const tagsByCategory = groupProductTags(productTags)
  // sort those categories by usage/priority
  const sortedCategories = sortCollection(tagsByCategory, sortByPriority)
  // transform them into SelectControl group format
  const optionGroups = sortedCategories.map(categoryToGroup(options))

  return optionGroups
}

// * HELPERS

// prettier-ignore
const getTagCategory = <TProductTag extends TGroupTagBase>(tag: TProductTag) => {
  return tag.category?.enum ?? ``
}

const groupProductTags = getGroupItemsByHash(getTagCategory)

// sort product tag groups as type > property > special > ...
// because that seems to follow the importance level of those categories
// (most important first)
const TAG_CATEGORY_ORDER: RoA<TRecipeTagCategoryTypeEnum> = [
  `WARE_TYPE`,
  `WARE_PROPERTY`,
  `SPECIAL_FLAG`,
]

const sortByPriority = <TProductTag extends TGroupTagBase>(
  categoryA: TGroupedItem<TProductTag>,
  categoryB: TGroupedItem<TProductTag>,
) => {
  const categoryAEnum = categoryA.firstItem.category?.enum
  const categoryBEnum = categoryB.firstItem.category?.enum

  const categoryAIndex = categoryAEnum
    ? TAG_CATEGORY_ORDER.indexOf(categoryAEnum)
    : -1
  const categoryBIndex = categoryBEnum
    ? TAG_CATEGORY_ORDER.indexOf(categoryBEnum)
    : -1

  return categoryAIndex - categoryBIndex
}

// helper fnc to combine groupLabelPrefix & group label
const buildGroupLabel = (options?: TGroupOptions) => (groupLabel?: string) => {
  const label = groupLabel ?? ``

  return options?.groupLabelPrefix
    ? `${options.groupLabelPrefix} – ${label}`
    : label
}

// returns a fnc for mapping over category array, transforming each item to SelectControl group type
const categoryToGroup = (options?: TGroupOptions) => {
  const getGroupLabel = buildGroupLabel(options)

  // prettier-ignore
  return <TProductTag extends TGroupTagBase>(category: TGroupedItem<TProductTag>): GroupType<TProductTag> => ({
    options: category.items,
    label: getGroupLabel(category.firstItem?.category?.label),
  })
}
