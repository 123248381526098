import { useRoot } from '-app/root'

export function useAccessGuard(
  queries: string[],
  mutations: string[],
): boolean {
  const { user } = useRoot()

  return queries.every(user.canQuery) && mutations.every(user.canMutate)
}

export function useCanQuery(...queries: string[]) {
  return useAccessGuard(queries, [])
}

export function useCanMutate(...mutations: string[]) {
  return useAccessGuard([], mutations)
}
