import { Trans } from '@lingui/macro'

import * as React from 'react'

import { FlexBox } from '../../../../atoms/Box/Box'
import { getSpaceValue } from '../../../../designSystem'
import { TFNotification } from '../../../../graph/generated'
import { formatDateOrTodaysTime } from '../../../../helpers/formats'
import { Colors } from '../../../../styling'
import { LinkWithIcon } from '../../../../ui/Link'
import { Text } from '../../../../ui/Typography'
import { useUpdateNotifications } from '../../hooks/useUpdateNotifications'

type TProps = NoChildren &
  Pick<TFNotification, 'id' | 'isRead' | 'createdAt' | 'url'>

/**
 * Footer for a notification.
 * Displays date when the notification was created
 * and link to the corresponding site.
 */
export const NotificationFooter: React.FC<TProps> = ({
  id,
  isRead,
  createdAt,
  url,
}) => {
  const { markSingleRead } = useUpdateNotifications()
  const date = formatDateOrTodaysTime(createdAt)

  const updateRead = () => {
    if (isRead) return
    markSingleRead({ id, isRead: true })
  }

  return (
    <FlexBox justifyContent={`space-between`} marginTop={getSpaceValue(`s8`)}>
      <Text color={Colors.grey.lightish} fontSize={`12px`}>
        {date}
      </Text>
      {url && (
        <LinkWithIcon
          href={url}
          onClick={updateRead}
          target={`_blank`}
          color={Colors.grey.lightish}
          colorHover={Colors.grey.lightest}
          text={{ fontSize: `12px` }}
        >
          <Trans>View more</Trans>
        </LinkWithIcon>
      )}
    </FlexBox>
  )
}
