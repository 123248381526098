import { getMarginValue, TMargin } from './margin'
import { TSpaceUnit } from './space'

/**
 * props or options related to element margin
 */
export type TMarginProps = {
  // general value
  margin?: TMargin
  // direction values
  marginVertical?: TMargin
  marginHorizontal?: TMargin
  // side values
  marginTop?: TMargin
  marginRight?: TMargin
  marginBottom?: TMargin
  marginLeft?: TMargin
}

/**
 * returns a value for top margin of an element
 * based on provided margin props,
 * prefers `side` > `direction` > `general` value
 */
export const getMarginTop = (
  { marginTop, marginVertical, margin }: TMarginProps,
  unit?: TSpaceUnit,
) => {
  const marginTopKey = marginTop ?? marginVertical ?? margin
  return marginTopKey ? getMarginValue(marginTopKey, unit) : undefined
}

/**
 * returns a value for right margin of an element
 * based on provided margin props,
 * prefers `side` > `direction` > `general` value
 */
export const getMarginRight = (
  { marginRight, marginHorizontal, margin }: TMarginProps,
  unit?: TSpaceUnit,
) => {
  const marginRightKey = marginRight ?? marginHorizontal ?? margin
  return marginRightKey ? getMarginValue(marginRightKey, unit) : undefined
}

/**
 * returns a value for bottom margin of an element
 * based on provided margin props,
 * prefers `side` > `direction` > `general` value
 */
export const getMarginBottom = (
  { marginBottom, marginVertical, margin }: TMarginProps,
  unit?: TSpaceUnit,
) => {
  const marginBottomKey = marginBottom ?? marginVertical ?? margin
  return marginBottomKey ? getMarginValue(marginBottomKey, unit) : undefined
}

/**
 * returns a value for left margin of an element
 * based on provided margin props,
 * prefers `side` > `direction` > `general` value
 */
export const getMarginLeft = (
  { marginLeft, marginHorizontal, margin }: TMarginProps,
  unit?: TSpaceUnit,
) => {
  const marginLeftKey = marginLeft ?? marginHorizontal ?? margin
  return marginLeftKey ? getMarginValue(marginLeftKey, unit) : undefined
}

/**
 * returns values of margin for all sides of an element
 * based on provided margin props,
 * prefers `side` > `direction` > `general` value for each side
 */
export const getMarginSideValues = (marginProps: TMarginProps) => {
  const marginTop = getMarginTop(marginProps)
  const marginRight = getMarginRight(marginProps)
  const marginBottom = getMarginBottom(marginProps)
  const marginLeft = getMarginLeft(marginProps)

  return { marginTop, marginRight, marginBottom, marginLeft }
}
