import * as React from 'react'

import { TPrinterTypeEnum } from '../../../graph/generated'
import { i18n } from '../../../i18n'
import { useAppSettings } from '../../../screens/settings/models/useAppSettings'
import { TPrintRecord } from '../components/PrintWatch'
import { convertHtmlToPng } from '../utils/convertHtmlToPng'

// *** SPEEDLO PRINTER API TYPES

// response type of `/computer` endpoint
type TSPComputer = {
  macAddress: string
}

// response type of the `/printers` endpoint is an array of these
type TSPPrinterInList = {
  name: string
  status?: string
  printerId: string
  isOffline: boolean // true = not connected, offline or can’t print
}

// possible options for the `/print` POST request body
type TSPPrintRequestBody = {
  printerId: ID
  printRecordId: ID
  printPngImage?: string // base 64 encoded
}

// response type of the `/print` endpoint
type TSPPrintRequestResponse = {
  errors: RoA<string> // error messages – is an array? is it undefined?
  isSuccessful: boolean // successfully added to the printers’ print queue
}

// response type of the `/print/:recordId` endpoint
type TSPPrintStatusResponse = {
  status: null // ??
  isSuccessful: boolean // the record was printed
}

type TSPLogInList = string // date as YYY-MM-DD

type TSPLog = {
  content: string // content of the log file
  contentType: string // eg. text/html; charset=utf-8
}

export type TPcInfo = TSPComputer
export type TConnectedPrinter = TSPPrinterInList

const extractDate = (date: Date) => date.toISOString().substr(0, 10) // YYY-MM-DD

const SERVICE_PORT = 5000
const SERVICE_BASE_URL = `http://localhost:${SERVICE_PORT}`

const SERVICE_ENDPOINTS = {
  PC_INFO: `computer`, // GET
  CONNECTED_PRINTER_LIST: `printers`, // GET

  REQUEST_PRINT: `print`, // POST
  GET_PRINT_STATUS: (printRecordId: string) => `print/${printRecordId}`, // GET

  GET_ALL_LOG_FILES: `printlog`, // GET
  GET_LOG_FILE: (logDate: Date) => `printlog/${extractDate(logDate)}`, // GET
  DELETE_LOG_FILE: (logDate: Date) => `printlog/${extractDate(logDate)}`, // DELETE -> 200 (deleted), 400 (wrong format of the date), 204 (no such log file)
}

// for each printer type, define how wide (in px) should the requested image be
type TImageWidths = Record<TPrinterTypeEnum, number>

// perfect, measured precisely in trial by combat
const IMAGE_WIDTHS: TImageWidths = {
  KIOSK: 580, // not used here, has custom transforms
  W80MM: 580,
  W58MM: 380,
}

const baseHeaders = {
  Accept: `*/*`,
  'Content-Type': `application/json`,
}

/**
 * connection between this `speedlo admin` app and the `speedlo printer` app that can run on the user PC & provide support for receipt printing
 * this can also simulate the Printitko interface (successful & unsuccessful printings) in test mode on DEV
 * (in such case, small toasts are presented in Admin instead of actual printing)
 */
export const useSpeedloPrinter = () => {
  /**
   * get some basic info about the PC this is running on (mainly the MAC address to bind the print queue-printer bindings to this specific PC)
   */
  const fetchPcInfo = React.useCallback(async (): Promise<TSPComputer> => {
    const response = await fetch(
      `${SERVICE_BASE_URL}/${SERVICE_ENDPOINTS.PC_INFO}`,
      {
        method: `GET`,
        headers: {
          Accept: `application/json; charset=utf-8`,
        },
      },
    )

    return response.json()
  }, [])

  /**
   * get printers that are registered in the operating system of this PC
   * from those we should be able to recognize connected printers that user can use
   * (which we’d like to get print records for)
   */
  const fetchConnectedPrinters = React.useCallback(async (): Promise<
    RoA<TSPPrinterInList>
  > => {
    const response = await fetch(
      `${SERVICE_BASE_URL}/${SERVICE_ENDPOINTS.CONNECTED_PRINTER_LIST}`,
      {
        method: `GET`,
        headers: {
          Accept: `application/json; charset=utf-8`,
        },
      },
    )

    return response.json()
  }, [])

  /**
   * ask the service to insert the print record into the operating system printing queue
   * ! the success here means only that the printing was requested, not that the record was printed!
   */
  const postPrintRequest = React.useCallback(
    async (printRecord: TPrintRecord): Promise<TSPPrintRequestResponse> => {
      try {
        const convertResult = await convertHtmlToPng({
          base64Html: printRecord.renderer.content,
          requiredImageWidth:
            IMAGE_WIDTHS[printRecord.printer.printerType.enum],
        })

        if (!convertResult.isSuccess) {
          return {
            isSuccessful: false,
            errors: [i18n.t`Image converting failed`],
          }
        }

        const printRequestBody: TSPPrintRequestBody = {
          printRecordId: printRecord.id,
          printerId: printRecord.printer.MAC,
          printPngImage: convertResult.base64Image,
        }

        const stringifiedPrintRequestBody = JSON.stringify(printRequestBody)

        const response = await fetch(
          `${SERVICE_BASE_URL}/${SERVICE_ENDPOINTS.REQUEST_PRINT}`,
          {
            method: `POST`,
            body: stringifiedPrintRequestBody,
            headers: {
              ...baseHeaders,
              'Content-Length': String(stringifiedPrintRequestBody.length),
            },
          },
        )

        return response.json()
      } catch (error) {
        return {
          isSuccessful: false,
          errors: [i18n.t`Record couldn’t be printed`],
        }
      }
    },
    [],
  )

  /**
   * ask the service how printing of this specific record went
   * the service should get it from the operating system printing queue or from its logs
   */
  const getPrintResult = React.useCallback(
    async (printRecordId: ID): Promise<TSPPrintStatusResponse> => {
      try {
        const response = await fetch(
          `${SERVICE_BASE_URL}/${SERVICE_ENDPOINTS.GET_PRINT_STATUS(
            printRecordId,
          )}`,
          {
            method: `GET`,
            headers: baseHeaders,
          },
        )

        return response.json()
      } catch (error) {
        return {
          isSuccessful: false,
          status: null,
        }
      }
    },
    [],
  )

  /**
   * returns list of dates (YYY-MM-DD) for which the logs are available
   * these strings can then be used to ask for specific log’s content via next api
   */
  const getLogs = React.useCallback(async (): Promise<RoA<TSPLogInList>> => {
    try {
      const response = await fetch(
        `${SERVICE_BASE_URL}/${SERVICE_ENDPOINTS.GET_ALL_LOG_FILES}`,
        {
          method: `GET`,
          headers: baseHeaders,
        },
      )

      return response.json()
    } catch (error) {
      return []
    }
  }, [])

  /**
   * returns a content of a log file for provided date (if available)
   */
  const getLog = React.useCallback(async (date: Date): Promise<TSPLog> => {
    try {
      const response = await fetch(
        `${SERVICE_BASE_URL}/${SERVICE_ENDPOINTS.GET_LOG_FILE(date)}`,
        {
          method: `GET`,
          headers: baseHeaders,
        },
      )

      // TODO response HTTP status (200, 204, 400) ?
      return response.json()
    } catch (error) {
      return {
        content: ``,
        contentType: `text/html; charset=utf-8`,
      }
    }
  }, [])

  /**
   * asks the service to delete the log file for specified date (if it exists)
   */
  const deleteLog = React.useCallback(async (date: Date) => {
    try {
      const response = await fetch(
        `${SERVICE_BASE_URL}/${SERVICE_ENDPOINTS.DELETE_LOG_FILE(date)}`,
        {
          method: `GET`,
          headers: baseHeaders,
        },
      )

      // TODO response HTTP status (200, 204, 400)
      return response.json()
    } catch (error) {
      // TODO failed
      return
    }
  }, [])

  return {
    getLog,
    getLogs,
    deleteLog,
    fetchPcInfo,
    getPrintResult,
    postPrintRequest,
    fetchConnectedPrinters,
  }
}

// * MOCKS for testing

// just some mocked data used in testing mode
export const mockedConnectedPrinters: RoA<TSPPrinterInList> = [
  {
    name: 'Harry Potter Laser Jet',
    status: 'Unknown',
    printerId: 'HP_00AA23',
    isOffline: false,
  },
  {
    // status: 'Degraded',
    status: 'Unknown',
    name: 'Hermiona Ink',
    printerId: 'HI_0023QQ',
    isOffline: false,
  },
  {
    name: 'Ron Bon',
    status: 'Unknown',
    printerId: 'RB_0032GG',
    isOffline: false,
  },
]

export const useMockedPc = () => {
  const { demoPcMac } = useAppSettings()

  if (!demoPcMac) {
    return { mockedPC: null }
  }

  const mockedPC: TSPComputer = {
    macAddress: demoPcMac,
  }

  return {
    mockedPC,
  }
}

export const mockedSuccessfulPrintingResponse: TSPPrintRequestResponse = {
  isSuccessful: true,
  errors: [],
}

export const mockedUnsuccessfulPrintingResponse: TSPPrintRequestResponse = {
  isSuccessful: false,
  errors: [`Some print error`],
}
