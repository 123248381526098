import { i18nMark } from '@lingui/core'
import { isSameDay } from 'date-fns'
import { format } from 'date-fns/fp'
import numeral from 'numeral'

import { TFAddress, TFCompanyBranch } from '../graph/generated'
import { i18n } from '../i18n'
import { formatWithLocale } from '../tools/datefns'

export const fullDateFormat = 'iiii PPP'
export const formatFullDate = formatWithLocale(fullDateFormat)

export const fullDateTimeFormat = 'iiii PPPp'
export const formatFullDateTime = formatWithLocale(fullDateTimeFormat)

export const dateFormat = 'd. M. yyyy'
export const formatDate = formatWithLocale(dateFormat)

export const dateTimeFormat = 'd. M. yyyy, p'
export const formatDateTime = formatWithLocale(dateTimeFormat)

export const systemTimeFormat = 'H:mm'
export const formatSystemTime = format(systemTimeFormat)

export const timeFormat = 'p'
export const formatTime = formatWithLocale(timeFormat)

/**
 * Format a date and time. If the provided date is the same as today, return a formatted
 * time with "today." Otherwise, return a formatted date.
 */
export const formatDateOrTodaysTime = (
  dateTime: Date,
  today: Date = new Date(),
) => {
  if (isSameDay(dateTime, today)) {
    const today = i18nMark('today')
    const time = formatTime(dateTime)
    return i18n._(today) + ' ' + time
  }
  return formatDate(dateTime)
}

export const formatTimeWithoutTodayDate = (
  dateTime: Date,
  today: Date = new Date(),
) =>
  isSameDay(dateTime, today)
    ? formatTime(dateTime)
    : formatFullDateTime(dateTime)

export const threeDecimalsFixedFormat = '0[.]000'
export const threeDecimalsFormat = '0[.][000]'
export const sixDecimalsFormat = '0[.][000000]'
export const twoDecimalsFormat = '0[.][00]'
export const twoDecimalsFixedFormat = '0[.00]'

export const formatNumber = (
  num: string | number,
  numberFormat: string = threeDecimalsFixedFormat,
) => numeral(num).format(numberFormat)

export const priceFormat = '0,0.[00]'
export const priceFormatFixed = '0,0.00'
export const formatPrice = (
  price: string | number,
  currencyCode?: string,
  format = priceFormat,
) => {
  const priceNumber = numeral(price).format(format)
  return currencyCode ? `${priceNumber} ${currencyCode}` : priceNumber
}

/**
 * formats the GQL address fragment into two line format
 */
export const formatAddressIntoLines = ({
  city,
  state,
  venue,
  street,
  houseNumber,
}: TFAddress) => {
  const hasCity = Boolean(city)
  const hasVenue = Boolean(venue)
  const hasState = Boolean(state)
  const hasStreet = Boolean(street)
  const hasHouseNumber = Boolean(houseNumber)

  const streetWithHouseNumber = hasHouseNumber
    ? `${street} ${houseNumber}`
    : `${street}`

  if (hasCity && hasStreet) {
    return { firstLine: streetWithHouseNumber, secondLine: city }
  }

  if (hasCity && hasVenue) {
    return { firstLine: venue, secondLine: city }
  }

  if (hasCity && hasHouseNumber && hasState) {
    return { firstLine: `${city} ${houseNumber}`, secondLine: state }
  }

  if (hasCity && hasHouseNumber) {
    return { firstLine: `${city} ${houseNumber}`, secondLine: null }
  }

  if (hasCity) {
    return { firstLine: city, secondLine: null }
  }

  if (hasState) {
    return { firstLine: state, secondLine: null }
  }

  return { firstLine: null, secondLine: null }
}

/**
 * formats two address lines into a single string
 */
export const formatAddressFromLines = ({
  firstLine,
  secondLine,
}: ReturnType<typeof formatAddressIntoLines>) => {
  if (firstLine && secondLine) {
    return `${firstLine}, ${secondLine}`
  }

  if (firstLine) {
    return firstLine
  }

  if (secondLine) {
    return secondLine
  }

  return ``
}

/**
 * formats the GQL address fragment into a single string
 */
export const formatAddress = (address: TFAddress) => {
  const addressLines = formatAddressIntoLines(address)
  return formatAddressFromLines(addressLines)
}

type TPerson = {
  initials?: Nullable<string>
  lastName?: Nullable<string>
  firstName?: Nullable<string>
}

export function formatFullName({ firstName, lastName, initials }: TPerson) {
  const names: string[] = []

  if (firstName) {
    names.push(firstName)
  }

  if (lastName) {
    names.push(lastName)
  }

  if (initials !== undefined) {
    const userInitials = initials || '–_–'
    names.push(`(${userInitials})`)
  }

  return names.join(' ')
}

export function formatBrandName(brand: TFCompanyBranch['brand']) {
  return brand.name
}

export function formatBranchName(branch: TFCompanyBranch) {
  return branch.name
}

export function formatBranchNameWithBrand(branch: TFCompanyBranch) {
  return `${branch.name} [${branch.brand.name}]`
}

function groupByThreeChars(value: string) {
  return value.match(/\S{1,3}(?=(\S{3})*$)/g)
}

export function formatPhone(phoneNumber?: string) {
  if (phoneNumber === undefined) {
    return null
  }

  const groupedByThreeCharsArray = groupByThreeChars(phoneNumber)

  if (!groupedByThreeCharsArray || groupedByThreeCharsArray.length === 0) {
    return null
  }

  const firstGroup = groupedByThreeCharsArray[0]
  const hasMoreGroups = groupedByThreeCharsArray.length > 1

  if (firstGroup.length < 2 && hasMoreGroups) {
    // if the array has more than one group and the 1st group is small, join it with the second group (eg. + 420 -> +420)
    const secondGroup = groupedByThreeCharsArray[1]
    const combinedGroup = `${firstGroup}${secondGroup}`

    // remove the first two groups and add the combined one instead
    groupedByThreeCharsArray.shift()
    groupedByThreeCharsArray.shift()
    groupedByThreeCharsArray.unshift(combinedGroup)
  }

  // the space between groups is intentionally a non-breaking space
  return groupedByThreeCharsArray.join(' ')
}

/**
 * masks the phone number with asterisks,
 * keeps only the last 3 digits,
 * formats the masked phone number
 *
 * @example
 * maskPhone('123456789') // => '*** *** 789'
 */
export const maskPhone = (phone: string) => {
  const maskedPhone = phone.replace(/\d(?=\d{3})/g, '*')

  return formatPhone(maskedPhone)
}
