import { observable } from 'mobx'

const isOnline = observable.box(navigator.onLine)

window.addEventListener('offline', () => isOnline.set(false))
window.addEventListener('online', () => isOnline.set(true))

export const isNetworkOnline = () => isOnline.get()
export const isNetworkOffline = () => !isOnline.get()

export function whenGoingOnline(cb: () => void) {
  return isOnline.observe(change => {
    if (change.oldValue === false && change.newValue === true) {
      cb()
    }
  })
}
