import styled from 'styled-components/macro'

import * as React from 'react'

import { Icon } from '../../../atoms/Icon'
import { TColorKey, THEME } from '../../../designSystem'
import { getContraColor } from '../../../helpers/styles/colorAdjustments'
import { cn } from '../../../libs/classnames'

type TStyledProps = {
  badgeBgColor: string
}

type TProps = NoChildren & {
  className?: string
  badgeCount?: number
  badgeBgColor?: TColorKey
  icon: PropsOf<typeof Icon>['icon']
  onClick?: PropsOf<typeof StyledIndicator>['onClick']
}

const StyledIndicator = styled.div<TStyledProps>`
  position: relative;

  &.clickable {
    cursor: pointer;
  }

  .badge {
    position: absolute;
    top: -6px;
    right: -12px;

    width: 1.25rem;
    height: 1.25rem;

    display: grid;
    place-items: center;

    font-size: 0.75rem;
    border-radius: 50vh;

    background: ${({ badgeBgColor }) => badgeBgColor};
    color: ${({ badgeBgColor }) => getContraColor(badgeBgColor)};
  }
`

/**
 * status bar icon that can show a number as a badge,
 * clickable state is bound to the onClick prop
 */
export const StatusBarIcon: React.FC<TProps> = ({
  icon,
  onClick,
  className,
  badgeCount = null,
  badgeBgColor = `secondary`,
}) => {
  return (
    <StyledIndicator
      onClick={onClick}
      badgeBgColor={THEME[badgeBgColor].normal}
      className={cn(className, { clickable: Boolean(onClick) })}
    >
      <Icon fontSize={1.2} icon={icon} />
      {badgeCount !== null && <div className="badge">{badgeCount}</div>}
    </StyledIndicator>
  )
}
