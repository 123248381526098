import * as React from 'react'

import { AcknowledgeExternalDeliveryFailModal } from '../components/AcknowledgeExternalDeliveryFailModal'
import { TActiveExternalDelivery } from '../types/externalDelivery.types'
import { getExternalDeliveryStatusCategory } from '../utils/getExternalDeliveryStatusCategory'

/**
 * Returns a function wrapping logic
 * that opens a modal to acknowledge a failed external delivery state.
 * The modal is returned as well and can be rendered in the component.
 */
export const useAcknowledgeExternalDeliveryFail = (
  delivery: TActiveExternalDelivery,
) => {
  const [modalElement, setModalElement] = React.useState<React.ReactNode>(null)

  const acknowledgeFail = React.useCallback(() => {
    const statusCategory = getExternalDeliveryStatusCategory(delivery)

    if (statusCategory !== 'FAILED') {
      return
    }

    const modal = React.createElement(AcknowledgeExternalDeliveryFailModal, {
      delivery,
      isOpen: true,
      onClose: () => setModalElement(null),
    })

    setModalElement(modal)
  }, [delivery])

  return { modalElement, acknowledgeFail }
}
