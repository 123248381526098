import localforage from 'localforage'
import {
  applySnapshot,
  getSnapshot,
  getType,
  IStateTreeNode,
  onSnapshot,
} from 'mobx-state-tree'

import { appConfig } from '../config'
import { logPersistence } from './logger'

const persistenceKey = appConfig.persistenceKey

export async function persistModel(model: IStateTreeNode) {
  const modelName = getType(model).name
  const stateKey = `${persistenceKey}_${modelName}`

  const writeState = (snapshot: object) =>
    localforage.setItem(stateKey, snapshot)

  try {
    const state = await localforage.getItem(stateKey)

    if (state !== null) {
      applySnapshot(model, state)
      logPersistence('restored state %s', stateKey)
    } else {
      await writeState(getSnapshot(model))
      logPersistence('initialized state %s', stateKey)
    }
  } catch (err) {
    logPersistence('persistence failed', err)
    localforage.removeItem(stateKey)
  }

  return onSnapshot(model, writeState)
}
