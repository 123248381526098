import styled from 'styled-components/macro'

import {
  FONT_WEIGHTS,
  getMarginBottom,
  getMarginLeft,
  getMarginTop,
  TMargin,
} from '../../../designSystem'
import { Colors } from '../../../styling'

type TProps = {
  marginTop?: TMargin
  marginBottom?: TMargin
  marginLeft?: TMargin
  textAlign?: 'center' | 'left' | 'right'
  fontSize?: string
}

/**
 * text used for section messages, eg. empty list or filter message
 */
export const SectionMessage = styled.p<TProps>`
  color: ${Colors.grey.medium};
  font-size: ${({ fontSize = `1.125rem` }) => fontSize};
  font-weight: ${FONT_WEIGHTS.medium};
  text-align: ${({ textAlign = `left` }) => textAlign};

  margin-top: ${({ marginTop = 's8' }) => getMarginTop({ marginTop })};
  margin-left: ${({ marginLeft = 's0' }) => getMarginLeft({ marginLeft })};
  margin-bottom: ${({ marginBottom = 's0' }) =>
    getMarginBottom({ marginBottom })};

  @media print {
    color: ${Colors.black.pure};
  }
`
