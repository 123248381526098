import { Trans } from '@lingui/macro'
import * as React from 'react'
import styled from 'styled-components/macro'

import { GridBox } from '../../../atoms/Box/Box'
import { getSpaceValue } from '../../../designSystem'
import { useMAcknowledgeExternalDeliveryFail } from '../../../graph/generated'
import { getOrderNumber } from '../../../helpers/order/getOrderNumber'
import { Modal } from '../../../ui/Modal'
import { Bold } from '../../../ui/Typography'
import { TActiveExternalDelivery } from '../types/externalDelivery.types'

type TProps = NoChildren & {
  isOpen: boolean
  onClose: () => void
  delivery: TActiveExternalDelivery
}

const StyledList = styled.ul`
  list-style: disc;
  padding-left: ${getSpaceValue(`s24`)};
  margin-top: ${getSpaceValue(`s8`)};
`

export const AcknowledgeExternalDeliveryFailModal: React.FC<TProps> = ({
  isOpen,
  onClose,
  delivery,
}) => {
  const [mAcknowledge] = useMAcknowledgeExternalDeliveryFail()

  const deliveryId = delivery.id
  const orderNumber = getOrderNumber(delivery.order)

  const acknowledgeFail = React.useCallback(() => {
    mAcknowledge({ variables: { deliveryId } })
    onClose()
  }, [mAcknowledge, deliveryId, onClose])

  return (
    <Modal.Wrapper size={`sm`} open={isOpen} onClose={onClose}>
      <Modal.Header>
        <Modal.Title marginBottom={`s0`}>
          <Trans>External delivery has be cancelled</Trans>
        </Modal.Title>
      </Modal.Header>

      <Modal.Content>
        <GridBox gridGap={getSpaceValue(`s20`)}>
          <section>
            <Trans>
              The external delivery bound to order <Bold>n. {orderNumber}</Bold>{' '}
              is no longer active. If you did not request its termination it
              might have been canceled by the delivery service or some error
              occurred in its process.
            </Trans>
          </section>

          <ul>
            <li>
              <Bold>
                {' '}
                <Trans>Delivery provider</Trans>:
              </Bold>{' '}
              {delivery.provider.label}
            </li>
            <li>
              <Bold>
                <Trans>Final status</Trans>:
              </Bold>{' '}
              {delivery.status.label}
            </li>
            <li>
              <Bold>
                <Trans>Reason</Trans>:
              </Bold>{' '}
              {delivery.failReason}
            </li>
          </ul>

          <section>
            <Bold>
              <Trans>These are some of the actions you can take next</Trans>:
            </Bold>

            <StyledList>
              <li>
                <Trans>
                  create new external delivery request and try again
                </Trans>
              </li>
              <li>
                <Trans>deliver the order by other means</Trans>
              </li>
              <li>
                <Trans>cancel the order</Trans>
              </li>
            </StyledList>
          </section>
        </GridBox>
      </Modal.Content>

      <Modal.Footer>
        <Modal.Actions columns={`1fr`}>
          <Modal.Button type={`confirm`} onClick={acknowledgeFail}>
            <Trans>Understood</Trans>
          </Modal.Button>
        </Modal.Actions>
      </Modal.Footer>
    </Modal.Wrapper>
  )
}
