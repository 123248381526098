import { SvgIconProps } from '@material-ui/core/SvgIcon'
import * as React from 'react'
import styled from 'styled-components/macro'

const SCALE = `0.8em`

const StyledIcon = styled.div`
  width: 1em;
  height: 1em;
  display: grid;
  place-items: center;
  color: inherit;
`

// unusual typing to comply with the MUI icons

/**
 * icon for wolt external partner,
 * intended to be rendered on wolt brand color background
 */
export const IconWolt = (props: SvgIconProps): JSX.Element => {
  return (
    <StyledIcon>
      <svg viewBox="0 0 65.595055 57.610451" height={SCALE} width={SCALE}>
        <defs id="defs2" />
        <g transform="translate(-17.376694,-162.29172)">
          <path
            d="m 26.760741,219.79883 c -2.533006,-0.24958 -4.962995,-0.75383 -5.86612,-1.21729 -1.541296,-0.79096 -2.173783,-2.4675 -3.034509,-8.04359 -0.576637,-3.73567 -0.655808,-15.96938 -0.137836,-21.29896 0.789443,-8.12286 2.369297,-17.51209 3.262255,-19.38793 0.718303,-1.50893 1.873311,-2.3374 3.936761,-2.82375 1.759156,-0.41463 6.244503,-0.55244 6.760777,-0.20771 0.823394,0.5498 0.901524,1.31695 0.388209,3.81176 -2.603351,12.65277 -3.776332,25.33744 -3.209065,34.70297 0.471665,7.78717 0.325185,7.63234 2.646931,2.79776 4.704126,-9.79543 10.585906,-22.73422 13.68715,-30.10911 2.567487,-6.1056 3.07617,-7.03049 4.105505,-7.46467 0.580287,-0.24477 1.914641,-0.36932 3.956643,-0.36932 4.581739,0 4.297936,-0.53357 4.081245,7.67291 -0.332383,12.58777 -0.341815,13.77717 -0.142711,17.99167 0.501849,10.62272 2.282455,16.27188 5.128877,16.27188 0.70794,0 2.285333,-1.63018 3.050013,-3.15208 1.803898,-3.59018 3.00804,-8.0458 4.081944,-15.10417 1.117632,-7.34576 1.333754,-10.69572 1.345729,-20.85916 l 0.01132,-9.61437 0.624623,-0.50595 c 0.473065,-0.38318 1.11506,-0.52091 2.645834,-0.56762 5.419592,-0.16538 7.528155,0.32471 8.41857,1.95672 0.405511,0.74324 0.479574,1.4906 0.467704,4.71954 -0.01601,4.35368 -0.362913,7.54606 -1.48073,13.62605 -3.361663,18.28462 -10.310278,32.59298 -17.549603,36.13756 -1.709283,0.83691 -2.034159,0.9041 -4.371189,0.9041 -2.317472,0 -2.669083,-0.0711 -4.284324,-0.86627 -4.243832,-2.08923 -6.629977,-6.74375 -7.873008,-15.35742 -0.287324,-1.99103 -0.604234,-5.14418 -0.704241,-7.007 -0.10001,-1.86282 -0.265923,-3.29157 -0.368697,-3.175 -0.102775,0.11657 -1.171313,2.35507 -2.37453,4.97444 -4.370283,9.51402 -8.984005,18.39326 -10.289182,19.80184 -1.493744,1.61209 -3.429799,2.1055 -6.914352,1.76217 z"
            style={{ fill: `#ffffff`, strokeWidth: `0.264583` }}
          />
        </g>
      </svg>
    </StyledIcon>
  )
}
