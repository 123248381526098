import { FlatSelect } from '../FlatSelect'
import { MinuteSelectCustomInput } from './components/MinuteSelectCustomInput'
import { MinuteSelectOptions } from './components/MinuteSelectOptions'
import { MinuteSelectProvider } from './contexts/MinuteSelectProvider'

/**
 * MinuteSelect is a slightly specialized version of FlatSelect that is used
 * wherever we need to select minutes as a number from predefined options.
 */
export const MinuteSelect = {
  Provider: MinuteSelectProvider,
  Options: MinuteSelectOptions,
  CustomInput: MinuteSelectCustomInput,
  Option: FlatSelect.Option,
}
