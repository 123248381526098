import { Observer } from 'mobx-react-lite'
import * as React from 'react'

import { useRoot } from '../contexts/RootProvider'
import { TRootModel } from '../models/RootModel'

type TProps = NoChildren & {
  observe?: boolean
  render(root: TRootModel): React.ReactNode
}

export const WithRoot: React.FC<TProps> = ({ render, observe = true }) => {
  const root = useRoot()

  if (observe === true) {
    return <Observer>{() => <>{render(root)}</>}</Observer>
  }

  return <>{render(root)}</>
}
