import * as React from 'react'
import { Route } from 'react-router-dom'

import { logRouting } from '../helpers/logger'

type TProps = NoChildren

export const LogRoute: React.FC<TProps> = () => {
  if (process.env.NODE_ENV === 'development') {
    return (
      <Route>
        {({ location }) => {
          logRouting(`at ${location.pathname}`)
          return null
        }}
      </Route>
    )
  } else {
    return null
  }
}
