import { Instance, types } from 'mobx-state-tree'

import { TQCancelReasons } from '../../../graph/generated'
import { OrderListModel } from './OrderListModel'

type TCancelReason = TQCancelReasons['cancelReasons'][number]

type TVolatileProps = {
  cancelReasons: Nullable<RoA<TCancelReason>>
}

export const OrderModel = types
  .model('Order', {
    list: types.optional(OrderListModel, {}),
  })

  .volatile<TVolatileProps>(() => ({
    cancelReasons: null,
  }))

  .actions(self => ({
    setCancelReasons(reasons: RoA<TCancelReason>) {
      self.cancelReasons = reasons
    },
  }))

  .actions(self => ({
    invalidate() {
      self.list.clearBranchFilter()
    },
  }))

export interface TOrderModel extends Instance<typeof OrderModel> {}
