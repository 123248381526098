import styled from 'styled-components/macro'

import { cn } from '-libs/classnames'
import { SvgIconComponent } from '@material-ui/icons'
import * as React from 'react'

import { logError } from '../helpers/logger'
import { Colors, Spacing } from '../styling'

type TProps = Children & {
  color?: string
  onClick?(): void
  fontSize?: number | 'inherit'
  className?: string
  fontSizeUnit?: string
  icon?: SvgIconComponent
  title?: string
}

const StyledIcon = styled.span<TProps>`
  display: flex;

  color: ${({ color }) => color};

  > *,
  > svg {
    font-size: ${({ fontSize, fontSizeUnit }) =>
      fontSize === 'inherit' ? fontSize : `${fontSize}${fontSizeUnit}`};
  }

  > * {
    margin: auto;
  }

  &.clickable {
    cursor: pointer;
  }
`

export const Icon = React.memo<TProps>(
  ({
    icon,
    onClick,
    children,
    className,
    fontSize = 1.5,
    color = Colors.white.main,
    fontSizeUnit = Spacing.DefaultSizeUnit,
  }) => {
    if (icon && children) {
      // tslint:disable-next-line: no-console
      logError(
        `Icon: Do not use both icon prop & children! Only one of them is allowed.`,
      )

      return null
    }

    const iconNode = icon
      ? React.createElement(icon, { fontSize: 'inherit' })
      : children

    return (
      <StyledIcon
        color={color}
        onClick={onClick}
        fontSize={fontSize}
        fontSizeUnit={fontSizeUnit}
        className={cn(className, { clickable: Boolean(onClick) })}
      >
        {iconNode}
      </StyledIcon>
    )
  },
)
