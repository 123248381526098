import { SvgIconProps } from '@material-ui/core/SvgIcon'
import * as React from 'react'
import styled from 'styled-components/macro'

import { IconCard } from '../'
import { getRadiusValue } from '../../../designSystem'

const StyledIcon = styled.div`
  width: 1em;
  height: 1em;
  color: inherit;
  position: relative;

  /* strike through */
  &::after {
    content: '';
    position: absolute;

    left: -0.05em;
    right: -0.175em;
    top: 50%;
    height: 0.2em;

    rotate: -37deg;
    transform: translateY(-70%);

    border-radius: ${getRadiusValue(`pill`)};
    /* use icon color as the strike color */
    background: currentColor;
    /* take strike border color from the closest bg color variable */
    border: 0.07em solid var(--bg-color);
  }
`

// unusual typing to comply with the MUI icons

/**
 * renders payment type icon for prepaid orders,
 * basically a card icon with a strike through it,
 * the strike border should be set to icon bg
 * and can be set with `--bg-color` css variable
 */
export const IconPaidByCard = (props: SvgIconProps): JSX.Element => {
  return (
    <StyledIcon>
      <IconCard {...props} />
    </StyledIcon>
  )
}
