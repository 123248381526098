import styled from 'styled-components/macro'

import {
  FONT_WEIGHTS,
  getMarginBottom,
  getMarginTop,
  TMargin,
} from '../../../designSystem'

type TProps = {
  marginTop?: TMargin
  marginBottom?: TMargin
}

/**
 * H3 used for sub-section headlines.
 */
export const SubSectionHeadline = styled.h3<TProps>`
  font-size: 1.125rem;
  line-height: 1.2;
  font-weight: ${FONT_WEIGHTS.medium};

  margin-top: ${({ marginTop = 's0' }) => getMarginTop({ marginTop })};
  margin-bottom: ${({ marginBottom = 's8' }) =>
    getMarginBottom({ marginBottom })};
`
